<div class="snb_change_password" [ngClass]="{'document-loaded' : intialScreenAnimationClass}">
  <div class="change_pw">
    <form class="form" #fpForm="ngForm" autocomplete="off">
      <div class="form__cover"></div>
      <div class="form__loader">
        <div class="spinner active">
          <svg class="spinner__circular" viewBox="25 25 50 50">
            <circle class="spinner__path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"></circle>
          </svg>
        </div>
      </div>
      <div class="form__content">
        <div class="top_sec">
          <p>
            <!-- <span class="animated fadeInUp" *ngIf="loginForm.value.loginAsForeman=='false'">ROC</span>
                <span  class="animated fadeInUp" *ngIf="loginForm.value.loginAsForeman=='true'">Foreman</span> -->
          </p>

          <h2>Change Password</h2>
        </div>
        <div class="styled-input">

          <mat-form-field appearance="outline" class="md-block snb-form-field-without-icon">
            <mat-label>New Password</mat-label>
            <input matInput type="password" name="password" pattern=".{5,30}"  [(ngModel)]="user.password"
              required #password="ngModel">

          </mat-form-field>

        </div>
        <div class="styled-input">
          <mat-form-field appearance="outline" class="md-block snb-form-field-without-icon">
            <mat-label>Re-type Password</mat-label>
            <input matInput type="password" name="confirmPassword" [(ngModel)]="user.confirmPassword"
              required #confirmPassword="ngModel">
            <mat-hint [ngStyle]="{'color': 'red'}" [hidden]="user.confirmPassword == user.password || !user.confirmPassword"
              align="start">
              Passwords does not match!</mat-hint>
          </mat-form-field>
        </div>
        <button mat-raised-button type="submit" [disabled]="!fpForm.valid || user.confirmPassword != user.password"
          (click)="createPassword(fpForm)" class="snb-raised-button">
          Submit
        </button>
      </div>

    </form>
  </div>
</div>