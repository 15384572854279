<div class="dialog-wrapper">
    <div class="dialog-title">
        <span *ngIf="data.foremanUser && data.userType=='ADMIN'">Admin's Information</span>
        <span *ngIf="data.foremanUser && data.userType=='DIRECTOR'">{{'foremanCompany.new.directorDetails' |
            translate}}</span>
        <span *ngIf="data.foremanUser && data.userType=='CEO'">{{'foremanCompany.new.ceoDetails' | translate}}</span>
        <span *ngIf="data.foremanUser && !data.userType">{{'foremanCompany.new.otherForemen' | translate}}</span>
        <span *ngIf="data.bankAndAuditor && data.userType=='BANKER'">{{'foremanCompany.new.banker' | translate}}</span>
        <span *ngIf="data.bankAndAuditor && data.userType=='AUDITOR'">{{'foremanCompany.new.auditor' | translate}}</span>
        <span *ngIf="data.foremanUser && data.userType=='ADVOCATE'">{{'foremanCompany.new.advocate' | translate}}</span>
        <span *ngIf="data.foremanUser && data.userType=='EMPLOYEE'">{{'foremanCompany.new.employeeInfo' | translate}}</span>
        <button (click)='closeDialog()' type="button" style="display: contents;">
            <span><i class="material-icons close-btn">close</i></span>
        </button>

    </div>
    <div class="dialog-content-wrapper">
        <form [formGroup]="foremanUser" *ngIf="data.foremanUser" novalidate
            (ngSubmit)="submitUserData(foremanUser, $event, 'save')" autocomplete="off">
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'party.basicDetails.title' | translate}}</mat-label>
                        <mat-select formControlName="title" matInput
                            required>                           
                            <mat-option value="Mr.">{{'foremanCompany.title.mr' | translate}}</mat-option>
                            <mat-option value="Mrs.">{{'foremanCompany.title.mrs' | translate}}</mat-option>
                            <mat-option value="Ms.">{{'foremanCompany.title.ms' | translate}}</mat-option>
                            <mat-option value="Miss">{{'foremanCompany.title.miss' | translate}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="foremanUser.controls.title.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'party.basicDetails.firstName' | translate}}</mat-label>
                        <input type="text" formControlName="firstName" matInput
                         required>
                        <mat-error *ngIf="foremanUser.controls.firstName.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'party.basicDetails.lastName' | translate}}</mat-label>
                        <input type="text" formControlName="lastName" matInput
                            required>
                        <mat-error *ngIf="foremanUser.controls.lastName.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6" formGroupName="birthDetails">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'party.basicDetails.dob' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" (click)="picker.open();"
                                (focus)="picker.open();" format-date formControlName="dateOfBirth">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'party.basicDetails.designation' | translate}}</mat-label>
                        <mat-select formControlName="designation" [disabled]="true" matInput
                            required>
                            <mat-option *ngFor="let designation of designationTypes" [value]="designation">
                                {{ designation }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="foremanUser.controls.designation.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'party.basicDetails.suffix' | translate}}</mat-label>
                        <input type="text" formControlName="suffix" matInput>
                        <mat-hint align="start">
                            (Example B.A. LLB, BE etc..)
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div formGroupName="contactDetails">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>{{'party.basicDetails.email' | translate}}</mat-label>
                                <input type="email" required formControlName="email" matInput>
                                <mat-error
                                    *ngIf="foremanUser.get('contactDetails')['controls'].email.value && foremanUser.get('contactDetails')['controls'].email.errors?.pattern"
                                    jhiTranslate="entity.validation.email"></mat-error>
                                <mat-error *ngIf="foremanUser.get('contactDetails')['controls'].email.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>{{'party.basicDetails.mobileNumber' | translate}}</mat-label>
                                <input type="tel" required formControlName="mobileNumber" matInput>
                                <mat-error
                                    *ngIf="foremanUser.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                                <mat-error
                                    *ngIf="foremanUser.get('contactDetails')['controls'].mobileNumber.value && foremanUser.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                    jhiTranslate="entity.validation.mobile"></mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12" formArrayName="addresses" class="snb-form-field-without-icon">
                            <cm-address addressPlaceHolder="Address"
                                [group]="foremanUser?.controls?.contactDetails?.controls?.addresses?.controls[0]"
                                [addressValue]="data?.data?.contactDetails?.addresses?.length ? data?.data?.contactDetails?.addresses[0]: {}"></cm-address>
                        </div>
                    </div>
            </div>

            <button mat-raised-button type="submit" class="btn-group snb-raised-button snb_btn_submit"
                [disabled]="isFormSubmitted" style="float: right;" *ngIf="data?.create">
                <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;"
                    class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </button>

            <button mat-raised-button class="btn-group snb-raised-button snb_btn_submit" style="float: right;"
                [disabled]="isFormSubmitted" *ngIf="data?.update"
                (click)="submitUserData(foremanUser, $event, 'update')">
                <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;"
                    class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </button>
            <button mat-raised-button class="btn-group" [mat-dialog-close]="true"
                style="margin-right: 10px;float: right;border-radius: 30px;">
                <span>Cancel</span>
            </button>
        </form>

        <form [formGroup]="bankAndAuditors" *ngIf="data.bankAndAuditor" novalidate
            (ngSubmit)="saveBankerAndAuditor(bankAndAuditors, $event, 'save')" autocomplete="off">
            <div>
                <div class="snb_single_col">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label *ngIf="data.userType=='BANKER'">{{'foremanCompany.new.displayName' | translate}}</mat-label>
                        <input snb-start-case type="text" formControlName="name" matInput
                            required *ngIf="data.userType=='BANKER'">
                            <mat-hint align="start" *ngIf="data.userType=='BANKER'">
                                Give a unique name
                            </mat-hint>
                        <mat-label *ngIf="data.userType=='AUDITOR'">{{'foremanCompany.new.auditorName' | translate}}</mat-label>    
                        <input type="text" formControlName="name" matInput
                             required *ngIf="data.userType=='AUDITOR'">
                        <mat-error *ngIf="bankAndAuditors.controls.name.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="snb_single_col" *ngIf="data.userType=='BANKER'">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.bankName' | translate}}</mat-label>
                        <input snb-start-case type="text" formControlName="bankName" matInput
                            required
                            *ngIf="data.userType=='BANKER'">
                        <mat-error *ngIf="bankAndAuditors.controls.bankName.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div formGroupName="address" *ngIf="data.userType=='BANKER'">
                    <cm-address addressPlaceHolder="Bank Address" [group]="bankAndAuditors.controls.address"
                        [addressValue]="data?.data?.address"></cm-address>
                </div>
                <div formGroupName="address" *ngIf="data.userType=='AUDITOR'">
                    <cm-address addressPlaceHolder="Auditor Address" [group]="bankAndAuditors.controls.address"
                        [addressValue]="data?.data?.address"></cm-address>
                </div>
                <div class="snb_single_col" *ngIf="data.userType=='BANKER'">
                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                        <mat-label>Foreman Branch</mat-label>
                        <mat-select formControlName="foremanCompanyBranchCode" matInput required>
                            <mat-option *ngFor="let branch of foremanBranches" [value]="branch.branchCode">{{branch.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="bankAndAuditors.controls.foremanCompanyBranchCode.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
            </div>
                <div *ngIf="data.userType=='BANKER'">
                    <p class="text-left" style="font-weight: bolder;">Account Details:</p>
                    <hr style="margin-top: 5px; margin-bottom: 5px;">
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.bankAccountUsageType' | translate}}</mat-label>
                            <mat-select formControlName="bankAccountUsageType" placeholder="{{'foremanCompany.new.bankAccountUsageType' | translate}}" matInput (blur)="onAccountDetailsTouched()">
                                <mat-option value="COLLECTION">Collection(1st Account)</mat-option>
                                <mat-option value="SEPARATE">Separate(2nd Account)</mat-option>

                            </mat-select>
                            <mat-error *ngIf="bankAndAuditors.controls.bankAccountUsageType.hasError('required')"
                                jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label *ngIf="data.userType=='BANKER'">{{'foremanCompany.new.acctHolderName' | translate}}</mat-label>
                                    <input snb-start-case type="text" formControlName="acctHolderName" matInput
                                        *ngIf="data.userType=='BANKER'"
                                        required>
                                    <mat-error *ngIf="bankAndAuditors.controls.acctHolderName.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-6">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label *ngIf="data.userType=='BANKER'">{{'foremanCompany.new.acctNumber' | translate}}</mat-label>
                                    <input snb-start-case type="text" formControlName="acctNumber" matInput
                                        *ngIf="data.userType=='BANKER'"
                                        required>
                                    <mat-error *ngIf="bankAndAuditors.controls.acctNumber.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>{{'foremanCompany.new.accountType' | translate}}</mat-label>
                                    <mat-select formControlName="accountType" matInput (blur)="onAccountDetailsTouched()">
                                        <mat-option value="SAVINGS">Savings</mat-option>
                                        <mat-option value="CURRENT">Current</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="bankAndAuditors.controls.accountType.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label *ngIf="data.userType=='BANKER'">{{'foremanCompany.new.ifscCode' | translate}}</mat-label>
                                <input type="text" snb-start-case formControlName="ifscCode" matInput
                                    *ngIf="data.userType=='BANKER'">
                                <mat-error *ngIf="bankAndAuditors.controls.ifscCode.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="picker1" [max]="maxDate" (click)="picker1.open();"
                                    (focus)="picker1.open();" format-date formControlName="startDate" required>
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="bankAndAuditors?.controls?.startDate?.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                    </div>
                    </div>
                </div>
                <button mat-raised-button type="submit" class="btn-group snb-raised-button snb_btn_submit" style="float: right;"
                    [disabled]="isFormSubmitted" *ngIf="data?.create">
                    <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;"
                        class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>

                <button mat-raised-button class="btn-group snb-raised-button snb_btn_submit" style="float: right;"
                    [disabled]="isFormSubmitted" *ngIf="data?.update"
                    (click)="saveBankerAndAuditor(bankAndAuditors, $event, 'update')">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;"
                        class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>
                <button mat-raised-button class="btn-group" [mat-dialog-close]="true"
                    style="margin-right: 10px;float: right;border-radius: 30px;">
                    <span>Cancel</span>
                </button>

            </div>
        </form>
    </div>
</div>
