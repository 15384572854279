import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, Input} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommentMetaData } from "./comment-meta-data.model";
import { SnBToasterService } from "../components/toaster/toaster.service";


@Component({
    selector: "comment-component",
    templateUrl: "./comment.component.html",
    styleUrls: ['./commentBox.scss']
})

export class CommentComponent implements OnInit{
    @Input() isRequestInProgress: boolean = false;
    @Input() metaData: CommentMetaData;
    @Output() commentData = new EventEmitter<Object>();
    @ViewChild('myTextarea') myTextarea: ElementRef;
    
    commentsFormGroup: FormGroup;
    attachments: any = [];

    constructor(
        private _formBuilder: FormBuilder,
        public toasterService: SnBToasterService
    ) {
        
    }    

    ngOnInit(): void {
        this.commentsFormGroup = this._formBuilder.group({
            comment: ['', Validators.required],    
        });
        // Auto-focus enabled when the component is initialized.
        this.myTextarea.nativeElement.focus();
    }

    emitFileData(fileData) {
        const self = this;
        Object.keys(fileData).map(function (file) {
            if(!self.attachments) {
                self.attachments = [];
            }
            if(fileData[file] && fileData[file].created) {
                self.attachments[file] = fileData[file];
            }
        })
    }

    submitComment(commentData:any,event?: Event) {
        event.preventDefault();
        // Creating a comment Object having Two fields Both Comment Data and Attachment 
        let commentObj = {
            data : commentData,
            attachment : this.attachments['commentsAttachment'] ? this.attachments['commentsAttachment'] : null ,
        };
        if (!this.commentsFormGroup.get('comment').valid) {
            this.commentsFormGroup.get('comment').markAsTouched();
            return;
        }
        this.toasterService.showSuccessToaster("New Comment added to comment section !");
        this.commentData.emit(commentObj);
        // After emitting the data resetting the fields initial state
        this.commentsFormGroup.controls['comment'].reset();
        this.attachments = [];
    }
}