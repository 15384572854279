<div style="padding-top: 25px !important; padding-bottom: 20px !important" *ngIf="taskData && !bulkMode">
    <div class="row no-margin no-padding">
        <h6> Application Fee Details</h6>
        <form [formGroup]="paymentsForm" novalidate autocomplete="off">

            <div class="no-margin">
                <div class="transaction_row clearfix row">
                    <div class="col-md-6">
                        <div class="fee_label">
                            Fee :

                            <span *ngIf="!taskData?.processVariables?.paymentInfo">
                                <i class="fa fa-rupee"></i>
                                {{taskData?.processVariables?.applicationFee?.toLocaleString('en-IN')}}
                            </span>

                            <span *ngIf="taskData?.processVariables?.paymentInfo?.amount">
                                <i class="fa fa-rupee"></i>
                                {{taskData?.processVariables?.paymentInfo?.amount?.toLocaleString('en-IN')}}
                            </span>

                        </div>
                    </div>
                    <div class="col-md-6 text-right">
                        <div class="fee_value" *ngIf="!addOfflinePaymentSubmit">
                            <span *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone"
                                class="fee_paid yes">Paid</span>
                            <span *ngIf="taskData && !taskData?.processVariables?.paymentInfo"
                                class="fee_paid no">Pending</span>
                        </div>
                        <div class="fee_value" *ngIf="addOfflinePaymentSubmit">
                            <span>Processing <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                    style="margin-left: 10px; font-size: 16px;"></i></span>
                        </div>
                    </div>
                     <div class="col-md-12" style="padding-top: 15px;font-size: 12px;"
                        *ngIf="taskData && !taskData?.processVariables?.paymentInfo && !hideOfflinePaymentDetails">
                        <p style="font-weight: 400;color: #6e6e6e;">
                            If the money got debited from Foreman account and still Application shows Pending status,
                            click on Reconcile Transaction Button to update the payment status of Application.
                            <span class="recons_btn" (click)='reconcileByApplication(taskData?.processInstanceId)'>
                                Reconcile Payment
                                <i class="fa fa-refresh" aria-hidden="true"
                                    [ngClass]="{'fa-spin' : paymentReconcileInProcess}"></i>
                                    
                            </span>
                        </p>

                    </div>
                </div>
                <div class="col-md-12 no-padding"
                    *ngIf="taskData && (taskData?.processVariables?.paymentInfo && (taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'PAY' && taskData?.processVariables?.paymentInfo?.pspType == 'TWALLET'))">
                    <div class="col-md-12 no-padding">
                        <div class="col-md-6 no-padding">
                            <strong>Paid Online via Twallet </strong>
                        </div>
                        <div class="col-md-6 no-padding">
                            Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                                [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                        </div>
                    </div>
                    <div class="col-md-12 no-padding" style="margin-top:2%;">
                        Amount: <i class="fa fa-rupee"></i> {{taskData?.processVariables?.paymentInfo?.amount}}
                    </div>
                </div>

                <div class="col-md-12 no-padding" [hidden]="hideOfflinePaymentDetails"
                    *ngIf="isForeman && taskData && ((taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'OFFLINE_PAY'))">
                    <div class="col-md-12 no-padding row">
                        <div class="col-md-6 no-padding"
                            *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                            <strong>Paid in Cash/Cheque </strong>
                        </div>
                        <div class="col-md-6 no-padding"
                            *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                            Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                                [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 no-padding" [hidden]="hideOfflinePaymentDetails"
                    *ngIf="!isForeman && taskData && (!taskData?.processVariables?.paymentInfo || (taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'OFFLINE_PAY'))">
                    <div class="col-md-12 no-padding row">
                        <!-- (environmentType === 'production') -->
                        <mat-checkbox class="col-md-6 no-padding"
                            [hidden]="(!(taskData && !taskData?.processVariables?.paymentInfo)) || isUser"
                            formControlName="isPaidOfflineMode" (change)="showOfflinePaymentOptions($event)">
                            <strong>Paid in Cash/Cheque/T App Folio</strong>
                        </mat-checkbox>
                        <div class="col-md-6 no-padding"
                            *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                            <strong>Paid in Cash/Cheque </strong>
                        </div>
                        <div class="col-md-6 no-padding"
                            *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                            Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                                [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                        </div>
                    </div>
                    <div class="row"*ngIf="paymentsForm?.value?.isPaidOfflineMode" style="padding-top:2%;">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="snb-form-field-without-icon no-padding" style="padding-right:10px !important">
                                <mat-label>Amount</mat-label>
                                <input type="tel" format-inr-currency matInput
                                formControlName="paymentAmount" required />
                                <mat-error *ngIf="paymentsForm.controls.paymentAmount.hasError('required')"
                                jhiTranslate="entity.validation.required"></mat-error>
                                <mat-error *ngIf="paymentsForm.controls.paymentAmount.hasError('minAmountErr')">Amount can
                                not be less than <i class="fa fa-rupee"></i>
                                {{taskData?.processVariables?.applicationFee?.toLocaleString('en-IN')}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline" class="snb-form-field-without-icon no-padding">
                                <mat-label>Reference Id</mat-label>
                                <input matInput formControlName="paymentRefId" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="snb-form-field-without-icon no-padding">
                                <mat-label>Remarks</mat-label>
                                <input matInput formControlName="paymentDesc" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="text-center" style="color: green;"
                        *ngIf="!isClosedTask && paymentsForm?.value?.isPaidOfflineMode && paymentDone">
                        Payment added successfully
                    </div>
                    <div class="text-right"
                        *ngIf="(paymentsForm?.value?.isPaidOfflineMode && !taskData?.processVariables?.paymentInfo)"
                        [hidden]="isClosedTask || paymentDone">
                        <button mat-raised-button class="snb_btn_reset" id="btnAddPayment"
                            (click)="payNow(taskData, paymentsForm, $event)" [disabled]="addOfflinePaymentSubmit">
                            <i class="material-icons">credit_card</i> 
                            <span>Add Payment</span>
                            <i *ngIf="addOfflinePaymentSubmit" style="margin-left: 10px; font-size: 20px;"
                                class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </button>
                    </div>
                </div>

                <div class="col-md-12 text-center no-padding" [hidden]="isUser"
                    *ngIf="isForeman && taskData && !taskData?.processVariables?.paymentInfo && !isClosedTask">
                    <mat-form-field appearance="outline" class="snb-form-field-without-icon col-md-12 no-padding">
                        <mat-label>Remarks</mat-label>
                        <input matInput formControlName="trnxDesc" />
                    </mat-form-field>
                </div>

                <div class="snb_form_terms text-left" [hidden]="isUser"
                    *ngIf="isForeman && taskData && !taskData?.processVariables?.paymentInfo && !isClosedTask">
                    <mat-checkbox formControlName="termsAndConditionsAgreed">I agree to comply with
                        <a style="font-weight:bold;" href="http://www.registration.telangana.gov.in/ChitFundAct.pdf"
                            target="_blank">The
                            Chit Fund Act 1982</a>
                    </mat-checkbox>
                    <mat-error *ngIf="!paymentsForm.value.termsAndConditionsAgreed && formSubmitted"
                        jhiTranslate="entity.validation.termsAndConditions"></mat-error>
                </div>
            </div>

        </form>
        <div class="text-right"
            *ngIf="(isForeman || isUser) && taskData && !taskData?.processVariables?.paymentInfo && !isClosedTask">
            <button mat-raised-button class="snb_btn_reset" (click)="payNow(taskData, paymentsForm, $event)">
                <i class="material-icons">credit_card</i> Pay now
            </button>
        </div>
        <app-twallet-auto-submit-form></app-twallet-auto-submit-form>
    </div>
    <div class="text-left" *ngIf="showPaymentPendingError && !paymentDone" style="padding-top:10PX">
        <mat-error jhiTranslate="entity.validation.paymentPendingError"></mat-error>
    </div>
</div>
<div *ngIf="(isForeman || isUser) && !taskData">
    <!-- <div class="text-right">
        <button mat-raised-button class="snb_btn_reset" (click)="MiscPayNow(taskData, paymentsForm, $event)">
            <i class="material-icons">credit_card</i> Pay now
        </button>
    </div> -->
    <app-twallet-auto-submit-form></app-twallet-auto-submit-form>
</div>

<div class="row no-margin no-padding" *ngIf="bulkMode">
    <form [formGroup]="paymentsForm" novalidate autocomplete="off">
        <div class="row no-margin">
            <div class="transaction_row clearfix" style="padding: 0px 0px;">
                <div class="col-md-6" style="padding-left: 0px;">
                    <div class="fee_label">
                        Fee :
                        <span *ngIf="!taskData?.processVariables?.paymentInfo">
                            <i class="fa fa-rupee"></i>
                            {{taskData?.processVariables?.applicationFee?.toLocaleString('en-IN')}}
                        </span>

                        <span *ngIf="taskData?.processVariables?.paymentInfo?.amount">
                            <i class="fa fa-rupee"></i>
                            {{taskData?.processVariables?.paymentInfo?.amount?.toLocaleString('en-IN')}}
                        </span>

                    </div>
                </div>
                <div class="col-md-6 text-right">
                    <div class="fee_value" *ngIf="!addOfflinePaymentSubmit">
                        <span *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone"
                            class="fee_paid yes">Paid</span>
                        <span *ngIf="taskData && !taskData?.processVariables?.paymentInfo && !paymentDone"
                            class="fee_paid no">Pending</span>
                    </div>
                    <div class="fee_value" *ngIf="addOfflinePaymentSubmit">
                        <span>Processing <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                style="margin-left: 10px; font-size: 16px;"></i></span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 no-padding"
                *ngIf="taskData && (taskData?.processVariables?.paymentInfo && (taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'PAY' && taskData?.processVariables?.paymentInfo?.pspType == 'TWALLET'))">
                <div class="col-md-12 no-padding">
                    <div class="col-md-6 no-padding">
                        <strong>Paid Online via Twallet </strong>
                    </div>
                    <div class="col-md-6 no-padding">
                        Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                            [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                    </div>
                </div>
                <div class="col-md-12 no-padding" style="margin-top:2%;">
                    Amount: <i class="fa fa-rupee"></i> {{taskData?.processVariables?.paymentInfo?.amount}}
                </div>
            </div>

            <div class="col-md-12 no-padding" [hidden]="hideOfflinePaymentDetails"
                *ngIf="isForeman && taskData && ((taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'OFFLINE_PAY'))">
                <div class="col-md-12 no-padding"  style="font-size: 11px">
                    <div class="col-md-4 no-padding"
                        *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                        <strong>Paid in Cash/Cheque </strong>
                    </div>
                    <div class="col-md-8 no-padding"
                        *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                        Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                            [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                    </div>
                </div>
            </div>

            <div class="col-md-12 no-padding" [hidden]="hideOfflinePaymentDetails"
                *ngIf="!isForeman && taskData && (!taskData?.processVariables?.paymentInfo || (taskData?.processVariables?.paymentInfo?.trnxType && taskData?.processVariables?.paymentInfo?.trnxType == 'OFFLINE_PAY'))">
                <div class="col-md-12 no-padding">
                    <!-- (environmentType === 'production') -->
                    <mat-checkbox class="col-md-4 no-padding"
                        [hidden]="(!(taskData && !taskData?.processVariables?.paymentInfo && !paymentDone)) || isUser"
                        formControlName="isPaidOfflineMode" (change)="showOfflinePaymentOptions($event)">
                        <strong>Paid in Cash/Cheque/T App Folio</strong>
                    </mat-checkbox>
                    <div class="col-md-4 no-padding"
                        *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                        <strong>Paid in Cash/Cheque/T App Folio </strong>
                    </div>
                    <div class="col-md-8 no-padding"
                        *ngIf="(taskData && taskData?.processVariables?.paymentInfo) || paymentDone">
                        Transaction ID: <a [routerLink]="'/pages/payment/transactions-details'"
                            [queryParams]="{id:taskData?.processVariables?.paymentInfo?.id }">{{taskData?.processVariables?.paymentInfo?.appTrnxRefId}}</a>
                    </div>
                </div>
                <div *ngIf="paymentsForm?.value?.isPaidOfflineMode && !paymentDone" style="padding-top:5%;">
                    <mat-form-field appearance="outline" class="snb-form-field-without-icon no-padding col-md-6" style="padding-right:10px !important">
                        <mat-label>Amount</mat-label>
                        <input type="tel" format-inr-currency matInput
                            formControlName="paymentAmount" required />
                        <mat-error *ngIf="paymentsForm.controls.paymentAmount.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                        <mat-error *ngIf="paymentsForm.controls.paymentAmount.hasError('minAmountErr')">Amount can
                            not be less than <i class="fa fa-rupee"></i>
                            {{taskData?.processVariables?.applicationFee?.toLocaleString('en-IN')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="snb-form-field-without-icon no-padding col-md-6">
                        <mat-label>Reference Id</mat-label>
                        <input matInput formControlName="paymentRefId" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="snb-form-field-without-icon col-md-12 no-padding">
                        <mat-label>Remarks</mat-label>
                        <input matInput formControlName="paymentDesc" />
                    </mat-form-field>
                </div>
                <div class="text-center" style="color: green;"
                    *ngIf="!isClosedTask && paymentsForm?.value?.isPaidOfflineMode && paymentDone">
                    Payment added successfully
                </div>
                <div class="text-right"
                    *ngIf="(paymentsForm?.value?.isPaidOfflineMode && !taskData?.processVariables?.paymentInfo)"
                    [hidden]="isClosedTask || paymentDone">
                    <button mat-raised-button class="snb_btn_reset" id="btnAddPayment"
                        (click)="payNow(taskData, paymentsForm, $event)" [disabled]="addOfflinePaymentSubmit">
                        <i class="material-icons">credit_card</i> Add Payment
                        <i *ngIf="addOfflinePaymentSubmit" style="margin-left: 10px; font-size: 20px;"
                            class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>