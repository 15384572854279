import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { USER_TYPES } from '../../app.constants';
import { SnBToasterService } from '../../shared/components/toaster/toaster.service';
import { FormatInputs } from '../../shared/format-values';
import { ApplicationTypes } from '../applications/application-types';
import { AppTwalletAutoSubmitFormComponent } from './app-twallet-auto-submit-form';
import { PaymentRequest } from './payment-request.model';
import { PaymentService } from './payment.service';
import { TransactionItem } from './transaction-item.model';
import { Transaction, TransactionType } from './transaction.model';
import { AlertService } from '../../blocks/interceptor/alert.service';

@Component({
    selector: 'app-payment-component',
    styleUrls: ['./payment.scss'],
    templateUrl: './payment-form.html',
    providers: [PaymentService]
})
export class PaymentFormComponent implements OnInit, OnChanges {
    environmentType = 'production';
    isForeman = false;
    paymentsForm: FormGroup;
    formSubmitted = false;
    showPaymentPendingError = false;
    paymentDone = false;
    addOfflinePaymentSubmit = false;
    publicApplications = {
        CERTIFIED_COPY_OF_AGREEMENT: 'Certified copy of Agreement',
        CERTIFIED_COPY_OF_AWARD_APPLICATION: 'Certified copy of Award',
        OTHER_PAYMENTS: 'Other Payments'
    };

    @ViewChild(AppTwalletAutoSubmitFormComponent)
    private autosubmitchild: AppTwalletAutoSubmitFormComponent;

    @Input() paymentRequest: PaymentRequest;

    @Input() public taskData: any;

    @Input() public hideOfflinePaymentDetails = true;

    // Tells whether this form being used in bulk add mode. So that we will show data accordinlgy
    @Input() public bulkMode: false;

    private twalletFormData: TwalletFormData;
    isClosedTask = false;
    isUser = false;
    applicationTypes: any;
    @Output() updatePaymentInfo: EventEmitter<any> = new EventEmitter();
    paymentReconcileInProcess: boolean;

    constructor(
        private paymentService: PaymentService,
        private alertService: AlertService,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private toasterService: SnBToasterService,
        private activatedRoute: ActivatedRoute,
        private formatInputs: FormatInputs
    ) {
        this.applicationTypes = ApplicationTypes;
        this.environmentType = process.env.NODE_ENV;
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
        } else if (USER_TYPES.IS_FOREMAN) {
            this.isForeman = true;
        } else {
            this.isUser = true;
        }
        
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.status && params.status === 'closed') {
                this.isClosedTask = true;
            }
        });
        console.log('Environment Type is ', this.environmentType);
        if(this.environmentType === 'development' ) {
            this.hideOfflinePaymentDetails = false;
            console.log('Offline payment is enabled for Development Env. ', this.environmentType);
        }
    }

    get autoSubmitForm() {
        return this.autosubmitchild;
    }


    ngOnInit() {
        this.paymentsForm = this.fb.group({
            trnxDesc: [''],
            termsAndConditionsAgreed: false,
            isPaidOfflineMode: false,
            paymentAmount: [''],
            paymentRefId: [''],
            paymentDesc: ['']
        });

        if(this.bulkMode) {
            if (this.taskData && this.taskData.processVariables.applicationFee && !this.taskData.processVariables.paymentInfo) {
                // if the application requires a payment and if not paid yet, set the values to allow bulk payment.
                this.paymentsForm.controls.isPaidOfflineMode.setValue(true);
                this.paymentsForm.controls.paymentAmount.setValue(this.taskData.processVariables.applicationFee.toLocaleString('en-IN'));
                this.paymentsForm.controls.paymentRefId.setValue("Paid Via TApp Folio");
                this.paymentsForm.controls.paymentDesc.setValue("Offline payment done in TApp Folio");
            }
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['taskData']) {
            if (this.taskData && this.taskData.processVariables && this.taskData.processVariables.paymentInfo &&
                this.taskData.processVariables.paymentInfo.trnxType === TransactionType[TransactionType.OFFLINE_PAY] && this.paymentsForm) {
                this.paymentsForm.controls.isPaidOfflineMode.setValue(true);
                this.paymentsForm.disable();
                if (this.taskData.processVariables.applicationFee) {
                    this.paymentsForm.controls.paymentAmount
                        .setValue(this.taskData.processVariables.applicationFee.toLocaleString('en-IN'));
                }
                this.paymentsForm.controls.paymentRefId.setValue(this.taskData.processVariables.paymentInfo.pspTrnxRefId);
                this.paymentsForm.controls.paymentDesc.setValue(this.taskData.processVariables.paymentInfo.trnxDesc);
            }
        }
    }

    showOfflinePaymentOptions() {
        if (this.taskData && this.taskData.processVariables.applicationFee) {
            this.paymentsForm.controls.paymentAmount.setValue(this.taskData.processVariables.applicationFee.toLocaleString('en-IN'));
        }
    }

    checkFormValidityForOfflinePayment() {
        if (this.taskData.processVariables.paymentInfo) {
            return true;
        } else if (this.paymentsForm.value.isPaidOfflineMode) {
            if (!this.paymentsForm.value.paymentAmount) {
                this.paymentsForm.controls.paymentAmount.markAsTouched({ onlySelf: true });
                return false;
            } else if (!this.taskData.processVariables.paymentInfo) {
                this.showPaymentPendingError = true;
            } else {
                return true;
            }
        } else {
            this.showPaymentPendingError = true;
        }
    }

    validatePaymentRequest(paymentRequest: PaymentRequest) {

        if (!paymentRequest) {
            this.onError('error.invalidPaymentRequest', null);
        }

        if (!paymentRequest.amount || paymentRequest.amount <= 0) {
            this.onError('error.paymentRequest.amount', null);
        }

        if (!paymentRequest.items) {
            this.onError('error.paymentRequest.items', null);
        } else {
            if (!paymentRequest.userId) {
                this.onError('error.paymentRequest.userId', null);
            }

            paymentRequest.items.forEach(item => {

                if (!item.amount) {
                    this.onError('error.paymentRequest.item.amount', null);
                }

                if (!item.foremanCompanyBranchCode) {
                    this.onError('error.paymentRequest.item.foremanCompanyBranchCode', null);
                }

                if (!item.workFlowProcessInstanceId) {
                    this.onError('error.paymentRequest.item.workFlowProcessInstanceId', null);
                }

                if (!item.chitRefId) {
                    this.onError('error.paymentRequest.item.chitRefId', null);
                }

                if (!item.workFlowFormName) {
                    this.onError('error.termsNconditions.item.workFlowFormName', null);
                }
            });
        }
    }

    public payNow(paymentRequest: PaymentRequest, formData, event: Event) {
        event.preventDefault();
        // if (!formData.value.isPaidOfflineMode) {
        //     this.dialog.open(TWalletDisabledDialogComponent, {
        //         width: '360px',
        //     });
        //     return;
        // }
        this.formSubmitted = true;
        if (!formData.value.termsAndConditionsAgreed && !formData.value.isPaidOfflineMode) {
            return false;
        }

        if (formData.value.isPaidOfflineMode &&
            (this.formatInputs.formatStringToValue(formData.value.paymentAmount) < this.taskData.processVariables.applicationFee)) {
            this.paymentsForm.controls.paymentAmount.setErrors({ 'minAmountErr': true });
            return false;
        }
        const formDetails = JSON.parse(JSON.stringify(paymentRequest));
        const paymetData = this.paymentService.formatPaymetDetails(paymentRequest);
        paymentRequest = new PaymentRequest();
        paymentRequest = paymetData;
        paymentRequest.agreedToTermsAndConditions = true;
        this.validatePaymentRequest(this.paymentRequest ? this.paymentRequest : paymentRequest);

        if (this.paymentRequest) {
            paymentRequest = this.paymentRequest;
        }

        const trnx: Transaction = new Transaction();
        trnx.amount = paymentRequest.amount;
        trnx.trnxDesc = formData.value.trnxDesc;
        trnx.pspType = 'TWALLET';
        trnx.clientId = 'snbchain_web_client';
        trnx.trnxAddlRef1 = '' + paymentRequest.userId;
        trnx.trnxAddlRef2 = '' + paymentRequest.provenance;
        trnx.trnxAddlRef3 = '' + paymentRequest.regulatorBranchCode;
        trnx.trnxAddlRef4 = '' + paymentRequest.foremanCompanyCode;

        const trnxItems: TransactionItem[] = [];
        paymentRequest.items.forEach(item => {
            const trnxItem: TransactionItem = new TransactionItem();
            trnxItem.amount = item.amount;
            trnxItem.description = item.trnxDesc;
            // set chit group information.
            trnxItem.trnxItemAddlRef1 = item.chitRefId;
            trnxItem.trnxItemAddlRef2 = item.foremanCompanyBranchCode;
            trnxItem.trnxItemAddlRef3 = item.workFlowProcessInstanceId;
            trnxItem.trnxItemAddlRef4 = item.workFlowFormName;
            trnxItem.trnxItemAddlRef8 = item.userCharges;
            if (this.taskData && this.taskData.processDefKey) {
                // this field trnxItemAddlRef5 can be used as suffix to show in print preview.
                if (this.taskData.processDefKey === 'ProcessChitAuctionMinutes' ||
                    this.taskData.processDefKey === 'ProcessChitAuctionMinutesLegacy') {
                    trnxItem.trnxItemAddlRef5 = this.taskData.processVariables.installmentNumber;
                }
                if (this.taskData.processDefKey === 'SubscriberSubstitutionProcess') {
                    trnxItem.trnxItemAddlRef5 = this.taskData.processVariables.serialNumber;
                }
            }
            trnxItem.trnxItemAddlRef6 = this.taskData.processVariables.name;
            // Send additional data (eg: Show service request type - (Arbitration Payment))
            if (formDetails.processVariables.requestDetails &&
                formDetails.processVariables.requestDetails.serviceRequestType === 'ARBITRATION_PAYMENT') {
                trnxItem.trnxItemAddlRef7 = 'Arbitration Payment';
            }
            return trnxItems.push(trnxItem);
        });


        trnx.items = trnxItems;
        if (formData.value.isPaidOfflineMode) {
            delete trnx.pspType;
            trnx.trnxDesc = formData.value.paymentDesc;
            trnx.pspTrnxRefId = formData.value.paymentRefId;
            trnx.amount = this.formatInputs.formatStringToValue(formData.value.paymentAmount);
            trnx.items[0].amount = this.formatInputs.formatStringToValue(formData.value.paymentAmount);
            this.addOfflinePaymentSubmit = true;
            this.paymentService.createOfflinePayment(trnx).subscribe((success: any) => {
                const self = this;
                setTimeout(function () {
                    self.paymentDone = true;
                    self.paymentsForm.disable();
                    if(self.bulkMode) {
                        self.updatePaymentInfo.emit({
                            taskData: self.taskData,
                            paymentInfo: success["body"],
                        });
                    } else {
                        self.updatePaymentInfo.emit();
                    }
                    
                    self.addOfflinePaymentSubmit = false;
                }, 3000);
            }, (fail) => {
                if (fail) {
                    this.addOfflinePaymentSubmit = false;
                    this.toasterService.showCustomError(fail);
                }
            });
        } else {
            this._validateAndInitiateTransaction(trnx, paymentRequest.agreedToTermsAndConditions);
        }
    }

    MiscPayNow(formdata, event: Event) {
        event.preventDefault();
        const userData = this.localStorage.retrieve('authenticationToken');
        const formData_tmp = JSON.parse(JSON.stringify(formdata));
        const paymetData = {
            amount: Number(formdata.applicationFee),
            userId: userData.user_internal_id,
            provenance: userData.provenance,
            foremanCompanyCode: formdata.branch.companyCode,
            regulatorBranchCode: formdata.branch.regulatorBranchCode,
            items: [{
                amount: Number(formdata.applicationFee),
                trnxDesc: (formdata.typeOfRequest === 'OTHER' || formdata.applicationType.applicationType === 'OTHER') ?
                    formdata.applicationTypeOther : formdata.applicationType.applicationName,
                foremanCompanyBranchCode: formdata.branch.branchCode,
                // chitRefId: formdata.chitRefId,
            }]
        };

        formdata = paymetData;
        formdata.agreedToTermsAndConditions = true;

        const trnx: Transaction = new Transaction();
        trnx.amount = formdata.amount;
        trnx.trnxDesc = formData_tmp.remarks;
        trnx.pspType = 'TWALLET';
        trnx.clientId = 'snbchain_web_client';
        trnx.trnxAddlRef1 = '' + formdata.userId;
        trnx.trnxAddlRef2 = '' + formdata.provenance;
        trnx.trnxAddlRef3 = '' + formdata.regulatorBranchCode;
        trnx.trnxAddlRef4 = '' + formdata.foremanCompanyCode;

        const trnxItems: TransactionItem[] = [];

        formdata.items.forEach(item => {
            const trnxItem: TransactionItem = new TransactionItem();
            trnxItem.amount = item.amount;
            trnxItem.description = item.trnxDesc;
            // set chit group information.
            trnxItem.trnxItemAddlRef1 = item.chitRefId;
            trnxItem.trnxItemAddlRef2 = item.foremanCompanyBranchCode;

            trnxItem.trnxItemAddlRef4 =
                (formData_tmp.typeOfRequest === 'OTHER' || formData_tmp.applicationType.applicationType === 'OTHER') ?
                    formData_tmp.applicationTypeOther : (formData_tmp.applicationType ? (formData_tmp.applicationType.applicationType ?
                        formData_tmp.applicationType.applicationName : undefined) : undefined);
            trnxItem.trnxItemAddlRef6 = formData_tmp.chitGroupName ? formData_tmp.chitGroupName : undefined;
            return trnxItems.push(trnxItem);
        });

        trnx.items = trnxItems;
        this._validateAndInitiateTransaction(trnx, formdata.agreedToTermsAndConditions);
    }

    // It is used to create transaction for public service requests
    PublicPayNow(formdata, taskData, event: Event) {
        event.preventDefault();
        let userData = this.sessionStorage.retrieve('userAuthenticationToken');
        userData = JSON.parse(atob(userData.access_token.toString().split('.')[1]));
        const formData_tmp = JSON.parse(JSON.stringify(formdata));
        const paymetData = {
            amount: Number(formdata.applicationFee),
            userId: userData.user_internal_id,
            provenance: formdata.regulatorOrgCode,
            foremanCompanyCode: formdata.companyCode,
            regulatorBranchCode: formdata.regulatorBranchCode,
            items: [{
                amount: Number(formdata.applicationFee),
                trnxDesc: this.publicApplications[formdata.requestDetails.serviceRequestType],
                foremanCompanyBranchCode: formdata.foremanCompanyBranchCode,
                // chitRefId: formdata.chitRefId,
                workFlowFormName: this.applicationTypes[taskData.activeTask.taskDefKey].type,
                workFlowProcessInstanceId: taskData.processInstanceId
            }]
        };

        formdata = paymetData;
        formdata.agreedToTermsAndConditions = true;

        const trnx: Transaction = new Transaction();
        trnx.amount = formdata.amount;
        trnx.trnxDesc = formData_tmp.requestDetails.description;
        trnx.pspType = 'TWALLET';
        trnx.clientId = 'user_portal_app_client';
        /*
         * For public user, we don't use this variable trnxAddlRef1, it is reserved for 
         * foreman user id. We use trnxAddlRef4 as login
         */
        trnx.trnxAddlRef1 = null;
        trnx.trnxAddlRef2 = '' + formData_tmp.regulatorOrgCode;
        trnx.trnxAddlRef3 = '' + formdata.regulatorBranchCode;
        trnx.trnxAddlRef4 = '' + formData_tmp.companyCode;
        trnx.trnxAddlRef5 = '' + userData.user_name;

        const trnxItems: TransactionItem[] = [];

        formdata.items.forEach(item => {
            const trnxItem: TransactionItem = new TransactionItem();
            trnxItem.amount = item.amount;
            trnxItem.description = item.trnxDesc;
            // set chit group information.
            trnxItem.trnxItemAddlRef1 = item.chitRefId;
            trnxItem.trnxItemAddlRef2 = item.foremanCompanyBranchCode;
            trnxItem.trnxItemAddlRef3 = item.workFlowProcessInstanceId;
            trnxItem.trnxItemAddlRef4 = this.publicApplications[formData_tmp.requestDetails.serviceRequestType];

            trnxItem.trnxItemAddlRef6 = formData_tmp.chitNumber ? formData_tmp.chitNumber : undefined;
            return trnxItems.push(trnxItem);
        });
        trnx.items = trnxItems;
        this._validateAndInitiateTransaction(trnx, formdata.agreedToTermsAndConditions);
    }

    BulkPayNow(data, event: Event) {
        event.preventDefault();
        const userData = this.localStorage.retrieve('authenticationToken');
        data.agreedToTermsAndConditions = true;

        const trnx: Transaction = new Transaction();
        trnx.amount = Number(data.totalAmount);
        trnx.trnxDesc = 'Bulk Payment';
        trnx.pspType = 'TWALLET';
        trnx.clientId = 'snbchain_web_client';
        trnx.trnxAddlRef1 = userData.user_internal_id;
        trnx.trnxAddlRef2 = userData.provenance;
        trnx.trnxAddlRef3 = data.regulatorBranchCode;
        trnx.trnxAddlRef4 = data.foremanCompanyCode;

        const trnxItems: TransactionItem[] = [];

        data.selectedTasks.forEach(item => {
            const trnxItem: TransactionItem = new TransactionItem();
            trnxItem.amount = Number(item.processVariables.applicationFee);
            trnxItem.description = 'Online - Bulk payment - ' + item.processInstanceId + ' - ' + item.processDefKey;
            // set chit group information.
            trnxItem.trnxItemAddlRef1 = item.processVariables.chitRefId ? item.processVariables.chitRefId : '';
            trnxItem.trnxItemAddlRef2 = item.processVariables.foremanCompanyBranchCode;
            trnxItem.trnxItemAddlRef3 = item.processInstanceId;
            trnxItem.trnxItemAddlRef4 = ApplicationTypes[item.activeTask.taskDefKey].type;

            if (item.processDefKey) {
                // this field trnxItemAddlRef5 can be used as suffix to show in print preview.
                if (item.processDefKey === 'ProcessChitAuctionMinutes' ||
                    item.processDefKey === 'ProcessChitAuctionMinutesLegacy') {
                    trnxItem.trnxItemAddlRef5 = item.processVariables.installmentNumber;
                }
                if (item.processDefKey === 'SubscriberSubstitutionProcess') {
                    trnxItem.trnxItemAddlRef5 = item.processVariables.serialNumber;
                }
            }

            trnxItem.trnxItemAddlRef6 = item.processVariables.name ? item.processVariables.name : '';
            return trnxItems.push(trnxItem);
        });

        trnx.items = trnxItems;
        this._validateAndInitiateTransaction(trnx, data.agreedToTermsAndConditions);
    }

    private onError(error, params) {
        this.alertService.addAlert({type: 'danger', translationKey: error, translationParams: params}, []);
    }

    private _validateAndInitiateTransaction(trnx: Transaction, termsAndConditions: boolean) {
        if (!termsAndConditions) {
            this.onError('error.termsNconditions', null);
        } else {
            this.paymentService.create(trnx).subscribe((success: any) => {
                // console.log('transaction is successful' + JSON.stringify(success));
                // this.error = false;
                const wlRequest: any = success;
                const twalletRequestUrl: string = wlRequest.paymentGatewayRequestUrl;
                const data: string = wlRequest.twalletData;
                const skey: string = wlRequest.twalletSkey;
                console.log('url data ' + data);
                console.log('url skey ' + skey);
                console.log('url twalletRequestUrl ' + twalletRequestUrl);
                this.twalletFormData = {
                    redirectUrl: twalletRequestUrl,
                    redirectMethod: 'POST',
                    redirectData: {
                        data: data,
                        skey: skey
                    }
                };

                // this.postDataToTwallet(wlRequest.paymentGatewayRequestUrl);
                this.autosubmitchild.render(this.twalletFormData);

            }, (fail) => {
                if (fail) {
                    this.toasterService.showCustomError(fail);
                }
            });
        }

    }

    private postDataToTwallet(request: string) {
        this.paymentService.postDataToTwallet(request).subscribe((success) => {
            console.log('transaction is successful' + JSON.stringify(success));

        }, (fail) => {
            if (fail) {
                this.toasterService.showCustomError(fail);
            }
        });
    }

    private reconcileByApplication(id) {
        this.paymentReconcileInProcess = true;
        this.paymentService.reconcileByApplication(id).subscribe((success) => {
            console.log('transaction is successful' + JSON.stringify(success));
            this.paymentReconcileInProcess = false;
            this.toasterService.showSuccessToaster(this.translateService.instant('success.reconcile'));
            this.updatePaymentInfo.emit();
        }, (fail) => {
            if (fail) {
                this.paymentReconcileInProcess = false;
                this.toasterService.showCustomError(fail);
            }
        });
    }

}


@Component({
    template: `<mat-dialog-content>
                    <p style="font-size:18px"><b>Online payment service temporarily unavailable.</b></p>
                    <p>Inconvenience Regretted
                        <br/>
                        Please pay the amount at ARC office during application submission.
                    </p>
                </mat-dialog-content>
                <mat-dialog-actions class="button_group" style="float: right;">
                    <button mat-raised-button tabindex="0" mat-dialog-close (click)="onNoClick()">Close</button>
                </mat-dialog-actions>`
})

export class TWalletDisabledDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TWalletDisabledDialogComponent>
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

export class TwalletFormData {
    redirectUrl: string;
    redirectMethod: string;
    redirectData: {
        data: string,
        skey: string
    };
}

export class FormData {
    redirectUrl: string;
    redirectMethod: string;
    redirectData: {
        mid: string,
        merchantRequest: string
    };
}

@Pipe({
    name: 'minusSignToParens'
})
export class MinusSignToParens implements PipeTransform {

    transform(value: any, args?: any): any {
        value = value + '';
        return value.charAt(0) === '-' ?
            '' + value.substring(1, value.length) :
            value;
    }
}
