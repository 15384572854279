import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
export class FormatInputs {

    formatDateDDMMYYYY(date) {
        if (!date || typeof (date) !== 'object') {
            return;
        }
        const dateVal = (date.getDate()) > 9 ? (date.getDate()) : '0' + (date.getDate());
        const monthVal = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
        const yearVal = date.getFullYear();
        return dateVal + '/' + monthVal + '/' + yearVal;
    }

    formatDateYYYYMMDD(date) {
        if (typeof (date) !== 'object') {
            return;
        }
        const dateVal = (date.getDate()) > 9 ? (date.getDate()) : '0' + (date.getDate());
        const monthVal = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
        const yearVal = date.getFullYear();
        return yearVal + '-' + monthVal + '-' + dateVal;
    }

    formatDateToTimeStampToMilliSeconds(dateParam) {
        let date = dateParam;
        if (date && typeof (date) === 'string') {
            if(date.indexOf('[UTC]') !== -1) {
                date = dateParam.replace('[UTC]', '');
            } 
            if (Number(new Date(date)).toString().length < 13) {
                date = Number(new Date(date)) * 1000;
            } else if (Number(new Date(date)).toString().length > 13) {
                date = Number(new Date(date)) / 1000;
            } else if (Number(new Date(date)).toString().length === 13) {
                date = Number(new Date(date));
            }
        } else if (date && typeof (date) === 'number') {
            const tempDate = date.toString().split('.');
            if (tempDate[1] && tempDate[1].length) {
                switch (tempDate[1].length) {
                    case 1:
                        date *= 10;
                        date = this.checkLengthForDecimals(date);
                        break;
                    case 2:
                        date *= 100;
                        date = this.checkLengthForDecimals(date);
                        break;
                    case 3:
                        date *= 1000;
                        date = this.checkLengthForDecimals(date);
                        break;
                    default:
                        date = date;
                        break;
                }
            }
            if (date.toString().length !== 13) {
                if (date.toString().length <= 10) {
                    date *= 1000;
                } else if (date.toString().length > 10) {
                    date /= 1000;
                }
            }
        }
        if (date.toString().length < 13) {
            return this.formatDateToTimeStampToMilliSeconds(date);
        } else if (date.toString().length === 13) {
            return date;
        }

        return date;
    }

    checkLengthForDecimals(date) {
        switch (date.toString().length) {
            case 8:
                date *= 100;
                break;
            case 9:
                date *= 10;
                break;
            case 11:
                date *= 100;
                break;
            case 12:
                date *= 10;
                break;
            default:
                date = date;
                break;
        }
        return date;
    }

    formatAddress(address) {
        if (!address) { return false; }
        let resp =  Object.keys(address).map(function (k) {
            if (k !== 'type' && k !== 'addressField') {
                return address[k];
            }
        }).filter(Boolean).join(', ');
        return resp;
    }

    formatAmountToLocaleString(amount) {
        if (!amount) { return false; }
        return parseInt(amount.toString().replace(/,/g, ''), 0).toLocaleString('en-IN');
    }

    formatStringToValue(amount) {
        if (!amount) {
            return 0;
        }
        return parseInt(amount.toString().replace(/,/g, ''), 0);
    }

    /**
     * This method converts amount fields from local format
     * to number format
     * e.g. 1,00,000 to 100000
     * It iterates all feilds of given object and converts only
     * numbers fields.
     * @param data holds data to be updated
     */
    formatLocalCurrencyFormantToNumber(data: any) {
        const numberFields: string[] = Object.keys(data).filter(fieldName => data[fieldName].constructor === Number);
        for (let i = 0; i < numberFields.length; i++) {
            data[numberFields[i]] = parseInt(data[numberFields[i]].replace(/,/g, ''), 0);
        }
    }

    convertCurrencyToWords(amount) {
        if (!amount) {
            return false;
        }
        if (isNaN(amount)) {
            amount = amount.replace(/[^0-9]/g, '');
        }
        const words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        const atemp = amount.split('.');
        const number = atemp[0].split(',').join('');
        const n_length = number.length;
        let words_string = '';
        if (n_length <= 9) {
            words_string += this.getWordStringForNumber(amount, words);
        } else {
            let tmp = number.substr(0, number.length - 7);
            words_string += this.getWordStringForNumber(tmp, words);
            words_string += ' Crores ';
            tmp = number.substr((number.length - 7), 7);
            words_string += this.getWordStringForNumber(tmp, words);
        }
        return words_string;
    }

    getWordStringForNumber(amount, words) {
        amount = amount.toString();
        const atemp = amount.split('.');
        const number = atemp[0].split(',').join('');
        const n_length = number.length;
        let words_string = '';
        if (n_length === 0) {
            return words_string = '';
        } else if (n_length === 1) {
            return words_string = this.get1Digits(amount, words);
        } else if (n_length === 2) {
            return words_string = this.get2Digits(amount, words);
        } else if (n_length === 3) {
            return words_string = this.get3Digits(amount, words);
        } else if (n_length === 4) {
            let str = '';
            if (this.get1Digits(amount.substring(1, 0), words)) {
                str += this.get1Digits(amount.substring(1, 0), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(4, 1), words)) {
                str += this.get3Digits(amount.substring(4, 1), words);
            }
            return str;
        } else if (n_length === 5) {
            let str = '';
            if (this.get2Digits(amount.substring(2, 0), words)) {
                str += this.get2Digits(amount.substring(2, 0), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(5, 2), words)) {
                str += this.get3Digits(amount.substring(5, 2), words);
            }
            return str;
        } else if (n_length === 6) {
            let str = '';
            if (this.get1Digits(amount.substring(1, 0), words)) {
                str += this.get1Digits(amount.substring(1, 0), words) + ' Lakh ';
            }
            if (this.get2Digits(amount.substring(3, 1), words)) {
                str += this.get2Digits(amount.substring(3, 1), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(6, 3), words)) {
                str += this.get3Digits(amount.substring(6, 3), words);
            }
            return str;
        } else if (n_length === 7) {
            let str = '';
            if (this.get2Digits(amount.substring(2, 0), words)) {
                str += this.get2Digits(amount.substring(2, 0), words) + ' Lakh ';
            }
            if (this.get2Digits(amount.substring(4, 2), words)) {
                str += this.get2Digits(amount.substring(4, 2), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(7, 4), words)) {
                str += this.get3Digits(amount.substring(7, 4), words);
            }
            return str;
        } else if (n_length === 8) {
            let str = '';
            if (this.get1Digits(amount.substring(1, 0), words)) {
                str += this.get1Digits(amount.substring(1, 0), words) + ' Crore ';
            }
            if (this.get2Digits(amount.substring(3, 1), words)) {
                str += this.get2Digits(amount.substring(3, 1), words) + ' Lakh ';
            }
            if (this.get2Digits(amount.substring(5, 3), words)) {
                str += this.get2Digits(amount.substring(5, 3), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(8, 5), words)) {
                str += this.get3Digits(amount.substring(8, 5), words);
            }
            return str;
        } else if (n_length === 9) {
            let str = '';
            if (this.get2Digits(amount.substring(2, 0), words)) {
                str += this.get2Digits(amount.substring(2, 0), words) + ' Crore ';
            }
            if (this.get2Digits(amount.substring(4, 2), words)) {
                str += this.get2Digits(amount.substring(4, 2), words) + ' Lakh ';
            }
            if (this.get2Digits(amount.substring(6, 4), words)) {
                str += this.get2Digits(amount.substring(6, 4), words) + ' Thousand ';
            }
            if (this.get3Digits(amount.substring(9, 6), words)) {
                str += this.get3Digits(amount.substring(9, 6), words);
            }
            return str;
        }
    }

    get1Digits(amount, words) {
        return words[amount];
    }

    get2Digits(amount, words) {
        if (words[amount]) {
            return words[amount];
        } else {
            if (words[amount.substring(1, 0) + '0']) {
                return words[amount.substring(1, 0) + '0'] + ' ' + words[amount.substring(2, 1)];
            } else {
                return words[amount.substring(2, 1)];
            }
        }
    }

    get3Digits(amount, words) {
        if (amount === '000') {
            return '';
        } else {
            if (words[amount.substring(1, 0)]) {
                return words[amount.substring(1, 0)] + ' Hundred ' + this.get2Digits(amount.substring(3, 1), words);
            } else {
                return this.get2Digits(amount.substring(3, 1), words);
            }
        }
    }

}

@Pipe({ name: 'stringToNumber' })
export class StringToNumberConverter implements PipeTransform {
    transform(amount) {
        if (!amount) { return 0; }
        return parseInt(amount.toString().replace(/,/g, ''), 0);
    }
}

@Pipe({name: 'replaceUnderscore'})
export class ReplaceUnderscorePipe implements PipeTransform {
  transform(value: string): string {
    return value? value.replace(/_/g, " ") : value;
  }
}

@Pipe({ name: 'chmAmountUnitPipe' })
export class ChmAmountUnitPipeConverter implements PipeTransform {
    transform(amount, currencyUnit: string = 'CRORE', showLabel: boolean = false) {
        if (!amount) { return 0; }
        let amountInNumberFormat = parseInt(amount.toString().replace(/,/g, ''), 0);
        let transformedValue = "Invalid"
        let numberValue: number = NaN;
        if(currencyUnit === 'CRORE') {
            numberValue = parseFloat((amountInNumberFormat / 10000000).toFixed(4));
            if(showLabel) {
                transformedValue = numberValue + ' Cr'
            }
        }

        if(currencyUnit === 'LAKH') {
            numberValue =  parseFloat((amountInNumberFormat / 100000).toFixed(2));
            if(showLabel) {
                transformedValue = numberValue + ' Lakh(s)'
            }
        }
        return transformedValue;
    }
}

@Pipe({ name: 'ordinal' })
export class NumberToOrdinalConverter implements PipeTransform {
    transform(n) {
        return["st","nd","rd"][(((n<0?-n:n)+90)%100-10)%10-1]||"th";
    }
}

// This pipe is used to convert a number in double format into a indian format counting number which is restricted to max. of 2 digits after decimal point 
@Pipe({ name: 'inrLocaleString' })
export class NumberToIndianLocaleConvertor implements PipeTransform {
    
    transform(value: any, locale: string): string | null {
        
        if (value == null || isNaN(value)) {
          return '';
        }
        const num = JSON.parse(value);
        const formattedNumber = num.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formattedNumber;
        
    }

}