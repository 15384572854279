<div class="animated fadeIn">
  <section id="upload">

    <div *ngIf="isResult">
      <p style="color:#FF9800;FONT-SIZE: 20PX;">Info : Error(s) in the below records, please go to the specific fields
        to find out the error and fix them before retry.
      </p>
    </div>
    <div class="Upload_fields row no-margin" style="margin-top: 15px;">
      <div class="upload_info col-md-9 col-sm-9 col-xs-12">
        <div>
          <p> File should meet following requirements !</p>
          <ul>
            <li>The maximum allowed file size is
              <span>{{fileSettings?.maximumFileSizeInMB}} MB</span>. Only
              <span>CSV</span> files are allowed.
            </li>
            <li>
              <a href="{{fileSettings?.templateUrl}}" download>
                <b>Click here to download the sample template </b> (
                <i class="ion ion-ios-cloud-download"><span class="pulse"></span></i>
              </a> ). Fill this file and Upload it.
            </li>
            <li>
              Example data is shown in the template.
            </li>
          </ul>
        </div>
      </div>
      <div class="upload-body col-md-3 text-center col-sm-2 col-xs-12">
        <form role="form" autocomplete="off">
          <div class="box">
            <div class="uploader-wrapper">
              <div class="document-container"> 
                 <img class="bounce animated" src="assets/img/upload-file.svg"/> 
               </div>
            </div>
            <p class="upload_pitch">Click here to upload the file</p>
            <input (change)="onFileChange($event)" type="file" accept=".csv" ng2FileSelect [uploader]="uploader">
            <!-- <label *ngIf="uploader?.queue?.length==0" class="animated" for="file-6" [ngClass]="{ 'zoomIn' : uploader?.queue?.length ==0 , 'zoomout' : !uploader?.queue?.length ==0    }">
                  <i class="fa fa-circle-o faa-burst animated"></i>
                  <i class="ion-ios-cloud-upload-outline ion"></i>
                </label> -->
          </div>
        </form>
      </div>
    </div>
  </section>
</div>