import { MatSort } from "@angular/material/sort";
import { AfterViewInit, Component, HostListener, ViewChild } from "@angular/core";
import { DATA_TABLE_CONFIG, ITEMS_PER_PAGE } from "../../constants/pagination.constants";
import { LoaderService } from "../../loader.service";
import { merge, Observable, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { LogService } from "../../services/log-service";
import { MatPaginator } from "@angular/material/paginator";

@Component({
    template: ''
})

export abstract class SnbTableComponent implements AfterViewInit {

    /* Holds the filter id corresponding fitler-meta-data.json file */
    filterId: string;
    /** Holds the filter query based on which table data is filtered */
    query: any;

    /** Holds the table data. */
    rows: any;

    /** ## Pagination & Sorting related params ## */
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    /** No Of Items to be shown in each page */
    itemsPerPage: number;
    /** Total elements present in a table */
    totalItems: number;
    /** Current offset position of total pages */
    offset: number;
    /** holds the sorting object */
    @ViewChild(MatSort,  {static: true}) sort: MatSort;

    /** ## Table Loading Status ## */
    requestInProgress: boolean;
    dataRefreshed: boolean;

    /** customization of header/footer/row heights */
    headerHeight: number;
    footerHeight: number;
    rowHeight: number;
    innerWidth: number;
    isColHide: boolean;

    // selected row details
    selectedRow: any;
    selectedRowIndex: any;

    abstract fetchData(): Observable<any>;
    abstract rowClicked(event: any, rowData: any): any;
    /** this tells that data is loaded completed, child component can do further actions. */
    abstract tableDataLoaded(): any;
    private paginatorSubscription: Subscription;


    constructor(
        public loaderService: LoaderService,
        public logService: LogService
    ) {
        this.requestInProgress = true;
        this.itemsPerPage = this.getItemsPerPage();

        this.headerHeight = DATA_TABLE_CONFIG.HEADER_HEIGHT;
        this.footerHeight = DATA_TABLE_CONFIG.FOOTER_HEIGHT;
        this.rowHeight = DATA_TABLE_CONFIG.ROW_HEIGHT;
    }

    loadAll() {
        this.requestInProgress = true;

        // If any subscription for paginator already present unsubscibing to resubscribe with updated paginator values
        if(this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
        }

        // this.loaderService.display(true);
        this.paginatorSubscription = merge(this.paginator.page, this.sort.sortChange)
            .pipe(
                startWith({}),
                switchMap(() => {
                    return this.fetchData();
                }),
                map((res: any) => {
                    this.totalItems = parseInt(
                        res.headers.get("X-Total-Count"),
                        0
                    );
                    this.loaderService.display(false);
                    this.requestInProgress = false;
                    this.dataRefreshed = true;
                    return res;
                }),
                catchError(() => {
                    this.loaderService.display(false);
                    this.requestInProgress = false;
                    this.dataRefreshed = true;
                    return [];
                })
            )
            .subscribe((data) => {
                this.rows = data["body"];
                this.tableDataLoaded();
                this.requestInProgress = false;
                this.loaderService.display(false);
            });
    }


    // detect window width on window resize
    @HostListener("window:resize", ["$event"])
    onResize() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 769) {
            this.isColHide = true;
        } else {
            this.isColHide = false;
        }
    }

    onFilter(event: any) {
        this.query = event;
        this.sort.direction;
        this.paginator.pageIndex = 0;
        this.loadAll();
    }

    refreshData() {
        this.offset = 0;
        this.dataRefreshed = false;
        this.loadAll();
    }


    loadPage(page) {
        this.loadAll();
    }

    onPage(event) {
        this.offset = event.offset;
        this.loadPage(event.offset);
    }

    protected getItemsPerPage() {
        return ITEMS_PER_PAGE;
    }

    ngAfterViewInit(){
        console.log(this.sort)
     }
}