<aside class="snb-sidebar" (mouseleave)="hoverElemTop=outOfArea" sidebarResize>
    <ul id="snb-sidebar-list" class="snb-sidebar-list" baSlimScroll [baSlimScrollOptions]="{height: menuHeight}">
        <app-ba-menu-item [menuItem]="item" (itemHover)="hoverItem($event)" (toggleSubMenu)="toggleSubMenu($event)"
            *ngFor="let item of menuItems"></app-ba-menu-item>
    </ul>
    <div class="sidebar-hover-elem" [ngStyle]="{top: (hoverElemTop+16) + 'px', height: hoverElemHeight + 'px'}"
        [ngClass]="{'show-hover-elem': showHoverElem }"></div>
</aside>

<!-- <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 384 512">
    <path fill="#ffffff"
    d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
</svg> -->
