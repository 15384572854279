import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileUploadModule } from 'ng2-file-upload';
import { FileReadComponent, SNBFileItemComponent, SNBFilePreviewComponent, SNBDownloadReportComponent } from './file-upload-read.component';
import { FileUploadService } from './file-upload.service';
import { FileUploadComponent, FileOverWriteConfirmationComponent, UserFileUploadComponent } from './file-upload.component';
import { ExcelFileUploadComponent, ConfirmDetailsSubmissionDialogComponent } from './excelfile-upload-read.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatInputModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatTableModule,
        MatExpansionModule,
        FileUploadModule
    ],
    declarations: [
        FileReadComponent,
        SNBFileItemComponent,
        SNBDownloadReportComponent,
        SNBFilePreviewComponent,
        FileUploadComponent,
        ExcelFileUploadComponent,
        FileOverWriteConfirmationComponent,
        UserFileUploadComponent,
        ConfirmDetailsSubmissionDialogComponent
    ],
    providers: [
        FileUploadService
    ],
    exports: [
        FileReadComponent,
        SNBFileItemComponent,
        SNBDownloadReportComponent,
        SNBFilePreviewComponent,
        FileUploadComponent,
        ExcelFileUploadComponent,
        UserFileUploadComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SNBFileModule { }
