<div class="confirmation-dialog-wrapper">
    <div class="confirmation-dialog-title">
        <mat-icon>info</mat-icon>
        Information
        <span><i class="material-icons close-btn" (click)="onNoClick()">close</i></span>
    </div>
    <div class="form_groups">
        <p class="text-left">New Software Update is Released on 05/01/2025</p>
        <div>
            <a class="text-left" href="/pages/new-features">Click here to know the details!</a>
        </div>

    </div>
    <div>
        <button mat-raised-button type="submit" color="primary" (click)="goToPage('/pages/dashboard')" style="border-radius: 30px;margin-left: 200px;
                        margin-bottom: 10px;">
            Ok
        </button>

    </div>
</div>
