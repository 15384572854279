
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError,  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, Injectable, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { HttpResponse, HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SNB_BASE_URL } from '../../app.constants';

@Injectable()
export class AutoCompleteFilterCriteriaService {
    constructor(
        private httpClient: HttpClient,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
    ) { }

    getFilterValues<T>(itemAttribute: any, uri: any, req?: any):Observable<any> {
        const options = this.prepareHeader(req);

        let url = '';

        if (SNB_BASE_URL === '') {
            url = location.origin;
        } else {
            url = SNB_BASE_URL;
        }

        url = `${url}${uri}/get-filter-values/${itemAttribute}`;


        return this.httpClient.get(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }


    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');

        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params.set('stateIn', urlSearchParams.stateIn);
            }
            if (urlSearchParams.autoCompleteText) {
                params = params.set('autoCompleteText', urlSearchParams.autoCompleteText);
            }

            if(urlSearchParams.returnAttributes) {
                params = params.set('returnAttributes', urlSearchParams.returnAttributes);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }

}






@Component({
    selector: 'app-auto-complete-form-field',
    templateUrl: 'auto-complete-form-field.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AutoCompleteFormFieldComponent implements OnInit, OnChanges {


    inputFieldForm: FormGroup;
    filteredItem: string;
    filterItems = [];
    enteredValue: any;
    isReturnedString: boolean = true;
    @Input() searchCriteria;
    @Input() chitAutoCompleteSelectedValue: any;
    @Output() selectedValue: EventEmitter<String> = new EventEmitter();
    @Output() typedValue: EventEmitter<String> = new EventEmitter();

    constructor(
        private _formBuilder: FormBuilder ,
     private autoCompleteFilterCriteriaService: AutoCompleteFilterCriteriaService

    ) {
        
        
    }


    ngOnInit() {
        this.inputFieldForm = this._formBuilder.group({
            inputField: ['', Validators.required]
        });
        this.inputFieldForm.controls.inputField.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(value => {

                this.filteredItem = value;
                let query: any = {
                    autoCompleteText: this.filteredItem
                }
                
                if (this.searchCriteria.otherResourceReturnAttributes) {
                    query["returnAttributes"] = this.searchCriteria.otherResourceReturnAttributes;
                }
                // Emitting the value typed untill then, for auto completion suggestions
                this.emitTypedValue({target: { value: this.inputFieldForm.value }})
                this.autoCompleteFilterCriteriaService.getFilterValues(this.searchCriteria.attribute, this.searchCriteria.uri, query).subscribe((res: any) => {
                    if(typeof res[0] !== "string") {
                        this.isReturnedString = false;
                    }
                    this.filterItems = res;
                });
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.inputFieldForm && changes.chitAutoCompleteSelectedValue) {
            this.inputFieldForm.controls.inputField.disable();
        }
    }

    // Emit the selected value from the mat-options 
    emitFilteredValue(event: any) {
        if (this.enteredValue !== event.option.value) {
            this.enteredValue = event.option.value;
            this.selectedValue.emit(event.option.value);
        }
    }

    // Emit the typed value untill then, to populate and show values for auto completion
    emitTypedValue(event: any) {
        if (this.enteredValue !== event.target.value) {
            this.enteredValue = event.target.value;
            this.typedValue.emit(event.target.value);
        }
    }

    displayFn(chit): string | undefined {
        if(chit && chit.name) {
            return chit.name;
        } else {
            return chit;
        }
    }


}
