import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { USER_TYPES } from '../../../app.constants';

@Component({
    selector: 'app-ba-page-right-menu',
    templateUrl: './baPagesRightMenu.component.html',
    styleUrls: ['./baPagesRightMenu.scss'],
})
export class BaPageRightMenuComponent implements OnInit {
    // isSupportClicked = false;
    isForeman = false;
    showSelected: boolean;
    constructor(
        private router: Router,
        private renderer: Renderer2
    ) {
        this.showSelected = false;
        if (USER_TYPES.IS_FOREMAN) {
            this.isForeman = true;
        } else {
            this.isForeman = false;
        }
    }

    ngOnInit() {
        if (this.router.url.indexOf('/pages/dashboard') > -1) {
            this.showSelected = true;
        }
    }

    showSupportCard() {
        this.showSelected = !this.showSelected;
    }

    getWorkflowPending() {
        this.router.navigate(['/pages/workflows/pending']);
    }
    getChitGroups() {
        this.router.navigate(['/pages/chitgroups/view']);
    }
    copyMail(): void {
        const codes = Array.from(document.querySelectorAll('.email'));
        const codesText = codes.map((code: any) => code.innerText).join('\n');  
        const dummyElement = this.renderer.createElement('textarea');
        dummyElement.value = codesText;
        this.renderer.appendChild(document.body, dummyElement);
        dummyElement.select();
        document.execCommand('copy');
        this.renderer.removeChild(document.body, dummyElement);
      }
}
