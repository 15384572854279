import { Component, Inject, OnInit, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-arb-inst-and-due-interest-info-dialog',
    templateUrl: './arb-inst-and-due-interest-info-dialog.component.html',
    styleUrls: ['./arb-inst-and-due-interest-info-dialog.scss']
})
export class ArbInstAndDueInterestInfoDialog implements OnInit {
    
    // The dataForTableDialog variable stores all the data that is shown in the table
    dataForTableDialog: any;

    constructor(
        public dialogRef: MatDialogRef<ArbInstAndDueInterestInfoDialog>,@Inject(MAT_DIALOG_DATA) public matDialogData:any,
    ){  
        // If matDialogData variable is true, It represents that data is coming from the Dialog Reference or else it is coming from the parent component 
        this.dataForTableDialog = matDialogData.keyValueData;
    }

    ngOnInit(): void {

    }

    cancelDialog(action: string) {
		this.dialogRef.close({
			'action': action
		});
	}

}