import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { StateStorageService } from '../auth/state-storage.service';
import { missingTranslationHandler, translatePartialLoader } from '../../config/translation.config';
import { JhiLanguageService } from './language.service';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader
        ,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],
  providers:[JhiLanguageService]
})
export class TranslationModule {
  constructor(
    private translateService: TranslateService,
    private stateStorageService: StateStorageService,
  ) {
    this.translateService.setDefaultLang('en');
    // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    const langKey = this.stateStorageService.getLocale() ?? 'en';
    console.log("Using the lang key", langKey);
    this.translateService.use(langKey);
  }
}
