import { PaymentRequestItem } from './payment-item.model';
export class PaymentRequest {
    constructor(
        public userId?: number, // holds who has performed the payment
        public provenance?: string,
        public regulatorBranchCode?: string,
        public foremanCompanyCode?: string,
        public amount?: number,
        public trnxDesc?: string,
        public pspType?: string,
        public trnxType?: string,
        public items?: Array<PaymentRequestItem>,
        public agreedToTermsAndConditions?: boolean
    ) { }
}
