import { Component, OnInit } from "@angular/core";

@Component({
    templateUrl: './verification.component.html',
    styleUrls:["./verification.scss"]
})
export class VerificationComponet implements OnInit {
    title = 'otp-app';

    otp: string[] = []; // Array to store the input field values
    inputDigitLeft: string = "Verify code";
    btnStatus: string = "btn-light";
  
    public configOptions = {
      length: 6,
      inputClass: 'digit-otp',
      containerClass: 'd-flex justify-content-between'
    }
  
    
    
    ngOnInit() {
        
    }
    onOtpChange(event) {
      
        const filledDigits = this.otp.filter(digit => digit !== undefined && digit !== null && digit !== '');
        const remainingDigits = this.configOptions.length - filledDigits.length;
        
      
        if (remainingDigits > 0) {
          this.inputDigitLeft = `${remainingDigits} digit${remainingDigits > 1 ? 's' : ''} Left`;
          this.btnStatus = 'btn-light';
        } else if (remainingDigits === 0) {
          this.inputDigitLeft = "Submit";
          this.btnStatus = 'btn-outline';
        } else {
          this.inputDigitLeft = "Verify code";
          this.btnStatus = 'btn-light';
        }
      } 
   
  }
