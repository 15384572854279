
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ForemanCompanyNew } from './foreman-companies-new/foreman-companies-new.model';
import { SNB_CORE_URL, SNB_WF_URL, USER_TYPES, SNB_UAA_URL } from '../../app.constants';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { HttpUtilService } from '../../shared/http-util.service';
import { CommonService } from '../../shared/services/common-service.service';

@Injectable()
export class ForemanCompaniesService extends CommonService {
    
    constructor(
        private httpClient: HttpClient,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private httpUtilService: HttpUtilService,
    ) {
        super();
    }

    createNewForemanCompany<T>(
        foremanyCompany: ForemanCompanyNew,
        req?: any
    ):Observable<any> {
        const copy: ForemanCompanyNew = Object.assign({}, foremanyCompany);
        const options = this.prepareHeader(req);
        return this.httpClient
            .post(
                `${SNB_CORE_URL}/regulator/foreman-companies/create-company-and-admin-user`,
                copy,
                options
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    updateNewForemanCompany<T>(
        foremanyCompany: ForemanCompanyNew,
        req?: any
    ):Observable<any> {
        const copy: ForemanCompanyNew = Object.assign({}, foremanyCompany);
        const options = this.prepareHeader(req);
        return this.httpClient
            .put(`${SNB_CORE_URL}/regulator/foreman-companies`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createForemanCompanyBranch<T>(
        foremanyCompany,
        req?: any
    ):Observable<any> {
        const copy: ForemanCompanyNew = Object.assign({}, foremanyCompany);
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = "foreman/foreman-companies/branches";
        } else {
            url = "regulator/foreman-companies/branches";
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    updateForemanCompanyBranch<T>(
        foremanyCompany,
        req?: any
    ):Observable<any> {
        const copy: ForemanCompanyNew = Object.assign({}, foremanyCompany);
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = "foreman/foreman-companies/branches";
        } else {
            url = "regulator/foreman-companies/branches";
        }
        return this.httpClient
            .put(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    requestForemanCompanyBranchByForeman<T>(
        foremanyCompany,
        cin,
        foremanCompanyName,
        req?: any
    ):Observable<any> {
        const copy: ForemanCompanyNew = Object.assign({}, foremanyCompany);
        const options = this.prepareHeader(req);
        return this.httpClient
            .post(
                `${SNB_WF_URL}/foreman-company-branch/register?cin=${cin}&foremanCompanyName=${foremanCompanyName}`,
                copy,
                options
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompaniesList<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies`;
        }else{
            url =`public/foreman-companies/get-mini-details`;
        }
        return this.httpClient
        .get<T>(`${SNB_CORE_URL}/${url}`, {
            headers: options["headers"],
            params: options["params"],
            observe: "response",
        }).pipe(
        catchError((err) => {
            return observableThrowError(err);
        }));
    }


    getCompanyDetails<T>(companyCode: string, req?: any): Observable<HttpResponse<T>> {
        const options = this.prepareHeader(req);
        return this.httpClient
            .get<T>(`${SNB_CORE_URL}/public/foreman-companies/${companyCode}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyDetails<T>(id?: string):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/foreman-companies`;
        } else {
            url = `regulator/foreman-companies/${id}`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanDirectorInfo<T>(id: string,isActiveFlag: boolean,req?: any): Observable<any> {
        const options = this.prepareHeader(req);
    
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen`;
        } else {
            url = `foreman/foremen`;
        }
        let queryParams = `companyCode=${id}`;
        if (isActiveFlag) {
            queryParams += `&isActive=true`;
        }
    
        return this.httpClient
            .get(
                `${SNB_CORE_URL}/${url}?${queryParams}`,
                options
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }
    

    sendActivationLinkToForeman<T>(
        acctivationId,
        type,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient
            .post(
                `${SNB_UAA_URL}/account-act-details/send-activation-link/${acctivationId}?notificationType=${type}`,
                "",
                options
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createForemanUserWithoutLogin<T>(
        userData,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, userData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/create-without-login`;
        } else {
            url = `foreman/foremen/create-without-login`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

   

    addBankAccount<T> (accData: any, req?:any): Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, accData);
        
        return this.httpClient
            .post(`${SNB_WF_URL}/foreman/misc/bank-details`, copy, options)
             .pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createForemanCEO<T>(userData, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, userData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/create-ceo`;
        } else {
            url = `foreman/foremen/create-ceo`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createAdmin<T>(userData, companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, userData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `admin/${companyCode}/foremen/add-admin`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }


  

    createForemanUser<T>(userData, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, userData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen`;
        } else {
            url = `foreman/foremen`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    updateForemanUser<T>(userData, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, userData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen`;
        } else {
            url = `foreman/foremen`;
        }
        return this.httpClient
            .put(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    /**
     * This method makes given foreman as admin.
     * @param foremanId 
     * @param req 
     * @returns 
     */
    makeForemanAsAdmin<T>(companyCode, foremanId, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = {};
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `admin/${companyCode}/foremen/make-foreman-admin/${foremanId}`;
        } 
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

     /**
     * This method makes given foreman as admin.
     * @param foremanId 
     * @param req 
     * @returns 
     */
     removeAdminAccess<T>(companyCode, foremanId, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = {};
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `admin/${companyCode}/foremen/remove-admin-access/${foremanId}`;
        } 
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    /**
     * This method makes given foreman designation to the existing foreman user.
     * @param foremanId 
     * @param req 
     * @returns 
     */
    addForemanDesignation<T>(companyCode, foremanId, designation, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = {};
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `cust-support/${companyCode}/foremen/add-foreman-designation/${foremanId}?designation=${designation}`;
        } 
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    /**
     * This method removes the foreman designation to the given foreman.
     * @param foremanId 
     * @param req 
     * @returns 
     */
    removeForemanDesignation<T>(companyCode, foremanId, designation, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = {};
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `cust-support/${companyCode}/foremen/remove-foreman-designation/${foremanId}?designation=${designation}`;
        } 
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createBankForCompany<T>(bankData, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, bankData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/bank-details`;
        } else {
            url = `foreman/foreman-companies/bank-details`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    updateBankForCompany<T>(bankData, req?: any):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, bankData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/bank-details`;
        } else {
            url = `foreman/foreman-companies/bank-details`;
        }
        return this.httpClient
            .put(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getBankDetailsForCompany<T>(companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/bank-details?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/bank-details`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }
    getBankStatementLedger<T>(req?:any):Observable<any>{
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/sep-ac-bank-stmnt-ledger`;
        } else {
            url = `foreman/sep-ac-bank-stmnt-ledger`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));

    }
    
   createAuditorForCompany<T>(
        auditorData,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, auditorData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/auditor-details`;
        } else {
            url = `foreman/foreman-companies/auditor-details`;
        }
        return this.httpClient
            .post(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    updateAuditorForCompany<T>(
        auditorData,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader();
        const copy = Object.assign({}, auditorData);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/auditor-details`;
        } else {
            url = `foreman/foreman-companies/auditor-details`;
        }
        return this.httpClient
            .put(`${SNB_CORE_URL}/${url}`, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getAuditorsDetailsForCompany<T>(companyCode,req?:any): Observable<any> {
        const options = this.httpUtilService.createRequestOption(req);
       
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/auditor-details?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/auditor-details`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyMainBranchDetails<T>(
        id: string
    ):Observable<any> {
        const options = this.prepareHeader();
        return this.httpClient
            .get(
                `${SNB_CORE_URL}/regulator/foreman-companies/branches/` + id,
                options
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyMyOwnedBranches<T>(
        companyCode: string,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/my-owned-branches?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/my-owned-branches`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }
    getForemanCompanyBranches<T>(
        companyCode: string,
        branchCode:string,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/branches?companyCode=${companyCode}&branchCode=${branchCode}`;
        } else if(USER_TYPES.IS_FOREMAN) {
            url = `foreman/foreman-companies/my-owned-branches`;
        } else {
            url= `public/foreman-companies/${companyCode}/branches`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyHeadOfficeData<T>(
        companyCode: string
    ):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/main-branch?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/main-branch`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getROCBranchData<T>(branchId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/regulator-orgs/branches/${branchId}`;
        } else {
            url = `foreman/regulator-orgs/branches/${branchId}`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanySubBranchesList<T>(
        companyCode?: string,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/branches?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/branches`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanDetailsById<T>(id):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/${id}`;
        } else {
            url = `foreman/foremen/${id}`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }
    getBankDetailsById<T>(id,companyCode):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/foreman-companies/bank-details/${id}`;
        } else {
            url=`regulator/foreman-companies/bank-details/${id}?companyCode=${companyCode}`;     
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    deleteBankDetailsById<T>(id, companyCode):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/foreman-companies/bank-details/${id}`;
        } else {
            url=`regulator/foreman-companies/bank-details/${id}?companyCode=${companyCode}`;     
        }
        return this.httpClient
            .delete(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }
    updateBankDetailsActiveStatus<T>(id, status: boolean, companyCode?: string, date?: any):Observable<any> {
        const options = this.prepareHeader();
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            if (date) {
                url = `foreman/foreman-companies/bank-details/${id}/${status}?date=${date}`;
            }
            else {
                url = `foreman/foreman-companies/bank-details/${id}/${status}`;
            }
        } else {
            url=`regulator/foreman-companies/bank-details/${id}/${status}?companyCode=${companyCode}`;     
        }
        return this.httpClient
            .put(`${SNB_CORE_URL}/${url}`, {}, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyFilters<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";

        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/get-filters`;
        } else {
            url = `foreman/foreman-companies/get-filters`;
        }

        return this.httpClient
            .get<T>(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getFinancialData<T>(
        companyCode?: any,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/company-financial/financial-details/${companyCode}`;
        } else {
            url = `foreman/company-financial/financial-details`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getArcOffices<T>():Observable<any> {
        return this.httpClient
            .get<T>(`${SNB_CORE_URL}/public/regulator-branch`).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getforemanByBranchRefId<T>(id):Observable<any> {
        return this.httpClient
            .get<T>(`${SNB_CORE_URL}/foreman/foremen/by-branch-ref-id/${id}`).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getSepAccountStatements<T>(req?: any):Observable<any> {
        if(!req) {
            req = {};
        }
        if (!req.query) {
            req.query ={};
        }
        console.log("Flag-1");
        console.log('Request:', req);
        const copy: any = Object.assign({}, req);
        const options = this.httpUtilService.createRequestOption(copy) 
        return this.httpClient.get(`${SNB_CORE_URL}/${this.getUerType()}/bank-statement`,
        {
            headers: options['headers'],
            params: options['params'],
            observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    saveOrgInfo<T>(orgInfo: any):Observable<any> {
        return this.httpClient
            .post(`${SNB_WF_URL}/public/foreman-company/register`, orgInfo).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    deleteForeman<T>(
        companyCode,
        branchCode,
        foremanRefId
    ):Observable<any> {
        return this.httpClient
            .post(
                `${SNB_CORE_URL}/admin/${companyCode}/foremen/remove-foreman-as-owner/${branchCode}/${foremanRefId}`,
                {}
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getFormanDetailByMobileNumber(companyCode, mobileNo, req?: any) {
        const options = this.createRequestOption(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/by-mobile-number/${companyCode}/${mobileNo}`;
        } else {
            url = `foreman/foremen/by-mobile-number/${mobileNo}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`,{ headers: options.headers, params: options.params});
       
    }

    getCeoDirectorDetailsByMobileNumberAndDesignation(mobileNo: any,designation:string,req?: any) {
        const options = this.createRequestOption(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/validate-new-user/${mobileNo}?designation=${designation}`;
        } else {
            url = `foreman/foremen/validate-new-user/${mobileNo}?designation=${designation}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`,{ headers: options.headers, params: options.params});
    }

    checkIsRegulatorApprovalRequiredForOrgChanges(req?: any) {
        const options = this.createRequestOption(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/foreman-companies/is-regulator-approval-required-for-org-changes`,
            { headers: options.headers, params: options.params})
           .pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getMissedMonthsbyBankId(bankId,companyCode?:string, req?: any) {
        const options = this.createRequestOption(req);
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/bank-statement/${bankId}/missing-months`;
        } else {
            url = `regulator/bank-statement/${bankId}/missing-months/${companyCode}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, { headers: options.headers, params: options.params});
       
    }

    getBankStatements(req?: any) {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/bank-statement`;
        } else {
            url = `regulator/bank-statement`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, { 
              headers: options["headers"], 
              params: options["params"], 
            })
          
    }

    addExistingForeman<T>(
        companyCode,
        branchCode,
        foremanRefId
    ):Observable<any> {
        return this.httpClient
            .post(
                `${SNB_CORE_URL}/admin/${companyCode}/foremen/add-foreman-as-owner/${branchCode}/${foremanRefId}`,
                {}
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    addNewForemanToBranch<T>(
        companyCode,
        branchCode,
        foremanDetails
    ):Observable<any> {
        return this.httpClient
            .post(
                `${SNB_CORE_URL}/admin/${companyCode}/foremen/register-foreman/${branchCode}`,
                foremanDetails
            ).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    foremanChangeRequestByForeman<T>(requestData): Observable<any> {
        let url;
        if(requestData.foremanDetails && requestData.foremanDetails.designation[0] === 'FOREMAN') {
            url = `${SNB_WF_URL}/foreman/misc/request-foreman-change`
        } else {
            url = `${SNB_WF_URL}/foreman/misc/request-user-change`
        }
        return this.httpClient
            .post(url, requestData)
            .pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getBranchWiseATOs<T>(
        companyCode: string,
        req
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/company-financial/branch-atos/${companyCode}`;
        } else {
            url = `foreman/company-financial/branch-atos`;
        }

        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, {
                headers: options["headers"],
                params: options["params"],
                observe: "response",
            }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getFinancialStatus<T>(
        companyCode,
        includeAtoOfCompany: boolean,
        req?: any
    ):Observable<any> {
        const options = this.prepareHeader(req);
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/company-financial/status/${companyCode}?includeAtoOfCompany=${includeAtoOfCompany}`;
        } else {
            url = `foreman/company-financial/status?includeAtoOfCompany=${includeAtoOfCompany}`;
        }
        return this.httpClient
            .get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getLoginAccountDetails<T>(userId: number):Observable<any> {
        const options = this.prepareHeader({});
        let url = "";
        if (USER_TYPES.IS_REGULATOR) {
            url = `users/${userId}`;
        } else {
            let body: T = {} as T;
            return observableOf(new HttpResponse<T>({'body': body}));
        }
        return this.httpClient
            .get(`${SNB_UAA_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    private prepareHeader(
        urlSearchParams?: any,
        headers?: HttpHeaders | null
    ): object {
        headers = headers || new HttpHeaders();

        headers = headers.set("Content-Type", "application/json");
        headers = headers.set("Accept", "application/json");

        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof urlSearchParams.page !== "undefined") {
                params = params.set("page", urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set("size", urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set("sort", urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set("stateIn", urlSearchParams.stateIn);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach((key) => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params,
        };
    }

    createRequestOption(req?: any): { headers?: HttpHeaders; params?: HttpParams } {
        let headers = new HttpHeaders();
        let params = new HttpParams();
    
        const token =
          this.localStorage.retrieve("authenticationToken") ||
          this.sessionStorage.retrieve("authenticationToken");
    
        if (token && token.expires_at && token.expires_at > new Date().getTime()) {
          headers = headers.set("Authorization", "Bearer " + token.access_token);
        }
    
        if (req) {
          params = params.set("page", req.page);
          params = params.set("size", req.size);
          
          if (req.sort) {
            params = params.set("sort", req.sort);
          }
          
          params = params.set("query", req.query);
        }
    
        return { headers, params };
      }
}
