import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'error-page',
    templateUrl: './errorpage.component.html',
    styleUrls: ['./errorpage.scss'],
})
export class ErrorPageComponent {
    
    errorStatus: any;
    errorDetail: any;
    errorTitle: any;

    constructor(
        private activatedRoute: ActivatedRoute)    
    {

    }

    ngOnInit() {

        this.activatedRoute.queryParams.subscribe(params => {
            this.errorStatus = params.status;
            this.errorDetail = params.detail;
            this.errorTitle = params.title;
        });

    }

}
