<div id="register">
  <div class="auth-main" id="login">
    <div id="login-form-wrapper">



      <!-- <vg-player oncontextmenu="return false;">
            <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                <source src="http://static.videogular.com/assets/videos/videogular.mp4" type="video/mp4">
            </video>
        </vg-player> -->





      <div id="login-form">
        <div class="alert alert-success" *ngIf="success" jhiTranslate="register.messages.success">
          <strong>Registration Successful!</strong> We will contact you to activate your account!.
        </div>
        <div class="clearfix overflow-box  materialContainer" *ngIf="!success">


          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="left_wrapper">
                <i class="fa fa-building-o" *ngIf="!foremanBranchDetails" aria-hidden="true"></i>
                <div class="company_info" *ngIf="foremanBranchDetails">
                  <h1>
                    {{foremanBranchDetails?.foremanCompanyName }}
                  </h1>

                  <div>
                    <h3>
                      Branch Name : <span>{{foremanBranchDetails?.foremanBranchName }} </span></h3>
                    <h3>
                      ARC Office : <span>{{foremanBranchDetails?.regulatorBranchName }}</span>
                    </h3>
                    <h3>
                      State :<span> {{foremanBranchDetails?.regulatorProvenance }}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-xs-12">
              <form #f="ngForm" [formGroup]="registerNewForeman" autocomplete="off" (ngSubmit)="onFormSubmit($event)"
                class="box md-whiteframe-8dp">
                <div class="register_form">


                  <div class="row">
                    <div class="top_sec col-md-12">
                      <h2>Registration</h2>
                      <br />
                    </div>
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <!-- <input matInput placeholder="First Name" formControlName="firstName"> -->
                        <mat-label>First Name</mat-label>
                        <input matInput type="text" name="name" formControlName="firstName"
                          required>
                        <mat-error *ngIf="registerNewForeman?.controls?.firstName?.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <mat-label>Last Name</mat-label>
                        <input matInput type="text" name="lname" formControlName="lastName"
                          required>
                        <mat-error *ngIf="registerNewForeman?.controls?.lastName?.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput formControlName="mobileNumber">
                      </mat-form-field>
                    </div>

                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <!-- <mat-error *ngIf="registerNewForeman?.controls?.email?.hasError('required')" jhiTranslate="entity.validation.required"></mat-error> -->
                      </mat-form-field>
                    </div>

                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <mat-label>Enter Password</mat-label>
                        <input matInput type="password" (blur)="confirmPassword()" formControlName="password"
                          placeholder="Enter Password" required (input)="validatePassword();">
                        <mat-error *ngIf="registerNewForeman?.controls?.password?.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput type="password" (blur)="confirmPassword()" formControlName="ConfirmPassword"
                          required>
                        <mat-error *ngIf="registerNewForeman?.controls?.ConfirmPassword?.hasError('required')"
                          jhiTranslate="entity.validation.required"></mat-error>
                        <mat-error *ngIf="registerNewForeman?.controls?.ConfirmPassword?.hasError('passwordMismatch')">Passwords
                          didn't match</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <ul style="padding: 5px 0 10px 15px">
                        <li [ngStyle]="{'color': isEightChar ? 'green' : 'red'}" style="padding-bottom: 10px;">Atleat 8 characters &nbsp;<i class="fa fa-times" aria-hidden="true" *ngIf="!isEightChar"></i><i class="fa fa-check" aria-hidden="true" *ngIf="isEightChar"></i>
                        </li>
                        <li [ngStyle]="{'color': hasUppercase ? 'green' : 'red'}" style="padding-bottom: 10px;">Minimum one Upper & Lower case character &nbsp;<i class="fa fa-times" aria-hidden="true" *ngIf="!hasUppercase"></i><i class="fa fa-check" aria-hidden="true" *ngIf="hasUppercase"></i></li>
                        <li  [ngStyle]="{'color': hasSpecialAndNumericCase ? 'green' : 'red'}" style="padding-bottom: 10px;">Minimum one Numeric & Special character &nbsp;<i class="fa fa-times" aria-hidden="true" *ngIf="!hasSpecialAndNumericCase"></i><i class="fa fa-check" aria-hidden="true" *ngIf="hasSpecialAndNumericCase"></i></li>
                      </ul>
                    </div>
                    <div class="col-md-12 text-right">
                      <button mat-raised-button [disabled]="isFormSubmitted || !hasSpecialAndNumericCase || !hasUppercase || !isEightChar " [ngClass]="{'notAllowed':isFormSubmitted}"
                        type="submit">
                        <span style="display: flex;">
                           REGISTER 
                          <i *ngIf="isFormSubmitted" style="margin-left: 10px;font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </span>
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>