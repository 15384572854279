import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePasswordService } from '../changepassword/changepassword.service';
import { SnBToasterService } from '../../shared/components/toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './changepassword.html',
    styleUrls: ['../../theme/sass/conf/conf-a.scss', './changepassword.scss']
})
export class SnBChangePasswordComponent implements OnInit {

    user: any = {};
    userData: any = {};
    accessToken: any;


    intialScreenAnimationClass = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private changePasswordService: ChangePasswordService,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        private fb: FormBuilder,
    ) {
        this.user.password = '';
        this.user.confirmPassword = '';
    }


    createPassword(form) {

        event.preventDefault();
        if (!form.valid) {
            return false;
        }
        // delete this.user.confirmPassword;
        this.userData.activationToken = this.accessToken;
        this.userData.password = this.user.password;


        this.changePasswordService.updateUserPassword(this.userData).subscribe(
            success => {
                this.toasterService.showSuccessToaster('Account activated');
                setTimeout(() => {
                    // this.user.confirmPassword = "";
                    this.router.navigate(['/login']);
                    this.user = {};
                }, 800);
            },

            fail => {
                this.toasterService.showFailToaster(JSON.parse(fail._body).detail);
            }
        );
    }

    ngOnInit() {

        setTimeout(() => {
            this.intialScreenAnimationClass = true;
        }, 800);

        this.activatedRoute.queryParams.subscribe(params => {
            this.accessToken = params['token'];

            // if (this.accessToken) {
            //     this.changePasswordService.checkUserToken(this.accessToken).subscribe((res: Response) => {
            //         this.userData = res;
            //     }, fail => {
            //         this.toasterService.showSuccessToaster(fail.detail);
            //     });
            // }
        });
    }


}
