<form [formGroup]="searchForm" style="margin-top: 2%;margin-bottom: 1%;" novalidate
    (ngSubmit)="searchForChits()" autocomplete="off">
    <div [ngStyle]="{'text-align': isDataFetched ? 'left' : 'center'}">
        <mat-card style="padding-top: 0px;padding-bottom: 10px;display: inline-block;"
            [ngClass]="{'public-filter-data-fetched' : isDataFetched}"
            class="enroll_card public-filter-data-not-fetched">

            <div class="filters">
                <div class="row">

                    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-top: 15px">
                        <mat-form-field appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>Chit No.</mat-label>
                            <input type="text" matInput formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-top: 15px">
                        <mat-form-field appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>PSO No.</mat-label>
                            <input type="text" matInput formControlName="psoNumber">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-top: 15px">
                        <mat-form-field appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>Company Name</mat-label>
                            <input type="text" matInput [matAutocomplete]="autoFilterForemanCompanyNames"
                                formControlName="companyCode"
                                (blur)="clearBranchCode($event)">
                            <mat-autocomplete #autoFilterForemanCompanyNames="matAutocomplete"
                                [displayWith]="displayFn.bind(this)" (optionSelected)="companySelected($event)">
                                <mat-option *ngFor="let filteritem of filteredForemanCompanies"
                                    [value]="filteritem.companyCode">
                                    {{filteritem.name}}</mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="searchForm.controls.companyCode.hasError('required')"
                                jhiTranslate="entity.validation.required"></mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12" style="margin-top: 15px">
                        <mat-form-field appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>Company Branch</mat-label>
                            <mat-select formControlName="foremanCompanyBranchCode">
                                <mat-option *ngFor="let branch of foremanCompanyBranchNames" [value]="branch.branchCode"
                                    required>
                                    {{branch.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="searchForm.controls.foremanCompanyBranchCode.hasError('required')"
                                jhiTranslate="entity.validation.required">
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-10 col-sm-6 col-xs-12" style="padding-top: 10px;">
                        <mat-error *ngIf="filtersNotSelected">
                            Please Type Chit No. / PSO No. OR Select Chit company and Branch
                        </mat-error>
                        <mat-hint *ngIf="!filtersNotSelected">
                            Please Type Chit No. / PSO No. OR Select Chit company and Branch
                        </mat-hint>
                    </div>
                    <div class="col-md-2 col-sm-6 col-xs-12">
                        <button style="float:right" type="submit" mat-raised-button mat-button class="mat-raised-button" color="primary">
                            Search
                        </button>
                    </div>
                </div>

            </div>

            <div class="company-branch-data" *ngIf="isDataFetched">
                <div class="row form-top-section" *ngIf="loadedChitInfoByBranch">
                    <div class="snb_content_section chitgroup_details card">

                        <div class="tab-content">
                            <div class="row">

                                <mat-accordion>
                                    <mat-expansion-panel [expanded]="true">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Company Details
                                                <span *ngIf="foremanMainBranchAddress?.contactDetails?.mobileNumber"
                                                    style="padding-left: 5px;"> - Ph No:
                                                    <span>
                                                        {{foremanMainBranchAddress?.contactDetails?.mobileNumber}}
                                                    </span>
                                                </span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="step-col-content">
                                            <div class="col-wrapper">

                                                <div class="s-col-2" style="padding-top: 5px;">
                                                    <div class="property_2">
                                                        CIN / Registration No :
                                                    </div>
                                                    <div class="value_2" *ngIf="companyDetails?.cin">
                                                        {{companyDetails?.cin}} </div>
                                                    <div class="value_2" *ngIf="!companyDetails?.cin"> -- </div>
                                                    <br>
                                                    <div class="property_2">
                                                        Registered Office Address :
                                                    </div>
                                                    <div class="value_2"
                                                        *ngIf="foremanMainBranchAddress?.contactDetails?.addresses?.length">
                                                        <p>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.addressLine1">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.addressLine1}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.addressLine2">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.addressLine2}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.street">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.street}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.city">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.city}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.district">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.district}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.state">{{foremanMainBranchAddress?.contactDetails?.addresses[0]?.state}},</span>
                                                            <span
                                                                *ngIf="foremanMainBranchAddress?.contactDetails?.addresses[0]?.pin">PIN-
                                                                {{foremanMainBranchAddress?.contactDetails?.addresses[0]?.pin}}</span>
                                                            <span style="display: block">
                                                                Email : <span
                                                                    *ngIf="!foremanMainBranchAddress?.contactDetails?.email"
                                                                    style="padding-left: 30px">
                                                                    -- </span> <span
                                                                    *ngIf="foremanMainBranchAddress?.contactDetails?.email">{{foremanMainBranchAddress?.contactDetails?.email}}</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="value_2" *ngIf="!companyDetails?.cin"> -- </div>

                                                </div>
                                            </div>
                                        </div>

                                    </mat-expansion-panel>
                                    <mat-expansion-panel
                                        *ngIf="foremanMainBranchAddress?.id !== foremanBranchAddress?.id">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Branch Details
                                                <span *ngIf="foremanBranchAddress?.contactDetails?.mobileNumber"
                                                    style="padding-left: 5px;"> - Ph No:
                                                    <span>
                                                        {{foremanBranchAddress?.contactDetails?.mobileNumber}}
                                                    </span>
                                                </span>
                                            </mat-panel-title>
                                            <mat-panel-description>

                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div class="step-col-content">
                                            <div class="col-wrapper">

                                                <div class="s-col-2" style="padding-top: 5px;">
                                                    <div class="property_2">
                                                        Name :
                                                    </div>
                                                    <div class="value_2" *ngIf="foremanBranchAddress?.name">
                                                        {{foremanBranchAddress?.name}} </div>
                                                    <div class="value_2" *ngIf="!foremanBranchAddress?.name"> -- </div>
                                                    <br>

                                                    <div class="property_2">
                                                        Address :
                                                    </div>
                                                    <div class="value_2"
                                                        *ngIf="foremanBranchAddress?.contactDetails?.addresses?.length">
                                                        <p>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.addressLine1">{{foremanBranchAddress?.contactDetails?.addresses[0]?.addressLine1}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.addressLine2">{{foremanBranchAddress?.contactDetails?.addresses[0]?.addressLine2}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.street">{{foremanBranchAddress?.contactDetails?.addresses[0]?.street}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.city">{{foremanBranchAddress?.contactDetails?.addresses[0]?.city}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.district">{{foremanBranchAddress?.contactDetails?.addresses[0]?.district}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.state">{{foremanBranchAddress?.contactDetails?.addresses[0]?.state}},</span>
                                                            <span
                                                                *ngIf="foremanBranchAddress?.contactDetails?.addresses[0]?.pin">PIN-
                                                                {{foremanBranchAddress?.contactDetails?.addresses[0]?.pin}}</span>
                                                            <span style="display: block">
                                                                Email : <span
                                                                    *ngIf="!foremanBranchAddress?.contactDetails?.email"
                                                                    style="padding-left: 30px">
                                                                    -- </span> <span
                                                                    *ngIf="foremanBranchAddress?.contactDetails?.email">{{foremanBranchAddress?.contactDetails?.email}}</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="value_2"
                                                        *ngIf="!foremanBranchAddress?.contactDetails?.addresses?.length">
                                                        --
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</form>