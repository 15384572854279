export class ForemanCompanyNew {

    constructor(
        public name: string,
        public incorporationDate: Date,
        public cin: string,
        public pan?: string,
        public gstn?: string,
        public status?: string,
        public adminUser?: {
            firstName: string,
            lastName: string,
        },
        public mainBranch?: {

        },
        public contactDetails?: {
            mobileNumber: string,
            email: string
        }
    ) { }

}


export class ForemanSubBranchType {
    constructor(
        public foremanSubBranchType: ForemanSubBranchTypeSelection
    ) { }
}

export const enum ForemanSubBranchTypeSelection {
    PRESENT, OFFICE, PERMANENT
}

export const ForemanOrgsTypes = [
    {
        'name': 'Incorporated as company',
        'id': 'INCORPORATED_AS_COMPANY'
    }, {
        'name': 'Co-Operative OR Registered Society',
        'id': 'CO_OPERTIVE_OR_REGISTERED_SOCIETY'
    }, {
        'name': 'Unregistered association of individuals',
        'id': 'UNREGISTERED_ASSOCIATION_OF_INDIVIDUALS'
    }, {
        'name': 'Partnership',
        'id': 'PARTNERSHIP'
    }, {
        'name': 'Sole proprietorship',
        'id': 'SOLE_PROPRIETORSHIP'
    }
];
