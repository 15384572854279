<div class="snb_error_page">

  <div class="main_wrapper">
    <div class="top_nav">

      <a href="https://t-chits.telangana.gov.in">Home</a>
      <a href="https://foreman.t-chits.telangana.gov.in/">Login as Foreman</a>
      <a href="https://roc.t-chits.telangana.gov.in/">Login as RoC</a>
    </div>
    <div class="left_box">
      <h1>Oops!</h1>
      <h2>{{errorTitle}}</h2>
      <h2>

        {{errorDetail}}
        <!-- We can't seems to find the<br> page you're looking for. -->
      </h2>
      <h5>Error Code:
        <!-- 404 -->
        {{errorStatus}}
      </h5>


    </div>
    <div class="right_box">
      <img class="img-responsive" alt="snbchain error" src="../../../../assets/img/error.png" />
    </div>
  </div>
</div>
