
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SnBToasterService } from '../components/toaster/toaster.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private toasterService: SnBToasterService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
               this.toasterService.showFailToaster(err);
            }
        }));
    }
}