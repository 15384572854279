export class PaymentRequestItem {
    constructor(
        public amount?: number, // holds trnx amount for this item.
        public trnxDesc?: string, // description of transaction item.
        public chitRefId?: string, // chit ref id
        public foremanCompanyBranchCode?: string, // holds the foreman branch code.
        public workFlowProcessInstanceId?: string, // workflow process instance id
        public workFlowFormName?: string, // workflow process instance id
        public applicationFormName?: string, // chit ref id
        public userCharges?: number // holds the user charges
    ) { }
}
