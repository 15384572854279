import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByForNumbers',
    pure: false
})
export class ArraySortPipe implements PipeTransform {
    transform(arr: Array<any>, prop: any, reverse: boolean = false) {
        if (!arr.length) {
            return [];
        }
        const m = reverse ? -1 : 1;
        return arr.sort((a: any, b: any): number => {
            const x = Number(a[prop]);
            const y = Number(b[prop]);
            return (x === y) ? 0 : (x < y) ? -1 * m : 1 * m;
        });
    }
}
