
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SNB_UAA_URL } from '../../app.constants';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

@Injectable()
export class SubscriberCornerService {
    constructor(
        private httpClient: HttpClient
    ) { }

    generateOTP<T>(userData):Observable<any> {
        let url: string = SNB_UAA_URL + '/account/tmp_login_otp_generate?';
        if (userData.name) {
            url = url + 'name=' + userData.name;
        }
        if (userData.login) {
            url = url + '&login=' + userData.login;
        }
        return this.httpClient.get(url).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

}
