import { Injectable } from '@angular/core';

// holds the mapping b/w UI function to User Privilges required to allow this function.
const priviligesList: any = require('./../../../assets/json/privileges.json');

@Injectable()
export class SnbFunctionToPrivilegeMappingServices {
     
    constructor() {
    }

    getPriviliges() {
        return priviligesList;
    }
}
