import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SNB_UAA_URL } from '../../app.constants';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ChangePasswordService {

    constructor(private httpClient: HttpClient) { }


    checkUserToken(token: string) {

        return this.httpClient.get(SNB_UAA_URL + '/account/activate-token?token=' + token);
    }



    updateUserPassword(user) {
        this.validate(user);
        var url = SNB_UAA_URL + '/account/reset_password_token';
        return this.httpClient.post(url, user);
    };


    validate(user) {
        var errors = [];
        if (user.comments != null && user.comments.length > 100) {
            errors.push("comments length can not be more than 100 characters");
        }

        if (errors.length > 0) {
            throw errors;
        }

    };

    validatePassword(password: string): { isEightChar: boolean, hasUppercase: boolean, hasSpecialAndNumericCase: boolean } {
        const isEightChar = password.length >= 8;
    
        const uppercasePattern = /[A-Z]/;
        const lowercasePattern = /[a-z]/;
        const hasUppercase = uppercasePattern.test(password) && lowercasePattern.test(password);
    
        const numericCasePattern = /[0-9]/;
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
        const hasSpecialAndNumericCase = numericCasePattern.test(password) && specialCharPattern.test(password);
    
        return {
          isEightChar,
          hasUppercase,
          hasSpecialAndNumericCase
        };
      }

}
