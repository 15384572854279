<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
    [ngClass]="{scrolled: isScrolled}" style="overflow: auto;">
    <div class="snb_logo_sec">
        <a routerLink="/pages/dashboard" class="snb-logo pay-mod-logo clearfix">
            <span class="company-logo">
                <!--<img class="img-responsive" src="assets/img/ap-logo.png" /> Andhra Pradesh -->
                <img class="img-responsive" src="assets/img/telangana-roc.png" />
                Telangana
            </span>
            <!-- <span *ngIf="currentAccount">
					{{currentAccount.firstName}}
				</span> -->
        </a>
    </div>
    <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon">
        <i class="fa fa-outdent" aria-hidden="true"></i>
    </a>

    <!-- <div class="search" style="display: none;">
    <i class="ion-ios-search-strong" ng-click="startSearch()"></i>
    <input id="searchInput" type="text" placeholder="Search for Chit Groups" />
  </div> -->
    <marquee class="snb-marquee" onmouseover="this.stop();" onmouseout="this.start();">
        <p>
            <a routerLink="/pages/new-features" target="_blank" style="color: red;position:relative;bottom:5px;">
                There are new changes made for digitizing the manual/offline arbitration applications. Click here to view them.
            </a>    
        </p>
        <p>
            <a routerLink="/pages/new-features" target="_blank" style="color: red;position:relative;bottom:5px;">
                New features are released. Please click here for more info
            </a>
        </p>
    </marquee>
    <!-- <div class="snb-language">
        <a [matMenuTriggerFor]="langChange" *ngIf="!user_user || isUserAuthenticated"
            class="profile-toggle-link dropdown-toggle">
            <i class="fa fa-language" aria-hidden="true"></i>
        </a>
    </div> -->

    <div class="right_block">

        <!-- Search -->
        <!-- <div class="snb_search">
      <i class="material-icons">search</i>
      <input type="search" placeholder="Search for Chit Groups" />
    </div> -->

        <!-- Notifications -->
        <!-- <div class="snb_notifications">
      <a href="#">
        <i class="material-icons">notifications</i>
      </a>
    </div> -->

        <!-- Help -->
        <!-- <div class="snb_help">
      <a href="#">
        <i class="material-icons">help</i>
      </a>
    </div> -->

        <!-- User Profile -->

        <div class="user-profile clearfix">

            <div class="dropdown al-user-profile">

                <a [matMenuTriggerFor]="userprofile" *ngIf="!user_user || isUserAuthenticated"
                    class="profile-toggle-link dropdown-toggle">
                    <i class="material-icons">person_pin</i>
                    <span class="icon_label" *ngIf="currentAccount">{{currentAccount?.firstName}}
                        {{currentAccount.lastName}}</span>
                </a>
                <mat-menu #userprofile="matMenu" class="user-profile">
                    <button *ngIf="isAuthenticated() && !user_user" mat-menu-item routerLink="/login"
                        routerLinkActive="active">
                        <i class="material-icons">assignment_ind</i>
                        <span>Sign In</span>
                    </button>
                    <button *ngIf="!isAuthenticated() && !user_user" mat-menu-item [routerLink]=" ['/pages/profile'] ">
                        <i class="material-icons">assignment_ind</i>
                        <span jhiTranslate="global.menu.account.profile">Profile</span>
                    </button>
                    <button *ngIf="!isAuthenticated() && !user_user" mat-menu-item (click)="logout()">
                        <i class="material-icons">exit_to_app</i>
                        <span>Sign out</span>
                    </button>
                    <button *ngIf="isUserAuthenticated" mat-menu-item (click)="userLogout()">
                        <i class="material-icons">exit_to_app</i>
                        <span> Sign out</span>
                    </button>
                    
                    <button *ngIf="lastLoginTimeStamp" mat-menu-item>
                        <i class="material-icons">history</i>
                        <span> Last Login: {{lastLoginTimeStamp * 1000 | date : "dd/MM/yy hh:mm a"}}</span>
                    </button>
                </mat-menu>

                <mat-menu #langChange="matMenu" class="user-profile lang-change-menu">
                    <button mat-menu-item (click)="changeLanguage('en')">
                        <span jhiTranslate="lang.en"></span>
                    </button>
                    <button mat-menu-item (click)="changeLanguage('ka')">
                        <span jhiTranslate="lang.ka"></span>
                    </button>
                </mat-menu>

            </div>
        </div>

    </div>



</div>