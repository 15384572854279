import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Routes, RoutesRecognized } from '@angular/router';
import { LoaderService } from '../app/shared/loader.service';
import { APP_TYPES, REGULATOR_APP_TYPE, USER_APP_TYPE, USER_TYPES } from './app.constants';
import { MENU, USER_MENU } from './app.menu';
import { ConnectionService } from './app.network.connection.service';
import { GlobalState } from './global.state';
import { StateStorageService } from './shared/auth/state-storage.service';
import { BaMenuService } from './theme';
import { BaThemePreloader, BaThemeSpinner } from './theme/services';

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app-component',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.scss', '/theme/sass/conf/conf-a.scss'],
    template: `
    <div *ngIf="!isConnected" id="no-internet-connection">No internet connection !</div>
    <div id="check-system-time" *ngIf="false"  class="system-different-time">
    <div class="col-md-3">
    <img  src="./assets/img/clock.png" class="center-block" />
    </div>
    <div class="col-md-9">
    <h4>Please check your system time/time zone.</h4>
        <p>Your system timezone should be IST (INDIAN Standard Time). Please correct the time zone and time before applying minutes.</p>
    </div>
    </div>
    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>

      <section class="loaders" *ngIf="showLoader">
            <span class="loader loader-quart"> </span>
            <span class="loader-message">{{loaderMessage}}</span>
      </section>
    </main>
  `
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {



    isMenuCollapsed = false;
    showLoader = false;
    loaderMessage = ''
    isConnected = true;
    isSysetemTimeDifferent = false;
    navigationInProgress = false;


    constructor(
        private _state: GlobalState,
        private _spinner: BaThemeSpinner,
        private _menuService: BaMenuService,
        private router: Router,
        private $storageService: StateStorageService,
        private loaderService: LoaderService,
        private cdRef: ChangeDetectorRef,
        private eltRef: ElementRef,
        private httpClient: HttpClient,
        private connectionService: ConnectionService
    ) {
        const appType = eltRef.nativeElement.getAttribute('app-type');
        console.log('app-type is  ' + appType);
        // console.log('CORE_URL' + process?.env?.CORE_URL);
        // console.log('WF_URL' + process?.env?.WF_URL);
        // console.log('UAA_URL' + process?.env?.UAA_URL);
        // console.log('PGW_URL' + process?.env?.PGW_URL);
        // console.log('REPORTS_URL' + process?.env?.REPORTS_URL);
        // console.log('GRV_URL' + process?.env?.GRV_URL);
        // console.log('REGULATOR_APP_TYPE: ' + process?.env?.REGULATOR_APP_TYPE);
        // console.log('FOREMAN_APP_TYPE: ' + process?.env?.FOREMAN_APP_TYPE);
        // console.log('USER_APP_TYPE: ' + process?.env?.USER_APP_TYPE);
        localStorage.removeItem("snb_file_cache");

        if (appType) {
            if (appType === REGULATOR_APP_TYPE) {
                USER_TYPES.IS_REGULATOR = true;
                APP_TYPES.REGULATOR_APP_TYPE = true;
                this._menuService.updateMenuByRoutes(<Routes>MENU);
            } else if (appType === USER_APP_TYPE) {
                USER_TYPES.IS_USER = true;
                APP_TYPES.USER_APP_TYPE = true;
                this._menuService.updateMenuByRoutes(<Routes>USER_MENU);
            } else {
                USER_TYPES.IS_FOREMAN = true;
                APP_TYPES.FOREMAN_APP_TYPE = true;
                this._menuService.updateMenuByRoutes(<Routes>MENU);
            }
        } else {
            USER_TYPES.IS_FOREMAN = false;
            USER_TYPES.IS_REGULATOR = false;
            USER_TYPES.IS_USER = false;
            this._menuService.updateMenuByRoutes(<Routes>MENU);
        }

        // this._loadImages();

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });

        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
        });

        if (!navigator.onLine) {
            this.isConnected = false;
        }

    }

    onActivate(event) {
        window.scroll(0, 0);
    }

    ngOnInit() {


        /*
        store each state in session using StateStorageService for redirection after authorization,
          */
        // this.router.events
        //   .filter((event) => event instanceof NavigationEnd)
        //   .subscribe((event) => {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart || event.type === 'NavigationStart') {
                if (!this.navigationInProgress) {
                    this.navigationInProgress = true;
                    this.showLoader = true;
                    this.cdRef.detectChanges();
                }
            } else if (
                event instanceof NavigationEnd || 
                event instanceof NavigationCancel || 
                event instanceof NavigationError
            ) {
                this.navigationInProgress = false;
                this.showLoader = false;
                this.cdRef.detectChanges();
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                window.scrollTo(0, 0);
                let params = {};
                let destinationData = {};
                let destinationName = '';
                const destinationEvent = event.state.root?.firstChild?.children[0];
                const completeUrl = event.urlAfterRedirects;
                if (destinationEvent !== undefined) {
                    const toCompareUrl = '/' + event.state.root.firstChild.url[0].path + '/' + destinationEvent.url[0].path;
                    if (toCompareUrl !== completeUrl) {
                        params = destinationEvent.firstChild.params;
                        destinationData = destinationEvent.firstChild.data;

                        if (destinationEvent.firstChild.url.length) {
                            destinationName = destinationEvent.firstChild.url[0].path;
                        } else {
                            destinationName = destinationEvent.url[0].path;
                        }
                    } else {
                        params = destinationEvent.params;
                        destinationData = destinationEvent.data;
                        destinationName = destinationEvent.url[0].path;
                    }

                }
                if (completeUrl.indexOf('/applications') > -1) {
                    const currentVersion = localStorage.getItem('currentVersion');
                    this.httpClient.get('./assets/js/manifest.json').subscribe(data => {
                        const manifestData = data;
                        if (manifestData && manifestData["version"] && manifestData["build_number"]) {
                            const buildVersion = manifestData["version"] + '.' + manifestData["build_number"];
                            if (currentVersion && buildVersion !== currentVersion) {
                                localStorage.setItem('currentVersion', buildVersion);
                                console.log('Version upgraded to ' + buildVersion + '. Reloading the client application');
                                location.reload();
                            } else {
                                localStorage.setItem('currentVersion', buildVersion);
                            }
                        }
                    });
                }
                const from = { name: this.router.url.slice(1) };
                const destination = { name: destinationName, data: destinationData };
                this.$storageService.storeDestinationState(destination, params, from);
            }
        });
        this.loaderService.status.subscribe((val: boolean) => {
            console.log('loader status callback came', val);
            this.showLoader = val;
        });
        this.loaderService.statusWithMessage.subscribe((statusMessage: string) => {
            console.log('loader with statusMessage callback came', statusMessage);
            if (statusMessage && statusMessage.length > 0) {
                this.showLoader = true;
            } else {
                this.showLoader = false
            }
            this.loaderMessage = statusMessage
        });
    }

    public ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then(() => {
            this._spinner.hide();
        });
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

}
