<div class="confirmation-dialog-wrapper">
    <div class="confirmation-dialog-title">
        <i class="material-icons">warning</i> Confirmation
        <span><i class="material-icons close-btn" (click)="onNoClick()">close</i></span>
    </div>
    <div class="form_groups" *ngIf="message">
        <p class="text-center">{{ message? message : "Are you sure to proceed ?" }}</p>
    </div>
    <div class="form_groups" *ngIf="messages">
        <p class="text-start" *ngFor="let msg of messages">{{ msg }}</p>
        <br>
    </div>
    <div class="button-wrapper">
        <div class="first">
            <button (click)="onNoClick()"> No</button>
        </div>
        <div class="second">
            <button (click)="onYesClick()"> Yes</button>
        </div>
    </div>
</div>