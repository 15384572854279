// import '../theme/sass/conf/conf-a';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
    AccountService,
    AuthServerProvider,
    AuthService,
    ChangePasswordService,
    ChitmonksApiSharedCommonModule,
    HasAnyAuthorityDirective,
    HasAuthorityDirective,
    JhiLoginModalComponent,
    LoginModalService,
    LoginService,
    Principal,
    SnBChangePasswordComponent,
    StateStorageService
} from './';

import { DatePipe } from '@angular/common';
import { Register } from '../pages/register/register.component';
import { ErrorPageComponent } from '../shared/components/errorpage/errorpage.component';
import { HasAnyPermissionDirective } from './auth/has-any-permission.directive';
import { ConfirmationDialog } from './components/dialogs/confirmation-dialog.component';
import { DeleteConfirmationDialog } from './components/dialogs/delete-confirmation-dialog.component';
import { InformationDialog } from './components/information-dialog/information-dialog.component';
import { HttpUtilService } from './http-util.service';
import { LogService } from './services/log-service';
import { VerificationComponet } from './verification/verificatio.component';
import { FormUtilService } from './services/form-util.service';
import { SnbFunctionToPrivilegeMappingServices } from './services/SnbFunctionToPrivilegeMapping.service';
import { ReportService } from './services/report.service';
import { ConversionUtilService } from './services/conversion-util.service';
import { BackwardCompatibilityService } from './services/backward-compatibility.service';

@NgModule({
    imports: [
        ChitmonksApiSharedCommonModule,
        // MomentModule,
    ],
    declarations: [
        JhiLoginModalComponent,
        SnBChangePasswordComponent,
        VerificationComponet,
        Register,
        ErrorPageComponent,
        HasAuthorityDirective,
        HasAnyAuthorityDirective,
        DeleteConfirmationDialog,
        ConfirmationDialog,
        InformationDialog,
        HasAnyPermissionDirective
    ],
    providers: [
        LoginService,
        LoginModalService,
        ChangePasswordService,
        AccountService,
        StateStorageService,
        Principal,
        AuthServerProvider,
        AuthService,
        DatePipe,
        HttpUtilService,
        LogService,
        FormUtilService,
        SnbFunctionToPrivilegeMappingServices,
        ReportService,
        ConversionUtilService,
        BackwardCompatibilityService
    ],
    exports: [
        ChitmonksApiSharedCommonModule,
        JhiLoginModalComponent,
        SnBChangePasswordComponent,
        VerificationComponet,
        HasAuthorityDirective,
        HasAnyAuthorityDirective,
        HasAnyPermissionDirective,
        DatePipe,
        ErrorPageComponent,
        DeleteConfirmationDialog,
        ConfirmationDialog,
        InformationDialog
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChitmonksApiSharedModule {
    constructor() { }
}
