import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-snb-refresh-data',
    templateUrl: './refresh-data.html',
    styleUrls: ['./refresh-data.scss']
})

export class RefreshDataComponent {

    @Output() refreshData: EventEmitter<any> = new EventEmitter();

    @Input() public dataRefreshed = true;

    constructor() { }

    refresh() {
        this.refreshData.emit();
    }
}
