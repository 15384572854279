
import {throwError as observableThrowError, of as observableOf,  Observable ,  forkJoin } from 'rxjs';

import {catchError,  map, mergeMap, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { SNB_CORE_URL, SNB_WF_URL, USER_TYPES } from '../../app.constants';

import { DmnRuleRequest } from "../models/dmn-rule-request.model";
import { EngineRestVariable } from "../models/engine-rest-variable.model";
import { FormatInputs } from "../format-values";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ForemanCompanyFinancialStatusService {
   
    constructor(
        private httpClient: HttpClient,
        private formatInputs: FormatInputs
    ) {
    }


    getFinancialDataWithAllowedLimit(companyCode, includeAtoOfCompany: boolean, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/company-financial/status/${companyCode}?includeAtoOfCompany=${includeAtoOfCompany}`;
        } else {
            url = `foreman/company-financial/status?includeAtoOfCompany=${includeAtoOfCompany}`;
        }

        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(
          map( finData => {
            finData['permissionBusinessLimitsApplicable'] = true;
            finData['fyDataApplicable'] = true;
            
            if(finData["orgType"] === 'INCORPORATED_AS_COMPANY' || 
                finData["orgType"] === 'CO_OPERTIVE_OR_REGISTERED_SOCIETY') {
                // company must submit FY data. if they have not submitted, we can not calculate allowed limit. 
                if(!finData["netownedAssets"]) {
                     finData["fyDataNotSubmitted"] = true;
                }
             } else {
                 // for non companies and co-oprations societies, they don't need to submit FY data.
                 finData["fyDataApplicable"] = false;
             }

            return finData;
          }),
          mergeMap( finData => {
            if(finData["permissionBusinessLimitsApplicable"]) {
                if(!finData["noOfPartners"]) {
                    finData["noOfPartners"] = 1;
                }
                return  forkJoin(observableOf(finData), this.getChitAggregateAmountAllowed(finData["orgType"],
                    finData["ageOfCompany"],finData["netownedAssets"], 
                    finData["noOfPartners"]));
            } else {
                return forkJoin(observableOf(finData), observableOf(undefined));
            }
          }
        ),
          take(1)
        );
      }



    getFinancialStatus<T>( companyCode, includeAtoOfCompany: boolean, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/company-financial/status/${companyCode}?includeAtoOfCompany=${includeAtoOfCompany}`;
        } else {
            url = `foreman/company-financial/status?includeAtoOfCompany=${includeAtoOfCompany}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getChitAggregateAmountAllowed<T>(orgType: string, ageOfCompany: number, netOwnedAssets: number, noOfPartners: number)
        : Observable<any> {
        let inputVariables: Array<EngineRestVariable> = [];

        const orgTypeVar: EngineRestVariable = new EngineRestVariable('orgType', 'string');
        const ageOfCompanyVar: EngineRestVariable = new EngineRestVariable('ageOfCompany', 'double');
        const netOwnedAssetsVar: EngineRestVariable = new EngineRestVariable('netOwnedAssets', 'double');
        const noOfPartnersVar: EngineRestVariable = new EngineRestVariable('noOfPartners', 'double');

        orgTypeVar.value = orgType;
        ageOfCompanyVar.value = '' + ageOfCompany;
        netOwnedAssetsVar.value = '' + this.formatInputs.formatStringToValue(netOwnedAssets);
        noOfPartnersVar.value = '' + noOfPartners

        inputVariables.push(orgTypeVar);
        inputVariables.push(ageOfCompanyVar);
        inputVariables.push(netOwnedAssetsVar);
        inputVariables.push(noOfPartnersVar);


        const request: DmnRuleRequest = new DmnRuleRequest();
        request.decisionKey = 'CHIT_AGGREGATE_AMOUNT_RULES';
        request.inputVariables = inputVariables;
        const options = this.prepareHeader(request);

        return this.httpClient.post(`${SNB_WF_URL}/rules/dmn-rule/execute`, request, options).pipe(map((res: any) => {
            let fee = 0;
            if (res) {
                const result = res.resultVariables[0];
                for (let i = 0; i < result.length; i++) {
                    if (result[i].name === 'feesToBeLeveyed') {
                        fee = result[i].value;
                    }
                }
            }
            return fee;
        }),catchError((err) => {
            return observableThrowError(err);
        }),);
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set('stateIn', urlSearchParams.stateIn);
            }
            if (urlSearchParams.query) {
                params = params.set('query', urlSearchParams.query);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }
}
