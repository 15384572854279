import { Directive, ElementRef } from "@angular/core";
import * as lodash from "lodash";
const dateMMDDYYYRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

@Directive({
    selector: '[format-inr-currency]',
    host: {
        '(keyup)': 'onKeyUp()',
        '(blur)': 'onBlur()'
    }
})
export class FormatINRCurrency {

    private el: ElementRef;
    constructor(el: ElementRef) {
        this.el = el;
    }

    onKeyUp() {
        let value = this.el.nativeElement.value;
        if (value) {
            value = value.replace(" ", ",");
            if (!isNaN(value)) {
                this.el.nativeElement.value = parseInt(value).toLocaleString('en-IN');
            }
            else {
                if (!isNaN(parseInt(value.replace(/,/g, '')))) {
                    this.el.nativeElement.value = parseInt(value.replace(/,/g, '')).toLocaleString('en-IN');
                }
                else if(value === "-") {
                    return value;
                } else {
                    this.el.nativeElement.value = value.slice(0, -1);
                }
            }
        }
        else {
            return '';
        }
    }

    onBlur() {
        let value = this.el.nativeElement.value;
        if (value) {
            value = value.replace(/,/g, '');
            if (isNaN(value)) {
                this.el.nativeElement.value = 0;
            }
        }
    }

}


@Directive({
    selector: '[format-date]',
    host: {
        '(blur)': 'onBlur()'
    }
})
export class FormatDate {

    private el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    onBlur() {
        let value = this.el.nativeElement.value;

        if (value) {
            if (!dateMMDDYYYRegex.test(value)) {
                if (Date.parse(value)) {
                    var date = new Date(value);
                    let day = date.getDate();
                    let month = date.getMonth() + 1;
                    let year = date.getFullYear();
                    this.el.nativeElement.value = this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
                }
            }
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }

}

@Directive({
    selector: '[format-number]',
    host: {
        '(keyup)': 'onKeyUp()',
        '(blur)': 'onBlur()'
    }
})
export class FormatNumber {

    private el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    onBlur() {
        let value = this.el.nativeElement.value;

        if (value) {
            if (!isNaN(value)) {
                this.el.nativeElement.value = parseInt(value).toLocaleString('en-IN');
            }
            else {
                if (!isNaN(parseInt(value.replace(/,/g, '')))) {
                    this.el.nativeElement.value = parseInt(value.replace(/,/g, '')).toLocaleString('en-IN');
                }
                else {
                    this.el.nativeElement.value = value.slice(0, -1);
                }
            }
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }

}

@Directive({
    selector: '[snb-upper-case]',
    host: {
        '(keyup)': 'onKeyUp()'
    }   
  })
  export class SnbUpperCase {
    constructor(private el: ElementRef) {}
  
    onKeyUp() {
      let inputValue: string = this.el.nativeElement.value;
  
      if (inputValue) {
        this.el.nativeElement.value = inputValue.toUpperCase();
      }
    }
}

@Directive({
    selector: '[snb-lower-case]',
    host: {
        '(keyup)': 'onKeyUp()'
    }   
  })
  export class SnbLowerCase {
    constructor(private el: ElementRef) {}
  
    onKeyUp() {
      let inputValue: string = this.el.nativeElement.value;
  
      if (inputValue) {
        this.el.nativeElement.value = inputValue.toLowerCase();
      }
    }
}

@Directive({
    selector: '[snb-start-case]',
    host: {
        '(keyup)': 'onKeyUp()'
    } 
  })
  export class SnbStartCase {
    constructor(private el: ElementRef) {}
  
    onKeyUp() {
      let inputValue = this.el.nativeElement.value;
  
      if (inputValue) {
        let words = inputValue.split(' ');
        words = words.map((word: string) => lodash.upperFirst(word));
        this.el.nativeElement.value =words.join(' ');
      }
    }
  }