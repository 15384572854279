import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpUtilService {
    constructor() {}
    /**
     * This method will check page request parametes and query paramters in the given
     * request and forms the HttpHeaders and HttpParams objects.
     * request is in the form of {
     *   size: 20,
     *   page: 0,
     *   sort: <>,
     *   query: {
     *      ... any query params
     *   }
     * }
     * @param request
     * @param headers
     * @returns
     */
    public createRequestOption(
        request?: any,
        headers?: HttpHeaders | null
    ): object {
        headers = headers || new HttpHeaders();

        headers = headers.set("Content-Type", "application/json");
        headers = headers.set("Accept", "application/json");

        let params = new HttpParams();
        if (request) {
            if (typeof request.page !== "undefined") {
                params = params.set("page", request.page);
            }
            if (request.size) {
                params = params.set("size", request.size);
            }
            if (request.sort) {
                params = params.set("sort", request.sort);
            }
            if (request.stateIn) {
                params = params.set("stateIn", request.stateIn);
            }

            if (request.query) {
                const keys: string[] = Object.keys(request.query);
                keys.forEach((key) => {
                    if (typeof request.query[key] !== "undefined") {
                        if (Array.isArray(request.query[key])) {
                            /*
                             * some times we need to send same attribute multiple times in request params
                             * like date duration etc..
                             */
                            request.query[key].forEach((element) => {
                                params = params.append(key, element);
                            });
                        } else {
                            params = params.append(key, request.query[key]);
                        }
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params,
        };
    }
}
