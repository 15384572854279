<div style="padding: 24px">
    <div class="snb_form_entries">

        <div class="single_rw">

          <div class="row no-padding">
            <div class="col-md-12 col-sm-12  col-xs-12">
                <label>
                  Are you sure, you want to submit?
                </label>
            </div>

          </div>
        </div>
      </div>

    <button mat-raised-button type="submit" (click)="submitDetails($event)" class="snb-raised-button"
      style="float: right; margin: 0px 0px 20px 0px;">
      <span>Yes</span>
    </button>
    <button mat-raised-button (click)="dialogRef.close()" style="float: right; margin: 0px 10px 20px 0px;border-radius: 30px;">
      <span>CANCEL</span>
    </button>
</div>
