import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "../../login/login.service";
import { Router } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Injectable()
export class SnBToasterService {
    actionButtonLabel = "Retry";
    action = true;
    setAutoHide = true;
    autoHide = 8000;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = "right";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    constructor(
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
        private loginService: LoginService,
        private router: Router
    ) {}

    showSuccessToaster(message: string, action?: string) {
        const config = this._createConfig();
        config.panelClass = ["snb-success-toaster"];
        this.snackBar.open(message, action ? "Action Label" : "X", config);
    }

    showErrorMessage(msg: any, action?: string, isCustomMsg?: boolean) {
        const message = !isCustomMsg ? this.translateService.instant(msg) : msg;
        const config = this._createConfig();
        config.panelClass = ["snb-fail-toaster"];
        this.snackBar.open(message, action ? "Action Label" : "X", config);
    }

    showFailToaster(error: any, action?: string) {
        if (error) {
            let message = error;
            if (error.status) {
                const code = error.status;
                switch (code) {
                    case 400:
                    case 403:
                        if (error.error) {
                            if (error.error.detail) {
                                message = error.error.detail;
                            } else if (error.error.title) {
                                message = error.error.title;
                            }
                        }

                        if (error._body) {
                            if (JSON.parse(error._body).detail) {
                                message = JSON.parse(error._body).detail;
                            } else if (JSON.parse(error._body).description) {
                                message = JSON.parse(error._body).description;
                            } else if (
                                JSON.parse(error._body).error_description
                            ) {
                                message = JSON.parse(
                                    error._body
                                ).error_description;
                            } else {
                                message = JSON.parse(error._body).message;
                            }
                        }
                        break;
                    case 401:
                        if (
                            error.error &&
                            error.error.error === "invalid_token"
                        ) {
                            message = this.translateService.instant(
                                "error.sessionExpired"
                            );
                            this.loginService.logout();
                            this.router.navigate(["login"]);
                            break;
                        }
                        message =
                            this.translateService.instant("error.accessDenied");
                        console.log(error);
                        break;
                    case 404:
                        message = this.translateService.instant(
                            "error.recordNotFound"
                        );
                        console.log(error);
                        break;
                    case 413:
                        message = this.translateService.instant(
                            "error.fileSizeError"
                        );
                        console.log(error);
                        break;
                    case 500:
                        message = this.translateService.instant(
                            "error.internalServer"
                        );
                        console.log(error);
                        break;
                    default:
                        /* if (error.json()) {
                            // response contains json
                            const jsonResposnse: any = error.json();
                            if (jsonResposnse.description) {
                                message = jsonResposnse.description;
                            } else if (jsonResposnse.error_description) {
                                message = jsonResposnse.error_description;
                            } else if (jsonResposnse.text) {
                                message = jsonResposnse.text;
                            } else if (jsonResposnse.message) {
                                message = jsonResposnse.message;
                            }
                        } else { */
                        message = this.translateService.instant(
                            "error.internalServer"
                        );
                        // }
                        break;
                }
            }
            const config = this._createConfig();
            config.panelClass = ["snb-fail-toaster"];
            this.snackBar.open(message, action ? "Action Label" : "X", config);
        }
    }

    showCustomError(error) {
        if (error) {
            const code = error.status;
            switch (code) {
                case 400:
                case 403:
                    if (JSON.parse(error._body).detail) {
                        this.showFailToaster(JSON.parse(error._body).detail);
                    } else if (JSON.parse(error._body).description) {
                        this.showFailToaster(
                            JSON.parse(error._body).description
                        );
                    } else if (JSON.parse(error._body).error_description) {
                        this.showFailToaster(
                            JSON.parse(error._body).error_description
                        );
                    } else {
                        this.showFailToaster(JSON.parse(error._body).message);
                    }
                    break;
                case 401:
                    this.showFailToaster(
                        this.translateService.instant("error.accessDenied")
                    );
                    console.log(error);
                    break;
                case 404:
                    this.showFailToaster(
                        this.translateService.instant(
                            "error.server.not.reachable"
                        )
                    );
                    console.log(error);
                    break;
                case 500:
                    this.showFailToaster(
                        this.translateService.instant("error.internalServer")
                    );
                    console.log(error);
                    break;
                default:
                    break;
            }
        }
    }

    private _createConfig() {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        // config.panelClass = ['custom-class'];
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = this.addExtraClass ? ["party"] : undefined;
        // config.direction = this.dir.value;
        return config;
    }
}
