import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StateStorageService } from "../auth/state-storage.service";

@Injectable()

export class JhiLanguageService {

    constructor(
        private translateService: TranslateService,
        private stateStorageService: StateStorageService,
    ) { }

    changeLanguage(languageKey: string): void {
        this.stateStorageService.storeLocale(languageKey);
        this.translateService.use(languageKey);
    }
}