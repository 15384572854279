import { Component, Input, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';


@Component({
    selector: 'app-subscriber-entry',
    templateUrl: './subscriberEntry.component.html'
})

export class SubscriberEntryComponent implements OnInit, OnChanges {

    displayedColumns = ['sno', 'name'];
    @Input() noOfTickets: any;
    @Input() subscribersList = [];
    constructor() {

    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.noOfTickets) {
            const noOfTickets: SimpleChange = changes.noOfTickets;
            this.noOfTickets = noOfTickets.currentValue;
        }
        if (changes.subscribersList) {
            const subscribersList: SimpleChange = changes.subscribersList;
            this.subscribersList = subscribersList.currentValue;
        }
        this.createSubscriberData();
    }
    ngOnInit() {
        // console.log(this.noOfTickets);
        this.createSubscriberData();

    }

    createSubscriberData() {
        if (this.noOfTickets && !this.subscribersList) {
            this.subscribersList = [];
            for (let i = 1; i <= this.noOfTickets; i++) {
                this.subscribersList.push({ 'serialNumber': i + '', 'subscriberName': '' });
            }
        }

        if (this.noOfTickets && this.subscribersList) {
            if (Array.isArray(this.subscribersList) && (this.subscribersList.length !== this.noOfTickets)) {
                const ticketNos = this.subscribersList.map(ele =>
                    ele.serialNumber
                );
                // console.log(ticketNos);
                for (let i = 1; i <= this.noOfTickets; i++) {
                    if (ticketNos.indexOf(i + '') === -1) {
                        this.subscribersList.push({ 'serialNumber': i + '', 'subscriberName': '' });
                    }
                }
            }
        }

    }
}


