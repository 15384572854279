<div class="custom-data-table">
    <div class="row no-margin">
        <div class="col-md-7 col-xs-12">
            <div class="timeline_card text-left">
                <div class="subscribers_list custome_table">
                    <mat-table #table [dataSource]="foremanAdmins">

                        <ng-container matColumnDef="firstName">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                </span>
                                <span class="afterFont">Name</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.title}} {{element.firstName}} {{element.lastName}} {{element.suffix? " ("+element.suffix+")" : ""}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="mobileNumber">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                </span>
                                <span class="afterFont" style="padding-left: 15px;">Mobile No.</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="padding-left: 20px !important;"> {{element.contactDetails.mobileNumber}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="designation">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon">
                                    <i class="fa fa-users" aria-hidden="true"></i>
                                </span>
                                <span class="afterFont" style="padding-left: 6px;">Designation</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="position: relative;">
                                {{element.designation}}
                                <div *ngIf="element?.designation?.length === 0" class="removed-flag" style="top:14px">
                                    <span>Removed</span>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon">
                                    <i class="fa fa-cog" aria-hidden="true"></i>
                                </span>
                                <span class="afterFont">Actions</span></mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a [matMenuTriggerFor]="workFlowActions">
                                    <i class="material-icons actions">more_vert</i>
                                </a>
                                <mat-menu xPosition="before" yPosition="below" #workFlowActions="matMenu">
                                    <button mat-menu-item class="chit-group-row-action" (click)="editForemanUser()" *ngIf="isForeman && checkAccess(element?.designation)">
                                        <i class="material-icons">edit</i>
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action" (click)="makeForemanAsAdmin()" *ngIf="isRoc">
                                        <i class="material-icons">edit</i>
                                        <span>Give Admin Access</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action" (click)="removeAdminAccess()" *ngIf="isRoc">
                                        <i class="material-icons">delete</i>
                                        <span>Remove Admin Access</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action" (click)="giveForemanDesignationToUser()" *ngIf="isRoc">
                                        <i class="material-icons">edit</i>
                                        <span>Add FOREMAN Designation</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action" (click)="removeForemanDesignationToUser()" *ngIf="isRoc">
                                        <i class="material-icons">delete</i>
                                        <span>Remove FOREMAN Designation</span>
                                    </button>
                                </mat-menu>
                                
                            </mat-cell>
                        </ng-container> -->

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row (click)="showMoreDetails(row, $event)" *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                    </mat-table>

                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>
                    <div *ngIf="foremanAdmins?.length===0 && !requestInProgress" class="empty-row">No data to display
                    </div>
                    <mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="itemsPerPage"
                        [showFirstLastButtons]="true"></mat-paginator>

                </div>
            </div>
        </div>
        <div class="col-md-5 hidden-xs" *ngIf="foremanAdmins?.length">
            <div class="right-col">
                <div class="step-col-content">
                    <h3 class="step-col-label">
                        More Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col">
                            <span class="property">
                                Date Of Birth
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="selectedForemanData?.birthDetails?.dateOfBirth">
                                {{selectedForemanData?.birthDetails?.dateOfBirth * 1000 | date: "dd-MM-yyyy"}}
                                {{selectedForemanData?.birthDetails?.age ? " (Age: "+selectedForemanData?.birthDetails?.age +")" : "" }}
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="!selectedForemanData?.birthDetails?.dateOfBirth">
                                --
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Email
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="selectedForemanData?.contactDetails?.email" [matTooltip]="selectedForemanData?.contactDetails?.email" matTooltipPosition="below">
                                {{selectedForemanData?.contactDetails?.email}}
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="!selectedForemanData?.contactDetails?.email">
                                --
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                User Status
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="!selectedForemanData?.activationId">
                                Activated
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="selectedForemanData?.activationId">
                                Not Activated
                                <a *ngIf="isRegulatorSuperAdmin || isCustSuport" style="float: right;cursor: pointer;"
                                    #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">Send
                                    Activation Link</a>
                                <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
                                    <button mat-menu-item
                                        (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'EMAIL')">
                                        <mat-icon>email</mat-icon>
                                        <span>EMAIL</span>
                                    </button>
                                    <button mat-menu-item
                                        (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'SMS')">
                                        <mat-icon>textsms</mat-icon>
                                        <span>SMS</span>
                                    </button>
                                    <button mat-menu-item
                                        (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'ALL')">
                                        <mat-icon>send</mat-icon>
                                        <span>EMAIL & SMS</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Address
                            </span>
                            <div class="value s-col-full" *ngIf="selectedForemanData?.contactDetails?.addresses">
                                {{selectedForemanData?.contactDetails?.addresses[0]?.addressLine1}},
                                {{selectedForemanData?.contactDetails?.addresses[0]?.addressLine2}},
                                {{selectedForemanData?.contactDetails?.addresses[0]?.street}},
                                {{selectedForemanData?.contactDetails?.addresses[0]?.city}},
                                {{selectedForemanData?.contactDetails?.addresses[0]?.district}},
                                {{selectedForemanData?.contactDetails?.addresses[0]?.state}},
                                <div>PIN- {{selectedForemanData?.contactDetails?.addresses[0]?.pin}}</div>
                            </div>
                            <div class="value s-col-full"
                                *ngIf="!selectedForemanData?.contactDetails?.addresses?.length">
                                --
                            </div>
                        </div>
                    </div>
                </div>

                <div class="step-col-content" *ngIf="selectedForemanData?.loginAccountDetails">
                    <h3 class="step-col-label">
                        Login Account Details
                    </h3>
                    <div class="s-col">
                        <span class="property">
                            User ID: 
                        </span>
                        <div class="value s-col-ellipsis" style="margin:0px">
                           <span style="padding-right: 50px;">{{selectedForemanData?.userId}}</span> 
                        </div>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            Is Login Active: 
                        </span>
                        <div class="value s-col-ellipsis" style="margin:0px">
                           <span style="padding-right: 50px;" *ngIf="selectedForemanData?.loginAccountDetails?.activated">Activated</span> 
                           <span style="padding-right: 50px;" *ngIf="!selectedForemanData?.loginAccountDetails?.activated">Not Activated</span> 
                        </div>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            Name: 
                        </span>
                        <div class="value s-col-ellipsis" style="margin:0px">
                           <span style="padding-right: 50px;">{{selectedForemanData?.loginAccountDetails?.lastName + " " + selectedForemanData?.loginAccountDetails?.firstName  }}</span> 
                        </div>
                    </div>
                    <div class="s-col" style="line-height: unset;">
                        <span class="property">
                            Roles: 
                        </span>
                        <div class="value s-col-ellipsis" style="margin:0px; height: auto;">
                           <span style="padding-right: 50px;" *ngFor="let role of selectedForemanData?.loginAccountDetails?.authorities">
                                {{role}} <br/>
                            </span>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>

   

    </div>
</div>
