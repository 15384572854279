import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-organisation-bank-detail',
    templateUrl: './organisation-bank-details.component.html',
    styleUrls: ['./dialog-popup-content.scss']
})
export class OrganisationBankDetailComponent implements OnInit {

    selectedBankData: any;
    constructor(
        public dialogRef: MatDialogRef<OrganisationBankDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.selectedBankData = this.data.selectedBankData;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

}
