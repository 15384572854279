
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SNB_BASE_URL, SNB_CORE_URL, SNB_WF_URL, USER_TYPES } from "../../app.constants";
import { FilterCriteria } from "./filter-criteria.model";
const filterMetaDataJson: any = require('./filter-meta-data.json');

@Injectable()
export class FilterCriteriaService {
    // holds the meta data of filters by fileter
    filterMetaDataDefMap: any;

    constructor(
        private httpClient: HttpClient
    ) { 
        this.filterMetaDataDefMap = filterMetaDataJson;
    }


    getFilters<T>(module, addlParamsForGetFilters, req?: any): Observable<T | HttpResponse<T>> {
        const options = this.prepareHeader(req);
        let url = '';

        if (USER_TYPES.IS_REGULATOR) {
            url = `/regulator/${module}/get-filters?`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `/foreman/${module}/get-filters?`;
        } else {
            url = `/public/${module}/get-filters?`;
        }

        const addlParams = [];
        if (addlParamsForGetFilters) {
            Object.keys(addlParamsForGetFilters).forEach(element => {
                addlParams.push(element + '=' + addlParamsForGetFilters[element]);
            });
        }

        url += addlParams.join('&');

        if (module === 'task' || module === 'task/payment') {
            return this.httpClient.get<T>(SNB_WF_URL + `${url}`, options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
        } else {
            return this.httpClient.get<T>(SNB_CORE_URL + `${url}`, options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
        }
    }

    getFilterValues<T>(itemAttribute: any, uri: any, req?: any, item?: any, selectedItems?: any): Observable<T | HttpResponse<T>> {
        const options = this.prepareHeader(req);
        let url = SNB_BASE_URL;

        url = `${url}${uri}/get-filter-values/${itemAttribute}`;
        if (item.dependsOnOtherAttribute) {
            const dependentItem = selectedItems.filter(x => item.dependsAttributeName === x.attribute);
            if (dependentItem[0].valueCode) {
                url = `${url}?${item.dependsAttributeName}=${dependentItem[0].valueCode}`;
            } else if (dependentItem[0].value) {
                url = `${url}?${item.dependsAttributeName}=${dependentItem[0].value}`;
            } else {
                url = url;
            }
        }

        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    /**
     * This is new implementation to get filter metadata by filter id.
     * @author: sidhu
     */
    getFiltersByFilterId(filterId: string): FilterCriteria [] {
        console.log('fetching filter meta by filterId', filterId);
        const data = this.filterMetaDataDefMap[filterId];
        console.log('filter meta data of filter ', filterId, ' is ', JSON.stringify(data));
        return data; 
    }


    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');

        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params.set('stateIn', urlSearchParams.stateIn);
            }
            if (urlSearchParams.autoCompleteText) {
                params = params.set('autoCompleteText', urlSearchParams.autoCompleteText);
            }
            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
            if (urlSearchParams.returnAttributes) {
                params = params.set('returnAttributes', urlSearchParams.returnAttributes);
            }

        }

        return {
            headers: headers,
            params: params
        };
    }

}