<div class="snb_new_foreman row" [ngClass]="{'snb__tabel__data' : foremanCompanyData}">
    <form [formGroup]="newForemanCompanyForm" novalidate (ngSubmit)="createNewForemanCompany(newForemanCompanyForm, $event)"
        autocomplete="off">
        <div class="row">
            <div class=" col-md-4 col-sm-6 col-xs-12">
                <div class="card dep-a">
                    <div class="snb_card_label">
                        {{'foremanCompany.new.foremanOrgInfo' | translate}}
                        <i (click)="editForemanOrganisation('ORGNISATION', foremanCompanyData)" *ngIf="companyCode && !isForeman"
                            class="material-icons branch_edit">mode_edit</i>
                    </div>

                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.organisationType' | translate}}</mat-label>
                            <mat-select formControlName="orgType" matInput 
                                required>
                                <mat-option *ngFor="let org of foremanOrgTypes" [value]="org.id">
                                    {{ org.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="newForemanCompanyForm.controls.orgType.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.companyName' | translate}}</mat-label>
                            <input type="text" formControlName="name" matInput
                                required>
                            <mat-error *ngIf="newForemanCompanyForm.controls.name.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.incorporationDate' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" format-date formControlName="incorporationDate"
                                (click)="picker.open();"
                                (focus)="picker.open();" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="newForemanCompanyForm.controls.incorporationDate.hasError('required')"
                                jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.cin' | translate}}</mat-label>
                            <input type="text" formControlName="cin" matInput 
                                required>
                            <mat-error *ngIf="newForemanCompanyForm.controls.cin.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col" *ngIf="foremanCompanyData?.orgType == 'UNREGISTERED_ASSOCIATION_OF_INDIVIDUALS' || foremanCompanyData?.orgType == 'PARTNERSHIP' ||  foremanCompanyData?.orgType == 'SOLE_PROPRIETORSHIP'">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.noOfPartners' | translate}}</mat-label>
                            <input type="text" formControlName="noOfPartners" matInput>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.pan' | translate}}</mat-label>
                            <input type="text" formControlName="pan" matInput>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.gstn' | translate}}</mat-label>
                            <input type="text" formControlName="gstn" matInput>
                        </mat-form-field>
                    </div>
                    <div class="snb_single_col">
                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.website' | translate}}</mat-label>
                            <input type="text" formControlName="website" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <span>Click
                    <a target="_blank" href="https://www.mca.gov.in/content/mca/global/en/mca/master-data/MDS.html">here</a> to
                    verify company details in MCA site.</span>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12" *ngIf="!companyCode">
                <div class="card dep-a">
                    <div class="snb_card_label">
                        {{'foremanCompany.new.headOfficeInfo' | translate}}
                        <i (click)="editForemanCompanySubBranch(foremanCompanyData?.mainBranch)" *ngIf="foremanCompanyData?.mainBranch"
                            class="material-icons branch_edit">mode_edit</i>

                    </div>
                    <div formGroupName="mainBranch">
                        <div class="snb_single_col">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>Office Name</mat-label>
                                <input type="text" formControlName="name" matInput required>
                                <mat-error *ngIf="newForemanCompanyForm.controls.mainBranch.controls.name.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div formGroupName="contactDetails">
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>Office Email</mat-label>
                                    <input type="email" formControlName="email" matInput>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].email.value && newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].email.hasError('pattern')"
                                        jhiTranslate="entity.validation.email"></mat-error>
                                </mat-form-field>
                            </div>
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>Office Mobile Number</mat-label>
                                    <input type="tel" required formControlName="mobileNumber" matInput>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].mobileNumber.value && newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                        jhiTranslate="entity.validation.mobile"></mat-error>
                                </mat-form-field>
                            </div>
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>{{'foremanCompany.new.altPhoneNumbers' | translate}}</mat-label>
                                    <input type="text" formControlName="altPhoneNumbers" matInput>
                                </mat-form-field>
                            </div>
                            <div formArrayName="addresses" class="snb_single_col">
                                <div *ngFor="let address of newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].addresses.controls; let i=index">
                                    <div [formGroupName]="i">
                                        <cm-address *ngIf="(!foremanCompanyId || (foremanCompanyId && foremanCompanyData?.mainBranch?.contactDetails?.addresses[i]))"
                                            addressPlaceHolder="Office Address" [group]="newForemanCompanyForm.controls.mainBranch.get('contactDetails')['controls'].addresses.controls[i]"
                                            [addressValue]="foremanCompanyData?.mainBranch?.contactDetails?.addresses[i]"></cm-address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="snb_single_col">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>Regulator Branch</mat-label>
                                <mat-select formControlName="regulatorBranchRefId" matInput
                                    required>
                                    <mat-option *ngFor="let rocBranch of rocBranchesList" [value]="rocBranch.id">
                                        {{ rocBranch.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="newForemanCompanyForm.controls.mainBranch.controls.regulatorBranchRefId.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12" *ngIf="!companyCode">
                <div class="card dep-a">
                    <div class="snb_card_label">
                        {{'foremanCompany.new.adminOfHeadOfficInfo' | translate}}
                    </div>
                    <div formGroupName="adminUser">
                        <div class="snb_single_col">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>{{'foremanCompany.new.title' | translate}}</mat-label>
                                <mat-select formControlName="title" matInput
                                    required>
                                    <mat-option value="Mr.">{{'foremanCompany.title.mr' | translate}}</mat-option>
                                    <mat-option value="Mrs.">{{'foremanCompany.title.mrs' | translate}}</mat-option>
                                    <mat-option value="Ms.">{{'foremanCompany.title.ms' | translate}}</mat-option>
                                    <mat-option value="Miss">{{'foremanCompany.title.miss' | translate}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="newForemanCompanyForm.get('adminUser')['controls'].title.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="snb_single_col">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>{{'foremanCompany.new.firstName' | translate}}</mat-label>
                                <input type="text" formControlName="firstName" matInput
                                    required>
                                <mat-error *ngIf="newForemanCompanyForm.get('adminUser')['controls'].firstName.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="snb_single_col">
                            <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                <mat-label>{{'foremanCompany.new.lastName' | translate}}</mat-label>
                                <input type="text" formControlName="lastName" matInput
                                    required>
                                <mat-error *ngIf="newForemanCompanyForm.get('adminUser')['controls'].lastName.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div formGroupName="contactDetails">
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>{{'foremanCompany.new.email' | translate}}</mat-label>
                                    <input type="email" required formControlName="email" matInput>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].email.value && newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].email.errors?.pattern"
                                        jhiTranslate="entity.validation.email"></mat-error>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].email.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                            </div>
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>{{'foremanCompany.new.mobileNumber' | translate}}</mat-label>
                                    <input type="tel" required formControlName="mobileNumber" matInput>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                    <mat-error *ngIf="newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.value && newForemanCompanyForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                        jhiTranslate="entity.validation.mobile"></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 text-center foreman_company_actions">
            <button mat-raised-button type="submit" *ngIf="!companyCode && !isForeman" [disabled]="isFormSubmitted"
                class="snb-raised-button">
                <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </button>

            <button mat-raised-button (click)="cancelForemanCompanyEdit()" *ngIf="false" class="snb-normal-button">
                <span jhiTranslate="foremanCompany.new.btnCancel"></span>
            </button>
        </div>

    </form>
</div>