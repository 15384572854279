<div class="custom-data-table">

    <div class="row no-margin">
        <div class="col-md-8 col-xs-12" [ngClass]="{'col-md-12' : !rows?.length}">
            <div class="timeline_card text-left">
                <div class="subscribers_list custome_table">
                    <mat-table #table [dataSource]="rows" matSort matSortActive="name" matSortDisableClear
                    matSortDirection="asc">

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="afterFont">Display Name</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="bank">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-building-o"
                                        aria-hidden="true"></i></span><span class="afterFont">Bank</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.bankName}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="usageType">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-building-o"
                                        aria-hidden="true"></i></span><span class="afterFont" style="padding-left: 7px;">Usage Type</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" style="padding-left: 10px !important;"> {{element.bankAccountUsageType}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="branchName">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-building-o"
                                        aria-hidden="true"></i></span><span class="afterFont">Foreman Branch</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.foremanCompanyBranchName}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-cog"
                                        aria-hidden="true"></i></span><span class="afterFont">Actions</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a [matMenuTriggerFor]="workFlowActions">
                                    <i class="material-icons actions">more_vert</i>
                                </a>
                                <mat-menu xPosition="before" yPosition="below" #workFlowActions="matMenu">
                                    <!-- <button mat-menu-item class="chit-group-row-action" (click)="editBank()">
                                        <i class="material-icons">edit</i>
                                        <span>Edit</span>
                                    </button> -->
                                    <button mat-menu-item class="chit-group-row-action foreman-company-more-actions" (click)="deleteBank(element)">
                                        <i class="material-icons">delete</i>
                                        <span>Delete</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action" *ngIf="element?.accountStatus === 'INACTIVE'" (click)="updateActivattionStatusOfBank(element, true)">
                                        <i class="material-icons">edit</i>
                                        <span>Make Active</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action foreman-company-more-actions" *ngIf="element?.accountStatus === 'ACTIVE'" (click)="updateActivattionStatusOfBank(element, false)">
                                        <i class="material-icons">edit</i>
                                        <span>Make In-Active</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row (click)="rowClicked($event, row)" *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                        </mat-table>
                    <div *ngIf="rows?.length===0 && !requestInProgress" class="empty-row">
                        No data to display
                    </div>
                    <mat-paginator [hidePageSize]="true" [length]="totalItems" [pageSize]="itemsPerPage"
                    [showFirstLastButtons]="true"></mat-paginator>
                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>

                </div>
            </div>
        </div>
        <div class="col-md-4 hidden-xs" *ngIf="rows?.length">
            <div class="right-col">
                <div class="step-col-content">
                    <h3 class="step-col-label">
                        More Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col">
                            <span class="property">
                                Account Status
                            </span>
                            <span class="value s-col-full">
                                {{selectedRow?.accountStatus || '--'}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Bank Name
                            </span>
                            <span class="value s-col-full">
                                {{selectedRow?.bankName || '--'}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Account Number
                            </span>
                            <span class="value s-col-full">
                                {{selectedRow?.acctNumber || '--'}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Account Holder Name
                            </span>
                            <span class="value s-col-full">
                                {{selectedRow?.acctHolderName || '--'}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Account Type
                            </span>
                            <span class="value s-col-full">
                                {{selectedRow?.accountType || '--'}}
                            </span>
                        </div>

                        <div class="s-col">
                            <span class="property">
                                Address
                            </span>
                            <div class="value s-col-full" *ngIf="selectedRow?.address">
                                {{selectedRow?.address?.addressLine1}},
                                {{selectedRow?.address?.addressLine2 || ''}},
                                {{selectedRow?.address?.street}},
                                {{selectedRow?.address?.city}}, {{selectedRow?.address?.district}},
                                {{selectedRow?.address?.state}},
                                <div>PIN- {{selectedRow?.address?.pin}}</div>
                            </div>
                            <div class="value s-col-full" *ngIf="!selectedRow?.address">
                                --
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
