export class Task {
    id: number;
    name: string;
    status: TaskStatus;
    messages: string [];
    redirection?: Redirection;
  
    constructor(id: number, name: string, status: TaskStatus, messages: string[], redirection: Redirection) {
      this.id = id;
      this.name = name;
      this.status = status;
      this.messages = messages;
      // In case if the user wnats to redirect to another page via progress dialog
      if (redirection) {
        this.redirection = redirection;
      }
    }
}

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  SKIPPED = "SKIPPED",
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}
export class Redirection {
  text: string;
  link: string;
  queryParams: Object;

  constructor(redirectionMsg: string, redirectionLink: string, queryParams: Object) {
    this.text = redirectionMsg;
    this.link = redirectionLink;
    this.queryParams = queryParams;
  }
}
  