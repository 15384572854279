  <div class="row form-top-section">
  <div class="snb_content_section card">
    <div id="payment_client" class="auth-main payment__status">
      <div id="login-form-wrapper" class="form__wrapper">
        <div id="login-form" [hidden]="errorOccured" class="md-whiteframe-8dp">
          <div class="payment_status_title_sec">
            <!-- <div class="title_dec">
            <h2> Transaction status</h2>
          </div> -->
          </div>
          <form>


            <!--Payment transaction status code-->
            <section class="cm-payment-transaction -status" [ngClass]="transactionStatus.trnxStatus == 'SUCCESS' ? 'success':
           transactionStatus.trnxStatus == 'PENDING' ? 'pending':
           transactionStatus.trnxStatus == 'FAILCHECKSUM' ? 'failed':
					transactionStatus.trnxStatus == 'FAILURE' ? 'failed':'' ">
              <!---failed-->
              <div class="transaction-header-">


                <div *ngIf="transactionStatus.trnxStatus == 'SUCCESS'">
                  <div class="page_status_title">Your payment is successful.

                  </div>
                  <!-- <div class="transaction__remarks">
                  <p>
                    <span>Remarks :</span> {{ transactionStatus.trnxDesc }}</p>
                </div> -->
                </div>

                <div *ngIf="transactionStatus.trnxStatus == 'PENDING'">
                  <div class="page_status_title">Your payment is pending.

                  </div>
                  <span> {{ transactionStatus.trnxDesc }}</span>
                </div>
                <div *ngIf="transactionStatus.trnxStatus == 'FAILURE'">
                  <div class="page_status_title">Your payment has failed !

                    <div>
                      <p>
                        Unfortunately your payment attempt was not successful. Please try again or Contact : +91 7997005770 or t-chits.support@snbchain.com
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="transactionStatus.trnxStatus == 'FAILCHECKSUM'">
                  <div class="page_status_title">Something went wrong !

                    <div>
                      <p>
                        Please check the transaction status in payment history.
                        <br /> For any query Contact : +91 7997005770 or t-chits[dot]support[at]snbchain[dot]com
                      </p>
                    </div>
                  </div>


                </div>

              </div>




              <div class="row" *ngIf="transactionStatus.trnxStatus !== 'FAILCHECKSUM'">
                <div class="col-md-6">

                  <div class="transaction-status-des ">
                    <!---error-->
                    <md-card>
                      <!--											
												<i class="fa" [ngClass]="transactionStatus.trnxStatus == 'SUCCESS' ? ' fa-check-circle' : 'fa-times-circle' " aria-hidden="true"></i>
											
									-->

                      <!--<div>
										<div *ngIf="transactionStatus.trnxStatus == 'SUCCESS'">
											<div class="md-headline">Your payment has been successfully processed. </div>
											<span class="md-subhead description"> {{ transactionStatus.trnxDesc }}</span>
										</div>
										<div *ngIf="transactionStatus.trnxStatus == 'FAILURE'">
											<div class="md-headline">Your payment has been failed ! </div>
											<span class="md-subhead description">{{ transactionStatus.trnxDesc }}</span>
										</div>

									</div>
								-->

                      <md-card-content>
                        <!--<div class="status-info" *ngIf="transactionStatus.trnxStatus == 'FAILURE'">
										<p>
											Unfortunately your payment attempt was not succesful.<br /> Please try again or Contact support.
										</p>
									</div>-->

                        <div class="-generated-details" style="font-size: 12px;">

                          <div class="-trans-remarks" *ngIf="transactionStatus.trnxStatus == 'FAILURE'">
                            <span class="lf__value">Failure Reason :</span>
                            <span class="rt__value">
                              {{ transactionStatus.trnxStatusDesc ? transactionStatus.trnxStatusDesc : "---" }}</span>
                          </div>

                          <div class="-trans-amount">
                            <span class="lf__value">Amount :</span>
                            <span class="rt__value">
                              <i class="fa fa-inr" aria-hidden="true"></i> {{ transactionStatus.amount }}</span>
                          </div>
                          <div class="-trans-remarks">
                            <span class="lf__value">Remarks :</span>
                            <span class="rt__value">
                              {{ transactionStatus.trnxDesc ? transactionStatus.trnxDesc : "---" }}</span>
                          </div>
                          <div class="-trans-order-id">
                            <span class="lf__value"> Transaction Ref No :</span>
                            <span class="rt__value"> {{ transactionStatus.appTrnxRefId }}</span>
                          </div>
                          <div class="-trans-order-date">
                            <span class="lf__value"> Transaction Date :</span>
                            <span class="rt__value">{{ transactionStatus.trnxDate | date:'dd/MM/yyyy hh:mm a'}}</span>
                          </div>
                        </div>
                      </md-card-content>
                    </md-card>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="statuc_icon">
                    <i *ngIf="transactionStatus.trnxStatus == 'SUCCESS'" class="fa success fa-check" aria-hidden="true"></i>
                    <i *ngIf="transactionStatus.trnxStatus == 'PENDING'" class="fa pending fa-times" aria-hidden="true"></i>
                    <i *ngIf="transactionStatus.trnxStatus == 'FAILURE'" class="fa fail fa-times-circle-o" aria-hidden="true"></i>
                    <i *ngIf="transactionStatus.trnxStatus == 'TIME_OUT'" class="fa time-out fa-exclamation"
                      aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </section>
            <!--</div>-->
          </form>
          <div class="col-md-12 text-left no-padding">
            <button color="primary" mat-raised-button class="snb_btn_submit" (click)="goToPage('pages/dashboard')">
              Close
            </button>
          </div>
        </div>
        <div [hidden]="!errorOccured">
          <h1 style="color:#f44336;text-align: center;">{{message}}</h1>
        </div>
      </div>
    </div>
  </div>
</div>