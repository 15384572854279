<div class="chm-dialog-popup">
    <div class="chm-dialog-popup-title">
        Cancel Application
        <span class="close-popup" (click)="onNoClick()"><i class="material-icons">close</i></span>
    </div>
    <div class="form_groups">
        <div class="warning-wrapper" *ngIf="!reasonFormWrapper">
            <p class="text-center">
                <i class="material-icons">warning</i>
                This action will cancel the application in the system, if any amount paid to this application will
                not be refunded.
            </p>

            <div class="col-md-12" style="margin-top: 10px;">
                <button type="button" mat-raised-button color="primary"
                    style="margin: 0 auto 15px;border-radius: 30px;display: table;" (click)="showReasonArea()">
                    Proceed
                </button>
            </div>
        </div>

        <div class="form-wrapper" *ngIf="reasonFormWrapper">
            <p class="text-center"><sup style="color: red;">*</sup>Please add Cancel reason below to continue.</p>
            <hr>
            <form novalidate [formGroup]="cancelApplicationForm" (ngSubmit)="cancelApplicaiton($event)">
                <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                    <mat-label>Reason</mat-label>
                    <mat-select formControlName="reason" matInput required
                        (selectionChange)="changeCancelService()">
                        <mat-option *ngFor="let mainReason of reasonsList" [value]="mainReason.value">
                            {{mainReason.viewValue}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="cancelApplicationForm.controls.reason.hasError('required')"
                        jhiTranslate="entity.validation.required">
                    </mat-error>
                </mat-form-field>

                <div *ngIf="cancelApplicationForm?.controls?.deleteReason">
                    <mat-form-field appearance="outline" class="example-full-width snb-form-field-without-icon snb-text-area" style="width: 100%">
                        <mat-label>Specify reason...</mat-label>
                        <textarea matAutosizeMinRows=1 matAutosizeMaxRows=5 matInput
                            formControlName="deleteReason" required></textarea>
                        <mat-error *ngIf="cancelApplicationForm?.controls?.deleteReason?.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>

                    </mat-form-field>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <button type="submit" mat-raised-button color="primary" class="pull-right"
                            style="float: right;margin-left: 10px;border-radius: 30px">
                            Submit
                        </button>
                        <button mat-raised-button type="button" (click)="onNoClick()"
                            style="float: right;border-radius: 30px">Close</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
