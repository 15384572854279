<div class="snb_new_foreman">
    <div class="row">
        <div class="snb_subheader_actions" *ngIf="isForeman" [matMenuTriggerFor]="moreActions">
            <label>
                <i class="material-icons">add</i>
            </label>
            <mat-menu #moreActions="matMenu" xPosition="before" overlapTrigger="false" yPosition="below">
                <button mat-menu-item class="foreman-company-more-actions" (click)="createNewBranch('requestByForeman')">
                    <i class="material-icons">add</i>
                    <span>Branch</span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" (click)="addForemanUser('DIRECTOR')" *ngIf="isAdminUser">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.director"></span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" *ngIf="foremanCEOs?.length<1 && isAdminUser" (click)="addForemanUser('CEO')">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.ceo"></span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" (click)="addForemanUser()">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.otherForemen"></span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="dropDown">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.banker"></span>
                </button>
                <mat-menu #dropDown="matMenu">
                    <button mat-menu-item [disabled]="!isAdminUser" (click)="addBankDetails('BANKER', 'COLLECTION')">
                        Collection Account
                    </button>
                    <button mat-menu-item (click)="addBankDetails('BANKER', 'SEPARATE')">
                        Separate Account
                    </button>
                </mat-menu>
                <button mat-menu-item class="foreman-company-more-actions" *ngIf="foremanAuditors?.length<1 && isAdminUser" (click)="addForemanUser('AUDITOR')">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.auditor"></span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" (click)="addForemanUser('ADVOCATE')">
                    <i class="material-icons">add</i>
                    <span jhiTranslate="foremanCompany.new.advocate"></span>
                </button>
            </mat-menu>
        </div>
        <div class="snb_subheader_actions" *ngIf="!isForeman" [matMenuTriggerFor]="moreActions">
            <label>
                <i class="material-icons">add</i>
            </label>
            <mat-menu #moreActions="matMenu" xPosition="before" overlapTrigger="false" yPosition="below">
                <button mat-menu-item class="foreman-company-more-actions" (click)="createNewBranch()">
                    <i class="material-icons">add</i>
                    <span>Branch</span>
                </button>
                <button mat-menu-item class="foreman-company-more-actions" (click)="addForemanUser('ADMIN')">
                    <i class="material-icons">add</i>
                    <span>Add Admin</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>