export class EngineRestVariable {
    constructor(
        public name?: string,
        public type?: any,
        public value?: string,
        public valueUrl?: string,
    ) { }
}

export const enum VARIABLE_TYPE { double = 'double', string = 'string' }

