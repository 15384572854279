 <mat-table #table [dataSource]="subscribersList">
    <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef> Serial No.</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element?.serialNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Subscriber Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <input  [(ngModel)]="element.subscriberName"
                    style="border: 1px solid ccc" />

            </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div *ngIf="!subscribersList?.length" class="empty-row">No data to display</div>

