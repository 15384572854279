import { Component, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-twallet-auto-submit-form',
    template: `<form action='{{formData.redirectUrl}}' method='{{formData.redirectMethod}}'>
    <div>
        <input type="hidden" name="data"  value="{{formData.redirectData.data}}"/>
        <input type="hidden" name="skey"  value="{{formData.redirectData.skey}}"/>
    </div>
    </form>`
})
export class AppTwalletAutoSubmitFormComponent implements AfterViewInit {
    formData: any = {};
    private nativeElement: any;

    ngAfterViewInit() {

    }
    constructor(public el: ElementRef, private ref: ChangeDetectorRef) {
        this.formData.redirectData = {};
    }

    render(formData) {
        this.formData = formData;
        this.nativeElement = this.el.nativeElement;
        this.ref.detectChanges();
        jQuery(this.nativeElement.innerHTML).appendTo('body').submit();
    }
}
