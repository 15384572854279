import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-information-dialog',
    templateUrl: 'information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],

})

export class InformationDialog {
    NO_RESPONSE = 'NO';
    constructor(
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<InformationDialog>) {
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }


    goToPage(page) {
        this.dialogRef.close(true);
        this.router.navigate([page]);
    }

    onNoClick(): void {
        this.dialogRef.close(this.NO_RESPONSE);
    }

}
