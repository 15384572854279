<div class="dialog-wrapper" mat-dialog-content >
    <div class="dialog-title">
        <span><b>Calculated Interest Details & Subscription Arrears</b></span>
        <button (click)="cancelDialog('cancel')" type="button" style="display: contents;">
            <span><i class="material-icons close-btn" style="padding-right: 15px;">close</i></span>
        </button>
    </div>
    <div class="dialog-content-wrapper">
        <app-arb-inst-and-due-interest-info [data]="dataForTableDialog"></app-arb-inst-and-due-interest-info>
    </div>
</div>