<div class="public-authentication">
    <button mat-raised-button class="snb_btn_normal" type="button" (click)="logout()" *ngIf="isLoggedIn">
        <i class="material-icons">exit_to_app</i>
        <span style="margin-left: 10px;">Log out</span>
    </button>
</div>
<div class="row">
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="form_field" style="background: #fefefe;border: none;box-shadow: none;">
            <span class="title-ribbon">
                Search Chits Information
            </span>

            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/form-icon.png"> </span>
                                <span class="text_span">
                                    Chit Information Search
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <a routerLink="/pages/chitinfo">
                                <button name="btn_form_1" mat-button color="primary">
                                    Go ->
                                </button>
                            </a>
                        </div>
                        <span class="card-meta">
                            Public Chit Information Search
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/form-icon.png"> </span>
                                <span class="text_span">
                                    Notices for Security Release
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <a routerLink="/pages/notices">
                                <button name="btn_form_2" mat-button color="primary">
                                    Go ->
                                </button>
                            </a>
                        </div>
                        <span class="card-meta">
                            Chit Group Noitices for Security Release
                        </span>
                    </div>
                </div>


            </div>

        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="form_field" style="background: #fefefe;border: none;box-shadow: none;">
            <span class="title-ribbon">
                Application and Payments
            </span>

            <div class="row">

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    Raise New Grievance
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/grievance-public',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Grievances
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    Check My Grievances
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/grievance-public/status',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Check My Grievances
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    Certified Copy Of Chit Agreement
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/certified-copy-agreement',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Certified Copy Of Chit Agreement
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    Certified Copy Of Award
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/certified-copy-award',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Certified Copy Of Award
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    Other Payments
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/other-payment',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Other Payments
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                    My Pending Applications
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/pending-applications',null)">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            View Pending Applications
                        </span>
                    </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 app-card-wrapper">
                    <div class="card-2">
                        <div class="top_section text-center">
                            <h2>
                                <span class="image_span"><img src="assets/img/document.png"> </span>
                                <span class="text_span">
                                Arbitration Application
                                </span>
                            </h2>

                        </div>
                        <div class="bottom_section application_btn text-center">
                            <button name="btn_form_legacyMinutes" mat-button color="primary"
                                (click)="checkAuthAndGoToPage('/pages/arbitration/arbitration-public',{ applicationType: 'public-arbitration' })">
                                Go ->
                            </button>
                        </div>
                        <span class="card-meta">
                            Arbitration Applications
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
