import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { USER_TYPES } from '../../app.constants';

@Injectable()
export class UserRouteAccessService implements CanActivate {

    constructor(
        private router: Router, private auth: AuthService,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if (token && token.expires_at && token.expires_at > new Date().getTime()) {
            // user has logged in check for authorization
            const temp = this.auth.authorize(true).then(canActivate => {
                localStorage.setItem('canActivate', canActivate);
                return canActivate;
            });
            return temp;
        } else {
            // not logged in so redirect to login page with the return url
            if (USER_TYPES.IS_REGULATOR || USER_TYPES.IS_FOREMAN) {
                this.router.navigate(['/login']);
            } else if (USER_TYPES.IS_USER) {
                this.router.navigate(['/pages/citizen-corner']);
            } else {
                this.router.navigate(['/login']);
            }
            return false;
        }
    }
}
