import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServerProvider, ChangePasswordService } from '../../shared';
import { SnBToasterService } from '../../shared/components/toaster/toaster.service';
import { ErrorConfigService } from '../../shared/components/error/errors.config.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoaderService } from '../../shared/loader.service';



@Component({
  selector: 'register',
  templateUrl: './register.html',
  styleUrls: ['./register.scss']
})
export class Register implements OnInit {

  registerNewForeman: FormGroup;
  foremanDetails: any;
  href: string;
  token: any;
  success = false;
  passwordError: string;
  foremanBranchDetails: any;
  isFormSubmitted = false;
  isEightChar: boolean = false;
  hasUppercase: boolean = false;
  hasSpecialAndNumericCase: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthServerProvider,
    private toasterService: SnBToasterService,
    private errorService: ErrorConfigService,
    private activatedRoute: ActivatedRoute,
    // 
    public loaderService: LoaderService,
    private router: Router,
    private changePasswordService: ChangePasswordService
  ) {
    this.registerNewForeman = this.fb.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': [''],
      'password': ['', Validators.required],
      'ConfirmPassword': ['', Validators.required],
      'mobileNumber': [''],
    });
    this.registerNewForeman.controls.mobileNumber.disable();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params);
    });
    // 
  }

  loadForemanBranchDetails(req?: any) {
    this.authService.getForemanBranchDetails(req).subscribe(success => {
      this.foremanBranchDetails = success;
    }, fail => {
      if (fail) {
        this.toasterService.showFailToaster(fail)
      }
    })
  };


  loadForemanDetails(req?: any) {
    this.authService.getForemanRegister(req).subscribe(success => {
      this.foremanDetails = success;
      if (this.foremanDetails.accountType === "FOREMAN") {
        this.loadForemanBranchDetails(this.foremanDetails.id)
      }
      this.registerNewForeman.controls['firstName'].setValue(this.foremanDetails.firstName);
      this.registerNewForeman.controls['lastName'].setValue(this.foremanDetails.lastName);
      this.registerNewForeman.controls['mobileNumber'].setValue(this.foremanDetails.mobile);
      this.registerNewForeman.controls['email'].setValue(this.foremanDetails.email);
      this.registerNewForeman.controls.mobileNumber.disable();
    }, fail => {
      if (fail) {
        this.toasterService.showFailToaster(fail)
      }
    })
  };


  // Executed When Form Is Submitted
  onFormSubmit(event: Event) {

    event.preventDefault();

    if (!this.registerNewForeman.valid) {
      return false;
    }
    if (!this.isEightChar || !this.hasUppercase || !this.hasSpecialAndNumericCase) {
      return false;
    }
    let data: any = {};
    data = this.registerNewForeman.value;
    data.mobileNumber = this.foremanDetails.mobile;
    data.login = this.foremanDetails.mobile;
    delete data.ConfirmPassword;
    this.isFormSubmitted = true;
    try {
      this.authService.newForemanRegister(this.token, data).subscribe((res) => {
        console.log(res);
        this.toasterService.showSuccessToaster('Your account activated successfully');
        this.isFormSubmitted = false;
        this.router.navigate(['/login']);
      }, (error: any) => {
        console.log('data failed');
        this.isFormSubmitted = false;
        this.errorService.showError(error);
      });
    } catch (e) {
      // this.onError('error.invalidClient', null);
      this.isFormSubmitted = false;
    }
  }


  confirmPassword() {
    if (this.registerNewForeman.value.ConfirmPassword && this.registerNewForeman.value.password) {
      if (this.registerNewForeman.value.ConfirmPassword !== this.registerNewForeman.value.password) {
        this.registerNewForeman.controls.ConfirmPassword.setErrors({ 'passwordMismatch': true })
      }
    }
  }

  ngOnInit() {
    this.href = this.router.url;
    this.token = this.href.substr(this.href.lastIndexOf('/') + 1);
    this.loadForemanDetails(this.token)
  }

  validatePassword() {
    const validationResults = this.changePasswordService.validatePassword(this.registerNewForeman.value.password);
    this.isEightChar = validationResults.isEightChar;
    this.hasUppercase = validationResults.hasUppercase;
    this.hasSpecialAndNumericCase = validationResults.hasSpecialAndNumericCase;

  }

}
