<mat-dialog-content>
    <p class="text-center">
        <i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: #b71c1c"></i> Are you sure want to
        remove Foreman from Branch?
    </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>No</button>
    <button mat-raised-button color="primary" (click)="removeForeman()">Yes</button>
</mat-dialog-actions>
