import { RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from '../shared/components/errorpage/errorpage.component';
import { JhiLoginModalComponent } from '../shared/login/login.component';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages.component';
import { Register } from './register/register.component';
import { SnBChangePasswordComponent } from '../shared/changepassword/changepassword.component';
import { UserRouteAccessService } from '../shared';
import { VerificationComponet } from '../shared/verification/verificatio.component';

export const routes: Routes = [
  {
    path: 'login',
    component: JhiLoginModalComponent
  },
  {
    path: 'verification',
    component: VerificationComponet
  },
  {
    path: 'changepassword',
    component: SnBChangePasswordComponent
  },
  {
    path: 'register/:token',
    component: Register
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'violations',
        loadChildren: () => import('./violations/violation.module').then(m => m.ViolationModule)
      },
      {
        path: 'applications',
        loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule)
      },
      {
        path: 'foreman-companies',
        loadChildren: () => import('./foreman-companies/foreman-companies.module').then(m => m.ForemanCompaniesModule)
      },
      {
        path: 'roc-organization',
        loadChildren: () => import('./roc-companies/roc-companies.module').then(m => m.RocOrganisationModule)
      },
      {
        path: 'your-organisation',
        loadChildren: () => import('./foreman-companies/foreman-companies.module').then(m => m.ForemanCompaniesModule)
      },
      {
        path: 'workflows',
        loadChildren: () => import('./workflows/workflows.module').then(m => m.WorkflowsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'cm-ui-kit',
        loadChildren: () => import('./cm-ui-kit/ui-kit.module').then(m => m.CmUiKitModule)
      },
      {
        path: 'sample-docs',
        loadChildren: () => import('./sample-docs/sample-docs.module').then(m => m.SampleDocsModule)
      },
      {
        path: 'chitgroups',
        loadChildren: () => import('./chitgroups/chitgroups.module').then(m => m.ChitGroupsModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'legacy-services',
        loadChildren: () => import('./legacy-data-services/legacy-data-services.module').then(m => m.LegacyDataServicesModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'new-features',
        loadChildren: () => import('./new-features/new-features.module').then(m => m.NewFeatureModule)
      },
      {
        path: 'grievance',
        loadChildren: () => import('./grievance/grievance.module').then(m => m.GrievanceModule)
      },
      {
        path: 'arbitrations',
        loadChildren: () => import('./arbitrations/arbitrations.module').then(m => m.ArbitrationsModule)
      },
      {
        path: "formen-notices",
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'audit',
        loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
      },
      {
        path: 'block-chain-explorer',
        loadChildren: () => import('./block-chain/assets/asset-explorer.module').then(m => m.ExploreBCAssetsModule)
      },
      { 
        path: 'blockchain',
        loadChildren: () => import('./block-chain/blockchain.module').then(m => m.BlockchainModule) 
      },
      {
        path: 'reports-new',
        loadChildren: () => import('./google-chart-reports/google-chart-reports.module').then(m => m.GoogleChartReportsModule)
      },
      
    ],
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {
        path: 'citizen-corner',
        loadChildren: () => import('./subscriber-corner/subscriber-corner.module').then(m => m.SusbscriberCornerModule)
      },
      {
        path: 'certified-copy-agreement',
        loadChildren: () => import('./subscriber-certified-copy/subscriber-certified-copy.module').then(m => m.SusbscriberCertifiedCopyModule)
      },
      {
        path: 'certified-copy-award',
        loadChildren: () => import('./subscriber-certified-copy/subscriber-certified-copy.module').then(m => m.SusbscriberCertifiedCopyModule)
      },
      {
        path: 'other-payment',
        loadChildren: () => import('./subscriber-certified-copy/subscriber-certified-copy.module').then(m => m.SusbscriberCertifiedCopyModule)
      },
      {
        path: 'pending-applications',

        loadChildren: () => import('./subscriber-pending-applications/subscriber-pending-applications.module').then(m => m.SusbscriberPendingApplicationsModule)
      },
      {
        path: 'grievance-public',
        loadChildren: () => import('./grievance/grievance.module').then(m => m.GrievanceModule)
      },
      {
        path: 'chitinfo',
        loadChildren: () => import('./chitinfo/chitinfo.module').then(m => m.ChitInfoModule)
      },
      {
        path: 'notices',
        loadChildren: () => import('./notice/notices.module').then(m => m.NoticesModule)
      },
      {
        path: 'enroll-company',
        loadChildren: () => import('./enroll-companies/enroll-companies.module').then(m => m.EnrollCompaniesModule)
      },
      { path: 'arbitration', 
        loadChildren: () => import('./arbitrations/arbitrations.module').then(m => m.ArbitrationsModule)
      }
    ],
  },
  {
    path: 'claim-calculation',
    loadChildren: () => import('./arbitrations/arbitrations.module').then(m => m.ArbitrationsModule),
    canActivate: [UserRouteAccessService],
    pathMatch: 'full'
},

  ,
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetModule),
    pathMatch: 'full'
  },
];

export const PagesRoutingModule: ModuleWithProviders<any> = RouterModule.forChild(routes);
