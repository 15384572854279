
import { throwError as observableThrowError,  Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SNB_WF_URL, USER_TYPES, SNB_REPORTS_URL } from '../../../app.constants';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CommonService } from '../../services/common-service.service';


@Injectable()
export class FileUploadService extends CommonService {
    constructor(
        private httpClient: HttpClient
    ) { 
        super();
    }

    upload(url: string, formData: string): any {

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'text/xml');

        return this.httpClient.post(url, formData, { headers: headers, observe: 'body' }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    uploadFile<T>(file, req?: any): Observable<any> {
        let headers = new HttpHeaders();
        const params = new HttpParams();

        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.delete('Content-Type');

        let url = `${SNB_WF_URL}/${this.getUerType()}/content-service/content-items`;;
        
        return this.httpClient.post(`${url}`, file, {
            headers: headers, params: params, observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    downloadFile<T>(fileId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        options['responseType'] = 'arraybuffer';

        let url;
        if (USER_TYPES.IS_REGULATOR) {
            url = SNB_WF_URL + '/regulator';
        } else {
            url = SNB_WF_URL + ' /foreman';
        }

        url = `${url}/content-service/content-items/${fileId}/data`;

        return this.httpClient.get<T>(url,
            { headers: options['headers'], responseType: options['responseType'], observe: 'response' }).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    viewFile<T>(fileId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        options['responseType'] = 'arraybuffer';

        let url;
        if (USER_TYPES.IS_REGULATOR) {
            url = SNB_WF_URL + '/regulator';
        } else {
            url = SNB_WF_URL + '/foreman';
        }

        url = `${url}/content-service/content-items/${fileId}/data`;

        return this.httpClient.get<T>(url,
            { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    downloadReport<T>(reportName: string, reportFormat: string, req?: any):Observable<any> {
        const queryParams: any = { 'reportName': reportName, 'reportFormat': reportFormat };

        if (req) {
            req.query = Object.assign(queryParams, req.query);
        }
        const options = this.prepareHeader(req);
        options['responseType'] = 'arraybuffer';

        let url;
        if (USER_TYPES.IS_REGULATOR) {
            url = SNB_REPORTS_URL + '/regulator';
        } else {
            url = SNB_REPORTS_URL + '/foreman';
        }

        url = `${url}/reports`;

        return this.httpClient.get<T>(url,
            { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }
}
