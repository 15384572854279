import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class StateStorageService {
    private previousUrlKey = 'previousUrl';
    private authenticationKey = 'jhi-authenticationToken';
    private localeKey = 'locale';

    constructor(
        private $sessionStorage: SessionStorageService
    ) { }

    getPreviousState() {
        return this.$sessionStorage.retrieve('previousState');
    }

    resetPreviousState() {
        this.$sessionStorage.clear('previousState');
    }

    storePreviousState(previousStateName, previousStateParams) {
        let previousState = { 'name': previousStateName, 'params': previousStateParams };
        this.$sessionStorage.store('previousState', previousState);
    }

    getDestinationState() {
        return this.$sessionStorage.retrieve('destinationState');
    }
    
    storeUrl(url: string): void {
        sessionStorage.setItem(this.previousUrlKey, JSON.stringify(url));
    }

    getUrl(): string | null {
        const previousUrl = sessionStorage.getItem(this.previousUrlKey);
        return previousUrl ? (JSON.parse(previousUrl) as string | null) : previousUrl;
    }

    clearUrl(): void {
        sessionStorage.removeItem(this.previousUrlKey);
    }

    storeAuthenticationToken(authenticationToken: string, rememberMe: boolean): void {
        authenticationToken = JSON.stringify(authenticationToken);
        this.clearAuthenticationToken();
        if (rememberMe) {
            localStorage.setItem(this.authenticationKey, authenticationToken);
        } else {
            sessionStorage.setItem(this.authenticationKey, authenticationToken);
        }
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        let destinationInfo = {
            'destination': {
                'name': destinationState.name,
                'data': destinationState.data,
            },
            'params': destinationStateParams,
            'from': {
                'name': fromState.name,
            }
        };
        this.$sessionStorage.store('destinationState', destinationInfo);
    }

    getAuthenticationToken(): string | null {
        const authenticationToken = localStorage.getItem(this.authenticationKey) ?? sessionStorage.getItem(this.authenticationKey);
        return authenticationToken ? (JSON.parse(authenticationToken) as string | null) : authenticationToken;
    }

    clearAuthenticationToken(): void {
        sessionStorage.removeItem(this.authenticationKey);
        localStorage.removeItem(this.authenticationKey);
    }

    storeLocale(locale: string): void {
        sessionStorage.setItem(this.localeKey, locale);
    }

    getLocale(): string | null {
        return sessionStorage.getItem(this.localeKey);
    }

    clearLocale(): void {
        sessionStorage.removeItem(this.localeKey);
    }
}
