import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { ChitmonksApiSharedModule } from '../shared';
import { PagesComponent } from './pages.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    NgaModule,
    PagesRoutingModule,
    CommonModule,
    ChitmonksApiSharedModule,
    FormsModule
  ],
  declarations: [PagesComponent],
  providers: []
})
export class PagesModule { }

