import { SnbFunctionToPrivilegeMappingServices } from "./../services/SnbFunctionToPrivilegeMapping.service";
import { ElementRef, Renderer2, Input } from "@angular/core";
import { Directive, OnInit, AfterViewInit } from "@angular/core";
import { Principal } from "./principal.service";

/**
 * This directive checks whether given UI Function is allowed for the logged in user.
 * If not allowed either it disables/hides the UI component.
 */
@Directive({
    selector: "[appHasAnyPermission]",
})
export class HasAnyPermissionDirective implements OnInit {
    // holds the comma separated uiFunciton names.
    @Input() appHasAnyPermission: any;
    // formatted UI function names.
    uiFunctions: string[];
    // Holds the object from uiFunction to Privilge mapping
    privilegeDef: any;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private principal: Principal,
        private snbFunctionToPrivilegeMappingServices: SnbFunctionToPrivilegeMappingServices,
    ) {}

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        if(this.appHasAnyPermission) {
            this.uiFunctions = this.appHasAnyPermission
                .replace(/\s+/g, "")
                .split(",");
            this.setVisibilitySync();
        }
    }

    private setVisibilitySync() {
        let privileges: any[] = []
        let fnToPrivilegeMap = this.snbFunctionToPrivilegeMappingServices.getPriviliges();
        this.uiFunctions.forEach(fn => {
            let fnToPrivilegeObj = fnToPrivilegeMap[fn];
            if(fnToPrivilegeObj && fnToPrivilegeObj.operation) {
                this.privilegeDef  = fnToPrivilegeObj;
                privileges.push(fnToPrivilegeObj.operation);
            }
        });
       
        if (privileges.length > 0) {
            if(this.principal.hasPrivilege(privileges)) {
                // he has privilege to execute the function, so do nothing and return
                return;   
            } else {
                // user not having prigilege to exuecute function, disable or hide based on the def.
                this.handleEl();
            }
        } else {
            this.disableEL();
        }
    }

    private handleEl() {
        switch (this.privilegeDef.action) {
            case "disable":
                this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'auto');
                this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
                break;
            case "hidden":
                this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
                break;
            default:
                break;
        }
    }

    private disableEL() {
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
    }
}
