<div mat-dialog-content>
    <div>
        <div class="right-col">
            <div class="step-col-content">
                <h3 class="step-col-label">
                    More Details
                </h3>
                <div class="col-wrapper light-bg">
                    <div class="s-col">
                        <span class="property">
                            Email
                        </span>
                        <div class="value s-col-ellipsis" *ngIf="selectedForemanData?.contactDetails?.email">
                            {{selectedForemanData?.contactDetails?.email}}
                        </div>
                        <div class="value s-col-ellipsis" *ngIf="!selectedForemanData?.contactDetails?.email">
                            --
                        </div>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            User Status
                        </span>
                        <div class="value s-col-ellipsis" *ngIf="!selectedForemanData?.activationId">
                            Activated
                        </div>
                        <div class="value s-col-ellipsis" *ngIf="selectedForemanData?.activationId">
                            Not Activated
                            <a *ngIf="isRegulatorSuperAdmin || isCustSuport" style="float: right;cursor: pointer;" #menuTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="menu">Send
                                Activation Link</a>
                            <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
                                <button mat-menu-item (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'EMAIL')">
                                    <mat-icon>email</mat-icon>
                                    <span>EMAIL</span>
                                </button>
                                <button mat-menu-item (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'SMS')">
                                    <mat-icon>textsms</mat-icon>
                                    <span>SMS</span>
                                </button>
                                <button mat-menu-item (click)="sendActivationLinkToForeman(selectedForemanData?.activationId,'ALL')">
                                    <mat-icon>send</mat-icon>
                                    <span>EMAIL & SMS</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            Address
                        </span>
                        <div class="value s-col-full" *ngIf="selectedForemanData?.contactDetails?.addresses">
                            {{selectedForemanData?.contactDetails?.addresses[0]?.addressLine1}},
                            {{selectedForemanData?.contactDetails?.addresses[0]?.addressLine2}},
                            {{selectedForemanData?.contactDetails?.addresses[0]?.street}},
                            {{selectedForemanData?.contactDetails?.addresses[0]?.city}},
                            {{selectedForemanData?.contactDetails?.addresses[0]?.district}},
                            {{selectedForemanData?.contactDetails?.addresses[0]?.state}},
                            <div>PIN- {{selectedForemanData?.contactDetails?.addresses[0]?.pin}}</div>
                        </div>
                        <div class="value s-col-full" *ngIf="!selectedForemanData?.contactDetails?.addresses?.length">
                            --
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="text-align: right;display: block;">
    <div class="row">
        <div class="col-md-12">
            <button mat-button (click)="onNoClick()" class="snb-raised-button">Ok</button>
        </div>
    </div>
</div>