<div class="header-top-content">
    <div class="info">
        <h1>{{ activePageTitle }}</h1>


        <div class="back_action" (click)="goToBackView()" style="position: relative;" matTooltipPosition="below"
            matTooltip="Back" [hidden]="activePageTitle==='Grievance' ||
            activePageTitle==='Register Foreman Company' || hideBackButton">
            <label>
                <i class="material-icons">arrow_back_ios_new</i>
            </label>
        </div>

    </div>
</div>