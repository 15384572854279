import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-print-form',
    templateUrl: './print.html'
})
export class PrintComponent {


    expandRightBlock = false;

    @Input() public printsList: any;

    @Input('print') public printFormId: any;

    printForm(form) {
        const innerContents = <HTMLElement>document.querySelector('#' + form.printFormId);
        if (!innerContents) {
            return false;
        }
        if (innerContents.getElementsByClassName('printclick').length === 0 &&
            innerContents.getElementsByClassName('snb_form_preview')['0']) {
            innerContents.getElementsByClassName('snb_form_preview')['0'].classList.add('printclick');
        }
        if (innerContents.getElementsByClassName('printclick').length === 0 &&
            innerContents.getElementsByClassName('preview_step')['0']) {
            innerContents.getElementsByClassName('preview_step')['0'].classList.add('printclick');
        }
        const frameName = 'printIframe';
        let doc = window.frames[frameName];

        // var popupWinindow = window.open('', 'PRINT', 'width=1350,height=700,scrollbars=no,menubar=no,
        // toolbar=no,location=no,status=no,titlebar=no');
        // popupWinindow.document.open();
        // // popupWinindow.document.write('<html><head><link rel='stylesheet' type='text/css' href='../../assets/css/print.css' />
        // </head><body onload='window.print(); window.close();'>' + innerContents.innerHTML + '</html>');
        // popupWinindow.document.write('<html><head><link rel='stylesheet' type='text/css' href='../../assets/css/print.css' />
        // </head><body onload='window.print();'>' + innerContents.innerHTML + '</body></html>');
        // popupWinindow.document.close();
        // innerContents.getElementsByClassName('snb_form_preview')['0'].classList.remove('printclick');

        if (!doc) {
            $('<iframe>').hide().attr('name', frameName).appendTo(document.body);
            doc = window.frames[frameName];
        }
        const head = doc.document.head;
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = '../../assets/css/print.css';
        head.appendChild(link);
        const formName = form.printFormName;
        setTimeout(function () {
            doc.document.body.innerHTML = innerContents.innerHTML;
            const originalTitle = document.title;
            document.title = formName;
            doc.window.print();
            document.title = originalTitle;
            if (innerContents.getElementsByClassName('printclick').length !== 0 &&
                innerContents.getElementsByClassName('snb_form_preview')['0']) {
                innerContents.getElementsByClassName('snb_form_preview')['0'].classList.remove('printclick');
            }
            if (innerContents.getElementsByClassName('printclick').length !== 0 &&
                innerContents.getElementsByClassName('preview_step')['0']) {
                innerContents.getElementsByClassName('preview_step')['0'].classList.remove('printclick');
            }
        }, 500);
    }
}
