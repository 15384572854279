import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../blocks/interceptor/alert.service';


@Component({
    selector: 'app-payment-status',
    styleUrls: ['./payment.scss'],
    templateUrl: './payment-status.html'
})
export class PaymentStatusComponent implements AfterViewInit {

    transactionStatus: any = {};
    message: string = ''
    constructor(private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
    ) {
    }
    errorOccured = false;

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.trnxId) {
                this.getTransactionStatus(parseInt(params['trnxId'], 0));
            } else {
                this.getTransactionStatus('');
            }
        });
    }

    private onError(error) {
        this.alertService.addAlert({type: 'danger', translationKey: error, translationParams: {}}, []);
    }

    getTransactionStatus = function (trnxId) {
        if (trnxId === -1 || !trnxId) {
            this.transactionStatus.trnxStatus = 'FAILCHECKSUM';
            return;
        }
        this.paymentService.getTransaction(trnxId).subscribe(
            (success) => {
                this.transactionStatus = success;
                console.log('transaction status is ' + JSON.stringify(this.transactionStatus));
            }, (fail) => {
                if (fail) {
                    this.toasterService.showCustomError(fail);
                }
            }
        );

    };

    goToPage(page) {
        this.router.navigate([page]);
    }

}
