<div class="animated fadeIn">
    <section id="upload">

        <div class="Upload_fields row">
            <div class="upload-body col-md-2 col-sm-2 col-xs-12" [hidden]="isResult"
                *ngIf="uploader.queue.length === 0 || (uploader.queue.length === 1 && showNotCSVFileError) || (uploadFailed && !showEditTemplate)">
                <form role="form" autocomplete="off">
                    <div class="box" matTooltip="Upload here!" matTooltipPosition="above">
                        <input (change)="onFileChange($event)" type="file" ng2FileSelect [uploader]="uploader"
                            class="inputfile inputfile-5" data-multiple-caption="{count} files selected" style="top:0"
                            accept=".csv" />
                        <label for="file-6" style="margin-top: -25px">
                            <div class="uploader-wrapper">
                                <div class="document-container">
                                    <img class="bounce animated center-block" src="assets/img/upload-file.svg" />
                                </div>
                            </div>
                            <p class="upload_pitch text-center">Click here to <br>upload the file</p>
                        </label>
                    </div>
                </form>
            </div>
            <div class="upload_info col-md-9 col-sm-9 col-xs-12">
                <div [hidden]="isResult"
                    *ngIf="uploader.queue.length === 0 || (uploader.queue.length === 1 && showNotCSVFileError) || (uploadFailed && !showEditTemplate)">
                    <p> File should meet following requirements !</p>

                    <ul>
                        <li>The maximum allowed file size is <span>{{fileSettings.maximumFileSizeInMB}} MB</span>.
                            <b style="color: red;">Only <span>CSV</span> files are allowed.</b>
                        </li>
                        <li>
                            <a href="{{fileSettings.templateUrl}}" matTooltip="Download template here!"
                                matTooltipPosition="above" download>
                                <b>Click here to download the template</b>
                                ( <i class="ion ion-ios-cloud-download"></i> )
                            </a>.
                            <br>Fill this file and Upload it. Example data is shown in the template.
                        </li>
                    </ul>
                </div>
                <mat-error *ngIf="showNotCSVFileError"><b>Only CSV files are accepted!!!</b></mat-error>
            </div>
        </div>

        <div id="upload-queue animated fadeIn" style="margin-bottom: 40px">

            <mat-card>

                <ul *ngFor="let item of uploader.queue" class="ul_after_upload"
                    [hidden]="fileUploadResponse && (fileUploadResponse?.successfulRecords === fileUploadResponse?.totalRecords)">

                    <li><span class="title-name"> Name </span><span>: {{ item?.file?.name }}
                            <i *ngIf="uploadedInprogress"
                                style="font-size: 20px; color: green;margin-top: 10px;margin-left: 10px;"
                                class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </span>
                    </li>
                    <li>
                        <span class="title-size"> Size</span><span>: {{ item?.file?.size/1024 | number:'.2' }} KB
                        </span>
                    </li>

                    <li *ngIf="showEditTemplate">
                        <button mat-raised-button type="button" class="u-btn btn-xs" color="primary"
                            (click)="upload()" style="margin-right: 3px;">Submit</button>
                        <button mat-raised-button type="button" class="r-btn btn-xs"
                            (click)="cancelUpload()">Cancel</button>
                        <div class="undo_lastrow" *ngIf="tempItem.length > 0" style="float: right;margin-top: 25px;"
                            (click)="undoLast()">
                            <a mat-raised-button>
                                <i class="fa fa-undo fa-2x" style="color: #1a83bd;font-size: 14px;margin-right: 5px;"
                                    aria-hidden="true"></i>
                                Undo
                            </a>
                        </div>
                    </li>
                </ul>

                <div>

                    <div *ngIf="isResult" style="color:green">
                        Record(s) have been successfully uploaded.
                    </div>

                    <div *ngIf="uploadFailed">
                        <p *ngFor="let error of errors" style="color: red;FONT-SIZE: 100%;">{{error.message}}</p>
                    </div>

                    <div *ngIf="uploadFailed" style="margin-bottom: 10px">
                        <mat-accordion *ngIf="warnings.length !== 0" class="show-errors-expansion-panel">
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <b style="color: red">Show Errors</b>
                                    </mat-panel-title>
                                    <mat-panel-description>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p *ngFor="let warning of warnings" style="color: red;FONT-SIZE: 100%;">
                                    <span *ngIf="!showEditTemplate">
                                        Line No. {{warning.lineNumber+1}},
                                        Field No.{{warning.fieldNumber}} -
                                        {{warning.message}}
                                    </span>
                                    <span *ngIf="showEditTemplate">
                                        Line No. {{warning.lineNumber}},
                                        Field No.{{warning.fieldNumber}} -
                                        {{warning.message}}
                                    </span>
                                    <br>
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                </div>
                <div style="width:98%; padding-bottom: 10px; overflow:auto;"
                    [hidden]="fileUploadResponse && fileUploadResponse?.successfulRecords == fileUploadResponse?.totalRecords"
                    *ngIf="showEditTemplate">

                    <table class="table table-bordered table-hover edit_table">

                        <tbody>
                            <tr *ngFor="let row of data;let j = index;">
                                <td>
                                    <p *ngIf="j==0">#</p>
                                    <p *ngIf="j>0">{{j}}</p>
                                </td>
                                <td *ngFor="let val of row;let i = index;trackBy:trackByFn">

                                    <input [disabled]="j==0" name="lastname-{{j}}-{{i}}"
                                        [ngModel]="(i===12 || i===14)?data[j][i].trim():data[j][i]"
                                        (ngModelChange)="onChanges($event,j,i)"
                                        [matTooltip]="isResult?'':temp?temp.row==j?temp.column==i?temp.message:'':'':''"
                                        [ngClass]="showError(j,i)" style="border: 1px solid ccc" />
                                </td>
                                <span [hidden]="j==0" (click)="deleteRow(j)" matTooltip="Delete Row!"
                                    style="position: absolute;right: 20px;padding-top: 5px;cursor: pointer;">
                                    <i style="color: #1a83bd;" class="fa fa-trash" aria-hidden="true"></i>
                                </span>

                            </tr>

                        </tbody>
                    </table>
                </div>
            </mat-card>
        </div>
    </section>
</div>

<jhi-alert></jhi-alert>