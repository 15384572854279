
import { USER_TYPES } from "../../app.constants";
export class CommonService {

    constructor(
    ) {
    }

    protected getUerType(): string  {
        if (USER_TYPES.IS_REGULATOR) {
            return 'regulator';
        } else if(USER_TYPES.IS_FOREMAN){
            return 'foreman';
        } else {
            return 'public';
        }
        //return "unknown";
    }
}
