
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SNB_WF_URL, USER_TYPES } from '../../app.constants';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';


@Injectable()
export class WorkflowService {
    constructor(
        private httpClient: HttpClient,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService
    ) { }

    getApprovedWorkflowList<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = '/regulator/task/query-completed-tasks';
        } else {
            url = '/foreman/task/query-completed-tasks';
        }
        return this.httpClient.post(SNB_WF_URL + `${url}`, this.addBodyParams(req), {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getApprovedWorkflowListForChitGroup<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = '/regulator/task/query-application-approved-tasks';
        } else {
            url = '/foreman/task/query-application-approved-tasks';
        }
        return this.httpClient.post(SNB_WF_URL + `${url}`, this.addBodyParams(req), {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    /**
     * 
     * @param req Holds query params and page request details
     * @param reqBody  holds BODY request in POST request based on which pending tasks is obtained.
     * @returns 
     */
    getPendingWorkflowList<T>(req?: any, reqBody?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = '/regulator/task/query';
        } else {
            url = '/foreman/task/query';
        }
        return this.httpClient.post(SNB_WF_URL + `${url}`, this.addBodyParams(reqBody), {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getPendingWorkflowListForPendingPayments<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        const url = '/foreman/task/payment/query-pending-payments-tasks';
        return this.httpClient.post(SNB_WF_URL + `${url}`, this.addBodyParams(req), options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getFormDetailsByTaskId<T>(taskId, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let isActive = true;
        options['params'] = options['params'].set('cached-data', true);
        
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/task/${taskId}`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/task/${taskId}`;
        } else {
            url = `public/task/${taskId}`;
        }
        return this.httpClient.get(`${SNB_WF_URL}/${url}?active=${isActive}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitTask<T>(taskId, form1Data, req?: any): Observable<any> {
        const copy = Object.assign({}, form1Data);
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/task/submit/${taskId}`;
        } else {
            url = `foreman/task/submit/${taskId}`;
        }
        return this.httpClient.post(`${SNB_WF_URL}/${url}`, copy, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    addBodyParams(req?) {
        const bodyParamObject: any = {};
        bodyParamObject['processDefinitionKeys'] = req.processDefinitionKeys ? req.processDefinitionKeys : [];

        if (req.pendingWith) {
            bodyParamObject['pendingWith'] = req.pendingWith;
        }
        if (req.paymentStatus) {
            bodyParamObject['paymentStatus'] = req.paymentStatus;
        }

        if (req.startedOnFrom) {
            bodyParamObject['startedOnFrom'] = req.startedOnFrom;
        }
        if (req.startedOnTo) {
            bodyParamObject['startedOnTo'] = req.startedOnTo;
        }

        if (req.businessDateFrom) {
            bodyParamObject['businessDateFrom'] = req.businessDateFrom;
        }
        if (req.businessDateTo) {
            bodyParamObject['businessDateTo'] = req.businessDateTo;
        }
        if (req.processInstanceId) {
            bodyParamObject['processInstanceId'] = req.processInstanceId;
        }
        if (req.bodyParam) {
            const keys: string[] = Object.keys(req.bodyParam);
            keys.forEach(key => bodyParamObject[key] = req.bodyParam[key]);
        }
        return bodyParamObject;
    }


    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');

        let params = new HttpParams();
        if(!urlSearchParams) {
            urlSearchParams = {};
        }
        if (typeof (urlSearchParams.page) !== 'undefined') {
            params = params.set('page', urlSearchParams.page);
        }
        if (urlSearchParams.size) {
            params = params.set('size', urlSearchParams.size);
        }
        if (urlSearchParams.sort) {
            params = params.set('sort', urlSearchParams.sort);
        }
        if (urlSearchParams.stateIn) {
            params = params.set('stateIn', urlSearchParams.stateIn);
        }
        if (urlSearchParams.processDefinitionKeys) {
            params = params.set('processDefinitionKeys', urlSearchParams.processDefinitionKeys);
        }
        if (urlSearchParams['cached-data']) {
            params = params.set('cached-data', urlSearchParams['cached-data']);
        }

        if (urlSearchParams.query) {
            const keys: string[] = Object.keys(urlSearchParams.query);
            keys.forEach(key => {
                if (urlSearchParams.query[key]) {
                    params = params.set(key, urlSearchParams.query[key]);
                }
            });
        }

        return {
            headers: headers,
            params: params
        };
    }
}
