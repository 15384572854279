
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnInit } from "@angular/core";
import { SnbTableComponent } from "../table/snb-table.component";
import { LoaderService } from "../../loader.service";
import { LogService } from "../../services/log-service";

@Component({
    selector: 'app-arb-inst-and-due-interest-info',
    templateUrl: './arb-inst-due-and-interest-info.html',
    styleUrls: ['./arb-inst-due-and-interest-info.scss']
})

export class ArbInstDueAndInterestInfoComponent extends SnbTableComponent implements OnInit {

    @Input() data: any[];
    // completeTableData contains all the data including the final row which contains totals
    completeTableData: any[];
    displayedColumns: string[] = [
        'installmentNo',
        'installmentDate',
        'subscriptionAmount',
        'dividend',
        'netDue',
        'interestAmount',
        'days'
    ];

    constructor(        
        public loaderService: LoaderService,
        public logService: LogService,) 
    {
        super(loaderService, logService);
    }

    ngOnInit(): void {
        this.completeTableData = [...this.data];
        this.completeTableData.push(this.getFinalRowCalculations());
    }

    fetchData(): Observable<any> {
       return observableOf(this.data);
    }
    rowClicked(event: any, rowData: any) {
        //throw new Error("Method not implemented.");
    }
    tableDataLoaded() {
        //throw new Error("Method not implemented.");
    }

    getFinalRowCalculations() {
        let row = {'installmentNo':'TOTAL','installmentDate': 'null',
        'subscriptionAmount': this.data.map(t => t.subscriptionAmount).reduce((total, value) => total + value, 0) , 
        'dividend': this.data.map(t => t.dividend).reduce((totalDividend, value) => totalDividend + value, 0), 
        'netDue': this.data.map(t => t.netDue).reduce((due, value) => due + value, 0), 
        'interestAmount': this.data.map(t => t.interestAmount).reduce((total, value) => total + value, 0),
        'days': 'null'};
        return row;
    }
}