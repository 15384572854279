import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'content-left-block',
    template: `<div></div>`
 
})
export class LeftContentBlockComponent {

}


@Component({
    selector: 'content-right-block',
    template: `<div></div>`
})
export class RightContentBlockComponent  {

}
