import { Injectable } from '@angular/core';

import { SnBToasterService } from '../toaster/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorConfigService {
    constructor(
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
    ) { }

    showError(error) {
        if (error) {
            const code = error.status;
            switch (code) {
                case 400:
                case 403:
                    if (JSON.parse(error._body).detail) {
                        this.toasterService.showFailToaster(JSON.parse(error._body).detail);
                    } else if (JSON.parse(error._body).description) {
                        this.toasterService.showFailToaster(JSON.parse(error._body).description);
                    } else if (JSON.parse(error._body).error_description) {
                        this.toasterService.showFailToaster(JSON.parse(error._body).error_description);
                    } else {
                        this.toasterService.showFailToaster(JSON.parse(error._body).message);
                    }
                    break;
                case 404:
                    this.toasterService.showFailToaster(this.translateService.instant('error.server.not.reachable'));
                    console.log(error);
                    break;
                case 500:
                    this.toasterService.showFailToaster(this.translateService.instant('error.server.not.reachable'));
                    console.log(error);
                    break;
                default:
                    break;
            }
        }

    }
}
