import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WindowScrollService {
  scrollY = new BehaviorSubject(false);
  scrollY$ = this.scrollY.asObservable();

  constructor() {
    this.updateScrollY(false);
  }

  updateScrollY(value: boolean): void {
    this.scrollY.next(false);
  }
}
