import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgaModule } from '../theme/nga.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        NgbModule,
        NgaModule,
      
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,  
        NgaModule
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class ChitmonksApiSharedLibsModule {
    constructor() {
        console.log('Loading ChitmonksApiSharedLibsModule...')
    }
 }
