<div class="dialog-wrapper">
    <div class="dialog-title">
        Branch Update
        <span><i class="material-icons close-btn" (click)="onNoClick()">close</i></span>
    </div>
    <div class="dialog-content-wrapper">
        <div class="form-wrapper snb_form_entries">
            <form [formGroup]="subBranchForm" novalidate (ngSubmit)="submitBranchData(subBranchForm, $event, 'save')"
                autocomplete="off">
                <div class="row">
                    <div [ngClass]="{'col-md-6 col-sm-6 col-xs-12' : data?.viewBranchData && data?.viewAdminData }" *ngIf="data?.viewBranchData">
                        <div style="min-height: 280px !important;">
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>Branch Name</mat-label>
                                    <input type="text" formControlName="name" matInput required>
                                    <mat-error *ngIf="subBranchForm.controls.name.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                            </div>
                            <div formGroupName="contactDetails">
                                <div class="snb_single_col">
                                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                        <mat-label>Branch Email</mat-label>
                                        <input type="email" formControlName="email" matInput>
                                        <mat-error *ngIf="subBranchForm.get('contactDetails')['controls'].email.value && subBranchForm.get('contactDetails')['controls'].email.hasError('pattern')"
                                            jhiTranslate="entity.validation.email"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="snb_single_col">
                                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                        <mat-label>Branch Mobile Number</mat-label>
                                        <input type="number" formControlName="mobileNumber" matInput
                                            required>
                                        <mat-error *ngIf="subBranchForm.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                            jhiTranslate="entity.validation.required"></mat-error>
                                        <mat-error *ngIf="subBranchForm.get('contactDetails')['controls'].mobileNumber.value && subBranchForm.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                            jhiTranslate="entity.validation.mobile"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div formArrayName="addresses" class="snb_single_col">
                                    <div *ngFor="let address of subBranchForm.get('contactDetails')['controls'].addresses.controls; let i=index">
                                        <div [formGroupName]="i">
                                            <cm-address addressPlaceHolder="Branch Address" [group]="subBranchForm.get('contactDetails')['controls'].addresses.controls[i]"
                                                [addressValue]="data?.subBranchData?.contactDetails?.addresses[0]" [showIcon]="false"></cm-address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="snb_single_col">
                                <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                    <mat-label>Regulator Branch</mat-label>
                                    <mat-select formControlName="regulatorBranchCode"  [disabled]= "regulatorBranchOption" matInput
                                        required>
                                        <mat-option *ngFor="let rocBranch of rocBranchesList" [value]="rocBranch.branchCode">
                                            {{ rocBranch.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="subBranchForm?.controls?.regulatorBranchCode.hasError('required')"
                                        jhiTranslate="entity.validation.required"></mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field style="width: 100%" *ngIf="!data?.requestByForeman">
                        <mat-select formControlName="regulatorBranchRefId" matInput placeholder="Regulator Branch by roc" required>
                        <mat-option *ngFor="let rocBranch of rocBranchesList" [value]="rocBranch.id">
                            {{ rocBranch.name }}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="subBranchForm.controls.regulatorBranchRefId.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field> -->
                            </div>
    
                        </div>
                    </div>
                    <div [ngClass]="{'col-md-6 col-sm-6 col-xs-12' : data?.viewBranchData && data?.viewAdminData }" *ngIf="data?.viewAdminData">
                        <div class="dep-a" style="min-height: 280px !important;">
                            <div formGroupName="adminUser">
                                <div class="snb_single_col">
                                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                        <mat-label>Foreman Title</mat-label>
                                        <mat-select formControlName="title" matInput required>
                                            <mat-option value="Mr.">{{'foremanCompany.title.mr' | translate}}</mat-option>
                                            <mat-option value="Mrs.">{{'foremanCompany.title.mrs' | translate}}</mat-option>
                                            <mat-option value="Ms.">{{'foremanCompany.title.ms' | translate}}</mat-option>
                                            <mat-option value="Miss">{{'foremanCompany.title.miss' | translate}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="subBranchForm.get('adminUser')['controls'].title.hasError('required')"
                                            jhiTranslate="entity.validation.required"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="snb_single_col">
                                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                        <mat-label>Foreman First Name</mat-label>
                                        <input type="text" formControlName="firstName" matInput
                                            required>
                                        <mat-error *ngIf="subBranchForm.get('adminUser')['controls'].firstName.hasError('required')"
                                            jhiTranslate="entity.validation.required"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="snb_single_col">
                                    <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                        <mat-label>Foreman Last Name</mat-label>
                                        <input type="text" formControlName="lastName" matInput 
                                            required>
                                        <mat-error *ngIf="subBranchForm.get('adminUser')['controls'].lastName.hasError('required')"
                                            jhiTranslate="entity.validation.required"></mat-error>
                                    </mat-form-field>
                                </div>
                                <div formGroupName="contactDetails">
                                    <div class="snb_single_col">
                                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                            <mat-label>Foreman Email</mat-label>
                                            <input type="email" required formControlName="email" matInput>
                                            <mat-error *ngIf="subBranchForm.controls.adminUser.get('contactDetails')['controls'].email.value && subBranchForm.controls.adminUser.get('contactDetails')['controls'].email.errors?.pattern"
                                                jhiTranslate="entity.validation.email"></mat-error>
                                            <mat-error *ngIf="subBranchForm.controls.adminUser.get('contactDetails')['controls'].email.hasError('required')"
                                                jhiTranslate="entity.validation.required"></mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="snb_single_col">
                                        <mat-form-field style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                                            <mat-label>Foreman Mobile Number</mat-label>
                                            <input type="tel" required formControlName="mobileNumber" matInput>
                                            <mat-error *ngIf="subBranchForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                                jhiTranslate="entity.validation.required"></mat-error>
                                            <mat-error *ngIf="subBranchForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.value && subBranchForm.controls.adminUser.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                                jhiTranslate="entity.validation.mobile"></mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <button *ngIf="!foremanCompanyBranchHasData" mat-raised-button [disabled]="isFormSubmitted" type="submit" color="primary"
                    style="float: right;" class="snb-raised-button">
                    <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>

                <button *ngIf="foremanCompanyBranchHasData && data?.viewBranchData" [disabled]="isFormSubmitted"
                    mat-raised-button (click)="submitBranchData(subBranchForm, $event, 'update')" color="primary"
                    style="float: right;" class="snb-raised-button">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>
                <button mat-raised-button [mat-dialog-close]="true" style="margin-right: 10px;float: right;border-radius: 30px;">
                    <span>Cancel</span>
                </button>
            </form>
        </div>
    </div>
</div>
