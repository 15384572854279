export const SNB_BASE_URL = SERVER_API_BASE_URL;
export const SNB_CORE_URL = `${SNB_BASE_URL}/snbchain-core/api`;
export const SNB_UAA_URL = `${SNB_BASE_URL}/snbchain-uaa/api`;
export const SNB_WF_URL = `${SNB_BASE_URL}/snbchain-workflow/api`;
export const SNB_REPORTS_URL = `${SNB_BASE_URL}/snbchain-reports/api`;
export const SNB_PGW_URL = `${SNB_BASE_URL}/snbchain-pgw/api`;;
export const SNB_GRV_URL = `${SNB_BASE_URL}/snbchain-grs/api`;
export const SNB_FABRIC_EXPLORER_URL = 'https://explorer.dev.snbchain.com';

export const DEBUG_INFO_ENABLED = false;
export const REGULATOR_APP_TYPE = 'regulator';
export const FOREMAN_APP_TYPE = 'foreman';
export const USER_APP_TYPE = 'user';
/* let isForeman = false;
let isRegulator = false;
if (localStorage.getItem('loginAsForeman') === 'false') {
    isRegulator = true;
} else {
    isForeman = true;
} */

export const USER_TYPES = {
    'IS_FOREMAN': false,
    'IS_REGULATOR': false,
    'IS_USER': false
};

/**
 * This enum tells whethere what kind of app is launched.
 */
export const APP_TYPES = {
    'FOREMAN_APP_TYPE': false,
    'REGULATOR_APP_TYPE': true,
    'USER_APP_TYPE': false
};

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\+?\d{2}[- ]?\d{3}[- ]?\d{5}$/;

export const PARENT_MINUTES = "MINUTES";
export const PARENT_CHIT_RELEASE = "CHIT_RELEASE";
export const PARENT_ARBITRATION = "ARBITRATION";
export const PARENT_CHIT_TRANSFER = "TRANSFERS";
