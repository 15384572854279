import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ApplicationsService } from '../../../pages/applications/applications.service';
import { SnBToasterService } from '../toaster/toaster.service';
import { FileUploadService } from './file-upload.service';
import { LocalStorageService } from 'ngx-webstorage';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
const URL = '';

@Component({
    templateUrl: './file-upload-overwrite-confirmation-dialog.html',
    styles : [
        `.dialog-container {
            padding: 24px;
        }`
    ]
})
export class FileOverWriteConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<FileOverWriteConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    goToReject() {
        this.dialogRef.close(true);
    }

}

@Component({
    selector: 'snb-file-upload',
    templateUrl: './file-upload.html'
})

export class FileUploadComponent implements OnInit {

    @Input() public placeholder: string;

    @Input() public acceptFileTypes: any;

    @Input() public accessDataName: any;

    @Input() public fileName: any;

    @Input() public fileData: any;

    @Output() emitFileData: EventEmitter<any> = new EventEmitter();

    fileUploadInProgress = false;

    uploadedAFile: any;
    uploaderA: FileUploader;
    attachments: any[];
    autoTestEnv = false;


    constructor(
        private applicationsService: ApplicationsService,
        private toasterService: SnBToasterService,
        private dialog: MatDialog,
        private localStorage: LocalStorageService
    ) {
        this.uploaderA = new FileUploader({
            url: URL,
            // autoUpload: true,
            // disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            // formatDataFunctionIsAsync: true,
            // formatDataFunction: async (item) => {
            //     return new Promise((resolve, reject) => {
            //         resolve({
            //             name: item._file.name,
            //             length: item._file.size,
            //             contentType: item._file.type,
            //             date: new Date()
            //         });
            //     });
            // }
        });

        if (this.localStorage.retrieve('autotesting')) {
            this.autoTestEnv = true;
            console.log('File component running in auto test mode.');
        }
    }

    ngOnInit() {
        this.attachments = [];
    }

    /**
     * This method is strictly for auto testing to avoid
     * file dialog pop up opening during auto testing. Auto test tool
     * not able to close file dialog once it is opened. Until we figure out
     * proper solution, this method is hack to solve that issue.
     * @param newValue
     */
    valuechange(newValue: Event) {
        if (this.autoTestEnv) {
            console.log('new Value' + newValue);

            const element = event.target as HTMLInputElement;
            const fileList: FileList = element['files'];

            console.log('File List' + fileList);
            this.uploadFile(fileList, null, newValue);
        }
    }

    public onFileSelected(e: Event) {
        this.uploaderA.onAfterAddingFile = (addedFile) => {
            if (this.acceptFileTypes.indexOf(addedFile.file.type) === -1) {
                e.srcElement['value'] = '';
                this.toasterService.showErrorMessage('error.invalidFile');
                return false;
            }
            addedFile.withCredentials = false;
            const element = event.target as HTMLInputElement;
            const fileList: FileList = element['files'];
            if (element.getAttribute('filename')) {
                const dialogRef = this.dialog.open(FileOverWriteConfirmationComponent, {
                    width: '420px',
                    data: this.fileName
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.uploadFile(fileList, addedFile, e);
                    } else {
                        element['value'] = '';
                    }
                });
            } else {
                this.uploadFile(fileList, addedFile, e);
            }
        };
    }

    removeNotSupportedSpecialChars(fileName: string) {
        // regular expression to match printable ASCII characters excluding Greek characters
        const regexToFollow = /[^a-zA-Z0-9_\-\s\.\(\)\[\]\{\}\+\-\*\/\"]|\.png|\.PNG|\.jpg|\.JPG|\.jpeg|\.JPEG|\.pdf|\.PDF/g; // Matches characters outside printable ASCII range (including Greek)
        // Remove characters matched by regexToRemove
        const cleanedString = fileName.replace(regexToFollow,'');

        return cleanedString;
    }

    uploadFile(uploadedFileData, addedFile, event: Event) {
        const fileList = uploadedFileData;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const formData: FormData = new FormData();
            let filteredFileName = this.removeNotSupportedSpecialChars(file.name)
            if(filteredFileName === '' || filteredFileName.length < 3) {
                filteredFileName = 'File'
            }
            formData.append('name', filteredFileName);
            formData.append('file', file);
            formData.append('mimeType', file.type);
            event.srcElement['value'] = '';
            this.fileUploadInProgress = true;
            this.applicationsService.uploadFile(formData).subscribe((res: any) => {
                if (res) {
                    let uploadedFile = {};
                    uploadedFile = res.body;
                    uploadedFile['description'] = this.fileName;
                    uploadedFile['mimeType'] = file.type;
                    uploadedFile['name'] = filteredFileName;
                    this.uploadedAFile = addedFile;
                    this.fileUploadInProgress = false;
                    if (uploadedFile) {
                        this.attachments[this.accessDataName] = {};
                        this.attachments[this.accessDataName] = uploadedFile;
                        const element = event.target as HTMLInputElement;
                        element.setAttribute('filename', filteredFileName);
                        this.emitFileData.emit(this.attachments);
                    }
                }
            }, fail => {
                if (fail) {
                    this.fileUploadInProgress = false;
                    this.toasterService.showFailToaster(fail);
                }
            });
        }
    }
}




@Component({
    selector: 'app-snb-user-file-upload',
    templateUrl: './file-upload.html'
})

export class UserFileUploadComponent implements OnInit {

    @Input() public placeholder: string;

    @Input() public acceptFileTypes: any;

    @Input() public accessDataName: any;

    @Input() public fileName: any;

    @Input() public fileData: any;

    @Output() emitFileData: EventEmitter<any> = new EventEmitter();
    fileUploadInProgress = false;
    uploadedAFile: any;
    uploaderA: FileUploader;
    attachments: any[];
    autoTestEnv = false;

    constructor(
        private applicationsService: ApplicationsService,
        private toasterService: SnBToasterService,
        private dialog: MatDialog,
        public fileUploaderService: FileUploadService
    ) {
        this.uploaderA = new FileUploader({
            url: URL,
            // autoUpload: true,
            // disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            // formatDataFunctionIsAsync: true,
            // formatDataFunction: async (item) => {
            //     return new Promise((resolve, reject) => {
            //         resolve({
            //             name: item._file.name,
            //             length: item._file.size,
            //             contentType: item._file.type,
            //             date: new Date()
            //         });
            //     });
            // }
        });
    }

    ngOnInit() {
        this.attachments = [];
    }

    /**
     * This method is strictly for auto testing to avoid
     * file dialog pop up opening during auto testing. Auto test tool
     * not able to close file dialog once it is opened. Until we figure out
     * proper solution, this method is hack to solve that issue.
     * @param newValue
     */
    valuechange(newValue: Event) {
        if (this.autoTestEnv) {
            console.log('new Value' + newValue);

            const element = event.target as HTMLInputElement;
            const fileList: FileList = element['files'];

            console.log('File List' + fileList);
            this.uploadFile(fileList, null);
        }
    }


    public onFileSelected(e: Event) {
        this.uploaderA.onAfterAddingFile = (addedFile) => {
            if (this.acceptFileTypes.indexOf(addedFile.file.type) === -1) {
                e.srcElement['value'] = '';
                this.toasterService.showErrorMessage('error.invalidFile');
                return false;
            }
            addedFile.withCredentials = false;
            const element = event.target as HTMLInputElement;
            const fileList: FileList = element['files'];
            if (element.getAttribute('filename')) {
                const dialogRef = this.dialog.open(FileOverWriteConfirmationComponent, {
                    width: '420px',
                    data: this.fileName
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.uploadedAFile = addedFile;
                        this.uploadFile(fileList, e);
                    } else {
                        element['value'] = '';
                    }
                });
            } else {
                this.uploadedAFile = addedFile;
                this.uploadFile(fileList, e);
            }
        };
    }

    uploadFile(uploadedFileData, event: Event) {
        const fileList = uploadedFileData;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            this.emitFileData.emit(file);
        }
    }
}
