export const ALL_ROC = ['ROLE_CIG', 'ROLE_AIG', 'ROLE_JIG', 'ROLE_DIG', 'ROLE_SCS', 'ROLE_DR', 'ROLE_ARC', 'ROLE_REGULATOR_SUPER_ADMIN'];
export const ALL_FOREMAN = ['ROLE_FOREMAN_SUPER_ADMIN', 'ROLE_FOREMAN'];

export const PAGES_MENU = [
    {
        path: "pages",
        children: [
            {
                path: "dashboard",
                data: {
                    menu: {
                        title: "Dashboard",
                        pagetitle: "Dashboard",
                        icon: "fa fa-th",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
            },
            {
                path: "profile",
                data: {
                    menu: {
                        ignoreItem: true,
                        title: "Profile",
                        pagetitle: "Profile",
                        icon: "ion-md-cube",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
            },
            {
                path: "applications",
                data: {
                    authorities: ["ROLE_FOREMAN_SUPER_ADMIN", "ROLE_FOREMAN"],
                    menu: {
                        title: "Applications",
                        pagetitle: "Applications",
                        icon: "fa fa-wpforms",
                        selected: false,
                        expanded: false,
                        order: 0,
                        ignoreChild: true,
                        route: {
                            paths: "applications",
                        },
                    },
                },
                children: [
                    {
                        path: "form1",
                        data: {
                            menu: {
                                title: "Form I",
                                pagetitle: "Applications > Form I & X",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form1",
                            },
                        },
                    },
                    {
                        path: "form1-details",
                        data: {
                            menu: {
                                title: "Form I Details",
                                pagetitle: "Applications > Form I > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/pso/details",
                            },
                        },
                    },
                    {
                        path: "form1a",
                        data: {
                            menu: {
                                title: "Form I-A",
                                pagetitle: "Applications > Form I-A",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form1a",
                            },
                        },
                    },
                    {
                        path: "form1b",
                        data: {
                            menu: {
                                title: "Form I-B",
                                pagetitle: "Applications > Form I-B",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form1b",
                            },
                        },
                    },
                    {
                        path: "form2",
                        data: {
                            menu: {
                                title: "Form II",
                                pagetitle: "Applications > Form II",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form2",
                            },
                        },
                    },
                    {
                        path: "form2-details",
                        data: {
                            menu: {
                                title: "Form II Details",
                                pagetitle: "Applications > Form II > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath:
                                    "/pages/applications/agreement/details",
                            },
                        },
                    },
                    {
                        path: "form3",
                        data: {
                            menu: {
                                title: "Form III",
                                pagetitle: "Applications > Form III",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form3",
                            },
                        },
                    },
                    {
                        path: "form5",
                        data: {
                            menu: {
                                title: "Form V",
                                pagetitle: "Applications > Form V",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form5",
                            },
                        },
                    },
                    {
                        path: "form5-details",
                        data: {
                            menu: {
                                title: "Form V",
                                pagetitle: "Applications > Form V > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form5",
                            },
                        },
                    },
                    {
                        path: "form7",
                        data: {
                            menu: {
                                title: "Form VII",
                                pagetitle: "Applications > Form VII",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form7",
                            },
                        },
                    },
                    {
                        path: "form7-details",
                        data: {
                            menu: {
                                title: "Form VII Details",
                                pagetitle: "Applications > Form VII > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form7-details",
                            },
                        },
                    },
                    {
                        path: "form10-details",
                        data: {
                            menu: {
                                title: "Form X Details",
                                pagetitle: "Applications > Form X > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form10-details",
                            },
                        },
                    },
                    {
                        path: "release",
                        data: {
                            menu: {
                                title: "Release",
                                pagetitle: "Applications > Release",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/release",
                            },
                        },
                    },
                    {
                        path: "release",
                        data: {
                            menu: {
                                title: "Release",
                                pagetitle: "Applications > Release > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath:
                                    "/pages/applications/chit-release-details",
                            },
                        },
                    },
                    {
                        path: "minutes",
                        data: {
                            menu: {
                                title: "Minutes of Proceedings",
                                pagetitle:
                                    "Applications > Minutes of Proceedings",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/minutes",
                            },
                        },
                    },
                    {
                        path: "minutes-details",
                        data: {
                            menu: {
                                title: "Minutes of Proceedings > Details",
                                pagetitle:
                                    "Applications > Minutes of Proceedings > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/minutes-details",
                            },
                        },
                    },
                    {
                        path: "transfer",
                        data: {
                            menu: {
                                title: "Chit Transfer",
                                pagetitle:
                                    "Applications > Chit Transfer Application",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/chit-transfer",
                            },
                        },
                    },
                    {
                        path: "chit-transfer-details",
                        data: {
                            menu: {
                                title: "Chit Transfer",
                                pagetitle:
                                    "Applications > Chit Transfer Application > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/chit-transfer",
                            },
                        },
                    },
                    {
                        path: "financial-data",
                        data: {
                            menu: {
                                title: "Financial Data",
                                pagetitle: "Applications > Financial Data",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/financial-data",
                            },
                        },
                    },
                    {
                    path: "separate-account-statement",
                    data: {
                        menu: {
                            title: "Separate Account Statement",
                            pagetitle: "Applications > Separate Account Statement",
                            icon: "ion-ios-document-outline",
                            childHidden: true,
                            fullPath: "/pages/applications/separate-account-statement/statement",
                        },
                    },
                },
                {
                    path: "correction-application",
                    data: {
                        menu: {
                            title: "Correction Application",
                            pagetitle: "Applications > Correction Application",
                            icon: "ion-ios-document-outline",
                            childHidden: true,
                            fullPath: "/pages/applications/correction-application",
                        },
                    },
                },
                {
                    path: "interlocutory-application",
                    data: {
                        menu: {
                            title: "Abj",
                            pagetitle: "Applications > Interlocutory Application",
                            icon: "ion-ios-document-outline",
                            childHidden: true,
                            fullPath: "/pages/applications/interlocutory-application",
                        },
                    },
                },
                    {
                        path: "form-17",
                        data: {
                            menu: {
                                title: "Form 17",
                                pagetitle: "Applications > Form 17",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/applications/form-17",
                            },
                        },
                    },
                ],
            },
            {
                path: "roc-organization",
                data: {
                    authorities: [
                        "ROLE_CIG",
                        "ROLE_AIG",
                        "ROLE_JIG",
                        "ROLE_DIG",
                        "ROLE_SCS",
                        "ROLE_DR",
                        "ROLE_ARC",
                        "ROLE_REGULATOR_SUPER_ADMIN",
                        "ROLE_CUST_SUPPORT",
                    ],
                    menu: {
                        // ignoreItem: true,
                        ignoreChild: true,
                        childHidden: true,
                        title: "ROC Organisation",
                        pagetitle: "ROC Organisation",
                        icon: "fa fa-building-o",
                        selected: false,
                        expanded: false,
                        order: 0,
                        fullPath: "/pages/roc-organization",
                    },
                },
            },
            {
                path: "foreman-companies",
                data: {
                    authorities: [
                        "ROLE_CIG",
                        "ROLE_AIG",
                        "ROLE_JIG",
                        "ROLE_DIG",
                        "ROLE_SCS",
                        "ROLE_DR",
                        "ROLE_ARC",
                        "ROLE_REGULATOR_SUPER_ADMIN",
                        "ROLE_CUST_SUPPORT",
                        "ROLE_ROC_GUEST",
                    ],
                    menu: {
                        title: "Foreman Organisations",
                        icon: "fa fa-building-o",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "new",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "New",
                                pagetitle: "Foreman Organisation > New",
                                icon: "fa fa-plus",
                            },
                        },
                    },
                    {
                        path: "list",
                        data: {
                            menu: {
                                title: "List",
                                pagetitle: "Foreman Organisations > List",
                                icon: "fa fa-th-list",
                            },
                        },
                    },
                    {
                        path: "details",
                        data: {
                            menu: {
                                title: "Details",
                                pagetitle: "Foreman Organisation > Details",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/foreman-companies/details",
                            },
                        },
                    },
                ],
            },
            {
                path: "your-organisation",
                data: {
                    authorities: ["ROLE_FOREMAN_SUPER_ADMIN", "ROLE_FOREMAN"],
                    menu: {
                        title: "My Organisation",
                        pagetitle: "My Organisation",
                        icon: "fa fa-building",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                // children: [
                //   {
                //     path: 'organisation',
                //     data: {
                //       menu: {
                //         title: 'Organisation',
                //         pagetitle: 'Your Organisation',
                //         icon: 'fa-th-list'
                //       }
                //     }
                //   }
                //   // , {
                //   //   path: 'branches',
                //   //   data: {
                //   //     menu: {
                //   //       title: 'Branches',
                //   //       pagetitle: 'Your Organisation > Branches',
                //   //       icon: 'fa-building-o'
                //   //     }
                //   //   }
                //   // }, {
                //   //   path: 'financial-info',
                //   //   data: {
                //   //     menu: {
                //   //       title: 'Financial Informaiton',
                //   //       pagetitle: 'Your Organisation > Financial Informaiton',
                //   //       icon: 'fa-inr'
                //   //     }
                //   //   }
                //   // }
                // ]
            },
            {
                path: "workflows",
                data: {
                    menu: {
                        title: "Workflows",
                        icon: "fa fa-arrows-h",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "pending",
                        data: {
                            menu: {
                                title: "Pending",
                                pagetitle: "Workflows > Pending",
                                icon: "fa fa-hourglass-start",
                                hasOtherRoute: true,
                                fullPath: "/pages/workflows/pending",
                            },
                        },
                    },
                    {
                        path: "completed",
                        data: {
                            menu: {
                                title: "Completed",
                                pagetitle: "Workflows > Completed",
                                icon: "fa fa-check",
                                hasOtherRoute: true,
                                fullPath: "/pages/workflows/completed",
                            },
                        },
                    },
                    // , {
                    //   path: 'rejected',
                    //   data: {
                    //     menu: {
                    //       title: 'Rejected',
                    //       pagetitle: 'Workflows > Rejected',
                    //       icon: 'fa-exchange'
                    //     }
                    //   }
                    // }
                ],
            },

            {
                path: "chitgroups",
                data: {
                    menu: {
                        title: "Chit Groups",
                        pagetitle: "Chit Groups",
                        icon: "fa fa-users",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "view",
                        data: {
                            menu: {
                                title: "Chit Information",
                                pagetitle: "Chit Groups Information",
                                icon: "fa fa-info",
                                hasOtherRoute: true,
                                fullPath: "/pages/chitgroups/view",
                            },
                        },
                    },
                    {
                        path: "details",
                        data: {
                            menu: {
                                title: "Chit detail",
                                pagetitle: "Chit Group Detail",
                                icon: "ion-ios-document-outline",
                                hasOtherRoute: true,
                                fullPath: "/pages/chitgroups/details",
                                childHidden: true,
                            },
                        },
                    },
                ],
            },
            {
                path: "arbitrations",
                data: {
                    menu: {
                        title: "Arbitrations",
                        pagetitle: "Arbitrations",
                        icon: "fa fa-gavel",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "view",
                        data: {
                            menu: {
                                title: "View",
                                pagetitle: "View",
                                icon: "fa fa-info",
                                hasOtherRoute: true,
                                fullPath: "/pages/arbitrations/view",
                            },
                        },
                    },
                    {
                        path: "scheduled-hearings",
                        data: {
                            menu: {
                                title: "Scheduled Hearings",
                                pagetitle: "View",
                                icon: "fa fa-calendar",
                                hasOtherRoute: true,
                                fullPath: "/pages/arbitrations/schduled-hearings",
                            },
                        },
                    },
                    {
                        path: "completed-hearings",
                        data: {
                            menu: {
                                title: "Completed Hearings",
                                pagetitle: "View",
                                icon: "fa fa-calendar",
                                hasOtherRoute: true,
                                fullPath: "/pages/arbitrations/completed-hearings",
                            },
                        },
                    },
                    {
                        path: "claim-calculation",
                        data: {
                            menu: {
                                title: "Claim Calculator",
                                pagetitle: "View",
                                icon: "fa fa-calculator",
                                hasOtherRoute: true,
                                fullPath: "/pages/arbitration?claimCalcMode=claimCalculator",
                            },
                        },
                    },
                    {
                        path: "details",
                        data: {
                            menu: {
                                title: "Arbitration detail",
                                pagetitle: "Arbitration Detail",
                                icon: "ion-ios-document-outline",
                                hasOtherRoute: true,
                                fullPath: "/pages/arbitrations/details",
                                childHidden: true,
                            },
                        },
                    },
                ],
            },

            {
                path: "violations",
                data: {
                    
                    menu: {
                        // ignoreItem: true,
                       
                        title: "Violations",
                        pagetitle: "Violations",
                        icon: "fa fa-exclamation",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "active-branchwise",
                        data: {
                            menu: {
                                title: "Foreman Branch-wise",
                                pagetitle: "View",
                                icon: "fa fa-building-o",
                                hasOtherRoute: true,
                                fullPath: "/pages/violations/active/active-branchwise",
                            },
                        },
                    },
                    {
                        path: "active",
                        data: {
                            menu: {
                                title: "Active",
                                pagetitle: "View",
                                icon: "fa fa-info",
                                hasOtherRoute: true,
                                fullPath: "/pages/violations/active",
                            },
                        },
                    },
                    {
                        path: "cleared",
                        data: {
                            menu: {
                                title: "Cleared",
                                pagetitle: "View",
                                icon: "fa fa-check",
                                hasOtherRoute: true,
                                fullPath: "/pages/violations/cleared",
                            },
                        },
                    },
                    {
                        path: "history",
                        data: {
                            authorities: [
                                "ROLE_REGULATOR_SUPER_ADMIN",
                                "ROLE_CUST_SUPPORT",
                            ],
                            menu: {
                                title: "History",
                                pagetitle: "View",
                                icon: "fa fa-history",
                                hasOtherRoute: true,
                                fullPath: "/pages/violations/history",
                            },
                        },
                    }
                ]
            },
            {
                path: "audit",
                data: {
                    menu: {
                        // ignoreItem: true,
                        title: "Audit",
                        pagetitle: "Audit",
                        icon: "fa fa-list",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "sep-account-audit",
                        data: {
                            menu: {
                                title: "Separate Account",
                                pagetitle: "View",
                                icon: "fa fa-university",
                                hasOtherRoute: true,
                                fullPath: "/pages/audit/sep-account-audit",
                            },
                        },
                        
                    },
                    {
                        path: "voucher-details",
                        data: {
                            authorities: ["ROLE_FOREMAN_SUPER_ADMIN", "ROLE_FOREMAN"],
                            menu: {
                                title: "Voucher Details",
                                pagetitle: "View",
                                icon: "fa fa-credit-card",
                                hasOtherRoute: true,
                                fullPath: "/pages/audit/voucher-details",
                            },
                        },
                        
                    },
                ]
            },
            {
                path: "blockchain",
                data: {
                    authorities: ['ROLE_REGULATOR_SUPER_ADMIN'],
                    menu: {
                        title: "Block Chain",
                        pagetitle: "",
                        selected: false,
                        expanded: false,
                        order: 0,
                        icon: "fa fa-link",
                    }
                },
                children: [
                    {
                        path: "block-chain-explorer",
                        data: {
                            menu: {
                                title: "Block Chain Explorer",
                                pagetitle: "View",
                                icon: "fa fa-search",
                                hasOtherRoute: true,
                                fullPath: "/pages/blockchain/block-chain-explorer",
                            },
                        },
                        
                    },
                    {
                        path: "fabric-event-summary",
                        data: {
                            menu: {
                                title: "Fabric Event Summary",
                                pagetitle: "View",
                                icon: "ion-ios-card",
                                hasOtherRoute: true,
                                fullPath: "/pages/blockchain/fabric-event-summary",
                            },
                        },
                        
                    },
                ]
            },
            {
                path: "grievance",
                data: {
                    menu: {
                        title: "Grievance",
                        pagetitle: "Grievance",
                        icon: "fa fa-hand-paper-o",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "roc-new",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "New",
                                pagetitle: "Grievance > New",
                                icon: "fa fa-plus",
                                hasOtherRoute: true,
                                fullPath: "/pages/grievance/roc-new",
                            },
                        },
                    },
                    {
                        path: "pending",
                        data: {
                            menu: {
                                title: "Pending",
                                pagetitle: "Grievance > Pending",
                                icon: "fa fa-hourglass-start",
                                hasOtherRoute: true,
                                fullPath: "/pages/grievance/pending",
                            },
                        },
                    },

                    {
                        path: "closed",
                        data: {
                            menu: {
                                title: "Closed",
                                pagetitle: "Grievance > Closed",
                                icon: "fa fa-check",
                                hasOtherRoute: true,
                                fullPath: "/pages/grievance/closed",
                            },
                        },
                    },
                ],
            },
            {
                path: "legacy-services",
                data: {
                    authorities: [
                        "ROLE_CIG",
                        "ROLE_AIG",
                        "ROLE_JIG",
                        "ROLE_DIG",
                        "ROLE_SCS",
                        "ROLE_DR",
                        "ROLE_ARC",
                        "ROLE_REGULATOR_SUPER_ADMIN",
                        "ROLE_FOREMAN_SUPER_ADMIN",
                        "ROLE_FOREMAN",
                    ],
                    menu: {
                        title: "Legacy Services",
                        pagetitle: "Legacy Services",
                        icon: "fa fa-wpforms",
                        selected: false,
                        expanded: false,
                        order: 0,
                        route: {
                            paths: "legacy-services",
                        },
                    },
                },
                children: [
                    {
                        path: "generate-can",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "Generate CA No",
                                pagetitle: "",
                                icon: "fa fa-plus",
                                childHidden: false,
                                fullPath: "/pages/legacy-services/generate-can",
                            },
                        },
                    },
                    {
                        path: "generate-arbitration-number",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "Generate Arbitration No",
                                pagetitle: "",
                                icon: "fa fa-plus",
                                childHidden: false,
                                fullPath: "/pages/legacy-services/generate-arbitration-number",
                            },
                        },
                    },
                    {
                        path: "legacy-cpad-list",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "List CA No's",
                                pagetitle: "",
                                icon: "fa fa-th-list",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/legacy-cpad-list",
                            },
                        },
                    },
                    {
                        path: "offline-arb-list",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "List Arbitration No's",
                                pagetitle: "",
                                icon: "fa fa-th-list",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/offline-arb-list",
                            },
                        },
                    },
                    {
                        path: "legacy-stats",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "Migration Stats",
                                pagetitle: "",
                                icon: "fa fa-line-chart",
                                childHidden: false,
                                fullPath: "/pages/legacy-services/legacy-stats",
                            },
                        },
                    },
                    {
                        path: "chitgroup-upload",
                        data: {
                            authorities: [
                                "ROLE_FOREMAN_SUPER_ADMIN",
                                "ROLE_FOREMAN",
                            ],
                            menu: {
                                title: "Chit Groups Upload",
                                pagetitle: "",
                                icon: "fa fa-upload",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/chitgroup-upload",
                            },
                        },
                    },
                    {
                        path: "subscriber-upload",
                        data: {
                            authorities: [
                                "ROLE_FOREMAN_SUPER_ADMIN",
                                "ROLE_FOREMAN",
                            ],
                            menu: {
                                title: "Subscribers Upload",
                                pagetitle: "",
                                icon: "fa fa-upload",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/subscriber-upload",
                            },
                        },
                    },
                    {
                        path: "legacy-chitgroup-list",
                        data: {
                            authorities: [
                                "ROLE_FOREMAN_SUPER_ADMIN",
                                "ROLE_FOREMAN",
                            ],
                            menu: {
                                title: "Legacy Chitgroup List",
                                pagetitle: "",
                                icon: "fa fa-th-list",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/legacy-chitgroup-list",
                            },
                        },
                    },
                    {
                        path: "offline-chit-verifier",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "Offline Chit Verifier",
                                pagetitle: "",
                                icon: "fa fa-check-circle",
                                childHidden: false,
                                fullPath:
                                    "/pages/legacy-services/offline-chit-verifier",
                            },
                        },
                    },
                ],
            },
            // {
            //   path: 'notices',
            //   data: {
            //     authorities: ['ROLE_USER'],
            //     menu: {
            //       title: 'Notices',
            //       pagetitle: 'Notices',
            //       icon: 'fa-th',
            //       selected: false,
            //       expanded: false,
            //       order: 0
            //     }
            //   }
            // },
            {
                path: "payment",
                data: {
                    menu: {
                        title: "Payment",
                        pagetitle: "Payment Status",
                        icon: "fa fa-credit-card",
                    },
                },
                children: [
                    {
                        path: "status",
                        data: {
                            menu: {
                                title: "Payment",
                                pagetitle: "Transaction Status",
                                icon: "ion-ios-document-outline",
                                childHidden: true,
                                fullPath: "/pages/payment/status",
                            },
                        },
                    },
                    {
                        path: "pending-payments",
                        data: {
                            authorities: [
                                "ROLE_FOREMAN_SUPER_ADMIN",
                                "ROLE_FOREMAN",
                            ],
                            menu: {
                                title: "Pending Payments",
                                pagetitle: "Payment > Pending Payments",
                                icon: "fa fa-credit-card",
                                fullPath: "/pages/payment/pending-payments",
                            },
                        },
                    },
                    // {
                    //     path: 'miscellaneous-payments',
                    //     data: {
                    //         authorities: ['ROLE_FOREMAN_SUPER_ADMIN', 'ROLE_FOREMAN'],
                    //         menu: {
                    //             title: 'Misc Payments',
                    //             pagetitle: 'Payment > Misc Payments',
                    //             icon: 'fa-money',
                    //             fullPath: '/pages/payment/miscellaneous-payments'
                    //         }
                    //     }
                    // },
                    {
                        path: "transactions",
                        data: {
                            menu: {
                                title: "Transactions",
                                pagetitle: "Payment > Transactions",
                                icon: "fa fa-history",
                                fullPath: "/pages/payment/transactions",
                            },
                        },
                    },
                ],
            },
            {
                path: "sample-docs",
                data: {
                    authorities: ["ROLE_FOREMAN_SUPER_ADMIN", "ROLE_FOREMAN"],
                    menu: {
                        title: "Sample Documents",
                        pagetitle: "Sample Documents",
                        icon: "fa fa-file",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
            },
            {
                path: "reports",
                data: {
                    authorities: [
                        "ROLE_CIG",
                        "ROLE_AIG",
                        "ROLE_JIG",
                        "ROLE_DIG",
                        "ROLE_SCS",
                        "ROLE_DR",
                        "ROLE_ARC",
                       "ROLE_REGULATOR_SUPER_ADMIN"
                    ],
                    menu: {
                        ignoreChild: true,
                        childHidden: true,
                        title: "Reports",
                        pagetitle: "Reports",
                        icon: "fa fa-file-text",
                        selected: false,
                        expanded: false,
                        order: 0,
                        fullPath: "/pages/reports",
                    },
                },
            },
            {
                path: "help",
                data: {
                    menu: {
                        title: "Help",
                        pagetitle: "Help",
                        icon: "fa fa-question-circle",
                        selected: false,
                        expanded: false,
                        order: 0,
                    },
                },
                children: [
                    {
                        path: "faq",
                        data: {
                            authorities: ["ROLE_FOREMAN"],
                            menu: {
                                title: "FAQ",
                                pagetitle: "Help > FAQ",
                                icon: "fa fa-comments-o",
                            },
                        },
                    },
                    {
                        path: "videos",
                        data: {
                            authorities: [
                                "ROLE_CIG",
                                "ROLE_AIG",
                                "ROLE_JIG",
                                "ROLE_DIG",
                                "ROLE_SCS",
                                "ROLE_DR",
                                "ROLE_ARC",
                                "ROLE_FOREMAN",
                                "ROLE_REGULATOR_SUPER_ADMIN",
                            ],
                            menu: {
                                title: "Videos",
                                pagetitle: "Help > Videos",
                                icon: "fa fa-video-camera",
                            },
                        },
                    },
                ],
            }
        ],
    },
];

