
import { Injectable } from "@angular/core";
import { FormatInputs } from "../format-values";
import { PaymentMode, PrizeMoneyStatus, VoucherCategory, VoucherType } from "../models/chit-payment-voucher.model";

@Injectable()
export class BackwardCompatibilityService {

    constructor(
        private formatInputs: FormatInputs,
    ) {
    }

    /**
     * In REL_5_9_0, we have deprecate old model of storing the Payment Vouchers ad using the mode ChitPaymentVoucher.model.ts
     * So, this method checks for payment details in old format and provides Backward Compatibility for pending 
     * and completed workflow auction minute Payment Vouchers 
     */
    paymentVoucherBCAndMapToLatestModel(vouchers:any, chit?: any) {
        vouchers.forEach(
            (voucher: any) => {
                // Providing backward compatibility for multiple payment vouchers

                // Variables instalmentNumber, chequeAmount, movedTo2ndAccount

                if(voucher.instalmentNumber) {
                    voucher.installmentNumber = voucher.instalmentNumber;
                }

                if(!voucher.prizeMoneyStatus) {
                    voucher.category = VoucherCategory.PS_MONEY;
                    if(voucher.chequeAmount) {
                        voucher.prizeMoneyStatus = PrizeMoneyStatus.DISBURSED; 
                        voucher.voucherType = 'PAID_TO_SUBSCRIBER';
                        voucher.paymentMode = PaymentMode.CHEQUE;
                        voucher.amount = voucher.chequeAmount;
                        voucher.paymentRefNumber = voucher.chequeNumber;
                        voucher.chqDate = voucher.chequeDate;
                        voucher.paymentDate = voucher.chequeDate;
                    }

                    if(voucher.movedTo2ndAccount && voucher.amountTransferred) {
                        voucher.prizeMoneyStatus = PrizeMoneyStatus.MOVED_TO_2_ACCOUNT;
                        voucher.amount = voucher.amountTransferred; 
                        voucher.paymentDate = voucher.transferredDate;
                    }

                    if(voucher.isVacant && voucher.chequeAmount === null) {
                        // No Voucher atAll,
                        voucher.prizeMoneyStatus = PrizeMoneyStatus.DISBURSED;
                        voucher.voucherType = VoucherType.SELF_PAID;
                    }
                    let prizeMoney;
                    if(chit && chit.basicDetails.amount) {
                        prizeMoney = chit.basicDetails.amount - voucher.bidOffer;
                        voucher.amountPayable = prizeMoney;
                    }
                    
                    //if(voucher.isBidPayableAdjusted) {
                    // earlier, if the bid payable adjustment, we used to take just cheque amount, then rest of the amount considered as adjusted
                    if(chit && chit.basicDetails.amount && prizeMoney && voucher.chequeAmount) {
                        let adjustedAmount = prizeMoney - voucher.chequeAmount;
                        if(adjustedAmount > 0) {
                            voucher.adjustedAmount = adjustedAmount;
                        }
                    }
                    //}
                }

                delete voucher.chequeAmount;
                delete voucher.movedTo2ndAccount;
                delete voucher.isBidPayableAdjusted;
                delete voucher.instalmentNumber;
            }
        );
    }
}
