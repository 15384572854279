<div class="commentComp">
    <form [formGroup]="commentsFormGroup" novalidate autocomplete="off">
        <div class="snb_form_entries">
            <div class="row">
                <div class="col-md-8 col-sm-6 col-xs-12">
                    <mat-form-field appearance="outline" class="snb-text-area">
                        <mat-label>{{metaData?.placeHolder ? metaData.placeHolder : 'Add comment'}}</mat-label>
                       <mat-icon matPrefix>short_text</mat-icon>
                        <textarea type="text" matInput formControlName="comment"
                             required #myTextarea autofocus></textarea>
                        <mat-error *ngIf="commentsFormGroup.controls.comment.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                        <mat-hint style="margin: 18px 0px;">Add notes and optionally attach any support document and press Add button</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12" style="margin-top: 40px; margin-bottom: -10px;">
                    <div class="snb_upload_section expanded_section">
                        <div class=" upload_wrapper">
                            <snb-file-upload (emitFileData)='emitFileData($event)'
                                [fileData]="attachments?.commentsAttachment"
                                [accessDataName]="'commentsAttachment'"
                                [fileName]="'Supporting Attachment'"
                                [placeholder]="metaData?.attachmentLabel ? metaData.attachmentLabel : 'Attach any Document'"
                                [acceptFileTypes]="'image/png,image/jpeg,application/pdf'"
                            >
                            </snb-file-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row no-margin">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="styled-input text-center snb_form_actions">
                        <button mat-raised-button class="snb_btn_submit"
                            [disabled]="isRequestInProgress || !commentsFormGroup.controls.comment.valid"
                            (click)="submitComment(commentsFormGroup.controls.comment.value, $event)" style="float:right">
                            <i class="material-icons reject">send</i>{{metaData?.buttonLabel ? metaData.buttonLabel : "Add Comment"}}
                            <i *ngIf="isRequestInProgress" style="margin-left: 10px;"
                                class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>