import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
// tslint:disable-next-line: import-blacklist
import { Subscription ,  Observable ,  Subject } from 'rxjs';
import { WindowScrollService } from '../services/window-scroll.service';
import { FilterCriteriaNewService } from './filter-criteria-new.service';
import { FilterCriteria } from './filter-criteria.model';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatChipInputEvent } from '@angular/material/chips';



@Component({
    selector: 'app-chm-filter-component',
    templateUrl: './filter-criteria-new.component.html',
    styleUrls: ["filter-criteria.scss"]
})

export class FilterCriteriaNewComponent implements OnChanges, OnDestroy {

    @Output() filterNow: EventEmitter<any> = new EventEmitter();
    @Output() autoComplete: EventEmitter<any> = new EventEmitter();
    @ViewChild(MatAutocompleteTrigger) inputAutoComplit: MatAutocompleteTrigger;

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    @ViewChild(MatMenuTrigger) dropdown: MatMenuTrigger;

    @Input() moduleType: any;
    @Input() searchCriteria: FilterCriteria[] = [];
    clonedSearchCriteria: FilterCriteria[];
    @Input() addlParamsForGetFilters: any;
    @Input() reportsInput: any;
    @Input() items: any;
    @Input() companyCode: any;
    subscriptionList: Subscription = new Subscription();
    packages$: Observable<any>;
    private searchText$ = new Subject<string>();
    dependentResourceItems = [];

    activeItem: any;
    searchDropdown: any;
    stateCtrl: FormControl;
    filteredStatesList: Observable<any[]>;
    filteredStates: any = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    nameEntered: string;
    separatorKeysCodes = [ENTER];
    selectedItems: FilterCriteria[] = [];
    from = undefined;
    to = undefined;
    today = new Date();
    searchValue: any;
    filterItems = [];
    filteredItem: any = '';
    focusedItem: any;
    filterObjectItems = [];
    filterEnumObjectItems = [];
    selectedItem: any;
    @Input() filterId: any;

    constructor(
        private filterCriteriaService: FilterCriteriaNewService,
        private windowScrollService: WindowScrollService
    ) {
        this.stateCtrl = new FormControl();
        // this.filteredStates = this.stateCtrl.valueChanges.pipe(startWith(''),
        //     map(state => state ? this.nameEntered ? [] : this.filterStates(state) :
        //         (this.clonedSearchCriteria ? this.clonedSearchCriteria.slice() : []))
        // );
        const sub = this.windowScrollService.scrollY.subscribe((res) => {
            setTimeout(() => {
                this.inputAutoComplit._onChange('');
                this.inputAutoComplit.closePanel();
            }, 0);
        });
        this.subscriptionList.add(sub);
    }


    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            if (this.nameEntered) {
                const filter: FilterCriteria = this.selectedItems.filter(x => x.attribute === this.nameEntered)[0];
                filter.value = value.trim();
                this.nameEntered = undefined;
                this.emitCommonFunc();
                this.openAutocomplete();

            } else {
                this.nameEntered = value.trim();
            }

        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    ngOnChanges() {
        console.log('Initializing filter component with id ', this.filterId);
        this.selectedItems = [];
        
        if (this.moduleType) { // need to fetch from the service
            if (this.moduleType === 'GOOGLE_REPORTS' || this.moduleType === 'NOTIFICATIONS') {
                this.populateAndFormatFiltersData(this.searchCriteria);
            } else {
                /*this.filterCriteriaService.getFilters(this.moduleType, this.addlParamsForGetFilters).subscribe((res: any) => {
                    this.populateAndFormatFiltersData(res);
                });*/
            }
        } else if(this.filterId) { 
            //new way of implementation, we get fitler metadata by id itself
            const res = this.filterCriteriaService.getFiltersByFilterId(this.filterId);
            this.populateAndFormatFiltersData(res);
            
        }
    }
    ngOnDestroy() {
        this.subscriptionList.unsubscribe();
    }
    populateAndFormatFiltersData(res) {
        this.searchCriteria = res;
        this.clonedSearchCriteria = [];
        this.dependentResourceItems = [];
        this.searchCriteria.forEach((x: any) => {
            if (this.reportsInput && Object.keys(this.reportsInput)[0] === x.attribute) {
                if (!x.value) {
                    x.isDefault = true;
                }
                x.value = this.reportsInput[x.attribute];
                x.valueCode = this.reportsInput[x.attribute];
            }
            if (!x.dependsOnOtherAttribute) {
                this.clonedSearchCriteria.push(x);
            } else if (x.dependsAttributeName) {
                this.dependentResourceItems[x.dependsAttributeName] = x;
            }
        });
        this.clonedSearchCriteria.forEach(async item => {

            // Checking the localstorage availability of filters
            // If the particular filter module is not set in local storage, initialize with {}
            // Push the selected item by setting either Value OR ValueCode to the item
            let storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
            if (storedFilters && storedFilters[this.filterId] && storedFilters[this.filterId][item.attribute]) {
                if (storedFilters[this.filterId][item.attribute]['value']) {
                    item.value = storedFilters[this.filterId][item.attribute]['value'];
                }
                if (storedFilters[this.filterId][item.attribute]['valueCode']) {
                    item.valueCode = storedFilters[this.filterId][item.attribute]['valueCode'];
                }
                if (item.type !== 'boolean') {
                    this.selectedItems.push(item);
                }
            }
            if (storedFilters && storedFilters[this.filterId]) {
                Object.keys(storedFilters[this.filterId]).forEach(x => {
                    if (storedFilters[this.filterId][x]['dependsOnOtherAttribute']
                        && item.attribute === storedFilters[this.filterId][x]['dependsAttributeName']) {
                        this.clonedSearchCriteria.push(storedFilters[this.filterId][x]);
                        this.selectedItems.push(storedFilters[this.filterId][x]);
                    }
                });
            }

            if (item.isDefault || item.isMandatory) {
                if (storedFilters && Object.keys(storedFilters).length) {
                    const moduleFilter = storedFilters[this.filterId];
                    if (moduleFilter && Object.keys(moduleFilter).length) {
                        if (item.type === 'string') {
                            await this.getFilterValues(item, event, true);
                            const index = this.filteredStates.findIndex(selItem => selItem.attribute === item.attribute);
                            if (index > -1) {
                                this.filteredStates.splice(index, 1);
                            }
                        } else if (item.type === 'boolean') {
                            this.selectedItems.push(item);
                        } else if (item.type === 'dateDuration' || item.type === 'dateDurationAndFinYear' || item.type === 'finYear') {
                            const index = this.selectedItems.findIndex(selItem => selItem.attribute === item.attribute);
                            if (index === -1) {
                                this.selectedItems.push(item);
                            }
                        }
                    } else {
                        storedFilters[this.filterId] = {};
                        storedFilters[this.filterId][item.attribute] = item;
                        localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                        if (item.type === 'string') {
                            await this.getFilterValues(item, event, true);
                            const index = this.filteredStates.findIndex(selItem => selItem.attribute === item.attribute);
                            if (index > -1) {
                                this.filteredStates.splice(index, 1);
                            }
                        } else if (item.type === 'boolean') {
                            this.selectedItems.push(item);
                        } else if (item.type === 'dateDuration' || item.type === 'dateDurationAndFinYear' || item.type === 'finYear') {
                            const index = this.selectedItems.findIndex(selItem => selItem.attribute === item.attribute);
                            if (index === -1) {
                                this.selectedItems.push(item);
                            }
                        }
                    }
                } else {
                    if (!storedFilters) {
                        storedFilters = {};
                    }
                    storedFilters[this.filterId] = {};
                    storedFilters[this.filterId][item.attribute] = item;
                    localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                    if (item.type === 'string') {
                        await this.getFilterValues(item, event, true);
                        const index = this.filteredStates.findIndex(selItem => selItem.attribute === item.attribute);
                        if (index > -1) {
                            this.filteredStates.splice(index, 1);
                        }
                    } else if (item.type === 'boolean') {
                        this.selectedItems.push(item);
                    } else if (item.type === 'dateDuration' || item.type === 'dateDurationAndFinYear' || item.type === 'finYear') {
                        const index = this.selectedItems.findIndex(selItem => selItem.attribute === item.attribute);
                        if (index === -1) {
                            this.selectedItems.push(item);
                        }
                    }
                }
            }
        });
        this.filteredStates = JSON.parse(JSON.stringify(this.clonedSearchCriteria));
        // Mandatory fields counter to emit the filter event.
        // If there are mandatory fields, skip the default filter call as,
        // it will trigger after the first value set in mandatroy field
        let mandatoryItemsCount = 0;
        this.filteredStates.forEach(item => {
            if (
                (item.isMandatory && !item.value && !item.valueCode) ||
                (item.isDefault && item.type === 'string' && item.uri) ||
                ((item.isDefault || item.isMandatory) && (item.type === 'dateDuration' || item.type === 'dateDurationAndFinYear' || item.type === 'finYear') && item.value)
            ) {
                mandatoryItemsCount += 1;
            }
        });
        this.selectedItems.forEach(item => {
            const index = this.filteredStates.findIndex(selItem => selItem.attribute === item.attribute);
            if (index > -1) {
                this.filteredStates.splice(index, 1);
            }
        });

        if (!mandatoryItemsCount) {
            this.emitCommonFunc();
        }
    }

    emitCommonFunc() {
        const emitItem: any = {};
        const fullItems: any = [];
        //    console.log(this.selectedItems);
        this.selectedItems.forEach(item => {
            if (item.type === 'boolean') {
                if (item.value === 'true') {
                    _.extend(emitItem, { [item.attribute]: true });
                } else {
                    if (item.attribute === 'agentUserId') {
                        const token = localStorage.retrieve('authenticationToken');
                        const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
                        _.extend(emitItem, { [item.attribute]: userData.user_internal_id });
                    } else {
                        _.extend(emitItem, { [item.attribute]: false });
                    }
                }
            } else if (item.type === 'date') {
                _.extend(emitItem, { [item.attribute]: new Date(item.value).toISOString() });
            } else if (item.type === 'dateDuration' || item.type === 'finYear' || item.type === 'dateDurationAndFinYear') {
                _.extend(emitItem, { [item.attribute]: item.valueCode });
            }  else if (item.type === 'number') {
                const numberVal = +item.value;
                if (isNaN(numberVal) || numberVal < -2147483648 || numberVal > 2147483647) {
                    console.log('Given filter value is not a valid number so setting back to null');
                    item.value = undefined;
                }
                _.extend(emitItem, { [item.attribute]: item.value });
            } else {
                if (item.otherResourceReturnMappingAttribute && item.fromOtherResource) {
                    if (this.moduleType === 'pgw-chit-payment-dues') {
                        _.extend(emitItem, { [item.attribute]: item.value });
                    } else {
                        _.extend(emitItem, { [item.attribute]: item.valueCode });
                    }
                } else {
                    _.extend(emitItem, { [item.attribute]: item.value });
                }

            }
            fullItems.push(item);
        });
        //  console.log(emitItem);
        this.filterCriteriaService.setFiltersWithFullData(fullItems);
        this.filterNow.emit(emitItem);
    }

    onDateChanged(data) {
        data.item.valueCode = data.value;
        this.emitCommonFunc();
    }

    remove(item: any, event: Event): void {
        const index = this.selectedItems.indexOf(item);
        if (index >= 0) {
            this.selectedItems[index].value = null;
            this.selectedItems[index].valueCode = null;
            this.filteredStates.push(this.selectedItems[index]);
            this.selectedItems.splice(index, 1);

            if (this.dependentResourceItems[item.attribute]) {
                const dependentItemIndex = this.clonedSearchCriteria.indexOf(this.dependentResourceItems[item.attribute]);
                if (dependentItemIndex > -1) {
                    this.clonedSearchCriteria.splice(dependentItemIndex, 1);
                }
                const dependedSelectedItemIndex = this.selectedItems.
                    findIndex((i: any) => i.dependsAttributeName === this.dependentResourceItems[item.attribute].dependsAttributeName);
                if (dependedSelectedItemIndex > -1) {
                    this.selectedItems.splice(dependedSelectedItemIndex, 1);
                }
                const depFilterdIndex = this.filteredStates
                    .findIndex(i => i.dependsAttributeName === this.dependentResourceItems[item.attribute].dependsAttributeName);
                if (depFilterdIndex > -1) {
                    this.filteredStates.splice(depFilterdIndex, 1);
                }
            }
            // Removing the selected item from local storage
            const storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
            if (storedFilters && storedFilters[this.filterId] && storedFilters[this.filterId][item.attribute]) {
                delete storedFilters[this.filterId][item.attribute];
                Object.keys(storedFilters[this.filterId]).forEach(key => {
                    // tslint:disable-next-line:max-line-length
                    // console.log(storedFilters[this.filterId][key]['dependsOnOtherAttribute'], storedFilters[this.filterId][key]['dependsAttributeName']);
                    if (storedFilters[this.filterId][key]['dependsOnOtherAttribute']
                        && !storedFilters[this.filterId][storedFilters[this.filterId][key]['dependsAttributeName']]) {
                        delete storedFilters[this.filterId][key];
                    }
                });
            }
            localStorage.setItem('CHM-F', JSON.stringify(storedFilters));

            this.nameEntered = undefined;
            this.emitCommonFunc();
            this.stateCtrl.setValue('');
            this.filteredItem = '';
        }
        this.openAutocomplete();
    }

    filterStates(name: string) {
        if (this.selectedItems.filter(item =>
            item.name.toLowerCase().indexOf(name.toLowerCase()) === 0
        ).length > 0 || this.nameEntered) {
            return;
        }
        return this.searchCriteria.filter(state =>
            state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        // Checking the localstoarage value for filters
        // Pushing the selected item from the dropdown, by checking the existing filter module
        let storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
        if (storedFilters && Object.keys(storedFilters).length) {
            const moduleFilter = storedFilters[this.filterId];
            if (moduleFilter && Object.keys(moduleFilter).length) {

            } else {
                storedFilters[this.filterId] = {};
                localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
            }
        } else {
            storedFilters = {};
            storedFilters[this.filterId] = {};
            localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
        }
        storedFilters = JSON.parse(localStorage.getItem('CHM-F'));

        if (!this.selectedItems.find(item => item.attribute === event.option.value)) {
            this.nameEntered = event.option.value;
            this.selectedItems.push(
                JSON.parse(JSON.stringify(this.searchCriteria.find(item => item.attribute === event.option.value)))
            );
            const selectedItem = this.searchCriteria.find(item => item.attribute === event.option.value);
            const index = this.filteredStates.findIndex(item => selectedItem.attribute === item.attribute);
            this.filteredStates.splice(index, 1);
            if (selectedItem.type === 'boolean') {
                storedFilters[this.filterId][selectedItem.attribute] = true;
                if (selectedItem.name === 'My Subscibers') {
                    const token = localStorage.retrieve('authenticationToken');
                    const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
                    storedFilters[this.filterId][selectedItem.attribute] = userData.user_internal_id;
                }
                localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                this.emitCommonFunc();
                this.openAutocomplete();
            } else if (selectedItem.type === 'date') {
                setTimeout(() => {
                    $('#' + selectedItem.attribute.replace('.', '') + '').focus();
                }, 20);
            } else {
                const self = this;
                setTimeout(() => {
                    storedFilters[this.filterId][selectedItem.attribute] = {};
                    localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                    $('#' + selectedItem.attribute.replace('.', '') + '').focus();
                }, 100);
            }
        }
    }

    selectedFilterItems(event: MatAutocompleteSelectedEvent): void {
        this.filteredItem = event.option.value;
        const value = event.option.value;
        this.selectedItems.push(
            JSON.parse(JSON.stringify(this.searchCriteria.find(item => item.attribute === value)))
        );
        this.emitCommonFunc();
    }

    setFilterItems(items) {
        this.filterObjectItems = [];
        // this.filterEnumObjectItems = [];
        // this.filterEnumObjectItems = items;
        this.filterItems = [];
        this.filterItems = items;
    }

    filterData(attrType, event: Event) {
        this.activeItem = attrType;
        event.stopImmediatePropagation();
        const self = this;
        this.selectedItems.forEach(item => {
            if (item.attribute === this.activeItem) {
                item.value = event.currentTarget['value'];
                self.emitCommonFunc();
            }
        });
    }

    filterDataForDates(attrType, event: Event) {
        this.activeItem = attrType;
        const self = this;
        this.selectedItems.forEach(item => {
            if (item.attribute === self.activeItem) {
                if (event['value']) {
                    item.value = event['value'];
                } else if (event.currentTarget && event.currentTarget['value']) {
                    item.value = event.currentTarget['value'];
                }
                self.emitCommonFunc();
            }
        });
    }

    setFocus(item) {
        this.activeItem = item.attribute;
        const self = this;
        setTimeout(() => {
            if (item.type === 'date') {
                // self.trigger.openMenu();
                // setTimeout(() => {
                //     $('#' + item.attribute + '').click();
                // }, 20);
            } else {
                $('#' + item.attribute.replace('.', '') + '').focus();
            }
        }, 10);
    }

    selectItemFromDropdown(item) {
        this.dropdown.closeMenu();
        this.searchDropdown = item;
        const selectedValue = item;
        const self = this;
        this.selectedItems.forEach(currentItem => {
            if (currentItem.attribute === self.activeItem) {
                if (self.dependentResourceItems[currentItem.attribute]) {
                    const selectedItem: any = this.selectedItems.find(
                        citem => citem.attribute === self.dependentResourceItems[currentItem.attribute].attribute);
                    if (selectedItem === -1 || typeof (selectedItem) === 'undefined') {
                        self.clonedSearchCriteria.push(self.dependentResourceItems[currentItem.attribute]);
                        self.filteredStates.push(self.dependentResourceItems[currentItem.attribute]);
                    } else if (selectedItem && selectedItem['dependsAttributeName']) {
                        delete selectedItem.value;
                        delete selectedItem.valueCode;
                    }
                }
                currentItem.value = selectedValue;
                const storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
                storedFilters[this.filterId][currentItem.attribute] = currentItem;
                localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                self.emitCommonFunc();
            }
        });
        //  console.log(this.selectedItems);
    }

    selectObjectFromDropdown(item) {
        this.dropdown.closeMenu();
        this.searchDropdown = item;
        const selectedValue = item[this.focusedItem.otherResourceAttributeName];
        const self = this;

        this.selectedItems.forEach(currentItem => {
            if (currentItem.attribute === self.activeItem) {
                if (self.dependentResourceItems[currentItem.attribute]) {
                    const selectedItem: any = this.selectedItems.find(
                        citem => citem.attribute === self.dependentResourceItems[currentItem.attribute].attribute);
                    if (selectedItem === -1 || typeof (selectedItem) === 'undefined') {
                        self.clonedSearchCriteria.push(self.dependentResourceItems[currentItem.attribute]);
                        self.filteredStates.push(self.dependentResourceItems[currentItem.attribute]);
                    } else if (selectedItem && selectedItem['dependsAttributeName']) {
                        delete selectedItem.value;
                        delete selectedItem.valueCode;
                    }
                }
                currentItem.value = selectedValue;
                currentItem.valueCode = item[currentItem.otherResourceReturnMappingAttribute];
                const storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
                storedFilters[this.filterId][currentItem.attribute] = currentItem;
                localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                self.emitCommonFunc();
            }
        });
        //  console.log(this.selectedItems);
    }

    openAutocomplete(e?: any) {
        setTimeout(() => {
            this.inputAutoComplit._onChange('');
            this.inputAutoComplit.openPanel();
        }, 0);
    }

    activeteFocusedItem(item) {
        this.activeItem = item.attribute;
        setTimeout(() => {
            this.closeAutoComplete();
        }, 0);
    }

    closeAutoComplete() {
        setTimeout(() => {
            this.inputAutoComplit.closePanel();
        }, 0);
    }

    getFilterValues(item: any, event: Event, isMandatory) {
        this.filteredItem = event.currentTarget['value'];
        this.activeItem = item.attribute;
        this.focusedItem = item;
        event.stopImmediatePropagation();
        if (item.uri) {
            let attributeName;
            let Obj;
            if (item.fromOtherResource) {
                attributeName = item.otherResourceAttributeName;
                Obj = {
                    autoCompleteText: this.filteredItem,
                    returnAttributes: [item.otherResourceReturnAttributes]
                };
            } else {
                attributeName = item.attribute;
                Obj = {
                    autoCompleteText: this.filteredItem
                };
            }
            if (this.addlParamsForGetFilters && this.addlParamsForGetFilters.companyCode) {
                Obj = { ...Obj, ...{'companyCode': this.addlParamsForGetFilters.companyCode} };
            } else if(this.companyCode) {
                Obj = { ...Obj, ...this.companyCode };
            }

            const self = this;
            const req = {'query': Obj}
            this.filterCriteriaService.getFilterValues(attributeName, item.uri, req, { attribute: item.attribute, value: item.value }, item, this.selectedItems).subscribe((res: any) => {
                if (isMandatory && res.length) {
                    if (res[0].name) {
                        item['value'] = res[0].name;
                    }
                    if (res[0][item.otherResourceReturnMappingAttribute]) {
                        item['valueCode'] = res[0][item.otherResourceReturnMappingAttribute];
                    }
                    let storedFilters = JSON.parse(localStorage.getItem('CHM-F'));
                    if (!storedFilters) {
                        storedFilters = {};
                    }
                    if (!storedFilters[self.filterId]) {
                        storedFilters[self.filterId] = {};
                    }
                    if (!storedFilters[self.filterId][item.attribute]) {
                        storedFilters[self.filterId][item.attribute] = {};
                    }
                    storedFilters[self.filterId][item.attribute] = item;
                    localStorage.setItem('CHM-F', JSON.stringify(storedFilters));
                    let thisItemCount = 0;
                    self.selectedItems.forEach(selItem => {
                        if (selItem.attribute === item.attribute) {
                            thisItemCount = 1;
                        }
                    });
                    if (!thisItemCount) {
                        self.selectedItems.push(item);
                    }
                    self.emitCommonFunc();
                } else {
                    setTimeout(() => {
                        this.inputAutoComplit._onChange('');
                        this.inputAutoComplit.closePanel();
                    }, 0);
                    // Handle accounting filter values //
                    if (res[item.attribute]) {
                        res = res[item.attribute];
                    }
                    if (typeof res[0] === 'string') {
                        this.filterItems = res;
                        this.filterObjectItems = [];
                        this.filterEnumObjectItems = [];
                    } else if (typeof res[0] === 'object') {
                        this.filterObjectItems = res;
                        this.filterItems = [];
                        this.filterEnumObjectItems = [];
                    } else if (res.length) {
                        this.filterItems = res;
                        this.filterObjectItems = [];
                        this.filterEnumObjectItems = [];
                    } else {
                        this.filterObjectItems = [];
                        this.filterEnumObjectItems = [];
                        this.filterItems = [];
                    }


                    this.dropdown.openMenu();
                }
            });
        } else {
            this.selectItemFromDropdown(this.filteredItem);
        }
    }

}


@Component({
    selector: 'app-cm-data-bind',
    template: `<div>{{showValue}}</div>`
})

export class DataBindingComponent implements OnInit {
    @Input() data: any;
    @Input() property: any;
    showValue: any;

    ngOnInit() {
        this.showValue = this.data[this.property.otherResourceAttributeName];
    }
}
