import { Component, OnInit } from '@angular/core';

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { GlobalState } from '../../../global.state';

import { Router } from '@angular/router';
import { USER_TYPES } from '../../../app.constants';
import { UserAuthenticationService } from '../../../shared/user-auth.service';
import { Principal } from './../../../shared/auth/principal.service';
import { LoginModalService, LoginService } from './../../../shared';

@Component({
    selector: 'app-ba-page-top',
    templateUrl: './baPageTop.html',
    styleUrls: ['./baPageTop.scss']
})

export class BaPageTopComponent implements OnInit {
    route: any;
    objType: any;
    modalRef: NgbModalRef;
    currentAccount: any;
    isAuthenticated: any;
    imageLocation: any;
    isUserAuthenticated = false;
    imageLocation2: any;

    public isScrolled = false;
    public isMenuCollapsed = false;
    user_regulator: boolean;
    user_foreman: boolean;
    public innerWidth: any;
    lastLoginTimeStamp: Date;

    user_user: boolean;
    constructor(private _state: GlobalState,
        private loginService: LoginService,
        private loginModalService: LoginModalService,
        private router: Router,
        private principal: Principal,
        private localStorage: LocalStorageService,
        private userAuthenticationService: UserAuthenticationService,
        private sessionStorage: SessionStorageService,
    ) {
        this.imageLocation = window.location.origin;
        this.imageLocation2 = window.location.origin;
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 769) {
            router.events.subscribe((val) => {
                if (val['url'] !== undefined) {
                    this.isMenuCollapsed = true;
                    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
                }
            });
        }

        if (this.imageLocation.indexOf('localhost')) {
            this.imageLocation = 'http://tst.chitmonks.com';
        }
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });

        this.principal.identity().then((account) => {
            this.currentAccount = account;

            const token = this.localStorage.retrieve('authenticationToken') ||
                this.sessionStorage.retrieve('authenticationToken');

            if (token && token.companyName) {
                this.currentAccount.companyName = token.companyName;
            }

            if(token.lastLoginTimeStamp) {
                this.lastLoginTimeStamp = new Date(token.lastLoginTimeStamp)
            }
        });
        this.isAuthenticated = this.principal.isAuthenticated;


        if (USER_TYPES.IS_REGULATOR) {
            this.user_regulator = true;
            this.user_foreman = false;
            this.user_user = false;
        } else if (USER_TYPES.IS_FOREMAN) {
            this.user_foreman = true;
            this.user_regulator = false;
            this.user_user = false;
        } else if (USER_TYPES.IS_USER) {
            this.user_foreman = false;
            this.user_regulator = false;
            this.user_user = true;
        }

        this.userAuthenticationService.isAuthenticated.subscribe(value => {
            this.isUserAuthenticated = value;
        });


    }

    ngOnInit() {
    }

    changeLanguage(languageKey: string) {
        this.sessionStorage.store('locale', languageKey);
    }

    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }

    login() {
        console.log('login is called');
        this.modalRef = this.loginModalService.open();
    }

    logout() {
        console.log('logout is called');
        this.isMenuCollapsed = true;
        this.loginService.logout();
        this.router.navigate(['login']);
    }

    userLogout() {
        this.userAuthenticationService.display(false);
        this.isMenuCollapsed = true;
        this.loginService.logout();
        this.sessionStorage.clear('userAuthenticationToken');
        this.sessionStorage.clear('complainerName');
        window.location.reload();
    }

    resetModule() {
        this.router.navigate(['password-reset']);
    }
}
