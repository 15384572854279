import { Component, OnInit, Inject } from '@angular/core';
import { BaPageTitleService } from '../../../theme/services/baMenu/baMenu.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-delete-confirmation-dialog',
    templateUrl: 'delete-confirmation-dialog.html',
    styleUrls: ['./dialogs.scss'],

})
export class DeleteConfirmationDialog {

    YES_RESPONSE = 'YES';
    NO_RESPONSE = 'NO';

    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close(this.NO_RESPONSE);
    }
    
    onYesClick(): void {
        this.dialogRef.close(this.YES_RESPONSE);
    }

}




