<mat-table #table [dataSource]="completeTableData">
    <ng-container matColumnDef="installmentNo">
        <mat-header-cell *matHeaderCellDef>
            Inst. No.
        </mat-header-cell>
        <mat-cell style="width: 60px ; flex: none; display: inline-block;" *matCellDef="let row; let i = index">
            {{row.installmentNo}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="installmentDate">
        <mat-header-cell *matHeaderCellDef> 
            Inst. Defaulting Date 
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span *ngIf="row?.installementDate != 'null'" >
                {{row?.installmentDate * 1000 | date: 'dd-MM-yy'}}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="subscriptionAmount">
        <mat-header-cell *matHeaderCellDef> 
            Subscription Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            {{row?.subscriptionAmount?.toLocaleString("en-IN")}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dividend">
        <mat-header-cell *matHeaderCellDef> 
            Dividend
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            {{row?.dividend?.toLocaleString("en-IN")}}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="netDue">
        <mat-header-cell *matHeaderCellDef> 
            Net Due
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            {{row?.netDue?.toLocaleString("en-IN")}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="interestAmount">
        <mat-header-cell *matHeaderCellDef> 
            Interest
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            {{row?.interestAmount?.toLocaleString("en-IN")}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="days">
        <mat-header-cell *matHeaderCellDef> 
            No. Of. Days considered
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
            <span *ngIf="row?.days != 'null'">
                {{row?.days?.toLocaleString("en-IN")}}
            </span>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="mat-cell-rows" *matRowDef="let row;let i = index; columns: displayedColumns;" [ngClass]="{'finalRowCss': completeTableData.length - 1 === i}"></mat-row>
</mat-table>