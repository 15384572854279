import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    template: `<div class="dialog-wrapper">
                    <div class="dialog-title">
                        <span>{{data?.title}}</span>
                        <button matDialogClose="no" style="display: contents;">
                            <span><i class="material-icons close-btn">close</i></span>
                        </button>
                    </div>
                    <div class="dialog-content-wrapper" style="padding: 0px;">
                        <div class="row">
                            <iframe *ngIf="fileUrl" [src]="fileUrl" width="100%" height="620px"></iframe>
                        </div>

                    </div>
                </div>`
})

export class PdfPreviewDialogComponent {
    fileUrl: any;
    constructor(
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.data.blob));
    }

}
