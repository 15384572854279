import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { USER_TYPES } from '../app.constants';
import { Principal } from '../shared';
import { BaPageRightMenuComponent } from '../theme/components/baPageRightMenu/baPagesRightMenu.component';

@Component({
    styleUrls: [],
    template: `
    <app-ba-sidebar *ngIf="showPagesLayout"></app-ba-sidebar>
    <app-ba-page-top *ngIf="showPagesLayout"></app-ba-page-top>
    <div [ngClass]="{'snb-main': showPagesLayout, 'snb-main-public': !showPagesLayout}">
      <div class="snb-content">
        <app-ba-content-top></app-ba-content-top>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <ng-template ngbModalContainer></ng-template>
      </div>
      <footer class="al-footer clearfix">
      <div class="al-footer-main clearfix">
            <div class="al-copy">Copyright &copy; <a href="https://www.snbchain.com" target="_blank">SnBChain</a> {{currentYear}}.
            Product by Pangean Global Services Pvt Ltd.</div>
            <div class="al-version">
             <span  *ngIf="currentVersion"> Version: {{currentVersion}} </span>
            </div>
      </div>
    </footer>
    </div>
    <app-ba-page-right-menu *ngIf="showPagesLayout"></app-ba-page-right-menu>
    <app-ba-back-top position="200"></app-ba-back-top>
    `
})
export class PagesComponent implements OnInit {
    logo: string;
    name: string;
    currentYear: number;
    showPagesLayout = false;
    currentVersion: string;
    @ViewChild(BaPageRightMenuComponent) rightMenu: BaPageRightMenuComponent;

    constructor(
        private principal: Principal,
        private router: Router
    ) {
        this.currentYear = new Date().getFullYear();
        if (USER_TYPES.IS_REGULATOR || USER_TYPES.IS_FOREMAN) {
            this.showPagesLayout = true;
        }
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            if (account) {
                this.logo = account.companyName;
                this.name = account.name;
            }
        });
        this.currentVersion = this.getCurrentVersionRunning();
        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                const completeUrl = event.urlAfterRedirects;
                if (completeUrl.indexOf('/pages/dashboard') > -1 && this.rightMenu) {
                    this.rightMenu.showSelected = true;
                }
            }
        });

    }

    onActivate(event) {
        window.scroll(0, 0);
    }

    /**
     * This method returns the current version running in the system.
     */
    getCurrentVersionRunning(): string {
        const currentVersion = localStorage.getItem('currentVersion');

        if (currentVersion) {
            return currentVersion;
        }

        return '';
    }

}
