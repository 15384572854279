<div class="snb_new_foreman_ato">
    <div class="row ato-card">
        <div class="col-md-6 col-sm-6 col-xs-6" style="width: 45%;">
            <div class="card dep-a">
                <div class="snb_card_label">
                    Company ATO Details
                </div>
                <div>
                    <div>
                        <div class="right-col">
                            <div class="step-col-content">
                                <div class="col-wrapper light-bg" style="text-align:center">
                                    <div class="s-col">
                                        <span class="property">
                                            ATO's:
                                        </span>
                                        <span class="value s-col-full" matTooltip="{{financialStatus?.ato && formatCurrencyToWords(financialStatus?.ato)}}">
                                            {{financialStatus?.ato? (financialStatus.ato | chmAmountUnitPipe:'CRORE':true) : "Data Not Available" }}
                                        </span>
                                    </div>
                                    <div class="s-col">
                                        <span class="property" >
                                            Net Owned Funds {{financialStatus?.financialYear ? '(FY'+financialStatus.financialYear+')': ''}}:
                                        </span>
                                        <span class="value s-col-full" matTooltip="{{financialStatus?.netownedAssets && formatCurrencyToWords(financialStatus?.netownedAssets)}}">
                                            {{financialStatus?.netownedAssets? (financialStatus.netownedAssets | chmAmountUnitPipe:'CRORE':true) : "Data Not
                                            Available"
                                            }}
                                        </span>

                                    </div>

                                    <div class="s-col">
                                        <span class="property" >
                                            Allowed Limit : 
                                        </span>
                                        <span class="value s-col-full" matTooltip="{{financialStatus?.chitAggregateAmountAllowed && formatCurrencyToWords(financialStatus?.chitAggregateAmountAllowed)}}">
                                            {{financialStatus?.chitAggregateAmountAllowed? (financialStatus.chitAggregateAmountAllowed | chmAmountUnitPipe:'CRORE':true) : "Data Not
                                            Available"
                                            }}
                                        </span>
                                        
                                    </div>

                                    <div>
                                        <a (click)="openFaq()">Click here to understand how the allowed limit is calculated</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row branch-atos-card" >
        <div class=" col-md-12 col-sm-12 col-xs-12">
            <div class="card dep-a">
                <div class="snb_card_label">
                    Branch ATO Details
                </div>

                <div class="custom-data-table" style="text-align:center">
                    <div class="row no-margin">
                        <div class="col-md-7 col-xs-12">
                            <div class="timeline_card">
                                <div class="subscribers_list custome_table">
                                    <mat-table #table [dataSource]="atosData">

                                        <ng-container matColumnDef="name">
                                            <mat-header-cell *matHeaderCellDef>
                                                <span class="fontIcon"> <i class="fa fa-building-o" aria-hidden="true"></i> </span>
                                                <span class="afterFont">Branch Name</span>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="ato">
                                            <mat-header-cell *matHeaderCellDef>
                                                <span class="fontIcon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                                                <span class="afterFont">ATO's (in Crores)</span>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.ato? (element.ato / 10000000) : '-' }} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="regulatorBranchName">
                                            <mat-header-cell *matHeaderCellDef>
                                                <span class="fontIcon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                                                <span class="afterFont">Regulator Branch Name</span>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element"> {{element.regulatorBranchName}} </mat-cell>
                                        </ng-container>


                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                                    </mat-table>

                                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>
                                        <div *ngIf="atosData?.length===0 && !requestInProgress" class="empty-row">No data to
                                            display</div>

                                        <mat-paginator [hidePageSize]="true" [length]="totalItems"
                                            [pageSize]="itemsPerPage"
                                            [showFirstLastButtons]="true"></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

