import { Component, OnDestroy, PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { EventManager } from '../../blocks/interceptor/event-manager.service';
import { AlertService } from '../../blocks/interceptor/alert.service';

@Component({
    selector: 'jhi-alert-error',
    template: `
        <div class="alerts" role="alert">
            <div  class="animated"  *ngFor="let alert of alerts;let i=index"  [ngClass]="{\'alert.position bounceInRight\': true, \'toast\': alert.toast}">
                <ngb-alert type="{{alert.type}}" (close)="closeAlert(i,alerts)"><p [innerHTML]="alert.msg"></p></ngb-alert>
            </div>  
        </div>`
})
export class JhiAlertErrorComponent implements OnDestroy {

    alerts: any[];
    cleanHttpErrorListener: Subscription;

    constructor(
        private alertService: AlertService, 
        private eventManager: EventManager, 
        private translateService: TranslateService) {
        this.alerts = [];

        this.cleanHttpErrorListener = eventManager.subscribe('chitmonksApiApp.httpError', (response: any) => {
            let i;
            const httpResponse = response['content'];
            switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;

                case 400:
                    const arr = Array.from(httpResponse.headers._headers);
                    const headers = [];
                    for (i = 0; i < arr.length; i++) {
                        if (arr[i][0].endsWith('app-error') || arr[i][0].endsWith('app-params')) {
                            headers.push(arr[i][0]);
                        }
                    }
                    headers.sort();
                    const errorHeader = headers.length > 0 ? httpResponse.headers.get(headers[0]) : null;
                    const entityKey = headers.length > 1 ? httpResponse.headers.get(headers[1]) : null;
                    if (errorHeader) {
                        const entityName = translateService.instant('global.menu.entities.' + entityKey);
                        this.addErrorAlert(errorHeader, errorHeader, { entityName: entityName });
                    } else if (httpResponse.text() !== '' && httpResponse.json() && httpResponse.json().fieldErrors) {
                        const fieldErrors = httpResponse.json().fieldErrors;
                        for (i = 0; i < fieldErrors.length; i++) {
                            const fieldError = fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            const fieldName = translateService.instant('chitmonksApiApp.' +
                                fieldError.objectName + '.' + convertedField);
                            this.addErrorAlert(
                                'Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, { fieldName: fieldName });
                        }
                    } else if (httpResponse.text() !== '' && httpResponse.json() && httpResponse.json().message) {
                        this.addErrorAlert(httpResponse.json().message, httpResponse.json().message, httpResponse.json());
                    } else {
                        this.addErrorAlert(httpResponse.text());
                    }
                    break;

                case 404:
                    this.addErrorAlert('Not found', 'error.url.not.found');
                    break;

                default:
                    if (httpResponse.text() !== '' && httpResponse.json() && httpResponse.json().message) {
                        this.addErrorAlert(httpResponse.json().message);
                    }
                    else if (httpResponse.json().error == "invalid_client") {
                        this.addErrorAlert("error.invalidClient");
                    }
                    else {
                        this.addErrorAlert(JSON.stringify(httpResponse)); // Fixme find a way to parse httpResponse
                    }
            }
        });
    }

    ngOnDestroy() {
        if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
            this.eventManager.destroy(this.cleanHttpErrorListener);
            this.alerts = [];
        }
    }

    addErrorAlert(message, key?, data?) {
        key = key && key !== null ? key : message;
        const alerttmp = this.alertService.addAlert(
            {
                type: 'danger',
                translationKey: key,
                translationParams: data,
                timeout: 10000,
                toast: true
            },
            this.alerts
        );
        this.alerts.push(alerttmp
        );
    }

    closeAlert(id, alerts) {
        this.alertService.clear()
    }
}
