import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalState } from '../../../global.state';
import { BaPageTitleService } from '../../services/baMenu/baMenu.service';

@Component({
    selector: 'app-ba-content-top',
    templateUrl: './baContentTop.html',
    styleUrls: ['./baContentTop.scss']

})
export class BaContentTopComponent implements OnInit {

    public activePageTitle = '';
    public hideBackButton = false;

    constructor(
        private _state: GlobalState,
        private _location: Location,
        private pageTitleService: BaPageTitleService
    ) {
        this._state.subscribe('menu.activeLink', (activeLink) => {
            if (activeLink) {
                // this.activePageTitle = activeLink.pagetitle;
            }
        });
    }

    goToBackView() {
        this._location.back();
    }

    ngOnInit() {
        this.pageTitleService.currentMessage.subscribe(title => {
            this.activePageTitle = title;
            this.hideBackButton = false;
        });
        this.pageTitleService.fnShowBackButton.subscribe(hideBackButton => {
            if (hideBackButton) {
                this.hideBackButton = true;
            } else {
                this.hideBackButton = false;
            }
        });
    }
}
