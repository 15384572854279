
import { tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { Principal } from '../../shared/auth/principal.service';
import { Injectable, Injector } from "@angular/core";
import { AuthService } from '../../shared/auth/auth.service';
import { Router } from "@angular/router";
import { EventManager } from './event-manager.service';
import { LoginService } from "../../shared";

/**
 * This class is responsible of intercepting the http request and response.
 * For the request it injects the bearer token in the header which is taken from the local storage.
 * For the response it checks if the response is 401 and if it is then it redirects to login page.
 * If not 401, it process the error and returns the error.
 */
@Injectable()
export class HttpRequestResponseInterceptor implements HttpInterceptor {
    constructor(private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private injector: Injector,
        private eventManager: EventManager,
        private loginService: LoginService,
        private router: Router,) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
 
        if (token && token.expires_at && token.expires_at < new Date().getTime()) {
            //After session expired re directing to the login page
            console.log(token, "token expired");
            this.loginService.logout();
            this.router.navigate(['login']);
        }
         if (token && token.access_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token.access_token
                }
            });
        }
        const self = this;
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            console.log(err);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    const principal: Principal = self.injector.get(Principal);
                    if (principal.isAuthenticated()) {
                        const auth: AuthService = self.injector.get(AuthService);
                        auth.authorize(true);
                    } else {
                        // tslint:disable-next-line:triple-equals
                        if (err.error.error == 'invalid_token') {
                            const router: Router = self.injector.get(Router);
                            router.navigate(['./login']);
                        }
                        // event.stopPropagation();
                    }
                } else if (err.error ||
                    (err.url && err.url.indexOf('/account') === 0)) {
                    console.log('error occured');
                    this.eventManager.broadcast({ name: 'chitmonksApiApp.httpError', content: err });
                }
            }
        }));
    }
}