
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError,  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OnInit, Component, Output, EventEmitter, Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { SNB_CORE_URL, SNB_WF_URL } from '../../app.constants';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class PublicFilterCriteriaService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getForemanCompanyMiniDetails<T>(req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        url = `${SNB_CORE_URL}/public/foreman-companies/get-mini-details`;
        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyBranchMiniDetails<T>(req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        url = `${SNB_CORE_URL}/public/foreman-companies/branches/get-mini-details`;
        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getChitsMiniDetailsForPublic<T>(req?: any) {
        const options = this.prepareHeader(req);
        // tslint:disable-next-line: max-line-length
        return this.httpClient.get(`${SNB_CORE_URL}/public/chits/get-chits-with-min-info?stateIn=AGREEMENT_APPROVED,COMMENCEMENT_APPROVED,FORM_7_APPLIED,IN_PROGRESS,TERMINATED,CLOSED,ROS_APPLIED,ROS_NOTICE_PUBLISHED,SECURITY_RELEASED`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    createServiceRequestForPublic<T>(data, url, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        const copy = Object.assign({}, data);
        return this.httpClient.post(`${SNB_WF_URL}/public/process/misc/${url}`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getProessDetailsForPublic<T>(processInstanceId, req?: any) {
        const options = this.prepareHeader(req);
        const request = {
            'processInstanceId': processInstanceId
        };
        return this.httpClient.post(`${SNB_WF_URL}/public/process/query`, request, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getTaskDetailsPublic<T>(taskId, req?: any) {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_WF_URL}/public/task/${taskId}?active=true`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanBranchAddress<T>(branchId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';

        url = `${SNB_CORE_URL}/public/foreman-company-branch/${branchId}`;

        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyDetails<T>(companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';

        url = `${SNB_CORE_URL}/public/foreman-companies/${companyCode}`;

        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params.set('stateIn', urlSearchParams.stateIn);
            }


            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }

}


@Component({
    selector: 'app-public-search-filter',
    templateUrl: './public-search-filter.html',
    styleUrls: ['./public-search-filter.scss']
})

export class PublicSerachFilterComponent implements OnInit {
    searchForm: FormGroup;
    foremanCompanyNames = [];
    foremanCompanyBranchNames = [];
    filteredForemanCompanies = [];
    branchQuery: any;
    companyBranchQuery = {};
    query: any = {};
    foremanBranchAddress: any;
    foremanMainBranchAddress: any;
    companyDetails: any;
    loadedChitInfoByBranch = false;
    @Output() filterNow: EventEmitter<any> = new EventEmitter();
    isDataFetched = false;
    filtersNotSelected = false;
    psoNumber: any;
    chitNumber: any;

    constructor(
        private fb: FormBuilder,
        private publicFilterCriteriaService: PublicFilterCriteriaService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.searchForm = this.fb.group({
            name: [''],
            psoNumber: [''],
            companyCode: [''],
            foremanCompanyBranchCode: [''],
        });
        this.activatedRoute.queryParams.subscribe(params => {
            this.psoNumber = params.pso;
            this.chitNumber = params.chit;
        });
    }

    ngOnInit() {

        if (this.chitNumber) {
            this.searchForm.controls['name'].setValue(this.chitNumber);
        }
        if (this.psoNumber) {
            this.searchForm.controls['psoNumber'].setValue(this.psoNumber);
        }
        if (this.chitNumber || this.psoNumber) {
            this.searchForChits();
        }

        this.loadForemanCompanyMiniDetails();
        this.searchForm.controls.companyCode.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(foremanCompanyName => {
                const companyNames = this.foremanCompanyNames;
                this.filteredForemanCompanies = companyNames.filter(foremanCompany =>
                    foremanCompany.name.toLowerCase().indexOf(foremanCompanyName.toLowerCase()) !== -1
                );
            });
    }

    loadForemanCompanyMiniDetails() {
        this.publicFilterCriteriaService.getForemanCompanyMiniDetails({
            sort: ['name,asc']
        }).subscribe((res: any) => {
            this.foremanCompanyNames = res;
            this.filteredForemanCompanies = this.foremanCompanyNames;
        });
    }

    loadForemanBranchesMiniDetails(companyCode: string): void {
        this.branchQuery = { 'companyCode': companyCode };
        this.publicFilterCriteriaService.getForemanCompanyBranchMiniDetails({
            sort: ['name,asc'],
            query: this.branchQuery
        }).subscribe((res: any) => {
            this.foremanCompanyBranchNames = res;
        });
    }

    companySelected(event: any) {
        this.foremanCompanyBranchNames = [];
        this.loadForemanBranchesMiniDetails(event.option.value);
    }

    clearBranchCode(event) {
        if (!this.searchForm.value.companyCode) {
            this.searchForm.controls.foremanCompanyBranchCode.setValue('');
        }
    }

    loadForemanCompanyDetails(companyCode) {
        if (companyCode) {
            this.publicFilterCriteriaService.getForemanCompanyDetails(companyCode).subscribe(res => {
                this.companyDetails = res;
                this.publicFilterCriteriaService.getForemanBranchAddress(this.companyDetails.mainBranchRefId).subscribe((response: any) => {
                    this.foremanMainBranchAddress = response;
                });
            });
        } else {
            this.companyDetails = {};
            this.foremanMainBranchAddress = {};
            this.foremanBranchAddress = {};
        }
    }

    loadForemanBranchDetails(branchId) {
        this.publicFilterCriteriaService.getForemanBranchAddress(branchId).subscribe(res => {
            this.foremanBranchAddress = res;
        });
    }

    searchForChits() {
        this.filtersNotSelected = false;
        this.query = this.searchForm.getRawValue();
        const selectedBranchDetails = this.foremanCompanyBranchNames
            .find(branch => branch.branchCode === this.query.foremanCompanyBranchCode);
        if (selectedBranchDetails) {
            this.isDataFetched = true;
            this.loadForemanBranchDetails(selectedBranchDetails.id);
            this.loadForemanCompanyDetails(this.query.companyCode);
            this.loadedChitInfoByBranch = true;
        }
        if (!this.query || (!this.query.psoNumber && !this.query.name &&
            (!this.query.companyCode || !this.query.foremanCompanyBranchCode))) {
            this.filtersNotSelected = true;
            return false;
        } else if (this.query.companyCode && !this.query.foremanCompanyBranchCode) {
            this.filtersNotSelected = true;
            return;
        }
        this.filterNow.emit(this.query);
    }

    displayFn(companyCode) {
        if (!companyCode) {
            return '';
        }
        const index = this.filteredForemanCompanies.findIndex(state => state.companyCode === companyCode);
        return this.filteredForemanCompanies[index].name;
    }

}
