import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from "@ngx-translate/core";
import { EMAIL_REGEX, PHONE_REGEX, USER_TYPES } from "../../../app.constants";
import { SnBToasterService } from "../../../shared/components/toaster/toaster.service";
import { FormatInputs } from "../../../shared/format-values";
import { DesignationTypes } from "../../profile/profile.model";
import { ForemanCompaniesService } from "../foreman-companies.service";
import * as lodash from "lodash";

@Component({
    templateUrl: './add-foreman.html',
    styleUrls: ['./foreman-companies-new.scss', './dialog-popup-content.scss']
})
export class AddForemanDialogComponent implements OnInit, AfterViewChecked {

    foremanUser: FormGroup;
    bankAndAuditors: FormGroup;
    private designationTypes: any[];
    foremanDesignation: any;
    isFormSubmitted = false;
    foremanBranches: any;
    inputError = true;
    maxDate = new Date();
    
    constructor(
        public dialogRef: MatDialogRef<AddForemanDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private fb: FormBuilder,
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private formatInputs: FormatInputs,
        private toasterService: SnBToasterService,
    ) {
        this.designationTypes = DesignationTypes;
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    ngOnInit() {
        this.foremanDesignation = this.data.userType ? this.data.userType : 'EMPLOYEE';

        this.foremanUser = this.fb.group({
            title: '',
            firstName: '',
            lastName: '',
            birthDetails: this.fb.group({
                dateOfBirth: [undefined]
            }),
            suffix: [''],
            contactDetails: this.fb.group({
                email: ['', Validators.pattern(EMAIL_REGEX)],
                mobileNumber: ['', Validators.pattern(PHONE_REGEX)],
                addresses: this.fb.array([
                    this.fb.group({
                        addressField: [''],
                        addressLine1: ['', [Validators.required]],
                        addressLine2: [''],
                        street: ['', [Validators.required]],
                        city: ['', [Validators.required]],
                        district: ['', [Validators.required]],
                        state: ['', [Validators.required]],
                        pin: ['', [Validators.required]]
                    })
                ])
            }),
            isNextToCeoInManagerialSetup: false,
            designation: [this.foremanDesignation]
        });

        this.bankAndAuditors = this.fb.group({
            name: ['', [Validators.required]],
            companyCode: this.data.companyCode,
            address: this.fb.group({
                addressField: [''],
                addressLine1: ['', [Validators.required]],
                addressLine2: [''],
                street: ['', [Validators.required]],
                city: ['', [Validators.required]],
                district: ['', [Validators.required]],
                state: ['', [Validators.required]],
                pin: ['', [Validators.required]],
                type: ['OFFICE']
            }),
            bankName: [''],
            bankAccountUsageType: [''],
            accountType: ['', [Validators.required]],
            acctNumber: ['', [Validators.required]],
            acctHolderName: ['', [Validators.required]],
            ifscCode: [''],
            foremanCompanyBranchCode: [''],
            startDate:['', [Validators.required]],

        });
        // If account Type is already selected we populate account Type by Default
        if(this.data.accType) {
            let accType = this.bankAndAuditors.controls.bankAccountUsageType;
            accType.setValue(this.data.accType);
            accType.disable();
        }
        //IN the auditor case removed the validations which are using for bank 
        if (this.data.userType ==='AUDITOR') {
            this.bankAndAuditors.get('startDate').clearValidators();
            this.bankAndAuditors.get('startDate').updateValueAndValidity();
            this.bankAndAuditors.get('acctHolderName').clearValidators();
            this.bankAndAuditors.get('acctHolderName').updateValueAndValidity();
            this.bankAndAuditors.get('accountType').clearValidators();
            this.bankAndAuditors.get('accountType').updateValueAndValidity();
            this.bankAndAuditors.get('acctNumber').clearValidators();
            this.bankAndAuditors.get('acctNumber').updateValueAndValidity();
        }
        if (this.data.bankAndAuditor && this.data.data) {
            this.applyFormValues(this.bankAndAuditors, this.data.data);
        }
        if (this.data.foremanUser && this.data.data) {
            this.applyFormValues(this.foremanUser, this.data.data);
        }
        this.getForemanBranchCodes();
    }

    private isAccountDetailsTouched = false;

    onAccountDetailsTouched() {
        this.isAccountDetailsTouched = true;
        let requiredControls = ['bankName', 'bankAccountUsageType', 'accountType', 'acctNumber', 'acctHolderName', 'ifscCode', 'foremanCompanyBranchCode'];
        requiredControls.forEach((control) => {
            const myInputControl = this.bankAndAuditors.get(control) as UntypedFormControl;
            const validatorFn = Validators.required;
            myInputControl.setValidators(validatorFn);
            myInputControl.updateValueAndValidity();
        })
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        console.log('Key is ', key, formValues[key]);
                        if (key === 'dateOfBirth' && formValues[key]) {
                            formControl.setValue(new Date(formValues[key]*1000));
                        } else {
                            formControl.setValue(formValues[key]);
                        }
                    }
                }
            }
        });
    }

    submitUserData(foremanUser, event: Event, action) {
        event.preventDefault();
        if (!foremanUser.valid) {
            if (!this.foremanUser.controls.contactDetails['controls'].addresses.controls[0].controls.addressField.value) {
                this.foremanUser.controls.contactDetails['controls'].addresses.controls[0].controls.addressField.markAsTouched();
            }
            return false;
        }
        let data = foremanUser.value;
        data.companyCode = this.data.companyCode;
        // if (!this.data.userType) {
        data.isNextToCeoInManagerialSetup = true;
        // }
        const branchAddresses = data.contactDetails.addresses;
        for (let i = 0; i < branchAddresses.length; i++) {
            delete branchAddresses[i].addressField;
        }
        if ((data.designation && typeof (data.designation) !== 'object') || !data.designation) {
            data.designation = [data.designation];
        }
        if (action === "update") {
            data = Object.assign(this.data.data, data);
            this.foremanCompanyService.updateForemanUser(data).subscribe(
                (res) => {
                    if (this.data.userType === "DIRECTOR") {
                        this.toasterService.showSuccessToaster(
                            this.translateService.instant(
                                "foremanCompany.new.updateDirector"
                            )
                        );
                    } else if (this.data.userType === "CEO") {
                        this.toasterService.showSuccessToaster(
                            this.translateService.instant(
                                "foremanCompany.new.updateCEO"
                            )
                        );
                    } else if(this.data.userType === 'ADVOCATE') {
                        this.toasterService.showSuccessToaster(
                            this.translateService.instant(
                                "foremanCompany.new.updatedAdvocate"
                            )
                        );
                    } else {
                        this.toasterService.showSuccessToaster(
                            this.translateService.instant(
                                "foremanCompany.new.updateOtherForeman"
                            )
                        );
                    }
                    this.isFormSubmitted = true;
                    this.dialogRef.close(true);
                },
                (fail) => {
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                }
            );
        } else if (this.data.userType === "CEO") {
            // this user is CEO, so call CEO api.
            this.foremanCompanyService.createForemanCEO(data).subscribe(
                (res) => {
                    this.toasterService.showSuccessToaster(
                        this.translateService.instant(
                            "foremanCompany.new.createCEO"
                        )
                    );
                    this.dialogRef.close(true);
                },
                (fail) => {
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                }
            );
        } else if (this.data.userType === "ADMIN") {
            this.foremanCompanyService
                .createAdmin(data, this.data.companyCode)
                .subscribe(
                    (res) => {
                        this.toasterService.showSuccessToaster(
                            this.translateService.instant(
                                "foremanCompany.new.createAdmin"
                            )
                        );
                        this.dialogRef.close(true);
                    },
                    (fail) => {
                        if (fail) {
                            this.toasterService.showFailToaster(fail);
                        }
                    }
                );
        } else if (this.data.userType === 'AUDITOR') {
            this.foremanCompanyService.createAuditorForCompany(data).subscribe(() => {
                this.dialogRef.close(true);
                this.toasterService.showSuccessToaster(
                    this.translateService.instant('foremanCompany.new.createAuditor'
                    )
                );
            })
        } else {
            // this user is other type of foreman, so call generic api which creates the foreman without login.
            this.foremanCompanyService
                .createForemanUserWithoutLogin(data)
                .subscribe(
                    (res) => {
                        if (this.data.userType === "DIRECTOR") {
                            this.toasterService.showSuccessToaster(
                                this.translateService.instant(
                                    "foremanCompany.new.createDirector"
                                )
                            );
                        } else if(this.data.userType == "ADVOCATE") {
                            this.toasterService.showSuccessToaster(
                                this.translateService.instant(
                                    "foremanCompany.new.createAdvocate"
                                )
                            )
                        } else {
                            this.toasterService.showSuccessToaster(
                                this.translateService.instant(
                                    "foremanCompany.new.createOtherForeman"
                                )
                            );
                        }
                        this.dialogRef.close(true);
                    },
                    (fail) => {
                        if (fail) {
                            this.toasterService.showFailToaster(fail);
                        }
                    }
                );
        }
        
    }

    saveBankerAndAuditor(bankAndAuditors, event: Event, action) {
        event.preventDefault();
        if (!bankAndAuditors.valid) {
            console.log(bankAndAuditors.valid);
            if (!this.bankAndAuditors.controls.address['controls'].addressField.value) {
                this.bankAndAuditors.controls.address['controls'].addressField.markAsTouched();
            }
            return false;
        }
        let data = bankAndAuditors.getRawValue();
        console.log("add foreman",data);
        delete data.address.addressField;

        if (!data.bankAccountUsageType || !data.accountType) {
            console.log('usage type is empty');
            delete data.bankAccountUsageType;
            delete data.accountType;
        }

        data.companyCode = this.data.companyCode;
        if (this.data.userType === 'BANKER') {
            this.isFormSubmitted = true;
            if (action === 'update') {
                data = Object.assign(this.data.data, data);
                this.foremanCompanyService.updateBankForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateBank'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            } else {
                this.foremanCompanyService.createBankForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createBank'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        } else if (this.data.userType === 'AUDITOR') {
            this.isFormSubmitted = true;
            if (action === 'update') {
                data = Object.assign(this.data.data, data);
                let auditorDetails = lodash.pick(data, ['id', 'name','address','foremanCompanyBranchRefId','companyCode']);
                this.foremanCompanyService.updateAuditorForCompany(auditorDetails).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateAuditor'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    if (fail) {
                        this.isFormSubmitted = false;
                        this.toasterService.showFailToaster(fail);
                    }
                });
            } else {
                let auditorDetails = lodash.pick(data, ['name','address'])
                this.foremanCompanyService.createAuditorForCompany(auditorDetails).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createAuditor'));
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    if (fail) {
                        this.isFormSubmitted = false;
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        }
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

    getForemanBranchCodes() {
        console.log(this.data.companyCode);
        if(USER_TYPES.IS_FOREMAN) {
            this.foremanCompanyService.getForemanCompanyMyOwnedBranches(this.data.companyCode, { 'size': 500 }).subscribe(res => {
                this.foremanBranches = res['body'];
            });
        }
    }

}

