import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import { FileUploadSettings } from './file-upload-settings.model';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { LoaderService } from '../../loader.service';
import * as XLSX from 'xlsx';
type AOA = Array<Array<any>>;

import { ReportService } from '../../services/report.service';
import { ReportRequest } from '../../models/report-request.model';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-snb-file-read',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.scss']
})

export class FileReadComponent {
    @Input() public fileSettings: FileUploadSettings;
    public uploader: FileUploader;
    formattedData: any;
    data: AOA = [];
    tmp: AOA = [];
    csv: string;
    isResult: boolean = false;

    @Output() emitFileData: EventEmitter<any> = new EventEmitter();
    @Output() emitFile: EventEmitter<any> = new EventEmitter();
    @Output() emitJSONandCSV: EventEmitter<any> = new EventEmitter();
    constructor(
    ) {
        //let options: FileUploaderOptions = {};
        //this.uploader = new FileUploader(options);
    }

    public onFileChange(evt: any) {
        const scope = this;
        const fileRarget = evt.target || evt.srcElement;
        /* wire up file reader */
        const target: DataTransfer = (<DataTransfer>(evt.target));
        const reader = new FileReader();
        reader.onload = function (e: any) {
            /* read workbook */
            const bstr = e.target.result;
            let wbl;
            if (scope.fileSettings && scope.fileSettings.typeOfUpload && scope.fileSettings.typeOfUpload === 'dues') {
                wbl = XLSX.read(bstr, { type: 'binary', dateNF: 'mm-dd' });
            } else {
                wbl = XLSX.read(bstr, { type: 'binary' });
            }
            const wb = wbl;
            /* grab first sheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            if ((<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }))).length <= scope.fileSettings.maximunRows) {
                /* save data to scope */
                scope.data = (<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 })));
                scope.formatData(scope.data);
                fileRarget.value = '';
                scope.tmp = (<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 })));
                scope.csv = bstr;
                scope.convertToBinaryString();

            } else {
                scope.uploader.clearQueue();
            }
        };

        reader.readAsBinaryString(target.files[0]);
    }

    formatData(data) {
        this.formattedData = data;
        this.emitFileData.emit(this.formattedData);
    }

    formatFile(file) {
        this.emitFile.emit(file);
        this.returnJSONandCSVdata(file);
    }

    returnJSONandCSVdata(file) {
        this.emitJSONandCSV.emit({ json: this.data, csv: file });
    }

    convertToBinaryString() {

        const ws = XLSX.utils.aoa_to_sheet(this.tmp, { cellDates: true });
        const csv = XLSX.utils.sheet_to_csv(ws);
        this.csv = csv;
        this.formatFile(csv);
    }
}

@Component({
    selector: 'app-snb-file-item',
    template: `<div class="file-item">{{ hideDescr? '': fileData?.description}}
              <i (click)="viewFile(fileData)" matTooltipPosition="above" matTooltip="View Document"
              class="fa fa-eye" aria-hidden="true"></i>
              </div>`,
    styleUrls: ['./file-upload.scss']
})

export class SNBFileItemComponent implements OnInit {
    @Input() fileData: any;
    @Input() hideDescr: false;
    snb_file_cache: any = [];

    constructor(
        public fileUploaderService: FileUploadService,
        public loaderService: LoaderService
    ) { }

    ngOnInit(): void { }

    viewFile(data) {
        console.log("The whle data is in the form: ", data);
        if (!localStorage.getItem("snb_file_cache")) {
            const snb_file_cache = {};
            localStorage.setItem("snb_file_cache", JSON.stringify(snb_file_cache));
        }

        this.loaderService.display(true);
        const uniqident = data.id;
        let retrievedArray = JSON.parse(localStorage.getItem("snb_file_cache"));
        let targetValue = retrievedArray[data.id];

        if (targetValue) {

            // key is present in local storage
            window.open(targetValue);
            this.loaderService.display(false);

        } else {

            // key is not present in local storage
            this.fileUploaderService.viewFile(data.id).subscribe(res => {
                const blobData: any = res.body;
                console.log("The blob item received from the response is: ", res);
                this.loaderService.display(false);
                const blob = new Blob([blobData], { type: res.headers.get('content-type') });
                const url = window.URL.createObjectURL(blob);
                retrievedArray[data.id] = url;
                localStorage.setItem("snb_file_cache", JSON.stringify(retrievedArray));

                const pwa = window.open(url);

                if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
                    alert('Please disable your Pop-up blocker and try again.');
                }
            }, fail => {
                console.log(fail);
                this.loaderService.display(false);
            });
        }
    }
}


@Component({
    selector: 'app-snb-file-preview',
    template: `<i style="padding: 10px 10px 10px 20px; cursor: pointer; color: green;"
             (click)="viewFile(fileData)" matTooltipPosition="above" matTooltip="Preview Document"
              class="fa fa-eye" aria-hidden="true" *ngIf="fileData"></i>`,
    styleUrls: ['./file-upload.scss']
})

export class SNBFilePreviewComponent {
    @Input() fileData: any;

    constructor(
        public fileUploaderService: FileUploadService,
        public loaderService: LoaderService
    ) { }

    viewFile(data) {
        this.loaderService.display(true);
        this.fileUploaderService.viewFile(data.id).subscribe(res => {
            const blobData: any = res.body;
            this.loaderService.display(false);
            const blob = new Blob([blobData], { type: res.headers.get('content-type') });
            const url = window.URL.createObjectURL(blob);
            const pwa = window.open(url);

            if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
                alert('Please disable your Pop-up blocker and try again.');
            }
        }, fail => {
            console.log(fail);
            this.loaderService.display(false);
        });
    }
}

@Component({
    selector: 'app-download-report',
    templateUrl: './file-download-report.html',
    styleUrls: ['./file-upload.scss']
})
export class SNBDownloadReportComponent implements OnDestroy {
    @Input() reportData: any;
    @Input() reportName: any;
    @Input() reportType: any;
    @Input() reportDescription: any;
    @Input() iconType: any;

    subscriptionList: Subscription = new Subscription();
    isRequestInProgress: boolean;

    constructor(
        public fileUploaderService: FileUploadService,
        public loaderService: LoaderService,
        public reportService: ReportService,
        public dialog: MatDialog,
    ) { }

    downloadReport() {
        this.loaderService.display(true);
        this.isRequestInProgress = true;
        let request: ReportRequest = {};
        request.downloadFormat = this.reportType;
        request.additionalDetails = { ...this.reportData };
        request.companyCode = this.reportData.companyCode;

        const sub: Subscription = this.reportService.downloadStaticReportByReportRequest(this.reportName, request).subscribe(res => {
            const blobData: any = res.body;
            const blob = new Blob([blobData], { type: res.headers.get('content-type') });
            const fileUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = this.reportName + '.' + this.reportType.toLowerCase();
            link.href = fileUrl;
            document.body.appendChild(link);
            link.click();
            this.loaderService.display(false);
            this.isRequestInProgress = false;
        }, fail => {
            this.loaderService.display(false);
            console.error(fail);
            this.isRequestInProgress = false;
        });
        this.subscriptionList.add(sub);

        /*
        this.fileUploaderService.downloadReport(this.reportName, this.reportType,
            { 'query': this.reportData }).subscribe(res => {
                const blobData: any = res.body;
                this.loaderService.display(false);
                const blob = new Blob([blobData], { type: res.headers.get('content-type') });
                const fileUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = this.reportName + '.' + this.reportType.toLowerCase();
                link.href = fileUrl;
                document.body.appendChild(link);
                link.click();
            }, fail => {
                this.loaderService.display(false);
                console.log(fail);
            });
            */
    }

    ngOnDestroy(): void {
        this.subscriptionList.unsubscribe();
    }
}
