
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventManager } from './event-manager.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private eventManager: EventManager) {
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
        tap({
            error: (err: HttpErrorResponse) => {
                if (!(err.status === 401 && (err.message === '' || err.url?.indexOf('account') === 0))) {
                    this.eventManager.broadcast({name: 'chitmonksApiApp.httpError', content: err});
                }
            },
        }),
        );
    }
}
