import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SNB_CORE_URL, SNB_UAA_URL } from '../../app.constants'

@Injectable()
export class AccountService {

    constructor(
        private httpClient : HttpClient,
        ) {
        }

    get(): Observable<any> {
        return this.httpClient.get(SNB_UAA_URL + '/account');
    }

    save(account: any): Observable<any> {
        return this.httpClient.post(SNB_UAA_URL + '/account', account);
    }

    generateOTPForReg(account: any): Observable<any> {
        let url: string = SNB_UAA_URL + '/register/foreman/reg_otp_generate?';
        if (account.login) {
            url = url + 'login=' + account.login;
        }
        // if (account.firstName) {
        //     url = url + '&firstName=' + account.login;
        // }
        return this.httpClient.get(url);
    }

    generateOTPForPayment(account: any): Observable<any> {
        let url: string = SNB_CORE_URL + '/account/pay_otp_generate?';
        if (account.username) {
            url = url + 'firstName=' + account.username;
        }
        if (account.mobile) {
            url = url + '&login=' + account.mobile;
        }
        if (account.client_id) {
            url = url + '&client_id=' + account.client_id;
        }
        // if (account.firstName) {
        //     url = url + '&firstName=' + account.login;
        // }
        return this.httpClient.get(url);
    }


    registerForeman(foreman: any): Observable<any> {
        let url: string = SNB_UAA_URL + '/register/foreman';
        return this.httpClient.post(url, foreman);
    }

    getForeman(): Observable<any> {
        return this.httpClient.get(SNB_CORE_URL + '/foreman/foremen/get-details');
    }

    getRegulator(): Observable<any> {
        return this.httpClient.get(SNB_CORE_URL + '/regulator/regulators/get-details');
    }

}
