<app-organisation-add-actions [companyCode]="foremanCompanyId"
  [companyCIN]="foremanCompanyData?.cin" [foremanCompanyName]="foremanCompanyData?.name" #orgAddActions>
</app-organisation-add-actions>
<div class="row form-top-section">
  <div class="snb_content_section card">
      <div class="fadein animated">
        <mat-card-content>
          <mat-tab-group class="demo-tab-group" #tabGroup (selectedTabChange)="tabChanged($event, orgAddActions)">
            <mat-tab label="Organisation Details">
              <div class="tab-content">
                <app-foreman-organisation class="row" [companyCode]="foremanCompanyId" *ngIf="foremanCompanyId"></app-foreman-organisation>
              </div>
            </mat-tab>
            <mat-tab label="Branches">
              <div>
                <app-organisation-branches [companyCode]="foremanCompanyId" *ngIf="tabGroup.selectedIndex === 1" #branches></app-organisation-branches>
              </div>
            </mat-tab>
            <mat-tab label="Users">
              <div class="financial-data-for-roc">
                <app-foreman-users [companyCode]="foremanCompanyId"  *ngIf="tabGroup.selectedIndex === 2" #foreman></app-foreman-users>
              </div>
            </mat-tab>
            <mat-tab label="Bank Details">
              <div class="financial-data-for-roc">
                <app-organisation-bank [companyCode]="foremanCompanyId" *ngIf="tabGroup.selectedIndex === 3" #bank></app-organisation-bank>
              </div>
            </mat-tab>
            <mat-tab label="Auditor Details">
              <div class="financial-data-for-roc">
                <app-organisation-auditor [companyCode]="foremanCompanyId" *ngIf="tabGroup.selectedIndex === 4" #auditor></app-organisation-auditor>
              </div>
            </mat-tab>
            <mat-tab label="Financial Details">
              <div class="financial-data-for-roc">
                <app-foreman-org-financial-data [companyCode]="foremanCompanyId" *ngIf="tabGroup.selectedIndex === 5"></app-foreman-org-financial-data>
              </div>
            </mat-tab>
            <mat-tab label="ATO's">
              <div>
                <app-atos-data [companyCode]="foremanCompanyId" *ngIf="tabGroup.selectedIndex === 6"></app-atos-data>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </div>
  </div>
</div>