const enum AmountUnit {
    'RS',
    'USD'
}
export class TransactionItem {
    constructor(
        public id?: number,
        public amount?: number,
        public amountUnit?: AmountUnit,
        public appTrnxRefId?: string,
        public description?: string,
        public trnxItemAddlRef1?: string, // store userid in this
        public trnxItemAddlRef2?: string, // store the foreman branch code.
        public trnxItemAddlRef3?: string, // work flow process instance id
        public trnxItemAddlRef4?: string, // chit ref id
        public trnxItemAddlRef5?: string,
        public trnxItemAddlRef6?: string,
        public trnxItemAddlRef7?: string,
        public trnxItemAddlRef8?: number
    ) {
    }
}
