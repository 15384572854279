<div class="Upload_fields" *ngIf="iconType === 'doc_download_icon'">
  <div class="upload-body" matTooltipPosition="above" matTooltip="Download existing vouchers">
    <div class="box" (click)="downloadReport()">
      <div class="uploader-wrapper">
        <div class="document-container download-vouchers-wrapper ">
          <img class="bounce animated center-block" src="assets/img/download-file.svg" />
          <p>Download existing vouchers</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="downloadReport()" matTooltip="{{reportDescription}}" *ngIf="iconType == null || iconType === 'button'" [ngClass]="{ 'disabled': isRequestInProgress }">
  Download <i class="fa fa-download" aria-hidden="true"></i>
</div>