
import { Observable, of as observableOf } from 'rxjs';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventManager } from './../../blocks/interceptor/event-manager.service';
import { LoginService } from '../login/login.service';
import { StateStorageService } from '../auth/state-storage.service';
import { SnBToasterService } from '../components/toaster/toaster.service';
import { USER_TYPES } from '../../app.constants';
import { AccountService } from '../auth/account.service';
import { Principal } from '../auth/principal.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../loader.service';

@Component({
    templateUrl: './login.html',
    styleUrls: ['./login.scss'],
})
export class JhiLoginModalComponent implements OnInit {
    authenticationError: boolean;
    password: string;
    rememberMe: boolean;
    username: string;
    intialScreenAnimationClass = false;
    public loginForm: FormGroup;
    isRocApp = false;
    isForemanApp = false;
    errDesc: any;
    ismfaEnabled = false;
    isRecoveryCodes = false;
    validSessionExists = false;
    checkedForValidSession = false;
    isRequestInProgress = false;

    constructor(
        private fb: FormBuilder,
        private eventManager: EventManager,
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private elementRef: ElementRef,
        private router: Router,
        private toasterService: SnBToasterService,
        private principal: Principal,
        private account: AccountService,
        private $localStorage: LocalStorageService,
        private httpClient: HttpClient,
        private sessionStorage: SessionStorageService,
        private loadingService: LoaderService
    ) {
        this.isRocApp = USER_TYPES.IS_REGULATOR;
        this.isForemanApp = USER_TYPES.IS_FOREMAN;
        console.log('Loading login component')
        console.log('USER_TYPES.IS_FOREMAN ' + USER_TYPES.IS_FOREMAN);
        console.log('USER_TYPES.IS_REGULATOR ' + USER_TYPES.IS_REGULATOR);
    }

    ngOnInit() {
        setTimeout(() => {
            this.intialScreenAnimationClass = true;
        }, 400);
        this.loadingService.display(true);
        this.checkValidSessionExists().subscribe(validSessionExists => {
            this.validSessionExists = validSessionExists;
            if (validSessionExists) {
                this.loadingService.displayMessage("There is an existing session. Redirecting to home page...");
                setTimeout(() => {
                    //wait for 2 seconds and redict to home page.
                    this.loadingService.display(false);
                    this.router.navigate(['']);
                }, 2000);
                return;
            } else {

                this.loginForm = this.fb.group({
                    username: ['', Validators.required],
                    password: ['', Validators.required],
                    generatedToken: [''],
                    recoveryCode: ['']
                });
                this.checkedForValidSession = true;
                this.loadingService.display(false);
                const currentVersion = localStorage.getItem('currentVersion');
                this.httpClient.get('./assets/js/manifest.json').subscribe(data => {
                    const manifestData = data;
                    if (manifestData && manifestData["version"] && manifestData["build_number"]) {
                        const buildVersion = manifestData["version"] + '.' + manifestData["build_number"];
                        if (currentVersion && buildVersion !== currentVersion) {
                            localStorage.setItem('currentVersion', buildVersion);
                            console.log('Version upgraded to ' + buildVersion + '. Reloading the client application');
                            location.reload();
                        } else {
                            localStorage.setItem('currentVersion', buildVersion);
                        }
                    }
                });
            }
        });
    }

    checkValidSessionExists(): Observable<boolean> {
        const token = this.$localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

        if (token && token.expires_at && token.expires_at > new Date().getTime()) {
            // looks like valid session still exists.
            this.toasterService.showSuccessToaster('There is an active session exists, redirecting to home page');
            return observableOf(true);
        }

        return observableOf(false);
    }

    login(loginData, event: Event) {

        event.preventDefault();
        if (!loginData.valid) {
            return false;
        }
        this.isRequestInProgress = true;

        console.log('login button clicked with form data', loginData.value)
        this.loginService.login(loginData.value).then(() => {
            this.authenticationError = false;
            this.isRequestInProgress = false;
            if (this.router.url === '/register' || this.router.url === '/activate' ||
                this.router.url === '/finishReset' || this.router.url === '/requestReset' ||
                this.router.url === '/login') {
                if (USER_TYPES.IS_REGULATOR) {
                    this.account.getRegulator().toPromise().then(regulatorAccount => {
                        // regulator accont exits with core, so allow the access.
                        this.principal.updateIdentity(regulatorAccount);
                        this.router.navigate(['']);
                    }).catch(err => {
                        this.principal.removeIdentity();
                        this.router.navigate(['login']);
                        this.$localStorage.clear('authenticationToken');
                        this.$localStorage.clear('access');
                        if (err && err['_body'] && JSON.parse(err['_body']).detail) {
                            this.toasterService.showFailToaster(JSON.parse(err['_body']).detail);
                        }
                        return false;
                    });
                } else {
                    this.account.getForeman().toPromise().then(foremanAccount => {
                        // foreman accont exits with core, so allow the access.
                        this.principal.updateIdentity(foremanAccount);
                        this.router.navigate(['']);
                    }).catch(err => {
                        this.principal.removeIdentity();
                        this.router.navigate(['login']);
                        this.$localStorage.clear('authenticationToken');
                        this.$localStorage.clear('access');
                        if (err && err['_body'] && JSON.parse(err['_body']).detail) {
                            this.toasterService.showFailToaster(JSON.parse(err['_body']).detail);
                        }
                        return false;
                    });
                }
            }
            this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });
            const previousState = this.stateStorageService.getPreviousState();
            if (previousState) {
                this.stateStorageService.resetPreviousState();
                this.router.navigate([previousState.name], { queryParams: previousState.params });
            }
        }).catch((res: any) => {
            this.authenticationError = true;
            
            if (res && res.error) {
                let httpErrorResp = res as any
                let error = httpErrorResp.error;
                if(error) {
                    this.errDesc = error.detail;
                    if(this.errDesc === "Two Factor Authentication code required"){
                        this.ismfaEnabled=true;
                    } else if(this.errDesc === "Bad credentials"){
                        this.toasterService.showFailToaster(this.errDesc);
                    }
                    this.toasterService.showFailToaster(this.errDesc);
                } else {
                    this.toasterService.showErrorMessage("Login failed. Contact support", '', true);
                }
            } else if(res instanceof HttpErrorResponse || res['_body']) {
                if(res.error) {
                    this.errDesc = res.error.detail;
                } else {
                    let body = JSON.parse(res['_body']);
                    this.errDesc = body.error_description
                    if (!this.errDesc) {
                        this.errDesc = body.detail;
                    }
                }
                if (this.errDesc === "Two Factor Authentication code required") {
                    this.ismfaEnabled = true;
                } else if (this.errDesc === "Bad credentials") {
                    this.toasterService.showFailToaster(this.errDesc);
                }
                this.toasterService.showFailToaster(this.errDesc);
            } else {
                // we got the response as body directly
                if (res && res.message === 'invalid_nonce') {
                    // invalid nonce, redirect to login page.
                    this.errDesc = res.error_description;
                    this.toasterService.showErrorMessage("Login session invalid. Login again", '', true);
                    this.loginService.logout();
                    this.router.navigate(['/login', 'request'])
                } else if (res && res?.error?.detail === 'Bad credentials') {
                   this.toasterService.showFailToaster(res.error.detail);
                } else if (res && res.detail === 'Two Factor Authentication code required') {
                    this.ismfaEnabled = true;
                } else {
                   this.toasterService.showErrorMessage("Login failed. Contact support", '', true);
                }
            }
            this.isRequestInProgress = false;
            console.log('Login failed:', this.errDesc); // Add this line to log the error
        });
    }
    requestResetPassword() {
        this.router.navigate(['/reset', 'request']);
    }
    selectionChange() {
        this.isRecoveryCodes = true;

    }

}
