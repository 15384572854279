<div mat-dialog-content>
    <div>
        <div class="right-col">
            <div class="step-col-content">
                <h3 class="step-col-label">
                    More Details
                </h3>
                <div class="col-wrapper light-bg">
                    <div class="s-col">
                        <span class="property">
                            Bank Name
                        </span>
                        <span class="value s-col-full">
                            {{selectedBankData?.name}}
                        </span>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            Address
                        </span>
                        <div class="value s-col-full" *ngIf="selectedBankData?.address">
                            {{selectedBankData?.address?.addressLine1}},
                            {{selectedBankData?.address?.addressLine2}},
                            {{selectedBankData?.address?.street}},
                            {{selectedBankData?.address?.city}}, {{selectedBankData?.address?.district}},
                            {{selectedBankData?.address?.state}},
                            <div>PIN- {{selectedBankData?.address?.pin}}</div>
                        </div>
                        <div class="value s-col-full" *ngIf="!selectedBankData?.address">
                            --
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="text-align: right;display: block;">
    <div class="row">
        <div class="col-md-12">
            <button mat-button (click)="onNoClick()" class="snb-raised-button">Ok</button>
        </div>
    </div>
</div>