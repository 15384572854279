import { Component, Input } from '@angular/core';

@Component({
    selector: 'right-panel',
    templateUrl: './right-panel.component.html',
    styleUrls: ['./right-panel.scss'],
})
export class RightPanelComponent {

    @Input() expandRightBlock = false;

    @Input() hideFullScreenIcon = false;

    @Input('print')
    public printForm: any;

    @Input('showPrintOption')
    public showPrintOption: boolean;

    @Input('printsList')
    public printsList: any;

    screenToggleRight() {
        this.expandRightBlock = !this.expandRightBlock;
    }

    ngOnInit() { }
}
