import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { ApplicationsService } from '../applications.service';
import { SnBToasterService } from '../../../shared/components/toaster/toaster.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-delete-application-dialog',
    templateUrl: './delete-application-dialog.component.html',
    styleUrls: ['./delete-application-dialog.scss']
})

export class DeleteApplicationDialogComponent implements OnInit {
    reasonFormWrapper = false;
    cancelApplicationForm: FormGroup;
    reasonsList = [
        { value: 'APPLIED_BY_MISTAKE', viewValue: 'Application applied by mistake' },
        { value: 'APPLIED_WITH_WRONG_DETAILS', viewValue: 'Application applied with wrong details' },
        { value: 'NO_LONGER_NEEDED', viewValue: 'Application no longer needed' },
        { value: 'OTHER', viewValue: 'Other' }
    ];
    constructor(
        private fb: FormBuilder,
        
        private applicationsService: ApplicationsService,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<DeleteApplicationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        
        
    }

    ngOnInit() {
        this.cancelApplicationForm = this.fb.group({
            reason: ['', [Validators.required]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    changeCancelService() {
        if (this.cancelApplicationForm.value.reason === 'OTHER') {
            this.cancelApplicationForm.addControl('deleteReason', new UntypedFormControl('', Validators.required));
        } else {
            this.cancelApplicationForm.removeControl('deleteReason');
        }
    }

    showReasonArea() {
        this.reasonFormWrapper = true;
    }

    cancelApplicaiton(event: Event) {
        event.preventDefault();
        if (this.cancelApplicationForm.invalid) {
            return false;
        }
        const cancelFormData = this.cancelApplicationForm.value;
        const deleteReasonData = {
            deleteReason: ''
        };
        if (cancelFormData.reason === 'OTHER') {
            deleteReasonData.deleteReason = cancelFormData.deleteReason;
        } else {
            let tmp = {};
            this.reasonsList.forEach(element => {
                if (element.value === cancelFormData.reason) {
                    tmp = element;
                }
            });
            deleteReasonData.deleteReason = tmp['viewValue'];
        }
        this.applicationsService.cancelApplication(this.data.processInstanceId, deleteReasonData).subscribe(() => {
            this.toasterService.showSuccessToaster(this.translateService.instant('serviceRequest.cancelRequestProcess'));
            this.dialogRef.close(true);
        });
    }
}
