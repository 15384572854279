<form [formGroup]="inputFieldForm" autocomplete="off">
  <mat-form-field appearance="outline">
    <mat-label>{{searchCriteria.name}}</mat-label>
    <mat-icon matPrefix>short_text</mat-icon>
    <input type="text" aria-label="Number" matInput [matAutocomplete]="auto"
      formControlName="inputField" required [value]="chitAutoCompleteSelectedValue?.name">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="emitFilteredValue($event)" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filterItems" [value]="option">
        {{!option.name ? option : option?.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="inputFieldForm.controls.inputField.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
  </mat-form-field>
</form>