import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'keyValue',
    pure: false
})
export class IterateObjKeyValuesPipe implements PipeTransform {
    transform(value: any, args?: any): Array<{ key: string; value: any }> {
        if (!value || typeof value !== 'object') {
            return [];
        }
        return Object.keys(value).map(key => ({ key, value: value[key] }));
    }
}