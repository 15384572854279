<div class="snb_login">
    <div class="change_pw">
        <div class="form">
            <div class="form_field" style="background: white;">                 
                <div class="icon-block">
                    <img src="https://img.icons8.com/ios/50/2E9AFE/lock-2.png"/>
                  </div>  
                  <span>
                    Enter the 6 digts Code from your Authenticator App
                  </span>  
                  <div class="my-4">
                    <div class="otp-container">
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[0]" (input)="onOtpChange($event)" />
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[1]" (input)="onOtpChange($event)" />
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[2]" (input)="onOtpChange($event)" />
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[3]" (input)="onOtpChange($event)" />
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[4]" (input)="onOtpChange($event)" />
                        <input type="tel" maxlength="1" class="otp-input" [(ngModel)]="otp[5]" (input)="onOtpChange($event)" />
                      </div>  
                  </div>    
                  <!-- <div style="align-items: center; justify-content: center; display: flex;">
                    <button class="btn btn-lg w-100 mt-3 mb-4 py-3"  [ngClass]="btnStatus">{{ inputDigitLeft }}</button> 

                  </div> -->
                      
                </div>
            </div>
           
        </div>
     
        
        
    </div>
