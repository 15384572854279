import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

@Component({
    selector: 'cm-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    inputs: ['controlName: control-name']
})
export class CmDatePickerComponent implements OnInit {
    public form: FormGroup;
    @Input()('init')
    controlName: any;
    constructor(private fb: FormBuilder) {
        this.form = new FormGroup({})
    }

    ngOnInit() {
        this.form.addControl(this.controlName, this.fb.control(undefined))
    }
}
