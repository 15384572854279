import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { USER_TYPES } from '../../app.constants';
import { EventManager } from './../../blocks/interceptor/event-manager.service';
import { AccountService } from './account.service';

@Injectable()
export class Principal {
    private _identity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(
        private account: AccountService,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private eventManager: EventManager
    ) { }

    authenticate(_identity) {
        this._identity = _identity;
        this.authenticated = _identity !== null;
    }

    hasAnyAuthority(authorities) {
        if (!this.authenticated || !this._identity || !this._identity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this._identity.authorities.indexOf(authorities[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority): Promise<any> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(id => {
            return id.authorities && id.authorities.indexOf(authority) !== -1;
        }, () => {
            return false;
        });
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this._identity = undefined;
        }

        // check and see if we have retrieved the _identity data from the server.
        // if we have, reuse it by immediately resolving
        if (this._identity) {
            return Promise.resolve(this._identity);
        }

        if (USER_TYPES.IS_USER || !this.localStorage.retrieve('authenticationToken')) {
            return Promise.resolve(this._identity);
        }

        // retrieve the _identity data from the server, update the _identity object, and then resolve.
        return this.account.get().toPromise().then(account => {
            if (account) {
                this._identity = account;
                this.authenticated = true;
                // USER_TYPES.IS_REGULATOR = false;
                // USER_TYPES.IS_FOREMAN = false;
                // // Here we'll set the User types based on the login user
                if (!USER_TYPES.IS_REGULATOR && !USER_TYPES.IS_FOREMAN) {
                    if (this.hasAnyAuthority(['ROLE_CIG',
                        'ROLE_AIG', 'ROLE_JIG', 'ROLE_DIG', 'ROLE_SCS', 'ROLE_DR', 'ROLE_ARC', 'ROLE_REGULATOR_SUPER_ADMIN'])) {
                        USER_TYPES.IS_REGULATOR = true;
                    } else {
                        USER_TYPES.IS_FOREMAN = true;
                    }
                }
            } else {
                this._identity = null;
                this.authenticated = false;
            }
            this.authenticationState.next(this._identity);
            return this._identity;
        }).catch(err => {
            this._identity = null;
            this.authenticated = false;
            this.eventManager.broadcast({ name: 'chitmonksApiApp.httpError', content: err });

            if (err.status === 403) {
                this.localStorage.clear('authenticationToken');
                this.localStorage.clear('access');
            }

            this.authenticationState.next(this._identity);
            return null;
        });
    }
    updateIdentity(accountFromCore: any) {
        this._identity.firstName = accountFromCore.firstName;
        this._identity.lastName = accountFromCore.lastName;
        this._identity.mobileNumber = accountFromCore.mobileNumber;
        this._identity.email = accountFromCore.email;
    }

    removeIdentity() {
        this._identity = null;
        this.authenticated = false;
        this.authenticationState.next(this._identity);
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this._identity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): String {
        return this.isIdentityResolved() ? this._identity.imageUrl : null;
    }

    /**
     * This method checks whether the logged in user has given privileges or not.
     * @param privileges holds privileges to be checked.
     */
    hasPrivilege(privileges) {
        // Privilges are stored in JWT token, so get it and check it.
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

        if (token && token.expires_at && token.expires_at < new Date().getTime()) {
            // session has expired.
            return false;
        }

        if (token && token.privileges) {
            for (let i = 0; i < privileges.length; i++) {
                if (token.privileges.indexOf(privileges[i]) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }

    getAuthorities() {
        // Authorities are stored in JWT token, so get it and check it.
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if (token && token.expires_at && token.expires_at < new Date().getTime()) {
            // session has expired.
            return false;
        }
        // auth = JSON.parse(atob(auth.access_token.toString().split('.')[1]));
        const authorities = JSON.parse(atob(token.split('.')[1])).authorities;
        return authorities;
    }

    isForeman(): boolean {
        return USER_TYPES.IS_FOREMAN;
    }

    isRoc(): boolean {
        return USER_TYPES.IS_REGULATOR;
    }

}
