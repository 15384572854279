
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SNB_CORE_URL, SNB_UAA_URL, USER_TYPES } from '../../app.constants';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { T } from "@angular/core/src/render3";

@Injectable()
export class AuthServerProvider {
    constructor(
        // private base64: Base64,
        private $localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private httpClient: HttpClient,
        // private httpUtilService: HttpUtilService,
    ) { }

    getToken() {
        return this.$localStorage.retrieve("authenticationToken");
    }

    /**
     * Calls the token request towards UAA (OAUTH2 Authorisation Server).
     */

    private mapToLoginRequest(credentials): any {
        let login_as = "";
        if (USER_TYPES.IS_REGULATOR) {
            login_as = "REGULATOR";
        } else if (USER_TYPES.IS_FOREMAN) {
            login_as = "FOREMAN";
        } else if (USER_TYPES.IS_USER) {
            login_as = "PUBLIC_USER";
        }
        const loginRequest = new HttpParams()
            .set("username", credentials.username)
            .set("password", credentials.password)
            .set("generatedToken", credentials.generatedToken)
            .set("recoveryCode", credentials.recoveryCode)
            .set("grant_type", "password")
            .set("scope", "read write")
            .set("nonce", credentials.nonce)
            .set("login_as", login_as);

        return loginRequest;
    }

    login(credentials): Observable<any> {
        const request: HttpParams = this.mapToLoginRequest(credentials);
        let headersData = new HttpHeaders()
            .set("Content-Type", "application/x-www-form-urlencoded")
            .set("Accept", "application/json")
            .set("Authorization", "Basic " + btoa("snbchain_web_client" + ":" + "swcdKStniITYNe00GHmcgLtlynCv"));

        return this.httpClient
            .post(SNB_UAA_URL + "/oauth2/token", request.toString(), {
                headers: headersData,
            })
            .pipe(
                map(authSuccess.bind(this))
            );

        function authSuccess(resp) {
            console.log(
                "Authentication is Success, token expires in ",
                resp.expires_in
            );
            const response = resp;
            if (!this.verifyNonceFromResponse(resp)) {
                throw new Error("invalid_nonce");
            }
            const expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
            response.expires_at = expiredAt.getTime();
            this.$localStorage.store("authenticationToken", response);
            return response;
        }
    }


    /**
     * This method verifies the nonce from the response with the nonce stored in the local storage.
     * @param nonce
     * @param resp
     */
    verifyNonceFromResponse(resp: any): boolean {
        let nonce: string = window.localStorage.getItem("nonce");
        let data = resp;
        const jwtTokenData = JSON.parse(
            atob(data.access_token.toString().split(".")[1])
        );
        if (jwtTokenData.nonce === nonce) {
            // nonce matched, allow the login.
            console.log("nonce matched");
            return true;
        } else {
            console.log("nonce did not match");
            return false;
        }
    }

    newForemanRegister(token, data): Observable<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Accept: "application/json",
        });
        return this.httpClient.post(SNB_UAA_URL + "/register/" + token, data, {
            headers: headers,
        });
    }

    getForemanRegister(token: any): Observable<any> {
        return this.httpClient.get(
            SNB_UAA_URL + "/account-act-details-by-token/" + token
        );
    }
    getForemanBranchDetails(activationId: any): Observable<any> {
        return this.httpClient.get(
            SNB_CORE_URL + "/foreman/foremen/by-activation-id/" + activationId
        );
    }

    validateOTP(credentials): Observable<any> {
        const client_id = credentials.client_id;
        const client_secret = credentials.client_pw;
        const data =
            "username=" +
            encodeURIComponent(credentials.username) +
            "&otp=" +
            encodeURIComponent(credentials.otp) +
            "&client_secret=" +
            client_secret +
            "&grant_type=otp&scope=read+write&" +
            "&client_id=" +
            client_id + 
            "&login_as=TMP_SUBSCRIBER";
        const headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
        });

        return this.httpClient.post(SNB_UAA_URL + '/oauth2/token', data, {
            headers: headers
        }).pipe(map((resp: any) => {
            const response = resp;
            const expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
            response.expires_at = expiredAt.getTime();
            this.sessionStorage.store("authenticationToken", response);
            return response;
        }));
    }

    logout(): Observable<any> {
        return new Observable((observer) => {
            this.httpClient.post(SNB_UAA_URL + "/logout", {});
            localStorage.removeItem("snb-filters");
            localStorage.removeItem("nonce");
            this.$localStorage.clear("authenticationToken");
            this.$localStorage.clear("access");

            observer.complete();
        });
    }
}
