export class ChitPaymentVoucher {
    id?: string;
    chitRefId?: string;
    voucherNumber?: string;
    foremanCompanyBranchCode?:string;
    regulatorBranchCode?:string;
    appliedMinutesRefId?: string;
    // Subscriber Details
    amountPaidTo?: string;
    amountPaidToTktNumber?: number;
    // Auction details to which this voucher belongs
    auctionHeldDate?: any;
    installmentNumber?: number;
    bidOffer?: number;
    
    // Actual Voucher Details
    prizeMoneyStatus?: PrizeMoneyStatus;
    voucherType?: VoucherType;
    paymentMode?: PaymentMode;
    // Payment reference number.
    // - Cheque number incase of cheque payment
    // - Cash voucher number incase of cash payment
    // - Receipt number incase future installment adjustment
    paymentRefNumber?: string;
    amount?: number; // Amount paid to the subscriber
    paymentDate?: any; // Date on which payment is done.  
    // Bank details in which the money is deposted or from which the money is paid.
    bankId?: string;
    bankName?: string;
    bankAddress?: string;
    acctNumber?: string;
    // this tells how much amount is adjusted towards bid payments and process/verification charges
    adjustedAmount?:number;
    // this tells how much amount is adjusted towards bid payments and process/verification charges
    
    adjustmentReceiptNo?:string;
    // Additiona info on Voucher
    remarks?: string;
    // Scanned copy of payment voucher or receipts.
    attachments?: any;
    category?: VoucherCategory;
    // holds the prize money in the case of PS or amount payable in the case of removed subscriber.
    amountPayable?: number;
   
    // tells whether this Voucher Installment is declared as PS ticket for legacy chit.
    isLegacy?: boolean;
    // client side parameter used in Arbitration Case.
    public annexureNos?: number[];
    chequeDate?: any;
    isVacant?: boolean  

    //Holds the date o which the minutes are filed. Used in the case of Chit Release.
    minutesFiledDate?: any;
    //Tells whether it is no auction or not
    isNoAuction?: boolean;
    //Tells whether this voucher belongs to Re-auctioned Installment or not.
    isReAuction?: boolean;
}


export enum PrizeMoneyStatus {
    MOVED_TO_2_ACCOUNT = "MOVED_TO_2_ACCOUNT",
    DISBURSED = "DISBURSED",
    CHQ_REALISATION_PENDING = "CHQ_REALISATION_PENDING"
}

export enum VoucherType {
    PAID_TO_SUBSCRIBER = "PAID_TO_SUBSCRIBER", //Money paid to the subscriber
    BID_PAYABLE_ADJUSTED = "BID_PAYABLE_ADJUSTED", //ADJUSTED towards future installments of the subscriber
    SELF_PAID = "SELF_PAID", //Paid to company itself, incase of company ticket and there wont be any voucher available
    OTHER = "OTHER", //Unknown voucher type
}

export enum PaymentMode {
    CHEQUE = "CHEQUE", //Money paid through cheque
    CASH = "CASH", //Money paid through cash
    ONLINE_TRANSFER = "ONLINE_TRANSFER", //Online transfer of the money
    OTHER = "OTHER", //Payment mode unknown or not applicable
}

export enum VoucherCategory {
    DNPS_MONEY = "DNPS_MONEY",
    PS_MONEY = "PS_MONEY"
}
