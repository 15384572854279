<mat-card class="transaction_card">
    <app-print-form style="position: relative;z-index: 1;float: right;top: 25px;right: 10px;" [printsList]="printsList"
        *ngIf="transaction?.trnxStatus === 'SUCCESS'">
    </app-print-form>
    <div id="transactionDetailsPreview">
        <div class="snb_form_preview">
            <div class="transaction_title">
                <h4 style="display: inline-block; width: 90%;">Transaction No: {{transaction?.appTrnxRefId}}
                    <span class="payment-done" [ngClass]="transaction?.trnxStatus|lowercase">
                        <i class="material-icons" matTooltip="{{transaction?.trnxStatus|lowercase}}"
                            matTooltipPosition="right" [ngClass]="{ 'ion-ios-alert': transaction?.trnxStatus === 'PENDING' ,
                            'ion-ios-checkmark-circle': transaction?.trnxStatus === 'SUCCESS',
                            'ion-ios-close-circle': transaction?.trnxStatus === 'FAILURE'}"
                        >
                        check_circle
                        </i>
                    </span>
                </h4>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Transaction Details</h4>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="column_wrapper">
                        <div class="s_column">
                            <div class="property">Amount :</div>
                            <div class="value">
                                <span>₹ {{transaction?.amount?.toLocaleString('en-IN')}}</span>
                            </div>
                        </div>
                        <div class="s_column">
                            <div class="property">Remarks :</div>
                            <div class="value">
                                <span>{{transaction?.trnxDesc ? transaction?.trnxDesc: "-"}}</span>
                            </div>
                        </div>
                        <div class="s_column">
                            <div class="property">Status :</div>
                            <div class="value">
                                <span
                                    jhiTranslate="{{'chitmonksApiApp.TransactionStatus.' + transaction?.trnxStatus}}">{{transaction?.trnxStatus}}</span>
                            </div>
                        </div>
                        <div class="s_column">
                            <div class="property">Date :</div>
                            <div class="value">{{transaction?.trnxDate*1000 | date:'dd/MM/yyyy hh:mm a'}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="column_wrapper">
                        <div *ngIf="transaction?.trnxType != 'OFFLINE_PAY'" class="s_column">
                            <div class="property">Payer Name :</div>
                            <div class="value">{{transaction?.subscriberFirstName}} {{transaction?.subscriberLastName}}
                            </div>
                        </div>

                        <div *ngIf="transaction?.trnxType != 'OFFLINE_PAY'" class="s_column">
                            <div class="property">Payment Gateway Provider :</div>
                            <div class="value"><a>{{transaction?.pspType? transaction?.pspType: '-'}}</a></div>
                        </div>

                        <div *ngIf="transaction?.trnxType != 'OFFLINE_PAY'" class="s_column">
                            <div class="property">Payment Gateway Transaction No :</div>
                            <div class="value"><a>{{transaction?.pspTrnxRefId? transaction?.pspTrnxRefId: '-'}}</a>
                            </div>
                        </div>

                        <div class="s_column">
                            <div class="property">Transaction Type :</div>
                            <div *ngIf="transaction?.trnxType == 'PAY'" class="value">
                                ONLINE PAYMENT
                            </div>
                            <div *ngIf="transaction?.trnxType == 'OFFLINE_PAY'" class="value">
                                PAID by CASH
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-12 col-sm-12 col-xs-12">
                    <h4>Other Details</h4>
                    <div class="column_wrapper">
                        <div class="s_column">
                            <div class="property">Chit Group No. :</div>
                            <div class="value">{{transaction?.items ? transaction?.items[0]?.trnxItemAddlRef6 : ''}}
                            </div>
                        </div>
                        <div class="s_column">
                            <div class="property">Regulator Branch Name :</div>
                            <div class="value">{{transaction?.regulatorBranchName}}</div>
                        </div>
                        <div class="s_column">
                            <div class="property">Form Name :</div>
                            <div class="value">{{transaction?.items ? transaction?.items[0]?.trnxItemAddlRef4 : ''}}
                            </div>
                        </div>
                        <div class="text-right btn_action">
                            <button
                                *ngIf="(isRegulatorSuperAdmin || isCustSupport) && (transaction.trnxType !== 'OFFLINE_PAY')"
                                mat-button mat-raised-button (click)="reconcileTransaction(transaction)">
                                <i class="material-icons">refresh</i>
                                <span>Reconcile Transaction</span>
                            </button>

                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="clearfix"></div> -->
            <div class="row" style="margin-top: 30px">
                <div class="col-md-12">
                    <h4>Amount Paid Towards:</h4>

                    <div class="example-container well well-sm">
                        <mat-table #table [dataSource]="otherDetails">

                            <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="chitNo">
                                <mat-header-cell *matHeaderCellDef>Chit No. </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span *ngIf="element?.chitNo">{{element?.chitNo}}</span>
                                    <span *ngIf="!element?.chitNo" style="margin-left: 20px">--</span>
                                </mat-cell>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="BranchName">
                                <mat-header-cell *matHeaderCellDef> Branch Name </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span
                                        *ngIf="element?.foremanCompanyBranchName">{{element?.foremanCompanyBranchName}}</span>
                                    <span *ngIf="!element?.foremanCompanyBranchName" style="margin-left: 20px">--</span>
                                </mat-cell>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="formName">
                                <mat-header-cell *matHeaderCellDef> Form Name </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a target="_blank"  (click)="handleRouting(element?.workFlowProcessInstanceId)">
                                        {{element?.workFlowFormName}} 
                                        <span *ngIf="element?.trnxItemAddlRef5"> - {{element?.trnxItemAddlRef5}} </span>
                                        <span  *ngIf="element?.trnxItemAddlRef7"> - {{element?.trnxItemAddlRef7}} </span>
                                        <i class="fa fa-external-link" aria-hidden="true"></i>
                                    </a>
                                </mat-cell>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="amount">
                                <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element?.amount}} </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </div>
                </div>

                <div style="padding-left: 15px;" *ngIf="transaction?.trnxType !== 'OFFLINE_PAY'">
                    <div class="col-md-12" style="background-color: #f1f7f9; border-radius: 5px;padding: 10px 15px;">

                        <p style="font-weight: 400;color: #6e6e6e;line-height: 25px;margin: 0;">
                            <span>
                                If the money got debited from Foreman account and still Application shows Pending
                                status,
                                click on Reconcile <br />Transaction Button to update the payment status of
                                Application.</span>
                            <span class="recons_btn" (click)="reconcileTransaction(transaction)" style="display: flex;flex-wrap: wrap">
                                Reconcile Transaction
                                <!-- <i class="fa fa-refresh" aria-hidden="true"
                                    [ngClass]="{'fa-spin' : paymentReconcileInProcess}"></i> -->
                                    <mat-icon [class.spin]="paymentReconcileInProcess">refresh</mat-icon>

                            </span>
                        </p>

                    </div>
                </div>
            </div>
        </div>

        <div id="customTransactionPrint" [hidden]="true">
            <div class="print-wrapper snb_form_preview" style="overflow: auto;">
                <div class="print-header" style="position: relative;">
                    <div id="companyLogoPrint" style="width: 60px; position: absolute;"></div>
                    <h1 style="margin: 0 0;text-align: center;font-size: 24px;">Office of the Asst. Registrar of Chits
                    </h1>
                    <h4 style="margin-top: 10px;text-align: center;">{{transaction?.regulatorBranchName}}</h4>
                    <h4 style="margin: 10px 0 0 0;text-align: center">
                        <span style="font-size: 22px;">Payment Reciept</span>
                    </h4>
                    <h4>
                        <span>
                            No. : <b>{{transaction?.appTrnxRefId}}</b>
                        </span>
                    </h4>
                </div>
                <hr>
                <div class="print-body">
                    <h4 style="margin: 15px 0 0;">
                        <span style="font-weight: 500">Chit Fund Company :</span>
                        <span>{{transaction?.foremanCompanyName}}</span>

                    </h4>
                    <h4 style="margin-top: 10px;">
                        <span style="font-weight: 500"> Branch Name :</span>
                        <span
                            *ngIf="transaction?.items?.length">{{transaction?.items[0]?.foremanCompanyBranchName}}</span>
                        <span style="float: right;">Date :
                            {{transaction?.trnxDate*1000 | date:'dd/MM/yyyy hh:mm a'}}</span>
                    </h4>
                </div>

                <div>
                    <div style="display: table; width: 100%;">
                        <div style="background: #f1f1f1; display: table-row; font-weight: bold; text-align: center;">
                            <div
                                style="display: table-cell;border: 1px solid #808080; padding-left: 5px;padding-right: 5px; text-align: left;">
                                <p style="margin: 5px;">Chit No.</p>
                            </div>
                            <div
                                style="display: table-cell;border: 1px solid #808080;padding-left: 5px;padding-right: 5px;text-align: left;">
                                <p style="margin: 5px;">Form Name</p>
                            </div>
                            <div
                                style="display: table-cell;border: 1px solid #808080;padding-left: 5px;padding-right: 5px;text-align: left;">
                                <p style="margin: 5px;">Amount (Rs)</p>
                            </div>
                        </div>
                        <div style="display: table-row;" *ngFor="let row of otherDetails">
                            <div
                                style="display: table-cell; border: 1px solid #808080; padding-left: 5px; padding-right: 5px;text-align: left;">
                                <p style="margin: 5px;">{{row?.chitNo}}</p>
                            </div>
                            <div
                                style="display: table-cell; border: 1px solid #808080; padding-left: 5px; padding-right: 5px;text-align: left;">
                                <p style="margin: 5px;">
                                    {{row?.workFlowFormName}}
                                    <span *ngIf="row?.trnxItemAddlRef5"> - {{row?.trnxItemAddlRef5}}</span>
                                    <span *ngIf="row?.trnxItemAddlRef7"> - {{row?.trnxItemAddlRef7}}</span>
                                </p>
                            </div>
                            <div
                                style="display: table-cell; border: 1px solid #808080; padding-left: 5px; padding-right: 5px;text-align: right;">
                                <p style="margin: 5px;">{{row?.amount}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 30px">
                    <div class="para" *ngIf="psoList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>PSO</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{psoList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{psoList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="agreementList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>AGREEMENT</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{agreementList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{agreementList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="ccbList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>C.C.B.</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{ccbList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{ccbList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="form7List?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>FORM VII</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{form7List?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{form7List?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="minutesList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>MINUTES</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">x {{minutesList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">
                                ₹ {{minutesList?.applicationFee?.toLocaleString('en-IN')}}
                            </p>
                        </div>
                    </div>
                    <div class="para" *ngIf="transferList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>CHIT TRANFER</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{transferList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{transferList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="releaseList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>CHIT RELEASE</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{releaseList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{releaseList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="financialList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>FINANCE DATA</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{financialList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{financialList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="miscList?.count > 0">
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px"><b>MISCELLANEOUS</b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="margin: 5px">X {{miscList?.count}} =</p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p style="text-align: right;margin: 5px">₹ {{miscList?.applicationFee}}</p>
                        </div>
                    </div>
                    <div class="para" *ngIf="transaction?.amount > 0">
                        <div style="width: 33%;float: left;">
                            <p><b></b></p>
                        </div>
                        <div style="width: 33%;float: left;">
                            <p></p>
                        </div>
                        <div style="width: 33%;float: left;border-top: 1px solid #808080">
                            <p style="text-align: right;margin: 5px"><b>Total :
                                </b>₹ {{transaction?.amount?.toLocaleString('en-IN')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <button mat-button class="mat-raised-button center" color="primary" matStepperPrevious><i class="material-icons">save_alt</i>
        Download Receipt</button> -->

    <!-- <button mat-button class="mat-raised-button center" color="primary" matStepperPrevious><i class="material-icons">refresh</i> Reconcile Transaction</button> -->

</mat-card>
