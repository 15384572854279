import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public statusWithMessage: BehaviorSubject<string> = new BehaviorSubject<any>('');

    display(value: boolean) {
        this.status.next(value);
    }

    displayMessage(value: string) {
        this.statusWithMessage.next(value);
    }
}
