import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppCustomPreloader } from './app.routing.loader';

export const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/dashboard' }
];

export const AppRoutingModule: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  // preloadingStrategy: PreloadAllModules
  preloadingStrategy: AppCustomPreloader
});