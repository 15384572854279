export const ApplicationTypes = {
    form1AReplyToForemanTaks: {
        desc: "PSO Application Form",
        form: "Form I",
        clickRoute: "applications/pso/details",
        actionRoute: "applications/pso/details",
        fclass: "form1a",
        type: "PSO",
    },
    psoForemanReplyToROCForNeedMoreInfo: {
        desc: "PSO Application Form > Query from ROC",
        form: "Form I",
        clickRoute: "applications/pso",
        actionRoute: "applications/pso",
        fclass: "form1a",
        type: "PSO",
    },
    form1BPsoIssuanceTask: {
        desc: "PSO Issue Form",
        form: "Form I B",
        clickRoute: "applications/pso/form1b",
        actionRoute: "applications/pso/form1b",
        fclass: "form1b",
        type: "PSO",
    },
    rocReviewOfFormX: {
        desc: "Chit Security Process",
        form: "Form X",
        clickRoute: "applications/pso/form10-details",
        actionRoute: "applications/pso/form10-details",
        fclass: "formX",
        type: "PSO",
    },
    formIIIEnodrsementOfChit: {
        desc: "Chit Agreement Form",
        form: "Form II",
        clickRoute: "applications/agreement/details",
        actionRoute: "applications/agreement/details",
        fclass: "form3",
        type: "CAR",
    },
    chitAgreementforemanReplyToROCForNeedMoreInfo: {
        desc: "Chit Agreement Form > Query from ROC",
        form: "Form II",
        clickRoute: "applications/agreement",
        actionRoute: "applications/agreement",
        fclass: "form3",
        type: "CAR",
    },

    formVICertificateOfCommencement: {
        desc: "Chit Commencement Process",
        form: "Form V",
        clickRoute: "applications/commencement/details",
        actionRoute: "applications/commencement/details",
        fclass: "form5",
        type: "CCB",
    },
    foremanClarifiesWithMoreInfo: {
        desc: "Chit Commencement Process > Query from ROC",
        form: "Form V",
        clickRoute: "applications/commencement",
        actionRoute: "applications/commencement",
        fclass: "form5",
        type: "CCB",
    },
    rocReviewTask: {
        desc: "Filing Chit after first draw",
        form: "Form VII",
        clickRoute: "applications/declaration/details",
        actionRoute: "applications/declaration/details",
        fclass: "form7",
        type: "Form7",
    },
    firstDrawForemanReplyToROCTask: {
        desc: "Filing Chit after first draw > Query from ROC",
        form: "Form VII",
        clickRoute: "applications/declaration",
        actionRoute: "applications/declaration",
        fclass: "form7",
    },
    reviewAuctionMinutes: {
        desc: "Auction Minutes",
        form: "Auction Minutes",
        clickRoute: "applications/minutes/details",
        actionRoute: "applications/minutes/details",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    minutesProvideMoreInfo: {
        desc: "Auction Minutes > Query from ROC",
        form: "Auction Minutes",
        clickRoute: "applications/minutes",
        actionRoute: "applications/minutes",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    reviewAuctionMinutesLegacy: {
        desc: "Legacy Minutes",
        form: "Legacy Minutes",
        clickRoute: "applications/legacy-minutes/details",
        actionRoute: "applications/legacy-minutes/details",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    minutesProvideMoreInfoLegacy: {
        desc: "Legacy Minutes > Query from ROC",
        form: "Legacy Minutes",
        clickRoute: "applications/legacy-minutes",
        actionRoute: "applications/legacy-minutes",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    chitSubstitutionRocReviewTask: {
        desc: "Chit transfer application",
        form: "Form XIII",
        clickRoute: "applications/transfer/details",
        actionRoute: "applications/transfer/details",
        fclass: "form13",
        type: "ChitTransfers",
    },
    chitSubstitutionForemanReplyTask: {
        desc: "Chit transfer application > Query from ROC",
        form: "Form XIII",
        clickRoute: "applications/transfer",
        actionRoute: "applications/transfer",
        fclass: "form13",
        type: "ChitTransfers",
    },
    rocReviewOfReleaseOfSecurityTask: {
        desc: "Chit release",
        form: "Chit Release",
        clickRoute: "applications/release/details",
        actionRoute: "applications/release/details",
        fclass: "release",
        type: "Release",
    },
    releaseSecurity: {
        desc: "Release Security",
        form: "Release Security",
        clickRoute: "applications/release/details",
        actionRoute: "applications/release/details",
        fclass: "release",
        type: "Release",
    },
    foremanCommentsOnReleaseOfSecurityTask: {
        desc: "Chit release > Query from ROC",
        form: "Chit release",
        clickRoute: "applications/release",
        actionRoute: "applications/release",
        fclass: "release",
        type: "Release",
    },
    reviewFinancialData: {
        desc: "Financial Data",
        form: "Financial Data",
        clickRoute: "applications/financial-data/details",
        actionRoute: "applications/financial-data/details",
        fclass: "release",
        type: "Financial Data",
    },
    reviewSeparateAccountDetails: {//review
        desc: "Separate Account Statement",
        form: "Separate Account Statement",
        clickRoute: "applications/separate-account-statement/statement-details",
        actionRoute: "applications/separate-account-statement/statement-details",
        fclass: "release",
        type: "Account",
    },
    sepAcctStmtProvideMoreInfo: {//need more info
        desc: "Separate Account Statement> Query from ROC",
        form: "Separate Account Statement",
        clickRoute: "applications/separate-account-statement/statement",
        actionRoute: "applications/separate-account-statement/statement",
        fclass: "release",
        type: "Account",
    },
    reviewAndExecuteSepAccCorrectionRequest: {
        desc: "Correction Application > Query from ROC",
        form: "Sep A/C Correction",
        clickRoute: "applications/separate-account-statement/statement-details",
        actionRoute: "applications/separate-account-statement/statement-details",
        fclass: "release",
        type: "Account",
    },
    sepAccCorrectionProvideMoreInfo: {
        desc: "Correction Application> Query from ROC",
        form: "Sep A/C Correction",
        clickRoute: "applications/separate-account-statement/statement",
        actionRoute: "applications/separate-account-statement/statement",
        fclass: "release",
        type: "Account",
    },
    correctionRequestProvideMoreInfo: {//need more info
        desc: "Correction Application > Query from ROC",
        form: "Correction Application",
        clickRoute: "applications/correction-application",
        actionRoute: "applications/correction-application",
        fclass: "release",
        type: "Account",
    },
    chitDetailsCorrectionRequestProvideMoreInfo: {//need more info
        desc: "Correction Application > Query from ROC",
        form: "Correction Application",
        clickRoute: "applications/correction-application",
        actionRoute: "applications/correction-application",
        fclass: "release",
        type: "Account",
    },
    financialsCorrectionRequestProvideMoreInfo: {//need more info
        desc: "Correction Application > Query from ROC",
        form: "Correction Application",
        clickRoute: "applications/correction-application",
        actionRoute: "applications/correction-application",
        fclass: "release",
        type: "Account",
    },
    reviewAndExecuteCorrectionRequest:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    reviewAndExecuteFinancialsCorrectionRequest:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    reviewAndExecuteChitDetailsCorrectionRequest:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    reviewInterlocutoryApplication: {//review
        desc: "Interlocutory Application",
        form: "Interlocutory Application",
        clickRoute: "applications/interlocutory-application/details",
        actionRoute: "applications/interlocutory-application/details",
        fclass: "release",
        type: "Account",
    },
    ProvideMoreInfoforIA: {//need more info
        desc: "Interlocutory Application> Query from ROC",
        form: "Interlocutory Application",
        clickRoute: "applications/interlocutory-application",
        actionRoute: "applications/interlocutory-application",
        fclass: "release",
        type: "Account",
    },
    finDataProvideMoreInfo: {
        desc: "Financial Data > Query from ROC",
        form: "Financial Data",
        clickRoute: "applications/financial-data",
        actionRoute: "applications/financial-data",
        fclass: "release",
        type: "Financial Data",
    },
    reviewCompanyDetails: {
        desc: "Foreman Company Request",
        form: "Foreman Company Request",
        clickRoute: "enroll-company/details",
        actionRoute: "enroll-company/details",
        fclass: "release",
        type: "Release",
    },
    reviewCompanyBranchDetails: {
        desc: "Foreman Branch Request",
        form: "Foreman Branch Request",
        clickRoute: "foreman-companies/branch/details",
        actionRoute: "foreman-companies/branch/details",
        fclass: "release",
        type: "Release",
    },
    reviewReauctionDeclaration: {
        desc: "Re Auction Declaration",
        form: "Re Auction Declaration",
        clickRoute: "applications/re-auction",
        actionRoute: "applications/re-auction",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    provideMoreInfoReAuctionDeclaration: {
        desc: "Re Auction Declaration > Query from ROC",
        form: "Re Auction Declaration",
        clickRoute: "applications/re-auction",
        actionRoute: "applications/re-auction",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    reviewForemanChangeRequest: {
        desc: "Change Request",
        form: "Change Request",
        clickRoute: "applications/change-request/details",
        actionRoute: "applications/change-request/details",
        fclass: "form5",
        type: "Change Request",
    },
    reviewUserChangeRequest: {
        desc: "Change Request",
        form: "Change Request",
        clickRoute: "applications/change-request/details",
        actionRoute: "applications/change-request/details",
        fclass: "form5",
        type: "Change Request",
    },
    userChangeProvideMoreInfo: {
        desc: 'Change Request',
        form: 'Change Request',
        clickRoute: 'applications/change-request',
        actionRoute: 'applications/change-request',
        fclass: 'form5',
        type: 'Change Request'
    },
    foremanChangeProvideMoreInfo: {
        desc: "Change Request > Query from ROC",
        form: "Change Request",
        clickRoute: "applications/change-request",
        actionRoute: "applications/change-request",
        fclass: "form5",
        type: "Foreman Change Request",
    },
    CEOChangeProvideMoreInfo: {
        desc: "CEO Change Request > Query from ROC",
        form: "CEO Change Request",
        clickRoute: "applications/change-request",
        actionRoute: "applications/change-request",
        fclass: "form5",
        type: "CEO Change Request",
    },
    directorChangeProvideMoreInfo: {
        desc: "Director Change Request > Query from ROC",
        form: "Director Change Request",
        clickRoute: "applications/change-request",
        actionRoute: "applications/change-request",
        fclass: "form5",
        type: "Director Change Request",
    },
    executeServiceRequest: {
        desc: "Service Request",
        form: "Service Request",
        clickRoute: "applications/service-request/details",
        actionRoute: "applications/service-request/details",
        fclass: "form1a",
        type: "Service Request",
    },
    SubscriberServiceTask: {
        desc: "Service Request - Public",
        form: "Service Request - Public",
        clickRoute: "applications/service-request/details",
        actionRoute: "applications/service-request/details",
        fclass: "form1a",
        type: "Service Request - Public",
    },
    SubscriberCertifiedCopyOfAwardProcess: {
        desc: "Service Request - Public",
        form: "Service Request - Public",
        clickRoute: "applications/service-request/details",
        actionRoute: "applications/service-request/details",
        fclass: "form1a",
        type: "Service Request - Public",
    },
    serviceRequestProvideMoreInfo: {
        desc: "Service Request > Query from ROC",
        form: "Service Request",
        clickRoute: "applications/service-request",
        actionRoute: "applications/service-request",
        fclass: "form1a",
        type: "Service Request",
    },
    reviewBankInformationRequest: {
        desc: "Bank Account Request",
        form: "Bank Account Request",
        clickRoute: "applications/service-request/bank-account-details",
        actionRoute: "applications/service-request/bank-account-details",
        fclass: "form1a",
        type: "Service Request",
    },
    bankInformationProvideMoreInfo: {
        desc: "Bank Account Request",
        form: "Bank Account Request",
        clickRoute: "applications/service-request",
        actionRoute: "applications/service-request",
        fclass: "form1a",
        type: "Service Request",
    },
    psoCancellationRocReviewTask: {
        desc: "PSO Cancellation",
        form: "PSO Cancellation",
        clickRoute: "applications/cancel-pso/details",
        actionRoute: "applications/cancel-pso/details",
        fclass: "form1a",
        type: "PSO Cancellation",
    },
    psoCancellationProvideMoreInfoTask: {
        desc: "PSO Cancellation > Query from ROC",
        form: "PSO Cancellation",
        clickRoute: "applications/cancel-pso",
        actionRoute: "applications/cancel-pso",
        fclass: "form1a",
        type: "PSO Cancellation",
    },
    reviewAuctionPostponeDeclaration: {
        desc: "Auction Postpone",
        form: "Auction Postpone",
        clickRoute: "applications/auction-postpone/details",
        actionRoute: "applications/auction-postpone/details",
        fclass: "form1a",
        type: "Auction Postpone",
    },
    provideMoreInfoAuctionPostponeDeclaration: {
        desc: "Auction Postpone",
        form: "Auction Postpone",
        clickRoute: "applications/auction-postpone",
        actionRoute: "applications/auction-postpone",
        fclass: "form1a",
        type: "Auction Postpone",
    },
    reviewArbitrationApplication: {
        desc: "Arbitration",
        form: "Arbitration",
        clickRoute: "applications/arbitration/details",
        actionRoute: "applications/arbitration/details",
        fclass: "form1a",
        type: "Arbitration",
    },
    reviewLegacyArbitrationApplication: {
        desc: "Legacy Arbitration",
        form: "Legacy Arbitration",
        clickRoute: 'applications/arbitration/legacy-arb-details',
        actionRoute: 'applications/arbitration/legacy-arb-details',
        fclass: "form1a",
        type: "Arbitration"
    },
    reviewPublicArbitrationApplication: {
        desc: "Public Arbitration",
        form: "Public Arbitration",
        clickRoute: 'applications/arbitration/legacy-arb-details',
        actionRoute: 'applications/arbitration/legacy-arb-details',
        fclass: "form1a",
        type: "Arbitration"
    },
    LegacyArbProvideMoreInfo: {
        desc: "Legacy Arbitration",
        form: "Legacy Arbitration",
        clickRoute: 'applications/arbitration/legacy-arb',
        actionRoute: 'applications/arbitration/legacy-arb',
        fclass: "form1a",
        type: "Arbitration"
    },
    formXVIIProvideMoreInfo: {
        desc: "Arbitration",
        form: "Arbitration",
        clickRoute: "applications/arbitration",
        actionRoute: "applications/arbitration",
        fclass: "form1a",
        type: "Arbitration",
    },
    reviewLegacyRelasedGroup: {
        desc: "Legacy Released Group",
        form: "Legacy Released Group",
        clickRoute: "applications/legacy-minutes/details",
        actionRoute: "applications/legacy-minutes/details",
        fclass: "minutes",
        type: "MinutesFiling",
    },
    legacyRelasedGroupProvideMoreInfoLegacy: {
        desc: "Legacy Released Group > Query from ROC",
        form: "Legacy Released Group",
        clickRoute: "applications/legacy-minutes",
        actionRoute: "applications/legacy-minutes",
        fclass: "minutes",
        type: "MinutesFiling",
    }
};

export const ApplicationFormNames = {
    'PSO': 'Form I',
    'CAR': 'Form II',
    'CCB': 'Form V',
    'Form7': 'Form VII',
    'MinutesFiling': 'Auction Minutes',
    'ChitTransfers': 'Form XIII',
};

export const CompletedApplicationTypes = {
    ObtainingPreviousSanctionOrderProcess: {
        desc: 'PSO Application Form',
        form: 'Form I',
        clickRoute: 'applications/pso/details',
        actionRoute: 'applications/pso/details',
        fclass: 'form1a',
        type: 'PSO'
    },
    ChitAgreementProcess: {
        desc: 'Chit Agreement Form',
        form: 'Form II',
        clickRoute: 'applications/agreement/details',
        actionRoute: 'applications/agreement/details',
        fclass: 'form3',
        type: 'CAR'
    },
    ObtainingCertificateOCommencementOfChitProcess: {
        desc: 'Chit Commencement Process',
        form: 'Form V',
        clickRoute: 'applications/commencement/details',
        actionRoute: 'applications/commencement/details',
        fclass: 'form5',
        type: 'CCB'
    },
    FilingToRegistrarAfterFirstDrawProcess: {
        desc: 'Filing Chit after first draw',
        form: 'Form VII',
        clickRoute: 'applications/declaration/details',
        actionRoute: 'applications/declaration/details',
        fclass: 'form7',
        type: 'Form7'
    },
    ProcessChitAuctionMinutes: {
        desc: 'Auction Minutes',
        form: 'Auction Minutes',
        clickRoute: 'applications/minutes/details',
        actionRoute: 'applications/minutes/details',
        fclass: 'minutes',
        type: 'MinutesFiling'
    },
    ProcessChitAuctionMinutesLegacy: {
        desc: 'Legacy Minutes',
        form: 'Legacy Minutes',
        clickRoute: 'applications/legacy-minutes/details',
        actionRoute: 'applications/legacy-minutes/details',
        fclass: 'minutes',
        type: 'MinutesFiling'
    },
    SubscriberSubstitutionProcess: {
        desc: 'Chit transfer application',
        form: 'Form XIII',
        clickRoute: 'applications/transfer/details',
        actionRoute: 'applications/transfer/details',
        fclass: 'form13',
        type: 'ChitTransfers'
    },
    ReleaseOfSecurityProcess: {
        desc: 'Chit release',
        form: 'Chit Release',
        clickRoute: 'applications/release/details',
        actionRoute: 'applications/release/details',
        fclass: 'release',
        type: 'Release'
    },
    ForemanCompanyFinancialDataUpdateProcess: {
        desc: 'Financial Data',
        form: 'Financial Data',
        clickRoute: 'applications/financial-data/details',
        actionRoute: 'applications/financial-data/details',
        fclass: 'release',
        type: 'Financial Data'
    },
    RegisterChitCompanyProcess: {
        desc: 'Foreman Company Request',
        form: 'Foreman Company Request',
        clickRoute: 'enroll-company/details',
        actionRoute: 'enroll-company/details',
        fclass: 'release',
        type: 'Release'
    },
    ChitCompanyBranchRegistrationProcess: {
        desc: 'Foreman Branch Request',
        form: 'Foreman Branch Request',
        clickRoute: 'foreman-companies/branch/details',
        actionRoute: 'foreman-companies/branch/details',
        fclass: 'release',
        type: 'Release'
    },
    ReauctionDeclarationProcess: {
        desc: 'Re Auction Declaration',
        form: 'Re Auction Declaration',
        clickRoute: 'applications/re-auction',
        actionRoute: 'applications/re-auction',
        fclass: 'minutes',
        type: 'MinutesFiling'
    },
    BranchForemanChangeProcess: {
        desc: 'Change Request',
        form: 'Change Request',
        clickRoute: 'applications/change-request/details',
        actionRoute: 'applications/change-request/details',
        fclass: 'form5',
        type: 'Change Request'
    },
    UserChangeProcess: {
        desc: 'Change Request',
        form: 'Change Request',
        clickRoute: 'applications/change-request/details',
        actionRoute: 'applications/change-request/details',
        fclass: 'form5',
        type: 'Change Request'
    },
    ServiceRequestProcess: {
        desc: 'Service Request',
        form: 'Service Request',
        clickRoute: 'applications/service-request/details',
        actionRoute: 'applications/service-request/details',
        fclass: 'form1a',
        type: 'Service Request'
    },
    reviewPublicArbitrationApplication: {
        desc: 'Public Arbitration',
        form: 'Public Arbitration',
        clickRoute: 'applications/arbitration/legacy-arb-details',
        actionRoute: 'applications/arbitration/legacy-arb-details',
        fclass: 'form1a',
        type: 'Arbitration'
    },
    SubscriberServiceRequestProcess: {
        desc: 'Service Request - Public',
        form: 'Service Request - Public',
        clickRoute: 'applications/service-request/details',
        actionRoute: 'applications/service-request/details',
        fclass: 'form1a',
        type: 'Service Request - Public'
    },
    SubscriberCertifiedCopyOfAwardProcess: {
        desc: 'Service Request - Public',
        form: 'Service Request - Public',
        clickRoute: 'applications/service-request/details',
        actionRoute: 'applications/service-request/details',
        fclass: 'form1a',
        type: 'Service Request - Public'
    },
    PsoCancellationProcess: {
        desc: 'PSO Cancellation',
        form: 'PSO Cancellation',
        clickRoute: 'applications/cancel-pso/details',
        actionRoute: 'applications/cancel-pso/details',
        fclass: 'form1a',
        type: 'PSO Cancellation'
    },
    AuctionPostponeProcess: {
        desc: 'Auction Postpone',
        form: 'Auction Postpone',
        clickRoute: 'applications/auction-postpone/details',
        actionRoute: 'applications/auction-postpone/details',
        fclass: 'form1a',
        type: 'Auction Postpone'
    },
    ArbitrationApplicationProcess: {
        desc: 'Arbitration',
        form: 'Arbitration',
        clickRoute: 'applications/arbitration/details',
        actionRoute: 'applications/arbitration/details',
        fclass: 'form1a',
        type: 'Arbitration'
    },
    LegacyArbitrationApplicationProcess: {
        desc: 'Legacy Arbitration',
        form: 'Legacy Arbitration',
        clickRoute: 'applications/arbitration/legacy-arb-details',
        actionRoute: 'applications/arbitration/legacy-arb-details',
        fclass: 'form1a',
        type: 'Arbitration'
    },
    SubscriberArbitrationApplicationProcess: {
        desc: 'Public Arbitration',
        form: 'Public Arbitration',
        clickRoute: 'applications/arbitration/legacy-arb-details',
        actionRoute: 'applications/arbitration/legacy-arb-details',
        fclass: 'form1a',
        type: 'Arbitration'
    },
    SeparateAccountStatementProcess:{
        desc: 'Separate Account Statement',
        form: 'Separate Account Statement',
        clickRoute: 'applications/separate-account-statement/statement-details',
        actionRoute: 'applications/separate-account-statement/statement-details',
        fclass: 'release',
        type: 'SeparateAccountStatement'       
    },
    CorrectionRequestProcess:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    ChitDetailsCorrectionProcess:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    ForemanCompanyFinancialsCorrectionProcess:{
        desc: 'Correction Application',
        form: 'Correction Application',
        clickRoute: 'applications/correction-application/details',
        actionRoute: 'applications/correction-application/details',
        fclass: 'release',
        type: 'Account'       
    },
    SeparateAccountCorrectionProcess: {
        desc: 'Correction Application',
        form: 'Sep A/C Correction',
        clickRoute: 'applications/separate-account-statement/statement-details',
        actionRoute: 'applications/separate-account-statement/statement-details',
        fclass: 'release',
        type: 'Account'  
    },
    InterlocutoryApplicationProcess:{
        desc: 'Interlocutory Application ',
        form: 'Interlocutory Application ',
        clickRoute: 'applications/interlocutory-application/details',
        actionRoute: 'applications/interlocutory-application/details',
        fclass: 'release',
        type: 'InterlocutoryApplication'
    },
    reviewInterlocutoryApplication:{
        desc: 'Interlocutory Application ',
        form: 'Interlocutory Application ',
        clickRoute: 'applications/interlocutory-application/details',
        actionRoute: 'applications/interlocutory-application/details',
        fclass: 'release',
        type: 'Interlocutory Application '
        
    },
    ProcessLegacyReleasedChit: {
        desc: 'Legacy Released Group',
        form: 'Legacy Released Group',
        clickRoute: 'applications/legacy-minutes/details',
        actionRoute: 'applications/legacy-minutes/details',
        fclass: 'minutes',
        type: 'MinutesFiling'
    },
    BankInformationProcess: {
        desc: "Bank Account Request",
        form: "Bank Account Request",
        clickRoute: "applications/service-request/bank-account-details",
        actionRoute: "applications/service-request/bank-account-details",
        fclass: "form1a",
        type: "Service Request",
    }
};

export const chitGroupFields = [
    // { key: 'chitNumber', name: 'Chit Group Number', type: 'text' },
    { key: 'amount', name: 'Amount', type: 'number' },
    { key: 'maxBidAmount', name: 'Max Bid Amount', type: 'number' },
    { key: 'minBidAmount', name: 'Min Bid Amount', type: 'number' },
    { key: 'foremanCommission', name: 'Foreman Commission Amount', type: 'number' },
    // { key: 'duration', name: 'Duration', type: 'number' },
    // { key: 'noOfInstallments', name: 'No Of Installments', type: 'number' },
    // { key: 'installmentAmount', name: 'Installment Amount', type: 'number' },
    // { key: 'noOfTickets', name: 'No Of Tickets', type: 'number' },
    // { key: 'auctionFrequency', name: 'Auction Frequency', type: 'select' },
    // { key: 'psoNumber', name: 'Pso Number', type: 'text' },
    // { key: 'psoAppliedDate', name: 'Pso Applied Date', type: 'date' },
    // { key: 'registrationNumber', name: 'Registration Number', type: 'text' },
    // { key: 'agreementAppliedDate', name: 'Agreement Applied Date', type: 'date' },
    // { key: 'auctionSchedulerCron', name: 'AuctionS cheduler' }
];

export const newARCsList = [
    // { 'name': 'ARC Warangal', 'branch_code': 'TSDemo_Hyderabad11' },
    // { 'name': 'ARC Nizamabad', 'branch_code': 'TSARCNizamabad6' },
    // { 'name': 'ARC Karimnagar', 'branch_code': 'TSARCKarimnagar7' },
    // { 'name': 'ARC Khammam', 'branch_code': 'TSARCKhammam8' },
    // { 'name': 'ARC Nalgonda', 'branch_code': 'TSARCNalgonda9' },
    // { 'name': 'ARC Medak', 'branch_code': 'TSARCMedak10' },
    // { 'name': 'ARC Mahaboobnagar', 'branch_code': 'TSARCMahaboobnagar11' },
    // { 'name': 'ARC Adilabad', 'branch_code': 'TSARCAdilabad12' }
];
