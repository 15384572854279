import { TransactionItem } from './transaction-item.model';

const enum AmountUnit {
    'RS',
    'USD'
}
const enum TransactionStatus {
    'SUCCESS',
    'FAILURE',
    'TIME_OUT',
    'PENDING'
}
export enum TransactionType {
    'PAY',
    'OFFLINE_PAY',
    'UPI_PAY_P2P',
    'UPI_PAY_P2M',
    'UPI_COLLECT_P2P',
    'UPI_COLLECT_P2M'
}
const enum PaymentMode {
    'VA',
    'ACCOUNT_IFSC',
    'ADHAAR',
    'MOBILE_MMID'
}
export class Transaction {
    constructor(
        public id?: number,
        public amount?: number,
        public amountUnit?: AmountUnit,
        public appTrnxRefId?: string,
        public pspTrnxRefId?: string,
        public trnxDate?: any,
        public trnxStatus?: TransactionStatus,
        public trnxStatusDesc?: string,
        public trnxType?: string,
        public clientType?: string,
        public trnxDesc?: string,
        public pspType?: string,
        public clientId?: string,
        public paymentMode?: PaymentMode,
        public trnxAddlRef1?: string,
        public trnxAddlRef2?: string,
        public trnxAddlRef3?: string,
        public trnxAddlRef4?: string,
        public trnxAddlRef5?: string,
        public items?: TransactionItem[]
    ) { }
}
