import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../blocks/interceptor/alert.service';

@Component({
    selector: 'jhi-alert',
    template: `
        <div class="alerts" role="alert">
            <div class="animated" *ngFor="let alert of alerts" [ngClass]="{\'alert.position slideInRight\': true, \'toast\': alert.toast}">
                <ngb-alert [type]="alert.type" (close)="alert.close(alerts)"><p [innerHTML]="alert.msg"></p></ngb-alert>
            </div>
        </div>`
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: any[];

    constructor(
        private alertService: AlertService
        ) { }

    ngOnInit() {
       this.alerts = this.alertService.get();
    }

    ngOnDestroy() {
        this.alerts = [];
    }

}
