import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-application-sent-back',
    templateUrl: './application-sent-back.html',
    styleUrls: ['./application-sent-back.scss']
})

export class ApplicationSentBackComponent {
    constructor() { }
}
