
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SNB_CORE_URL, USER_TYPES ,SNB_UAA_URL} from '../../app.constants';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { HttpUtilService } from '../../shared/http-util.service';

@Injectable()
export class ProfileService {
    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private httpClient: HttpClient,
        private httpUtilService: HttpUtilService,
    ) { }

    getForemanProfileData<T>(req?: any): Observable<T> {
        const options = this.prepareHeader(req);
        return this.httpClient.get<T>(SNB_CORE_URL + '/foreman/foremen/get-details', options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    updateForemanProfileData<T>(profileData, req?: any): Observable<T> {
        const copy = Object.assign({}, profileData);
        const options = this.prepareHeader(req);
        return this.httpClient.post<T>(SNB_CORE_URL + '/foreman/foremen/update-profile', copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getROCProfileData<T>(req?: any): Observable<T> {
        const options = this.prepareHeader(req);
        return this.httpClient.get<T>(SNB_CORE_URL + '/regulator/regulators/get-details', options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set('stateIn', urlSearchParams.stateIn);
            }
            if (urlSearchParams.query) {
                params = params.set('query', urlSearchParams.query);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }
    
    getROCbranches<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = 'regulator/regulator-orgs/branches';
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    getForemanBranch<T>(branchCode:any,req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/branches?branchCode=${branchCode}`;
        } else {
            url = `foreman/foreman-companies/branches?branchCode=${branchCode}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    enable2fa<T>(req?:any):Observable<any>{
        const options = this.httpUtilService.createRequestOption(req);
        return this.httpClient.post(`${SNB_UAA_URL}/account/mfa/modify`, {}, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    disable2fa<T>(req?:any):Observable<any>{
        const options = this.httpUtilService.createRequestOption(req);
        return this.httpClient.post(`${SNB_UAA_URL}/mfa/admin/modify`, {}, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
     verifyTOTP<T>(req?:any):Observable<any>{
        const options = this.httpUtilService.createRequestOption(req);
        return this.httpClient.post(`${SNB_UAA_URL}/account/mfa/verify-totp`, {}, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    generateRecoveryCodes<T>(req?:any):Observable<any>{
        const options = this.httpUtilService.createRequestOption(req);
        return this.httpClient.post(`${SNB_UAA_URL}/account/mfa/generate-recovery-codes`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    getAccountDetails<T>(req?:any):Observable<any>{
        return this.httpClient.get(`${SNB_UAA_URL}/account`).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

}
