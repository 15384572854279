<div class="dialog-wrapper" mat-dialog-content>
    <div class="dialog-title">
        {{ title ? title: "Progress" }}
        <button (click)='closeDialog()' type="button" style="display: contents;">
            <span><i class="material-icons close-btn">close</i></span>
        </button>
    </div>
    <div class="dialog-content-wrapper">
        <div *ngFor="let task of tasks">
            <div class="task-progress-container">
                <div class="task-name">{{task.name}}</div>
                <div class="task-status">
                    <mat-icon color="primary" *ngIf="task.status === 'NOT_STARTED'">schedule</mat-icon>
                    <mat-spinner color="accent" diameter="20" color="blue"
                        *ngIf="task.status === 'RUNNING'"></mat-spinner>
                    <mat-icon style="color: green;" *ngIf="task.status === 'SUCCESS'">check_circle</mat-icon>
                    <mat-icon color="warn" *ngIf="task.status === 'FAILED'">cancel</mat-icon>
                    <mat-icon color="warn" *ngIf="task.status === 'SKIPPED'">next</mat-icon>
                    <mat-icon style="color: #E4A11B;" *ngIf="task.status === 'WARNING'">warning</mat-icon>
                    <span>{{task.status}}</span>
                </div>
            </div>
            <div *ngIf="task.status === 'FAILED' || task.status === 'WARNING'" class="message-container">
                <ul>
                    <li [ngClass]="{'message-content-failed': task.status === 'FAILED', 'message-content-warning': task.status === 'WARNING'}" *ngFor="let message of task.messages">
                        {{ message }}
                    </li>
                    <a *ngIf="task?.redirection" [routerLink]="[task?.redirection?.link]" target="_blank" [queryParams]="task?.redirection?.queryParams" (click)="dialogRef?.close('noRedirection')">
                        {{ task.redirection.text }}
                    </a>
                </ul>
            </div>
            <hr class="task-separator">
        </div>

        <button mat-raised-button class="snb-raised-button" [mat-dialog-close]="true" style="float: right;"
            (click)="closeDialog()">
            <span>Proceed</span>
        </button>

    </div>
</div>