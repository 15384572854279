import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnBToasterService } from '../../../shared/components/toaster/toaster.service';
import { ForemanCompaniesService } from '../foreman-companies.service';


@Component({
    selector: 'app-foreman-org-financial-data',
    templateUrl: './financial-data.html',
    styleUrls: ['./financial-data.scss']
})

export class ForemanOrgFinancialDataComponent implements OnInit {

    selectedRowIndex = 0;
    enableShowMoredetails = false;
    displayedColumns = ['financialYear','type','netOwnedAssets'];
    financialData: any;
    companyCode: any;
    panelOpenState = true;
    selectedRowData: any;
    requestInProgress = false;
    attachments: any = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private toasterService: SnBToasterService,
        private foremanCompaniesService: ForemanCompaniesService
    ) {
        this.requestInProgress = true;
        this.activatedRoute.queryParams.subscribe(params => {
            this.companyCode = params.id;
        });
    }

    ngOnInit() {
        this.financialData = [];
        this.attachments =[];
        this.getMinutes();
    }

    getMinutes() {
        this.requestInProgress = true;
        this.foremanCompaniesService.getFinancialData(this.companyCode).subscribe(res => {
            this.financialData = res;
            console.log(this.financialData);
            this.requestInProgress = false;
            if (res['length'] && !this.selectedRowIndex) {
                this.showMoreDetails(res[this.selectedRowIndex]);
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
                this.requestInProgress = false;
            }
        });
    }

    showMoreDetails(row) {
        this.enableShowMoredetails = true;
        this.selectedRowIndex = row.id;
        this.selectedRowData = row;
        
        (row.attachments) ? (this.attachments = row.attachments) : (this.attachments = null);
    }

}