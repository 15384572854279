<div class="refresh_data ">
    <button mat-raised-button (click)="refresh()" title="Refresh">
        <!-- <i class="material-icons"> refresh </i> -->
         <i class="material-icons snb-refresh" aria-hidden="true" [ngClass]="{'fa-spin':!dataRefreshed}">autorenew</i>
       



    </button>
   
</div>