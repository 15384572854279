<li *ngIf="!menuItem.hidden" [title]="menuItem.title"
  [ngClass]="{'snb-sidebar-list-item': !child, 'ba-sidebar-sublist-item': child, 'selected': menuItem.selected && !menuItem.expanded, 'with-sub-menu': menuItem.children, 'ba-sidebar-item-expanded': menuItem.expanded}">

  <a *ngIf="!menuItem.children && !menuItem.url && !menuItem.ignoreItem" (mouseenter)="onHoverItem($event, item)"
    [routerLink]="menuItem.route.paths" class="snb-sidebar-list-link">
   <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>
    <span>{{ menuItem.title }}</span>
  </a>

  <a *ngIf="!menuItem.children && menuItem.url && !menuItem.ignoreItem" (mouseenter)="onHoverItem($event, item)"
    [href]="menuItem.url" [target]="menuItem.target" class="snb-sidebar-list-link">
    <!-- <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i> -->
   <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>

    <span>{{ menuItem.title }}</span>
  </a>

  <a *ngIf="menuItem.children && menuItem.ignoreChild && !menuItem.ignoreItem" (mouseenter)="onHoverItem($event, item)"
    [routerLink]="menuItem.route.paths" class="snb-sidebar-list-link">
   <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>
    <span>{{ menuItem.title }}</span>
  </a>

  <a *ngIf="menuItem.children && !menuItem.ignoreChild && !menuItem.ignoreItem" (mouseenter)="onHoverItem($event, item)"
    href (click)="onToggleSubMenu($event, menuItem)" class="snb-sidebar-list-link">
   <i *ngIf="menuItem.icon" class="{{ menuItem.icon }}"></i>
    <span>{{ menuItem.title }}</span>
    <b class="fa fa-angle-down" [ngClass]="{'fa-angle-up': menuItem.expanded}">
      <fa-icon [icon]="faAngleDown"></fa-icon>
    </b>

  </a>

  <ul *ngIf="menuItem.children && !menuItem.ignoreChild && !menuItem.ignoreItem" class="snb-sidebar-sublist"
    [ngClass]="{'slide-right': menuItem.slideRight}">
    <div *ngFor="let subItem of menuItem.children" class="menu-child-items">
      <app-ba-menu-item [menuItem]="subItem" *ngIf="!subItem.childHidden" [child]="true"
        (itemHover)="onHoverItem($event)" (toggleSubMenu)="onToggleSubMenu($event, subItem)"></app-ba-menu-item>
    </div>
  </ul>

</li>