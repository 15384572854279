import { PreloadingStrategy, Route } from '@angular/router';
import { Observable ,  of ,  timer } from 'rxjs';
import { USER_TYPES } from './app.constants';
import { flatMap } from 'rxjs/operators';

export class AppCustomPreloader implements PreloadingStrategy {
    preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data.preload && !USER_TYPES.IS_USER ? load() : of(null);
    }
    // preload(route: Route, load: Function): Observable<any> {
    //     const loadRoute = (delay) => delay
    //         ? timer(2000).pipe(flatMap(_ => load()))
    //         : load();
    //     return route.data && route.data.preload
    //         ? loadRoute(route.data.delay)
    //         : of(null);
    // }
}