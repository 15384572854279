import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const newVal = value.sort((a: any, b: any) => {
            const date1 = new Date(a.date);
            const date2 = new Date(b.date);

            if (date1 < date2) {
                return 1;
            } else if (date1 > date2) {
                return -1;
            } else {
                return 0;
            }
        });
        return newVal;
    }

}

@Pipe({
    name: 'snbDateCorrection'
})
export class SNBDateCorrectionPipe extends DatePipe implements PipeTransform {
    transform(value: any): any {
        if (value && typeof (value) === 'string' && value.indexOf('[UTC]') > -1) {
            return value.split('[UTC]')[0];
        } else {
            return value;
        }
    }
}

@Pipe({
    name: 'snbDateConvertToMillis'
})
export class SNBDateConvertToMilisPipe extends DatePipe implements PipeTransform {

    transform(date: any): any {
        if (date) {
            return this.formatDateToTimeStampToMilliSeconds(date);
        } else {
            return null;
        }
    }

    formatDateToTimeStampToMilliSeconds(date) {
        if (date && typeof (date) === 'string') {
            const date_1 = new Date(date).toString();
            if (date_1 === 'Invalid Date') {
                if (date.indexOf('+') > -1) {
                    date = date.replace('+', '+0');
                }
                if (date.indexOf('[UTC]') > -1) {
                    date = new Date(date.split('[UTC]')[0]);
                } else {
                    date = new Date(date);
                }
            } else {
                date = new Date(date);
            }
            if (Number(date).toString().length < 13) {
                date = Number(date) * 1000;
            } else if (Number(date).toString().length > 13) {
                date = Number(date) / 1000;
            } else if (Number(date).toString().length === 13) {
                date = Number(date);
            }
        } else if (date && typeof (date) === 'number') {
            const tempDate = date.toString().split('.');
            if (tempDate[1] && tempDate[1].length) {
                switch (tempDate[1].length) {
                    case 1:
                        date *= 10;
                        date = this.checkLengthForDecimals(date);
                        break;
                    case 2:
                        date *= 100;
                        date = this.checkLengthForDecimals(date);
                        break;
                    case 3:
                        date *= 1000;
                        date = this.checkLengthForDecimals(date);
                        break;
                    default:
                        date = date;
                        break;
                }
            }
            if (date.toString().length !== 13) {
                if (date.toString().length <= 10) {
                    date *= 1000;
                } else if (date.toString().length > 10) {
                    date /= 1000;
                }
            }
        }
        if (date.toString() === 'NaN') {
            return;
        }
        if (date.toString().length < 13) {
            return this.formatDateToTimeStampToMilliSeconds(date);
        } else if (date.toString().length === 13) {
            return date;
        }
        return date;
    }

    checkLengthForDecimals(date) {
        switch (date.toString().length) {
            case 8:
                date *= 100;
                break;
            case 9:
                date *= 10;
                break;
            case 11:
                date *= 100;
                break;
            case 12:
                date *= 10;
                break;
            default:
                date = date;
                break;
        }
        return date;
    }
}
