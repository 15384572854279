<div *ngIf="printsList && printsList.length>1" style="display: inline;">
    <a class="print-form-icon" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
        <i class="material-icons">print</i>
    </a>
    <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
        <button mat-raised-button (click)="printForm(form)" *ngFor="let form of printsList" style="float: right; margin: 0px 10px 10px 10px ">
            <span>{{form?.printFormName}}</span>
        </button>
    </mat-menu>
</div>
<div *ngIf="printsList && printsList.length==1" style="display: inline;">
    <a (click)="printForm(form)" class="print-form-icon" *ngFor="let form of printsList" matTooltipPosition="above"
        matTooltip="{{form?.printFormName}}">
        <i class="material-icons">print</i>
    </a>
</div>