
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SNB_CORE_URL, SNB_WF_URL, USER_TYPES } from '../../app.constants';

import { ActivatedRoute } from '@angular/router';
import { Form1Model } from './form-1/form1-model';
import { FormatInputs } from '../../shared/format-values';
import { Injectable } from '@angular/core';
import { HttpUtilService } from '../../shared/http-util.service';
import { CommonService } from '../../shared/services/common-service.service';
import { ChitPaymentVoucher } from '../../shared/models/chit-payment-voucher.model';
import { OrgOrBranchMiniDTO } from '../legacy-data-services/org-or-branch-mini.model';

@Injectable()
export class ApplicationsService extends CommonService {
    private taskStauts: any;
    private chitGroupId: any;
    private token: any;

    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private activatedRoute: ActivatedRoute,
        private httpClient: HttpClient,
        private formatInputs: FormatInputs,
        private httpUtilService: HttpUtilService
    ) {
        super();
        this.activatedRoute.queryParams.subscribe(params => {
            this.taskStauts = params.status;
            this.chitGroupId = params.chitId;
        });
        this.token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
    }

   submitForm1<T>(form1Data: Form1Model, req?: any): Observable<any> {
        const copy: Form1Model = Object.assign({}, form1Data);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-pso-and-chit-security`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitForm2<T>(form2Data, req?: any): Observable<any> {
        const copy = Object.assign({}, form2Data);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-chit-agreement`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitPsoCancellationApplication<T>(psoCancellationForm: any, req?: any): Observable<any> {
        const copy = Object.assign({}, psoCancellationForm);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-pso-cancellation`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitForm5<T>(form5Data, req?: any): Observable<any> {
        const copy = Object.assign({}, form5Data);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-chit-commencement`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitForm7<T>(form7Data, req?: any): Observable<any> {
        const copy = Object.assign({}, form7Data);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-filings-after-first-draw`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitForm13<T>(form13Data, req?: any): Observable<any> {
        const copy = Object.assign({}, form13Data);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-chit-substitution-of-subscriber`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitChitRelease<T>(chitReleaseData, req?: any): Observable<any> {
        const copy = Object.assign({}, chitReleaseData);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-release-of-security`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitFinancialData<T>(financialData, req?: any): Observable<any> {
        const copy = Object.assign({}, financialData);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-financial-data`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    submitSeparatAccountApplicationData<T>(accountData, req?: any): Observable<any> {
        const copy = Object.assign({}, accountData);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/separate-account/apply-sep-account-statement`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    submitAttachmentBeforeJudgement<T>(accountData, req?: any): Observable<any> {
        const copy = Object.assign({}, accountData);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/arbitration/interlocutory-application`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }


    submitReAuctionDetails<T>(reAuctioDetails, req?: any): Observable<any> {
        const copy = Object.assign({}, reAuctioDetails);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-chit-reauction-declaration`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitAuctionMinutesLegacy<T>(legacyAuctionMinutes, req?: any): Observable<any> {
        const copy = Object.assign({}, legacyAuctionMinutes);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-chit-auction-minutes-legacy`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    submitArbitrationForm<T>(arbitrationForm: any, req?: any): Observable<any> {
        const copy: any = Object.assign({}, arbitrationForm);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/apply-arbitration-application`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitLegacyArbitrationForm<T>(legacyArbitrationForm: any,req?: any): Observable<any> {
        const copy: any = Object.assign({}, legacyArbitrationForm);
        const options = this.prepareHeader(req);
        let url = '';
        if(USER_TYPES.IS_FOREMAN){
            url = 'foreman/arbitration/apply-legacy-arbitration-application';
        }else{
            url = 'public/process/misc/apply-subscriber-arbitration-application';
        }
        return this.httpClient.post(`${SNB_WF_URL}/${url}`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }))
    }

    getLegacyChitInfoDetails<T>(chitNumber, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chits-staging/by-name?name=${chitNumber}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanProfileData<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/foremen/get-details`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanBranchesDetails<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/foreman-companies/my-owned-branches`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyName<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/foreman-companies`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getChitSecurities<T>(chitRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-security-details?chitRefId=${chitRefId}`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/chit-security-details?chitRefId=${chitRefId}`;
        } else if (USER_TYPES) {
            url = `public/chit-security-details?chitRefId=${chitRefId}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getROCbranches<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = 'regulator/regulator-orgs/branches';
        } else {
            url = 'foreman/regulator-orgs/branches';
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getAllROCBranches<T>(req?: any):Observable<any> {
        return this.httpClient.get(`${SNB_CORE_URL}/public/regulator-branch`).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getROCBranchData<T>(branchId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/regulator-orgs/branches/${branchId}`;
        } else {
            url = `foreman/regulator-orgs/branches/${branchId}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getROCBranchDataByCode<T>(branchCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/regulator-orgs/branches/by-code/${branchCode}`;
        } else {
            url = `foreman/regulator-orgs/branches/by-code/${branchCode}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanBranches<T>(companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/branches?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/branches?companyCode=${companyCode}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanBranchData<T>(branchId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/branches/${branchId}`;
        } else {
            url = `foreman/foreman-companies/branches/${branchId}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyMyOwnedBranches(companyCode: string, req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/my-owned-branches?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/my-owned-branches`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyHeadOfficeData(companyCode: string, req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/main-branch?companyCode=${companyCode}`;
        } else {
            url = `foreman/foreman-companies/main-branch`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanCompanyDetails(id: string, req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/foreman-companies`;
        } else if(USER_TYPES.IS_REGULATOR) {
            url = `regulator/foreman-companies/${id}`;
        } else {
            url = `public/foreman-companies/${id}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    

    getCompanyUserDetailsByMobileNumber(contactNo: number, req?: any) {
        const options = this.prepareHeader(req);
        let LoginUserType = '';
        if (USER_TYPES.IS_FOREMAN) {
            LoginUserType = `foreman`;
        } else if(USER_TYPES.IS_REGULATOR) {
            LoginUserType = `regulator`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${LoginUserType}/foremen?contactDetails.mobileNumber=${contactNo}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getChitDetailsById<T>(chitRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chits/${chitRefId}`;
        } else if(USER_TYPES.IS_FOREMAN) {
            url = `foreman/chits/${chitRefId}`;
        } else {
            url = `public/chits/${chitRefId}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getCEOandDirectorInfo<T>(companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/get-details-for-pso-application?companyCode=${companyCode}`;
        } else {
            url = `foreman/foremen/get-details-for-pso-application`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getForemanUsersForArbitrationApplication<T>(req?: any):Observable<any>{
        const options = this.httpUtilService.createRequestOption(req);
        let url = '';
        //The url of the API copied from postman is placed here
        if(USER_TYPES.IS_FOREMAN){
            url = `foreman/foremen/get-details-for-arbitration-application`;
        }else if(USER_TYPES.IS_REGULATOR){
            url = `regulator/foremen/get-details-for-arbitration-application`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

  

    getUpdatedTaskIdUsingProcessId<T>(processId: string, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        const request = {
            'processInstanceId': processId,
            "matchingVars": {},
            "processDefinitionKeys": [],
        };
        return this.httpClient.post(`${SNB_WF_URL}/regulator/task/query`, request, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getChitGroupsList<T>(chitListType, branchCode?: any, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = `${SNB_CORE_URL}/foreman/chits/get-chits-with-min-info?stateIn=${chitListType}`;
        if (branchCode) {
            url = `${url}&foremanCompanyBranchCode=${branchCode}`;
        }
        return this.httpClient.get(url, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyMiniDetails<T>(req?: any) {
        const options = this.prepareHeader(req);
        let url = '';
        url = `${SNB_CORE_URL}/public/foreman-companies/get-mini-details`;
        return this.httpClient.get<T>(`${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanCompanyBranchMiniDetails<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        url = `${SNB_CORE_URL}/regulator/foreman-companies/branches/get-mini-details/true`;
        return this.httpClient.get<OrgOrBranchMiniDTO>(`${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getChitGroupsListPublic<T>(chitListType, req?: any): Observable<any> {
        const options = this.httpUtilService.createRequestOption(req);
        let url = `${SNB_CORE_URL}/public/chits/get-chits-with-min-info?stateIn=${chitListType}`;
        return this.httpClient.get(url, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getAgreementAppliedChitGroupsList<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chits/get-chits-with-min-info?stateIn=AGREEMENT_APPROVED,AGREEMENT_APPLIED`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getForemanDetailsByUserId<T>(userId, companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/foremen/by-user-id/${companyCode}/${userId}`;
        } else {
            url = `foreman/foremen/by-user-id/${userId}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getROCProfileData<T>(req?: any): Observable<T> {
        const options = this.prepareHeader(req);
        return this.httpClient.get<T>(SNB_CORE_URL + '/regulator/regulators/get-details', options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitTask<T>(taskId, form1Data, req?: any):Observable<any> {
        const copy = Object.assign({}, form1Data);
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/task/submit/${taskId}`;
        } else {
            url = `foreman/task/submit/${taskId}`;
        }
        return this.httpClient.post(`${SNB_WF_URL}/${url}`, copy, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getProcessInstanceComments<T>(processInstanceId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/comments`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/comments`;
        } else {
            url = `public/process/comments`;
        }
        return this.httpClient.get(`${SNB_WF_URL}/${url}/${processInstanceId}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    validateOrgDataForPsoApplication<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chits/validate-org-data-for-pso-application`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getActiveViolationOfBranch<T>(violationCategory: string,branchCode, req?: any):Observable<any> {
        if(!req) {
            req = {};
        }
        req.query = {
            'category': violationCategory,
            'count': 2,
            'companyBranchCode': branchCode
        };
        
        const options = this.httpUtilService.createRequestOption(req);
        let url = `${this.getUerType()}/violation/violations-by-chitgroup`;
       
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    
    }

    getBranchWiseActiveViolations<T>(req?: any): Observable<any> {
        const options = this.httpUtilService.createRequestOption(req);
        return this.httpClient.get<T>(`${SNB_CORE_URL}/${this.getUerType()}/violation/violations-by-branch`,
        { headers: options['headers'], params: options['params'], observe: 'response' }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getProessDetails<T>(processInstanceId, req?: any) {
        const options = this.prepareHeader(req);
        const request = {
            'processInstanceId': processInstanceId
        };

        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman`;
        } else {
            url = `public`;
        }
        return this.httpClient.post(`${SNB_WF_URL}/${url}/process/query`, request, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getFormDetailsByTaskId<T>(taskId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let isActive = true;
        if (this.taskStauts === 'closed') {
            isActive = false;
            if (this.chitGroupId) {
                options['params'] = options['params'].set('cached-data', true);
            }
        }
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/task/${taskId}`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/task/${taskId}`;
        } else {
            url = `public/task/${taskId}`;
        }
        return this.httpClient.get(`${SNB_WF_URL}/${url}?active=${isActive}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getPSOChitsById<T>(id, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chits/${id}`;
        } else if(USER_TYPES.IS_FOREMAN){
            url = `foreman/chits/${id}`;
        }else {
            url = `public/chits/${id}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    
    /**
     * This method gets the chit counter for given regulator branch.
     *
     * @param regulatorBranchRefId holds the regulator branch ref id to which counters required
     * @param req currently it is not used.
     */
    getChitAgreementCounter<T>(regulatorBranchRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/regulator/chit-counter/get-branch-counters/${regulatorBranchRefId}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    uploadFile<T>(file, req?: any):Observable<any> {
        let headers = new HttpHeaders();
        const params = new HttpParams();

        headers = headers.append('Content-Type', 'multipart/form-data');
        headers = headers.delete('Content-Type');

        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_WF_URL}/regulator/content-service/content-items`;
        } else {
            url = `${SNB_WF_URL}/foreman/content-service/content-items`;
        }
        
        return this.httpClient.post(`${url}`, file, {
            headers: headers, params: params, observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    submitMinutesFiling<T>(minutesFilingData, req?: any):Observable<any> {
        const copy = Object.assign({}, minutesFilingData);
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-chit-auction-minutes`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getFDRBankDetails<T>(req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chit-security-details/get-existing-fdr-bank-details`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }


    getAuctionDetailsForMinutesFiling<T>(chitRefId, installmentNumber?: any, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-auction-minutes/get-auction-details-for-minutes-filing/${chitRefId}`;
        } else {
            url = `foreman/chit-auction-minutes/get-auction-details-for-minutes-filing/${chitRefId}`;
        }
        if (installmentNumber) {
            url = url + `?installmentNumber=${installmentNumber}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    validateVouchersForMinutesFiling<T>(chitRefId, installmentNumber: any, bidOffer: number, prizedTicket: number, vouchers: ChitPaymentVoucher[]):Observable<any> {
        let req = {
            'query': {
                'minutesFilingInstallment': installmentNumber,
                'bidOffer': bidOffer,
                'prizedTicket': prizedTicket
            }
        }
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-payment-voucher/validate-for-minute-filings/${chitRefId}`;
        } else {
            url = `foreman/chit-payment-voucher/validate-for-minute-filings/${chitRefId}`;
        }
        return this.httpClient.post(`${SNB_CORE_URL}/${url}`, vouchers, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getVoucherDetails<T>(req?: any): Observable<any> {
        let url = '';
        const options = this.httpUtilService.createRequestOption(req);        
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-payment-voucher`;
        } else {
            url = `foreman/chit-payment-voucher`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`,{
            headers: options["headers"],
            params: options["params"],
            observe: "response",
        }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getAllowedMinutesForReauction<T>(chitRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        const url = `foreman/chit-auction-minutes/get-re-auction-details-for-declaration/${chitRefId}`;
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getAuctionDateByInstallmentNo<T>(req?: any): Observable<HttpResponse<T>> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `/regulator/chit-auction-minutes/get-auction-date`;
        } else {
            url = `/foreman/chit-auction-minutes/get-auction-date`;
        }
        return this.httpClient.get<T>(SNB_CORE_URL + `${url}`,
            { headers: options['headers'], params: options['params'], observe: 'response' }).pipe(
                catchError((err) => {
                    return observableThrowError(err);
                }));
    }

    getPendingVouchersForRelease<T>(chitRefId, installmentNumber?: any, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-auction-minutes/get-pending-payment-details-for-release/${chitRefId}`;
        } else {
            url = `foreman/chit-auction-minutes/get-pending-payment-details-for-release/${chitRefId}`;
        }
        if (installmentNumber) {
            url = url + `?installmentNumber=${installmentNumber}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getLatestPaymentVouchers<T>(companyCode, chitGroupId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator/chit-auction-minutes/chit-payment-voucher/${chitGroupId}?companyCode=${companyCode}`;
        } else {
            url = `${SNB_CORE_URL}/foreman/chit-auction-minutes/chit-payment-voucher/${chitGroupId}`;
        }
        return this.httpClient.get<T>(`${url}`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    validateCorrectionPeriodForUpdatingVouchers<T>(req?: any) {
        if(!req) {
            req = {}
        };

        const options = this.prepareHeader(req);
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chit-auction-minutes/validate-voucher-correction`, {
            headers: options["headers"],
            params: options["params"]
        })
        .pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getPreviousMonthStatment<T>(bankId,finYear,month,req?:any):Observable<any>{
        const options = this.prepareHeader(req);
        const url = `foreman/bank-statement/${bankId}/previous-month-statement?financialYear=${finYear}&month=${month}`;
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    getBankStatement<T>(bankId,id,req?:any):Observable<any>{
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator/bank-statement/${bankId}/${id}`;
        } else {
            url = `${SNB_CORE_URL}/foreman/bank-statement/${bankId}/${id}`;
        }
        return this.httpClient.get<T>(`${url}`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }
    getBankStatementLedger<T>(bankId,req?:any): Observable<any>{
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator/bank-statement-ledger/${bankId}`;
        } else {
            url = `${SNB_CORE_URL}/foreman/bank-statement-ledger/${bankId}`;
        }
        return this.httpClient.get<T>(`${url}`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getSubscribersList<T>(chitRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chits/chit-subscribers/get-mini-details`;
        } else {
            url = `foreman/chits/chit-subscribers/get-mini-details`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}/${chitRefId}?sort=serialNumber,asc`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getPrizedSubscribersList<T>(chitRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chits/chit-subscribers/get-mini-details`;
        } else {
            url = `foreman/chits/chit-subscribers/get-mini-details`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}/${chitRefId}?sort=serialNumber,asc&state=PS`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getPrizedSubscribersListForPublic<T>(chitRefId, req?: any): Observable<any> {
        const options = this.prepareHeader(req);   
        return this.httpClient.get(`${SNB_CORE_URL}/public/chits/chit-subscribers/get-mini-details/${chitRefId}?sort=serialNumber,asc&state=PS&state=NPS`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getArbitrationNumber<T>(req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `/regulator/arbitration/check-offline-arbitration`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `/foreman/arbitration/check-offline-arbitration`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}${url}`, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

   
    getSubscriberDetailsById<T>(subscriberRefId, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chits/chit-subscribers/by-subscriber-id`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `foreman/chits/chit-subscribers/by-subscriber-id`;
        }else {
            url= `public/chits/chit-subscribers/by-subscriber-id`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}/${subscriberRefId}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getAuctionMinutesByinstallmentNumber<T>(chitRefId, installmentNumber, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `regulator/chit-auction-minutes/${chitRefId}/${installmentNumber}`;
        } else {
            url = `foreman/chit-auction-minutes/${chitRefId}/${installmentNumber}`;
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getMinutePaymentVouchersByChitForPublic<T>(chitRefId, companyCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        const url = `public/chit-auction-minutes/chit-payment-voucher/${companyCode}/${chitRefId}`;
        
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    createServiceRequest<T>(data, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        const copy = Object.assign({}, data);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/misc/service-request`, copy, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    
    createCorrectionApplicationRequest<T>(data, req?: any): Observable<any> {
        const options = this.prepareHeader(req);
        const copy = Object.assign({}, data);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/misc/correction-request `, copy, options).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    validateLedgerData<T>(chitId, ledgerData, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_CORE_URL}/foreman/chits/validate-ledger-details/${chitId}`, ledgerData, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getNextAuctionDates<T>(cron, firstAuctionDate, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        const firstAucDate = new Date(this.formatInputs.formatDateToTimeStampToMilliSeconds(firstAuctionDate)).toISOString();
        // tslint:disable-next-line: max-line-length
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chit-auction-minutes/calc-auction-date?first-auction-date=${(firstAucDate)}&auction-cron=${encodeURI(cron).replace('#', '%23')}`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    getChitGroupsByAuctionDate<T>(auctionDate, foremanBranchCode, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        // tslint:disable-next-line: max-line-length
        return this.httpClient.get(`${SNB_CORE_URL}/foreman/chit-auction-minutes/get-chit-auctions-scheduled-on-date?auctionDate=${auctionDate}&foremanBranchCode=${foremanBranchCode}`,
            options).pipe(catchError((err) => {
                return observableThrowError(err);
            }));
    }

    submitRescheduleForm<T>(data, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/chit/submit-chit-postpone-request`, data, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    cancelApplication<T>(processInstanceId, data, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        return this.httpClient.post(`${SNB_WF_URL}/foreman/delete-process/${processInstanceId}`, data, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    /**
     * This method returns payment vouchers paid to Prized Subscriber
     * @param chitRefId holds any other request paramters. can be null.
     * @param req holds any other request paramters. can be null.
     * @returns http response which contains the response and page details.
     */
    getPaymentVoucher<T>(chitRefId: string, prizedSubscriberTktId: number,req?: any):Observable<any> {
        if(!req) {
            req = {};
        }
        if(!req.query) {
            req.query = {}
        }
        req.query.amountPaidToTktNumber = prizedSubscriberTktId;
      
        const copy: any = Object.assign({}, req);
        const options = this.httpUtilService.createRequestOption(copy);
        return this.httpClient.get(`${SNB_CORE_URL}/${this.getUerType()}/chit-payment-voucher/${chitRefId}`,  
        { headers: options['headers'], params: options['params'], observe: 'response'}).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    checkNextMonthBSAsDraft<T>(finYr: string,bankId: string, month: number, req?: any) {
        if(!req) {
            req = {};
        }
        req.query = {
            'financialYear': finYr,
            'bankId': bankId,
            'month': month
        };

        const options = this.httpUtilService.createRequestOption(req);
        let url = `foreman/bank-statement/subsequent-month-exists`;
        return this.httpClient.get(`${SNB_CORE_URL}/${url}`, {
            headers: options['headers'],
            params: options['params'],
            observe: 'body'
          }).pipe(
            catchError((err) => {
              return observableThrowError(() => err);  // Using observableThrowError to handle errors
            })
          );
    }

    

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set('stateIn', urlSearchParams.stateIn);
            }
            if (urlSearchParams.query) {
                params = params.set('query', urlSearchParams.query);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }

}
