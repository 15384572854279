import { Component, Inject, Input } from "@angular/core";
import { Task, TaskStatus } from "../../models/task.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-progress-dialog",
    templateUrl: "./progress-dialog.html",
    styleUrls: ["./progress-dialog.component.scss"],
})
export class ProgressDialogComponent {
    tasks: Task[];
    title: string = "Progress";

    constructor(
        private dialogRef: MatDialogRef<ProgressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.tasks = data.tasks;
        if(data.title) {
          this.title = data.title;
        }
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public updateTheTask(task: Task) {
        let index = this.tasks.findIndex((t) => t.id === task.id);
        if (index >= 0) {
            this.tasks.splice(index, 1, task);
        }
    }
}
