
import { Observable, forkJoin, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SNB_CORE_URL, SNB_REPORTS_URL, USER_TYPES } from '../../app.constants';

import { ReportRequest } from '../models/report-request.model';

@Injectable()
export class ReportService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getResourceUrl() {
        if (USER_TYPES.IS_FOREMAN) {
            return SNB_CORE_URL + '/foreman/chit-reports';
        } else if (USER_TYPES.IS_REGULATOR) {
            return SNB_CORE_URL + '/roc/chit-reports';
        }
    }

    getReportServiceResourceUrl() {
        if (USER_TYPES.IS_FOREMAN) {
            return SNB_REPORTS_URL + '/foreman/chit-reports';
        } else if (USER_TYPES.IS_REGULATOR) {
            return SNB_REPORTS_URL + '/roc/chit-reports';
        }
    }

    /**
     * Generates the report on the fly and downloads the generated report.
     * There are two ways of using this function.
     * 1) if you want to have preview before creating actuall Notice, then send isPreview = true, inputData object.
     * 2) if the notice is already created, just use noticeId and isPreview = false.
     * @param reportName holds type of report need to be downloaded
     * @param reportFormat type of the FORMANT i.e PDF/XLSx/rocV
     * @param noticeId holds unique id of the notice. Server will fetch
     *                 all required data using this id and generates the report.
     * @param isPreview if you pass true, it will use inputData as data to fill the report. And ignores noticeId.
     * @param request holds the request params or request object
     */
    downloadReport(reportName: string, reportFormat: string, isPreview: boolean, request?: any) {

        const urlSearchParams = {};
        urlSearchParams['query'] = { reportName, reportFormat, isPreview };

        if (request) {
            urlSearchParams['query'] = Object.assign(urlSearchParams['query'], request);
        }
        const options = this.prepareHeader(urlSearchParams);
        options['responseType'] = 'arraybuffer';
        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url =  `${SNB_REPORTS_URL}/foreman/chit-reports/download`;
        } else if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/${request.companyCode}`;
        }
        return this.httpClient.get(url, { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    downloadReportById(reportId: string, reportFormat: string, req?: any) {

        if (!req) {
            req = {};
        }
        if (reportFormat) {
            req['reportFormat'] = reportFormat;
        }
        const options = this.prepareHeader(req);
        options['responseType'] = 'blob';
        const url = `${this.getReportServiceResourceUrl()}/download/${reportId}`;
        return this.httpClient.post(url, { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(map((res: Response) => {
            return res;
        }));
    }

    /**
     * This is same as ReportRequest
     * @param reportId
     * @param reportFormat
     * @param req
     */
    downloadStaticReportByReportRequest(reportId: string, reportRequest: ReportRequest) {

        const options = this.prepareHeader({});
        options['responseType'] = 'arraybuffer';
        let url = `${this.getReportServiceResourceUrl()}`;
        if (USER_TYPES.IS_REGULATOR) {
            url = `${url}/${reportRequest.companyCode}`;
        }
        url = `${url}/download-report-from-static-templates/${reportId}`;

        return this.httpClient.post(url, reportRequest, { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    /**
     * Downloades the report based on the given report request.
     */
    downloadReportByReportRequest(reportId: string, businessReportId, reportRequest: ReportRequest) {
        const options = this.prepareHeader({});
        options['responseType'] = 'arraybuffer';

        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url =  `${SNB_REPORTS_URL}/foreman/chit-reports/download/${reportId}`;
        } else if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/TS/download/${reportId}`;
        }
        if (businessReportId) {
            url += `/${businessReportId}`;
        }
        return this.httpClient.post(url, reportRequest, { headers: options['headers'], responseType: options['responseType'], params: options['params'], observe: 'response' }).pipe(
            catchError((err) => {
                return observableThrowError(err);
            }));
    }

    public requestDataFromMultipleSources(companyCode, req1, req2): Observable<any[]> {
        const response1 = this.getAllChmBusinessReports(companyCode, req1);
        const response2 = this.getAllChmBusinessReports(companyCode, req2);
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([response1, response2]);
    }

    getAllChmBusinessReports(companyCode, req?: any): Observable<HttpResponse<any>> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/TS/business-reports`;
        } else {
            url = `${SNB_REPORTS_URL}/foreman/business-reports`;
        }
        return this.httpClient.get<any>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    crateChmBusinessReport(data, req?: any): Observable<HttpResponse<any>> {
        const options = this.prepareHeader(req);
        return this.httpClient.post<any>(`${SNB_REPORTS_URL}/${USER_TYPES.IS_FOREMAN ? 'foreman' : 'cs'}/business-reports`, data, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getReportMetaDataByReportName(reportName: string, companyCode): Observable<HttpResponse<any>> {
        const options = this.prepareHeader({});
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/TS`;
        } else {
            url = `${SNB_REPORTS_URL}/foreman/chit-reports`;
        }
        return this.httpClient.get<any>(`${url}/get-report-meta/${reportName}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getReportDataByReportName(reportName: string, data, companyCode, req?: any): Observable<HttpResponse<any>> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/TS`;
        } else {
            url = `${SNB_REPORTS_URL}/foreman/chit-reports`;
        }
        return this.httpClient.post<any>(`${url}/generate/${reportName}`, data, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getReportDataByReportNameForCustomReports(reportName: string, businessReportId, companyCode, data, req?: any): Observable<HttpResponse<any>> {
        const options = this.prepareHeader(req);
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/TS`;
        } else {
            url = `${SNB_REPORTS_URL}/foreman/chit-reports`;
        }
        return this.httpClient.post<any>(`${url}/generate/${reportName}/${businessReportId}`, data, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getFiltersForBusinessreport(reportId: string, businessReportId, companyCode): Observable<HttpResponse<any>> {
        const options = this.prepareHeader({});
        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_REPORTS_URL}/foreman/chit-reports`;
        } else if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_REPORTS_URL}/roc/chit-reports/${companyCode}`;
        }
        return this.httpClient.get<any>(`${url}/get-filters/${reportId}/${businessReportId}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();
        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set('stateIn', urlSearchParams.stateIn);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (typeof (urlSearchParams.query[key]) !== 'undefined') {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }
}
