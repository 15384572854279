<div class="snb_filter">
    <mat-form-field class="demo-chip-list">
        <mat-chip-grid #chipList>
            <span matPrefix>
                <i class="material-icons filter_icon">filter_list</i>
            </span>
            <div *ngFor="let item of selectedItems"
                [ngStyle]="(item?.type=='dateDuration' || item?.type=='finYear' || item?.type=='dateDurationAndFinYear') && {'width': '415px'}"
                class="mat-chip mat-primary mat-standard-chip mat-chip-with-trailing-icon" (click)="setFocus(item)">

                <span class="filter_key" matTooltip="{{item?.name}}" matTooltipPosition="above"
                    style="font-weight: bold;">
                    {{item.name}}
                </span>

                <button mat-icon-button
                    *ngIf="item?.attribute === activeItem && (item?.type !== 'input' && item?.type !== 'number')"
                    id="btn-{{item?.attribute?.replace('.','')}}" class="filter-buttons invisible-menu-button"
                    [matMenuTriggerFor]="dropdownMenu" style="height: 0px; width: 0px;top: 20px;"> </button>
                <input autocomplete="off" spellcheck="false" class="filter_val string with_uri" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="(!item.type || item.type==='string') && item?.uri" id="{{item?.attribute?.replace('.','')}}"
                    (keyup)="getFilterValues(item, $event)" (keyup.enter)="emitCommonFunc()"
                    (focus)="activeItem=item?.attribute; getFilterValues(item, $event)" [(ngModel)]="item.value" />
                <input autocomplete="off" spellcheck="false" class="filter_val string no_uri" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput 
                    *ngIf="(!item.type || item.type==='string') && !item.uri" id="{{item?.attribute?.replace('.','')}}"
                    (keyup.enter)="emitCommonFunc()" [(ngModel)]="item.value" (blur)="emitCommonFunc()"
                    (focus)='activeItem=item?.attribute' />

                <!-- 
                    If the type is number and auto complete is required. Its auto complete field and it calls 
                    getFilterValues on input and displays the values from the server.
                -->
                <input autocomplete="off" spellcheck="false" class="filter_val string with_uri" type="number" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="item.type==='number' && item?.uri" id="{{item?.attribute?.replace('.','')}}"
                    (keyup)="getFilterValues(item, $event)" (keyup.enter)="emitCommonFunc()"
                    (focus)="activeItem=item?.attribute; getFilterValues(item, $event)" [(ngModel)]="item.value" />
                
                <!-- 
                    If the type is number and its not a auto completed and plain Input field and user has to input. 
                -->
                <input autocomplete="off" spellcheck="false" class="filter_val number" type="{{item.type=='number'? 'number': 'text'}}" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="(item.type=='input' || item.type=='number') && !item?.uri"
                    id="{{item?.attribute?.replace('.','')}}" (keyup.enter)="emitCommonFunc()" (blur)="emitCommonFunc()"
                    (focus)='activeItem=item?.attribute; closeAutoComplete()' [(ngModel)]="item.value" />

                <mat-select *ngIf="item.type=='enum' && item?.uri" class="filter_val"
                    style="width: auto;margin: 1px 0px 1px 6px;min-width: 185px !important;" [(ngModel)]="item.value"
                    [placeholder]="item.value? item.value :'Select value'"
                    (focus)="activeItem=item?.attribute; getFilterValues(item, $event)" panelClass="filter-select">
                    <mat-option [value]="item.value">{{ item.value }}</mat-option>
                </mat-select>
                <input autocomplete="off" spellcheck="false" class="filter_val number" readonly placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="item.type=='enum' && !item?.uri" id="{{item?.attribute?.replace('.','')}}"
                    (focus)='activeItem=item?.attribute; setFilterItems(item?.enumValues); closeAutoComplete(); dropdown?.openMenu();'
                    [(ngModel)]="item.value" />

                <app-date-range-picker id="{{item?.attribute?.replace('.','')}}" (click)='activeteFocusedItem(item);'
                    [defaultValue]='item?.value' [rangeOptions]='item.type'
                    (dateChanged)="onDateChanged({value: $event, item: item})"
                    *ngIf="item?.type=='dateDuration' || item?.type=='finYear' || item?.type=='dateDurationAndFinYear'">
                </app-date-range-picker>

                <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons"
                    *ngIf="!item?.isMandatory" (click)="remove(item)">
                    cancel
                </mat-icon>

            </div>
            <input matInput #inputAutoComplit [matChipInputFor]="chipList" placeholder="Filter.."
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                [matAutocomplete]="auto" [formControl]="stateCtrl" (matChipInputTokenEnd)="add($event)"
                (click)="openAutocomplete($event)" readonly />

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let state of filteredStates" [value]="state.attribute" class="filter-option">
                    <span>{{ state.name }}</span>
                </mat-option>
            </mat-autocomplete>

        </mat-chip-grid>


        <mat-menu #dropdownMenu yPosition="below" xPosition="after" class="filter-dropdown-items">
            <div class="filter-items" *ngFor="let item of filterItems" (click)="selectItemFromDropdown(item)">
                <div>{{item}} </div>
            </div>
            <div class="filter-items" *ngFor="let item of filterObjectItems" (click)="selectObjectFromDropdown(item)">
                <app-cm-data-bind [data]="item" [property]="focusedItem"></app-cm-data-bind>
            </div>
            <div class="filter-items" *ngFor="let item of filterEnumObjectItems"
                (click)="selectEnumObjectFromDropdown(item)">
                <app-cm-data-bind [data]="item" [property]="focusedItem"></app-cm-data-bind>
            </div>
            <div *ngIf="(filterObjectItems.length === 0) && (filterItems.length === 0)">No Match Found!</div>
        </mat-menu>


    </mat-form-field>


</div>