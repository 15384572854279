<div class="snb_login" [ngClass]="{'document-loaded' : intialScreenAnimationClass}">
  <div class="change_pw" *ngIf="checkedForValidSession">
    <form class="form" [formGroup]="loginForm" novalidate autocomplete="off" (ngSubmit)="login(loginForm, $event)">
      <div class="form__cover"></div>
      <div class="form__loader">
        <div class="spinner active">
          <svg class="spinner__circular" viewBox="25 25 50 50">
            <circle class="spinner__path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10">
            </circle>
          </svg>
        </div>
      </div>
      <div class="form__content">

        <div class="top_sec" *ngIf="!ismfaEnabled">
          <p>
            <span class="animated fadeInUp" *ngIf="isRocApp">ROC</span>
            <span class="animated fadeInUp" *ngIf="isForemanApp">Foreman</span>
          </p>

          <h2>Login</h2>
        </div>
        <div *ngIf="!ismfaEnabled">
          <div class="styled-input">
            <mat-form-field class="md-block" appearance="outline" class="snb-form-field-without-icon">
              <mat-label>Mobile Number</mat-label>
              <input type="text" formControlName="username" matInput required>
              <mat-error *ngIf="loginForm.controls.username.hasError('required')"
                jhiTranslate="entity.validation.required"></mat-error>
            </mat-form-field>
          </div>

          <div class="styled-input text-left">
            <mat-form-field class="md-block" appearance="outline" class="snb-form-field-without-icon">
              <mat-label>Password</mat-label>
              <input type="password" formControlName="password" matInput required>
              <mat-error *ngIf="loginForm.controls.password.hasError('required')"
                jhiTranslate="entity.validation.required"></mat-error>
            </mat-form-field>
            <!-- <mat-slide-toggle>Remember me</mat-slide-toggle> -->
            <!-- <a >Reset Password</a> -->
            <!-- <a [routerLink]="['/password-reset']">Reset Password</a> -->

          </div>

        </div>
        <div *ngIf="ismfaEnabled">
          <div class="styled-input">
            <div class="icon-block">
              <img src="assets/img/otp-icon.png" alt="Otp Logo" style="max-width: 70%; max-height: 70%;" />
            </div>
            <br>
            <div *ngIf="!isRecoveryCodes" style="margin-top: 15px;">
              <span style="margin: 17px;">
                Enter the 6 digts Code from your Authenticator App
              </span>
              <mat-form-field class="md-block" style="margin-top: 57px;" appearance="outline" class="snb-form-field-without-icon">
                <mat-label>Verification OTP</mat-label>
                <input type="text" maxlength="6" formControlName="generatedToken" matInput>
              </mat-form-field>
            </div>
            <div *ngIf="isRecoveryCodes" style="margin-top: 15px;">
              <span style="margin: 17px;">
                Enter the anyone of Recovery Codes from Your Saved Codes
              </span>
              <mat-form-field class="md-block" style="margin-top: 57px;" appearance="outline" class="snb-form-field-without-icon"> 
                <mat-label>Recovery Codes</mat-label>
                <input type="text" formControlName="recoveryCode" matInput>
              </mat-form-field>
            </div>
          </div>

        </div>
        <!--         <div class="styled-input text-left">
          <mat-radio-group formControlName="loginAsForeman">
            <mat-radio-button value="false">ROC</mat-radio-button>
            <mat-radio-button value="true">Foreman</mat-radio-button>
          </mat-radio-group>
        </div> -->

        <button mat-raised-button type="submit" [disabled]="isRequestInProgress" class="mat-raised-button">
          <span class="mat-button-wrapper">
            LOG IN
          </span>
        </button>

        <div class="remember-forgot-password" *ngIf="!ismfaEnabled">
          <a [routerLink]="['/password-reset']">Forgot Password?</a>
        </div>
        <!-- <div class="remember-forgot-password" *ngIf="ismfaEnabled ">
          <a class="recovery_codes_button" (click)="selectionChange()">Login with Recovery Codes</a>
        </div> -->

        <div>

        </div>

      </div>

    </form>

  </div>
</div>