import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaPageTitleService } from '../../theme/services';
import { AuthServerProvider } from '../../shared';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SubscriberCornerService } from './subscriber-corner.service';
import { HttpClient } from '@angular/common/http';
import { SnBToasterService } from '../../shared/components/toaster/toaster.service';

@Component({
    templateUrl: './subscriber-corner-login-dialog.html',
    styleUrls: ['./../applications/service-request/delete-application-dialog.scss']
})
export class SubscriberAuthDialogComponent {

    authForm: FormGroup;
    optGenerated = false;
    userData: any;

    constructor(
        private toasterService: SnBToasterService,
        public dialogRef: MatDialogRef<SubscriberAuthDialogComponent>,
        private authServerProvider: AuthServerProvider,
        private sessionStorage: SessionStorageService,
        private subscriberCornerService: SubscriberCornerService,
        public fb: FormBuilder
    ) {
        this.authForm = this.fb.group({
            name: ['', Validators.required],
            login: ['', Validators.required]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    validateMobile(mobileNumber) {
        const re = /^[0]?[789]\d{9}$/;
        return re.test(mobileNumber);
    }

    submitAuth(event: Event) {
        event.preventDefault();
        if (this.authForm.invalid) {
            return false;
        }
        if (this.optGenerated) {
            const data = this.authForm.getRawValue();
            const credentials: any = {};
            credentials.username = this.userData.login;
            credentials.otp = data.otp;
            credentials.client_id = 'user_portal_app_client';
            credentials.client_pw = 'upacOVfJYgQYHSubgMBMn2bXIMZe4';

            try {
                this.authServerProvider.validateOTP(credentials).subscribe((res) => {
                    this.sessionStorage.store('userAuthenticationToken', res.access_token);
                    this.dialogRef.close(true);
                }, (resp: Response) => {
                    console.log('otp validation failed');
                    this.toasterService.showFailToaster("Invalid OTP");
                });
            } catch (e) {
                // this.onError('error.invalidClient', null);
            }
        } else {
            this.userData = this.authForm.value;
            const self = this;
            this.subscriberCornerService.generateOTP(this.userData).subscribe((val: any) => {
                this.sessionStorage.store('complainerName', this.userData.name);
                this.optGenerated = true;
                this.authForm.addControl('otp', new UntypedFormControl('', Validators.required));
                this.authForm.controls.name.disable();
                this.authForm.controls.login.disable();
                setTimeout(function () {
                    self.authForm.controls.otp.reset();
                    self.authForm.controls.otp.markAsUntouched();
                }, 1000);
            }, (res: Response) => {
                console.log('otp generation failed');
            });
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

}


@Component({
    templateUrl: './subscriber-corner.html',
    styleUrls: ['./subscriber-corner.scss']
})

export class SubscriberCornerComponent implements OnInit {

    otpVerified = false;
    isLoggedIn: boolean = false;
    constructor(
        private pageTitleService: BaPageTitleService,
        private sessionStorage: SessionStorageService,
        private dialog: MatDialog,
        private router: Router,
        private httpClient: HttpClient,
        private toasterService: SnBToasterService,
    ) {



        const currentVersion = localStorage.getItem('currentVersion');
        this.httpClient.get('./assets/js/manifest.json').subscribe(data => {
            const manifestData = data;
            if (manifestData && manifestData["version"] && manifestData["build_number"]) {
                const buildVersion = manifestData["version"] + '.' + manifestData["build_number"];
                if (currentVersion && buildVersion !== currentVersion) {
                    localStorage.setItem('currentVersion', buildVersion);
                    console.log('Version upgraded to ' + buildVersion + '. Reloading the client application');
                    location.reload();
                } else {
                    localStorage.setItem('currentVersion', buildVersion);
                }
            }
        });
    }

    ngOnInit() {
        this.pageTitleService.currentMessage.subscribe();
        this.pageTitleService.changeMessage('Citizen Corner');
        this.pageTitleService.hideBackButton(true);
        this.checkAuth();
    }

    logout() {
        this.sessionStorage.clear();
        localStorage.clear();
        this.toasterService.showSuccessToaster("Logged out successfully !")
        this.isLoggedIn = false;
    }

    checkAuth() {
        const response = this.sessionStorage.retrieve('userAuthenticationToken');

        if (response) {
            if (response.expires_at && response.expires_at < new Date().getTime()) {
                this.sessionStorage.clear('userAuthenticationToken');
                this.sessionStorage.clear('complainerName');
                this.isLoggedIn = false;
                return false;
            } else {
                this.isLoggedIn = true;
                return true;
            }
        } else {
            return false;
        }
    }

    showAuthDialog(url, params) {
        const dialogRef = this.dialog.open(SubscriberAuthDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '440px',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.isLoggedIn = true;
                this.checkParamsAndGoToPage(url,params);
            }
        });
    }

    checkAuthAndGoToPage(url,params) {
        if (this.checkAuth()) {
            this.isLoggedIn = true;
            this.checkParamsAndGoToPage(url,params);
        } else {
            this.showAuthDialog(url,params);
        }
    }

    checkParamsAndGoToPage(url,params) {
        if(!params) {
            this.router.navigate([url]);
        } else {
            this.router.navigate([url], { queryParams: params });
        }
    } 

}


@Component({
    templateUrl: './../payment/payment-status.html',
    styleUrls: ['./../payment/payment.scss'],
})

export class SubscriberPaymentComponent implements AfterViewInit {
    transactionStatus: any = {};
    message: string;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pageTitleService: BaPageTitleService
    ) {
    }
    errorOccured = false;

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            if (params.trnxId) {
                this.getTransactionStatus(parseInt(params['trnxId'], 0));
            } else {
                this.getTransactionStatus('');
            }
        });
        this.pageTitleService.currentMessage.subscribe();
        this.pageTitleService.changeMessage('Payment Status');
        this.pageTitleService.hideBackButton(true);
    }

    getTransactionStatus = function (trnxId) {
        if (trnxId === -1 || !trnxId) {
            this.transactionStatus.trnxStatus = 'FAILCHECKSUM';
            return;
        }
        this.paymentService.getTransaction(trnxId).subscribe(
            (success) => {
                this.transactionStatus = success;
                console.log('transaction status is ' + JSON.stringify(this.transactionStatus));
            }, (fail) => {
                if (fail) {
                    this.toasterService.showCustomError(fail);
                }
            }
        );

    };

    goToPage(route: string) {
        if(route) {
            this.router.navigate([route]);
        } else {
            this.router.navigate(['/pages/citizen-corner']);
        }
    }
}
