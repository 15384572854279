import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ChitmonksApiSharedLibsModule } from './shared-libs.module';
import { DataBindingComponent, FilterCriteriaNewComponent } from './filter/filter-criteria-new.component';
import { FilterCriteriaService } from './filter/filter-criteria.service';
import { FilterCriteriaNewService } from './filter/filter-criteria-new.service';
import { FilterComponent } from './filter/filter-criteria.component';
import { MatSelectSearchComponent } from './filter/mat-select-search/mat-select-search.component';
import { WindowScrollService } from './services/window-scroll.service';
import { RefreshDataComponent } from './components/refresh-data/refresh-data.component';
import { SnBToasterService } from './components/toaster/toaster.service';
import { FindLanguageFromKeyPipe, TranslateDirective } from './language';
import { SubscriberEntryComponent } from './components/subscriberEntry/subscriberEntry.component';
import { SnbProcessCommentsComponent } from './components/process-comments/process.comments';
import { PublicFilterCriteriaService, PublicSerachFilterComponent } from './public-search-filter/public-search-filter.component';
import { ProgressDialogComponent } from './components/task-progress/progress-dialog.component';
import { JhiAlertComponent } from './alert/alert.component';
import { JhiAlertErrorComponent } from './alert/alert-error.component';
import { CmDatePickerComponent } from './components/date-picker/date-picker.component';
import { CmAddressComponent, SnBAddressFieldsDialogComponent } from './components/address/address.component';
import { SubscriberAuthDialogComponent } from '../pages/subscriber-corner/subscriber-corner.component';
import { PaymentTransactionDetailsComponent } from '../pages/payment/transactions/transaction-detail.component';
import { PaymentFormComponent, TWalletDisabledDialogComponent } from '../pages/payment/payment-form.component';
import { AppTwalletAutoSubmitFormComponent } from '../pages/payment/app-twallet-auto-submit-form';
import { PaymentStatusComponent } from '../pages/payment/payment-status.component';
import { SnBDateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { ApplicationSentBackComponent } from './components/appl-sent-back/application-sent-back.component';
import { LeftPanelComponent } from './components/left-block-panel/left-panel.component';
import { RightPanelComponent } from './components/right-block-panel/right-panel.component';
import { PrintComponent } from './components/print/print.component';
import { LeftContentBlockComponent, RightContentBlockComponent } from './components/form-content-block/form-content-block.component';
import { AddForemanToBranchDialogComponent, DeleteForemanDialogComponent } from '../pages/foreman-companies/foreman-companies-new/foreman-companies-new.component';
import { TableLoadingComponent } from './components/table-loader/table-loader.component';
import { DeleteApplicationDialogComponent } from '../pages/applications/service-request/delete-application-dialog.component';
import { DateRangePickerComponent } from './components/date-range-picker-1/date-range-picker.component';
import { CommentComponent } from './addComment/comment.component';
import { ArbInstAndDueInterestInfoDialog } from './arb-inst-and-due-interest-info-dialog/arb-inst-and-due-interest-info-dialog.component';
import { ArbInstDueAndInterestInfoComponent } from './components/arb-inst-and-due-interest-info/arb-inst-due-and-interest-info.component';
import { PdfPreviewDialogComponent } from './components/pdf-preview-dialog/pdf-preview-dialog.component';
import { FormatInputs } from './format-values';
import { ErrorConfigService } from './components/error/errors.config.service';
import { AutoCompleteFilterCriteriaService, AutoCompleteFormFieldComponent } from './autoCompleteField/auto-complete-form-field.component';
import { SubscriberCornerService } from '../pages/subscriber-corner/subscriber-corner.service';
import { SnBFormIconComponent } from './components/formicon/formicon.component';
import { SNBFileModule } from './components/file-upload/file-upload.module';

export function alertServiceProvider(
    sanitizer: DomSanitizer,
    // configService: JhiConfigService,
    ngZone: NgZone,
    translateService: TranslateService) {
    // set below to true to make alerts look like toast
    const isToast = true;
    // return new JhiAlertService(sanitizer, configService, ngZone, translateService);
}

@NgModule({
    imports: [
        ChitmonksApiSharedLibsModule,
        RouterModule,
        SNBFileModule,
        FindLanguageFromKeyPipe,
        TranslateDirective,
        TranslateModule
    ],
    declarations: [
        JhiAlertComponent,
        JhiAlertErrorComponent,
        CmDatePickerComponent,
        CmAddressComponent,
        PaymentFormComponent,
        PaymentTransactionDetailsComponent,
        AppTwalletAutoSubmitFormComponent,
        PaymentStatusComponent,
        SnBAddressFieldsDialogComponent,
        MatSelectSearchComponent,
        SnBDateRangePickerComponent,
        SnBDateRangePickerComponent,
        FilterComponent,
        DataBindingComponent,
        FilterCriteriaNewComponent,
        PublicSerachFilterComponent,
        AutoCompleteFormFieldComponent,
        RefreshDataComponent,
        ApplicationSentBackComponent,
        SnBFormIconComponent,
        LeftPanelComponent,
        RightPanelComponent,
        PrintComponent,
        SnbProcessCommentsComponent,
        SubscriberAuthDialogComponent,
        // LeftContentBlockComponent,
        // RightContentBlockComponent,
        LeftContentBlockComponent,
        RightContentBlockComponent,
        TWalletDisabledDialogComponent,
        SubscriberEntryComponent,
        AddForemanToBranchDialogComponent,
        DeleteForemanDialogComponent,
        // TableLoadingComponent,
        // DeleteApplicationDialogComponent,
        // DateRangePickerComponent,
        TableLoadingComponent,
        DeleteApplicationDialogComponent,
        DateRangePickerComponent,
        CommentComponent,
        ProgressDialogComponent,
        ArbInstAndDueInterestInfoDialog,
        ArbInstDueAndInterestInfoComponent,
        // PdfPreviewDialogComponent
        
        PdfPreviewDialogComponent
    ],
    providers: [
        // JhiLanguageHelper,
        SnBToasterService,
        // {
        //     provide: JhiAlertService,
        //     useFactory: alertServiceProvider,
        //     deps: [Sanitizer, TranslateService]
        // },
        Title,
        FormatInputs,
        ErrorConfigService,
        FilterCriteriaService,
        FilterCriteriaNewService,
        AutoCompleteFilterCriteriaService,
        PublicFilterCriteriaService,
        SubscriberCornerService,
        WindowScrollService
    ],
    exports: [
        ChitmonksApiSharedLibsModule,
        FindLanguageFromKeyPipe,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        CommentComponent,
        FilterComponent,
        DataBindingComponent,
        FilterCriteriaNewComponent,
        PublicSerachFilterComponent,
        AutoCompleteFormFieldComponent,
        RefreshDataComponent,
        ApplicationSentBackComponent,
        CmDatePickerComponent,
        CmAddressComponent,
        PaymentFormComponent,
        PaymentTransactionDetailsComponent,
        PaymentStatusComponent,
        // AppTwalletAutoSubmitFormComponent,
        AppTwalletAutoSubmitFormComponent,
        SnBAddressFieldsDialogComponent,
        MatSelectSearchComponent,
        SnBDateRangePickerComponent,
        SnBFormIconComponent,
        LeftPanelComponent,
        RightPanelComponent,
        PrintComponent,
        SnbProcessCommentsComponent,
        LeftContentBlockComponent,
        RightContentBlockComponent,
        SubscriberEntryComponent,
        TableLoadingComponent,
        DateRangePickerComponent,
        ProgressDialogComponent,
        ArbInstAndDueInterestInfoDialog,
        ArbInstDueAndInterestInfoComponent,
        TranslateDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChitmonksApiSharedCommonModule { }
