import { Injectable } from '@angular/core';

import { Principal } from '../auth/principal.service';
import { AuthServerProvider } from '../auth/auth-oauth2.service';
import { JhiLanguageService } from '../language/language.service';

@Injectable()
export class LoginService {

    constructor(
        private languageService: JhiLanguageService,
        private principal: Principal,
        private authServerProvider: AuthServerProvider
    ) { }

    login(credentials, callback?) {
        let cb = callback || function () { };
        let nonce = this.generateNonce(16);
        credentials.nonce = nonce;
        window.localStorage.setItem('nonce', nonce);
        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(data => {
                this.principal.identity(true).then(account => {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account !== null && account.langKey !== null) {
                        this.languageService.changeLanguage(account.langKey);
                    }
                    resolve(data);
                });
                return cb();
            }, err => {
                this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.principal.authenticate(null);
    }


    generateNonce(length): string {
        let text = '';
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ''
        result = this.generateId(length);
        /*while (length > 0) {
            let bytes = new Uint8Array(16);
            let randomBytes = window.crypto.getRandomValues(bytes);
            let randomByteArray = Array.from(randomBytes);
            for (let index = 0; index < randomByteArray.length; index++) {
                const c = randomByteArray[index];
                if (length == 0) {
                    return;
                }
                if (c < charset.length) {
                    result += charset[c];
                    length--;
                }
                
            }
        }*/
        console.log('generate nonce', result);
        return result;
    }

    dec2hex (dec) {
        return dec.toString(16).padStart(2, "0")
    }
      
    // generateId :: Integer -> String
    generateId (len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
      }
}
