<div class="custom-data-table">

    <div class="row no-margin">
        <div class="col-md-7 col-xs-12">
            <div class="timeline_card text-left">
                <div class="subscribers_list custome_table">
                    <mat-table #table [dataSource]="organisationBranches">

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon"> <mat-icon matPrefix>mkmbusiness</mat-icon> </span>
                                <span class="afterFont">Branch Name</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="mobileNumber">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                                <span class="afterFont">Branch Mobile No.</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.contactDetails.mobileNumber}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>
                                <span class="fontIcon"><i class="fa fa-cog" aria-hidden="true"></i></span>
                                <span class="afterFont">Actions</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a [matMenuTriggerFor]="workFlowActions">
                                    <i class="material-icons actions">more_vert</i>
                                </a>
                                <mat-menu xPosition="before" yPosition="below" #workFlowActions="matMenu">
                                    <button mat-menu-item class="chit-group-row-action foreman-company-more-actions " (click)="editBranch($event)">
                                        <i class="material-icons">edit</i>
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item class="chit-group-row-action foreman-company-more-actions" (click)="addForeman()"
                                        *ngIf="isRegulatorSuperAdmin">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span>Add Foreman</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row (click)="showMoreDetails(row, $event)" *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                    </mat-table>
                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>
                    <div *ngIf="organisationBranches?.length===0 && !requestInProgress" class="empty-row">No data to
                        display</div>

                    <mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="itemsPerPage"
                        [showFirstLastButtons]="true"></mat-paginator>

                </div>
            </div>
        </div>
        <div class="col-md-5 hidden-xs" *ngIf="organisationBranches?.length">
            <div class="right-col">
                <div class="step-col-content">
                    <h3 class="step-col-label">
                        More Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col" *ngIf="selectedBranchData?.status">
                            <span class="property">
                                Status
                            </span>
                            <div class="value s-col-ellipsis">
                                {{selectedBranchData?.status}}
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Email
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="selectedBranchData?.contactDetails?.email"
                                [matTooltip]="selectedBranchData?.contactDetails?.email" matTooltipPosition="below">
                                {{selectedBranchData?.contactDetails?.email}}
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="!selectedBranchData?.contactDetails?.email">
                                --
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Address
                            </span>
                            <div class="value s-col-full" *ngIf="selectedBranchData?.contactDetails?.addresses?.length">
                                {{selectedBranchData?.contactDetails?.addresses[0]?.addressLine1}},
                                {{selectedBranchData?.contactDetails?.addresses[0]?.addressLine2}},
                                {{selectedBranchData?.contactDetails?.addresses[0]?.street}},
                                {{selectedBranchData?.contactDetails?.addresses[0]?.city}},
                                {{selectedBranchData?.contactDetails?.addresses[0]?.district}},
                                {{selectedBranchData?.contactDetails?.addresses[0]?.state}},
                                <div>PIN- {{selectedBranchData?.contactDetails?.addresses[0]?.pin}}</div>
                            </div>
                            <div class="value s-col-full"
                                *ngIf="!selectedBranchData?.contactDetails?.addresses?.length">
                                --
                            </div>
                        </div>
                    </div>

                    <h3 class="step-col-label">
                        Branch Foreman Details
                        <span *ngIf="!branchAdminUsers?.length"
                            style="color: #010101">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            - No Owner</span>
                    </h3>
                    <mat-accordion class="branch_panel">
                        <mat-expansion-panel *ngFor="let branchAdminUser of branchAdminUsers">
                            <mat-expansion-panel-header style="background: #f7f7f7">
                                <mat-panel-title style="width: 60%;">
                                    <div class="value s-col-full" *ngIf="branchAdminUser"
                                        style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100%;">
                                        Name : <b>{{branchAdminUser?.title}} {{branchAdminUser?.firstName}}
                                            {{branchAdminUser?.lastName}}</b>
                                    </div>
                                    <div class="value s-col-full" *ngIf="!branchAdminUser">
                                        --
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span class="label label-success" *ngIf="!branchAdminUser?.activationId"
                                        style="border-radius: 15px;padding: 1px 15px;line-height: 17px;position: absolute;right: 80px">Activated</span>
                                    <span class="label label-danger" *ngIf="branchAdminUser?.activationId"
                                        style="border-radius: 15px;padding: 1px 15px;line-height: 17px;position: absolute;right: 80px;background-color: #f57621">Not
                                        Activated</span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="col-wrapper light-bg" *ngIf="branchAdminUsers" style="margin-top: 15px">
                                <div class="s-col">
                                    <span class="property">
                                        Email
                                    </span>
                                    <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.contactDetails?.email" 
                                        [matTooltip]="branchAdminUser?.contactDetails?.email" matTooltipPosition="below">
                                        {{branchAdminUser?.contactDetails?.email}}
                                    </div>
                                    <div class="value s-col-ellipsis" *ngIf="!branchAdminUser?.contactDetails?.email">
                                        --
                                    </div>
                                </div>
                                <div class="s-col">
                                    <span class="property">
                                        Mobile No.
                                    </span>
                                    <div class="value s-col-ellipsis"
                                        *ngIf="branchAdminUser?.contactDetails?.mobileNumber">
                                        {{branchAdminUser?.contactDetails?.mobileNumber}}
                                    </div>
                                    <div class="value s-col-ellipsis"
                                        *ngIf="!branchAdminUser?.contactDetails?.mobileNumber">
                                        --
                                    </div>
                                </div>
                                <div class="row">
                                    <!-- Send Activation Link starts here -->
                                    <div class="value s-col-ellipsis col-md-6" *ngIf="branchAdminUser?.activationId">

                                        <a *ngIf="isRegulatorSuperAdmin || isCustSuport" #menuTrigger="matMenuTrigger"
                                            [matMenuTriggerFor]="menu" style="margin-left: 5px;cursor: pointer">
                                            <span class="pulse"></span> Send Activation Link</a>
                                        <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
                                            <button mat-menu-item
                                                (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'EMAIL')">
                                                <mat-icon>email</mat-icon>
                                                <span>EMAIL</span>
                                            </button>
                                            <button mat-menu-item
                                                (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'SMS')">
                                                <mat-icon>textsms</mat-icon>
                                                <span>SMS</span>
                                            </button>
                                            <button mat-menu-item
                                                (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'ALL')">
                                                <mat-icon>send</mat-icon>
                                                <span>EMAIL & SMS</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <!-- Send activation link code ends here -->
                                    <!-- Remove button starts here -->
                                    <div class="col-md-6 pull-right" *ngIf="isRegulatorSuperAdmin">
                                        <button mat-icon-button color="warn" matTooltipClass="example-tooltip-red"
                                            matTooltipPosition="left" matTooltip="Delete Foreman" class="pull-right"
                                            style="background-color: #f7f7f7; box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                                            (click)="openForemanDeleteConfirmDialog(branchAdminUser, selectedBranchData)">
                                            <mat-icon aria-label="Delete Icon" style="color: #b71c1c">delete</mat-icon>
                                        </button>
                                    </div>
                                    <!-- remove button ends here -->
                                </div>

                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- <div class="col-wrapper light-bg" *ngIf="branchAdminUser">
                        <div class="s-col">
                            <span class="property">
                                Name
                            </span>
                            <div class="value s-col-full" *ngIf="branchAdminUser">
                                {{branchAdminUser?.title}} {{branchAdminUser?.firstName}}
                                {{branchAdminUser?.lastName}}
                            </div>
                            <div class="value s-col-full" *ngIf="!branchAdminUser">
                                --
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Email
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.contactDetails?.email">
                                {{branchAdminUser?.contactDetails?.email}}
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="!branchAdminUser?.contactDetails?.email">
                                --
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Mobile No.
                            </span>
                            <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.contactDetails?.mobileNumber">
                                {{branchAdminUser?.contactDetails?.mobileNumber}}
                            </div>
                            <div class="value s-col-ellipsis" *ngIf="!branchAdminUser?.contactDetails?.mobileNumber">
                                --
                            </div>
                        </div>
                    </div> -->

                    <h3 class="step-col-label" style="margin-top: 15px">
                        Regulator Branch Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col">
                            <span class="property">
                                Branch Name
                            </span>
                            <div class="value s-col-full">
                                {{regulatorDetails?.name}}
                            </div>
                        </div>

                    </div>
                    <div class="s-col">
                        <span class="property">
                            Address
                        </span>
                        <div class="value s-col-full" *ngIf="regulatorDetails?.contactDetails?.addresses?.length">
                            {{regulatorDetails?.contactDetails?.addresses[0]?.addressLine1}},
                            {{regulatorDetails?.contactDetails?.addresses[0]?.addressLine2}},
                            {{regulatorDetails?.contactDetails?.addresses[0]?.street}},
                            {{regulatorDetails?.contactDetails?.addresses[0]?.city}},
                            {{regulatorDetails?.contactDetails?.addresses[0]?.district}},
                            {{regulatorDetails?.contactDetails?.addresses[0]?.state}},
                            <div>PIN- {{regulatorDetails?.contactDetails?.addresses[0]?.pin}}</div>
                        </div>
                        <div class="value s-col-full" *ngIf="!regulatorDetails?.contactDetails?.addresses?.length">
                            --
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
