import { Component, OnInit, Inject } from '@angular/core';
import { BaPageTitleService } from '../../../theme/services/baMenu/baMenu.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'confirmation-dialog.html',
    styleUrls: ['./dialogs.scss'],

})
export class ConfirmationDialog {

    YES_RESPONSE = 'YES';
    NO_RESPONSE = 'NO';
    type: any; //holds confirmation type, DELETE/UPDATE
    message: any;
    messages?: string[]; //holds multiple messages
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { 
            console.log('Confirmation data is', JSON.stringify(data));
            this.type  = data.type;
            if(data.message) {
                this.message = data.message;
            }
            this.message = data.message;
            /* In case if there are multiple messages to be shown line 
               by line so user has to pass messages instead of message
            */
            if(data.messages){
                this.messages = data.messages;
            }
        }

    onNoClick(): void {
        this.dialogRef.close(this.NO_RESPONSE);
    }
    
    onYesClick(): void {
        this.dialogRef.close(this.YES_RESPONSE);
    }

}




