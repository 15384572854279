
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import {catchError, tap} from 'rxjs/operators';
import { RequestOptionsArgs, Response } from '@angular/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';
import { Principal } from '../../shared/auth/principal.service';
import { Router } from '@angular/router';
import { AccountService, LoginService, StateStorageService } from '../../shared';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor( 
        private loginService: LoginService,
        private stateStorageService: StateStorageService,
        private router: Router,
        private accountService: AccountService,
        private principal: Principal,
        private authService: AuthService) {
    }

    requestIntercept(options?: any ) {
        return options;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          tap({
            error: (err: HttpErrorResponse) => {
              if (err.status === 401) {
                if (this.principal.isAuthenticated()) {
                    this.authService.authorize(true);
                } else {
                    if (err.error == 'invalid_token') {
                        this.router.navigate(['./login']);
                    }
                    // event.stopPropagation();
                }
              } 
            },
          }),
        );
      }
}
