import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Principal } from '../../../../../shared';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
    selector: 'app-ba-menu-item',
    templateUrl: './baMenuItem.html',
    styleUrls: ['./baMenuItem.scss']
})
export class BaMenuItemComponent implements AfterViewInit {

    authority: string;
    @Input() menuItem: any;
    @Input() child = false;
    @Output() itemHover = new EventEmitter<any>();
    @Output() toggleSubMenu = new EventEmitter<any>();

    ngAfterViewInit() {
        setTimeout(() => {
            this.setVisibilitySync();
        }, 0);
    }

    constructor(private principal: Principal, public localStorage: LocalStorageService) {

    }

    public onHoverItem($event): void {
        this.itemHover.emit($event);
    }

    public onToggleSubMenu($event, item): boolean {
        $event.item = item;
        this.toggleSubMenu.emit($event);
        return false;
    }

    private setVisibilitySync() {
        if (this.menuItem.route.data.authorities) {
            // Commented to show the child sub menu items based on the authorities - Anvesh P
            // this.authority = this.menuItem.route.data.authorities[0].replace(/\s+/g, '').split(',');
            this.authority = this.menuItem.route.data.authorities;
            const result = this.principal.hasAnyAuthority(this.authority);
            if (result) {
                this.menuItem.hidden = false;

            } else {
                this.menuItem.hidden = true;
            }
            this.localStorage.store('access', result);
        }
    }
}
