<!-- <mat-menu #showAddressAction="matMenu" xPosition="before" yPosition="above" (close)="onMenuClosed(addressForm, $event)"> -->
<div class="dialog-wrapper">
    <div class="dialog-title">
        Address
    </div>
    <div class="dialog-content-wrapper">
        <div class="form-wrapper snb_form_entries">
            <div [formGroup]="addressForm">
            <div class="row" (click)="$event.stopPropagation();">
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.addressLine1' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="addressLine1"  required>
                        <mat-error *ngIf="addressForm.controls.addressLine1.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.addressLine2' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="addressLine2">
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.street' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="street" required>
                        <mat-error *ngIf="addressForm.controls.street.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.city' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="city"  required>
                        <mat-error *ngIf="addressForm.controls.city.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.district' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="district" required>
                        <mat-error *ngIf="addressForm.controls.district.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.state' | translate}}</mat-label>
                        <input matInput snb-start-case formControlName="state" required>
                        <mat-error *ngIf="addressForm.controls.state.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-md-6 col-xs-12">
                    <mat-form-field style="width: 100%; padding-bottom: 20px;" class="snb-form-field-without-icon" appearance="outline">
                        <mat-label>{{'address.pinCode' | translate}}</mat-label>
                        <input type="number" matInput formControlName="pin" required>
                        <mat-error *ngIf="addressForm.controls.pin.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
            </div>


            <button mat-raised-button color="primary" type="submit" (click)="saveAddress(addressForm, $event); $event.stopPropagation()" class="snb-raised-button" style="float: right; margin: 0px 5px;border-radius: 30px;">
                <span>OK</span>
            </button>
            <button mat-raised-button (click)="dialogRef.close(); $event.stopPropagation()" style="float: right; margin: 0px 5px;border-radius: 30px;">
                <span>CANCEL</span>
            </button>

            </div>
        </div>
    </div>
</div>