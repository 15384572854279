
import { Injectable } from "@angular/core";
import { FormatInputs } from "../format-values";

@Injectable()
export class ConversionUtilService {

    constructor(
        private formatInputs: FormatInputs,
    ) {
    }

    /**
     * This method converts the give object date fields to ISO string.
     * Typically this method will be used before sending the data to servers for saving.
     * 
     * @param object holds in which object the data fields should be convereted to ISO string.
     * @param dateFields holds array of fields in object which represents the date fields.
     */
    public updateDateFieldsToISOString(object: any, dateFields: string[]) {
        if(object && dateFields && dateFields.length > 0) {
            const keys: string[] = Object.keys(object);
            keys.forEach(key => {
                if (dateFields.indexOf(key) !== -1 &&  object[key]) {
                    object[key] = new Date(this.formatInputs.formatDateToTimeStampToMilliSeconds(object[key])).toISOString();
                }
            });
        }
    }

    /**
     * This method converts the give object date fields to typescrict date Object.
     * fields can be in ISO String or time stamp in seconds, it finds and converts.
     * Typically this method will be used when we retreive the data from Server and need to show
     * data in the UI
     * 
     * @param object holds in which object the data fields should be convereted to Date Object.
     * @param dateFields holds array of fields in object which represents the date fields.
     */
    public mapToDateObject(object: any, dateFields: string[]) {
        if(object && dateFields && dateFields.length > 0) {
            const keys: string[] = Object.keys(object);
            keys.forEach(key => {
                if (dateFields.indexOf(key) !== -1 &&  object[key]) {
                    object[key] = new Date(this.formatInputs.formatDateToTimeStampToMilliSeconds(object[key])).toISOString();
                }
            });
        }
    }

    /**
     * This method returns the date object of given month number and financial year.
     * Month number is in the format of integer 1 to 12.
     * finYaer in the format of 2022-24
     * @param month 
     * @param finYear 
     * @returns 
     */
    getMonthName(month: number, finYear) {
        let fyStartyear = Number(finYear.split("-")[0]);
        //we just need a month name
        return new Date(month <=3 ? fyStartyear +1: fyStartyear, month -1, 1);
      }
}
