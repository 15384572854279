<div class="side-strip hidden-xs">
    <!--Top UL icons starts here-->
    <ul class="top-icon-wrapper">
        <!-- <li>
            <a href="#">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/gear.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Settings</span>
        </li> -->
        <li>
            <a (click)="getWorkflowPending()" style="cursor: pointer">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/workflow-pending.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Pending Applications</span>
        </li>
        <li>
            <a (click)="getChitGroups()" style="cursor: pointer">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/chitgroup.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Chit Groups</span>
        </li>
        <li>
            <a [routerLink]="['/pages/new-features']" style="cursor: pointer">
                <div class="icon-box">
                    <span class="infinite-pulse">
                        <img src="assets/img/side-strip-icons/new-feature.png" class="img-responsive center-block">
                    </span>
                </div>
            </a>
            <span class="tooltiptext">New Features</span>
        </li>
        <!-- <li>
            <a href="#">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/marketing.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">What's new</span>
        </li> -->
    </ul>
    <!--bottom Ul icons starts here-->
    <ul class="top-icon-wrapper bottom-icon-wrpper">
        <li>
            <a href="https://t-chits.telangana.gov.in/#news">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/newspaper.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">News & Circulars</span>
        </li>
        <li *ngIf="isForeman">
            <a [routerLink]="['/pages/help/faq']" target="_blank" style="cursor: pointer">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/faq.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Faq's</span>
        </li>
        <li>
            <a [routerLink]="['/pages/help/videos']" style="cursor: pointer" target="_blank">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/video-help.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Video Help</span>
        </li>
        <li>
            <a href="https://remotedesktop.google.com/support" target="_blank">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/cms.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Remote Assistance</span>
        </li>
        <li>
            <a (click)="showSupportCard()" style="cursor: pointer">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/call.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Support</span>
        </li>
        <!-- <li>
            <a (click)="showSupportCard()" style="cursor: pointer">
                <div class="icon-box">
                    <img src="assets/img/side-strip-icons/mail.png" class="img-responsive center-block">
                </div>
            </a>
            <span class="tooltiptext">Mail us</span>
        </li> -->
    </ul>

    <div class="support-card" *ngIf="showSelected">
        <div class="info-block">

            <p style="margin-bottom: 7px;line-height: 20px;">Email : <span><a
                        href="mailto:t-chits.support@snbchain.com" class="email">t-chits.support@snbchain.com</a>    <i class="material-icons copy-clipboard" (click)="copyMail()">content_copy</i></span></p>
            <p>Call us : <span><a href="tel:7997005770">7997005770</a></span></p>
        </div>
        <div class="cross-box" (click)="showSupportCard()">
            <img src="assets/img/close.png" class="img-responsive" alt="close" style="width: 11px;cursor: pointer;">
        </div>
    </div>

</div>
