import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { merge } from "rxjs";
import { catchError ,  map ,  startWith ,  switchMap } from "rxjs/operators";
import { SnBToasterService } from "../../../shared/components/toaster/toaster.service";
import { FormatInputs } from "../../../shared/format-values";
import { LoaderService } from "../../../shared/loader.service";
import { ForemanCompanyFinancialStatusService } from "../../../shared/services/foreman-company-financial-status.service";
import { RocCompaniesService } from "../../roc-companies/roc-companies.services";
import { ForemanCompaniesService } from "../foreman-companies.service";import { Router } from "@angular/router";
;
import { MatPaginator } from "@angular/material/paginator";

export interface atosFormat {
    //Model defined For branch Ato's
    name: string;
    branchCode: string;
    ato: number;
    regulatorBranchCode: string;
    regulatorBranchName: string;
}

export interface companyAtosFormat {
    //Model defined For Company Ato's

    ato: number;
    netOwnedAssets: number;
}

const COMPANY_ELEMENT_DATA: companyAtosFormat[] = [
    {
        ato: 0.01,
        netOwnedAssets: 0.005,
    },
];

@Component({
    selector: "app-atos-data",
    templateUrl: "./atos-data.html",
    styleUrls: [
        "../foreman-companies-new/dialog-popup-content.scss",
        "./atos-data.scss",
    ],
})
export class AtosComponent implements OnInit {
    @Input() companyCode: any;

    displayedColumns: string[] = ["name", "regulatorBranchName", "ato"];
    atosData: any;
    financialStatus: any;

    displayedColumnsCompany: string[] = ["ato", "netOwnedAssets"];
    companyAtosData = COMPANY_ELEMENT_DATA;

    //Status of loading
    requestInProgress = false;

    //Pagination details
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    itemsPerPage = 20;
    totalItems = 0;

    constructor(
        public foremanCompaniesService: ForemanCompaniesService,
        private toasterService: SnBToasterService,
        public loaderService: LoaderService,
        private formatInputs: FormatInputs,
        private companyFinService: ForemanCompanyFinancialStatusService,
        private router: Router,

    ) {}

    ngOnInit() {
        this.getForemanatoFromApi();
        this.getFinancialStatus();
    }

    getFinancialStatus() {

        this.companyFinService.getFinancialDataWithAllowedLimit(this.companyCode, true).subscribe(res => {
            console.log('Financial data response is ', res);
            let finData = res[0];
            let allowedLimit = res[1];

            this.financialStatus = finData;
            this.financialStatus.chitAggregateAmountAllowed = allowedLimit;
        });
        
    }

    /**
     * This method fetches branch wise ATO's of a foremna company
     */
    getForemanatoFromApi() {
        this.requestInProgress = true;
        this.atosData = [];

        merge(this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    return this.foremanCompaniesService.getBranchWiseATOs(
                        this.companyCode,
                        {
                            page: this.paginator.pageIndex,
                            size: this.itemsPerPage,
                        }
                    );
                }),
                map((res: any) => {
                    this.loaderService.display(false);
                    this.requestInProgress = false;
                    return res;
                }),
                catchError((fail) => {
                    this.loaderService.display(false);
                    this.requestInProgress = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                    return [];
                })
            )
            .subscribe((data) => {
                this.atosData = data["body"];
                this.requestInProgress = false;
                this.totalItems = parseInt(
                    data.headers.get("X-Total-Count"),
                    0
                );
                this.loaderService.display(false);
            });
    }

    formatCurrencyToWords(amount) {
        if (amount) {
            return this.formatInputs.convertCurrencyToWords(amount);
        }
    }

    openFaq() {
        this.router.navigate(['/pages/help/faq'], { queryParams: { panel: 'allowed-limit' } });
      }
}
