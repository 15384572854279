<div class="row no-padding" style="margin-bottom: 3px !important">
    <mat-accordion>
        <mat-expansion-panel [expanded]="openByDefault">

            <mat-expansion-panel-header>
                <mat-panel-title *ngIf="!customTitle">
                    Comments
                </mat-panel-title>
                <mat-panel-title *ngIf="customTitle">
                    {{customTitle}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="comments_wrap">
                <div class="comment_row" *ngFor="let comment of comments; let i=index">
                    <div class="single_comment">
                        <div *ngIf="comment.userId!=userId" class="commented_by">{{comment.commentedBy}}
                            <span *ngIf="comment.type">(</span>{{comment.type}}<span *ngIf="comment.type">)</span></div>
                        <div *ngIf="comment.userId==userId" class="commented_by">You</div>
                        <div class="commented_time">{{comment.commentedOn | snbDateConvertToMillis | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                        <div class="commented_msg">{{comment.comment}}</div>
                        <div class="attachment" *ngIf="comment.attachment">
                            <span style="float:right">
                                <i class="fa fa-paperclip" style="color:#1976d2" aria-hidden="true"></i>
                                Attachment
                            </span>
                            <span style="bottom: 5px;position: relative;">
                                <app-snb-file-item [fileData]="comment?.attachment" [hideDescr]="'true'"></app-snb-file-item>
                            </span>
                        </div>                        
                    </div>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>
</div>