import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'left-panel',
    templateUrl: './left-panel.component.html',
    styleUrls: ['./left-panel.scss'],
})
export class LeftPanelComponent implements OnInit {


    showForm1PreviewForm: boolean;
    expandLeftBlock = false;

    @Input('previewForm')
    public previewForm: boolean;

    @Output() toggleClicked = new EventEmitter<any>();

    constructor() { }

    screenToggleLeft() {
        this.expandLeftBlock = !this.expandLeftBlock;
        this.toggleClicked.emit(this.expandLeftBlock);
    }

    showForm1Preview() {
        this.showForm1PreviewForm = true;
    }

    closeForm1Preview() {
        this.showForm1PreviewForm = false;
    }



    ngOnInit() {

    }

}

