<div class="confirmation-dialog-wrapper">
    <div class="confirmation-dialog-title">
        <i class="material-icons">warning</i> Confirmation
        <span><i class="material-icons close-btn" (click)="onNoClick()">close</i></span>
    </div>
    <div class="form_groups">
        <p class="text-center">Are you sure you want to Delete ?</p>
    </div>
    <div class="button-wrapper">
        <div class="first">
            <button (click)="onNoClick()"> No</button>
        </div>
        <div class="second">
            <button (click)="onYesClick()"> Yes</button>
        </div>
    </div>
</div>