
import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormControl, FormGroup } from "@angular/forms";

@Injectable()
export class FormUtilService {

    constructor(
    ) {
    }

    public applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else if (formControl instanceof UntypedFormArray) {
                if (key === 'addresses') {
                    formValues[key].map(x => {
                        x.addressField = Object.keys(x).map(function (k) {
                            if (k !== 'addressField') {
                                if (k !== 'type') {
                                    return x[k];
                                }
                            }
                        }).filter(Boolean).join(', ');
                        // delete x.type
                    })
                }
                formControl.setValue(formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        formControl.setValue(formValues[key]);
                    }
                }
            }
        });
    }

    public formGroup(group: FormGroup, data) {
        if (!group || !data) {
            return false;
        }
        Object.keys(group.controls).forEach( (formControlName: any)  => {
            const formControl = <UntypedFormControl>group.controls[formControlName];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, data[formControlName]);
            } else if (formControl instanceof UntypedFormArray) {
                if (formControlName === 'addresses') {
                    data[formControlName].map(x => {
                        x.addressField = Object.keys(x).map(function (k) {
                            if (k !== 'addressField') {
                                if (k !== 'type') {
                                    return x[k];
                                }
                            }
                        }).filter(Boolean).join(', ');
                        // delete x.type
                    })
                }
                console.log('ApplyFromGroup, address value: ', data[formControlName]);
                formControl.setValue(data[formControlName]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (data[formControlName] && typeof data[formControlName] === 'object' && data[formControlName].length) {
                        formControl.setValue(data[formControlName][0]);
                    } else {
                        formControl.setValue(data[formControlName]);
                    }
                }
            }
        });
    }

    public markFieldsAsTouched(group) {
        Object.keys(group.controls).forEach(field => {
            const control = group.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }
}
