import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-organisation-auditor-detail',
    templateUrl: './organisation-auditor-details.component.html',
    styleUrls: ['./dialog-popup-content.scss']
})

export class OrganisationAuditorDetailComponent implements OnInit {

    selectedAuditorData: any;
    constructor(
        public dialogRef: MatDialogRef<OrganisationAuditorDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.selectedAuditorData = this.data.selectedAuditorData;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

}
