import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './address-fields.html'
})

export class SnBAddressFieldsDialogComponent implements OnInit {
    addressForm: FormGroup;
    addressValue: any;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<SnBAddressFieldsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.addressForm = this.fb.group({
            type: ['PRESENT'],
            addressLine1: ['', [Validators.required]],
            addressLine2: [''],
            street: ['', [Validators.required]],
            city: ['', [Validators.required]],
            district: ['', [Validators.required]],
            state: ['Telangana', [Validators.required]],
            pin: ['', [Validators.required]]
        });

        this.applyFormValues(this.addressForm, this.data.addressValue);
        if (this.data.disableForm) {
            this.addressForm.disable();
        } else {
            this.addressForm.enable();
        }
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    formControl.setValue(formValues[key]);
                }
            }
        });
    }

    saveAddress(address, event) {
        event.stopPropagation();
        event.preventDefault();
        this.addressValue = address.value;
        if (!address.valid && !address.disabled) {
            Object.keys(address.controls).map(function (control) {
                address.controls[control].markAsTouched();
            });
            event.stopPropagation();
            return false;
        } else {
            this.dialogRef.close(address);
        }
    }
}

@Component({
    selector: 'cm-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class CmAddressComponent implements OnInit {

    @Input() mandatory: boolean = true;

    constructor(
        public dialog: MatDialog,
        
    ) {
       
    }

    @Input('group')
    public addressForm: FormGroup;

    @Input() addressPlaceHolder: any;

    @Input() addressValue: any;

    @Input() showIcon = false;

    ngOnInit(): void {
        this.applyFormValues(this.addressForm, this.addressValue);
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    formControl.setValue(formValues[key]);
                }
            }
        });
    }

    openAddressForm(frm, event, test) {
        const ele = event.offsetTop;
        const self = this;
        // setTimeout(function () {
        //     self.dialog['_overlayContainer']._containerElement.firstElementChild.style.background = 'transparent';
        // }, 10);
        event.stopPropagation();
        const dialogRef = this.dialog.open(SnBAddressFieldsDialogComponent, {
            panelClass: "custom-dialog-container",
            width: '600px',
            disableClose: true,
            // position: { top: event.clientX + 'px', left: event.clientY + 'px' },
            // position: { top: '170px', left: event.clientY + 'px' },
            // hasBackdrop: false,
            data: {
                addressValue: this.addressValue,
                disableForm: this.addressForm.disabled
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.onMenuClosed(result);
        });
    }

    onMenuClosed(addressform) {
        if (!addressform) {
            return false;
        }
        this.addressValue = addressform.value;
        if (addressform.disabled) {
            return false;
        }
        this.applyFormValues(this.addressForm, this.addressValue);
        addressform.value.addressField = Object.keys(addressform.value).map(function (k) {
            if (k !== 'addressField') {
                if (k !== 'type') {
                    return addressform.value[k];
                }
            }
        }).filter(Boolean).join(', ');
        this.addressForm.controls['addressField'].setValue(addressform.value.addressField);
        if (!addressform.valid && addressform.value.addressField !== '') {
            addressform.controls.addressField.setErrors({ 'allRequired': true });
        }
    }

    formatAddress(address) {
        if (address) {
            this.addressForm.controls['addressField'].setValue(Object.keys(address).map(function (k) {
                if (k !== 'addressField') {
                    if (k !== 'type') {
                        return address[k];
                    }
                }
            }).filter(Boolean).join(', '));
            this.applyFormValues(this.addressForm, this.addressValue);
        } else {
            this.addressForm.controls['addressField'].setValue('');
        }
    }

}


