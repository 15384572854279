<form [formGroup]="authForm" novalidate (ngSubmit)="submitAuth($event)" autocomplete="off">
    <div class="chm-dialog-popup">
        <div class="chm-dialog-popup-title">
            Login with OTP
        </div>
        <div class="form_groups" style="padding-bottom: 30px;">
            <div class="form-wrapper">

                <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                    <mat-error *ngIf="authForm.controls.name.hasError('required')"
                        jhiTranslate="entity.validation.required">
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                    <mat-label>Mobile Number</mat-label>
                    <input type="number" matInput formControlName="login" minlength=10 maxlength=10
                        required>
                    <mat-error *ngIf="authForm.controls.login.hasError('required')"
                        jhiTranslate="entity.validation.required">
                    </mat-error>
                    <mat-error *ngIf="authForm.controls.login.hasError('pattern') && authForm.controls.login.value"
                        jhiTranslate="entity.validation.mobile"></mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100%" *ngIf='authForm?.controls?.otp' class="snb-form-field-without-icon">
                    <mat-label>OTP</mat-label>
                    <input type="text" (keypress)="numberOnly($event)" pattern="[0-9]*" formControlName="otp" matInput
                        required>
                    <mat-hint>OTP sent successfully.</mat-hint>
                    <mat-error *ngIf="authForm?.controls?.otp?.hasError('required')"
                        jhiTranslate="entity.validation.required">
                    </mat-error>
                </mat-form-field>

            </div>

            <mat-dialog-actions align="end">
                <button mat-raised-button mat-dialog-close style="border-radius: 30px">Close</button>
                <button mat-raised-button color="primary" type="submit" *ngIf="!optGenerated"
                    style="border-radius: 30px">
                    Generate OTP
                </button>
                <button mat-raised-button color="primary" type="submit" *ngIf="optGenerated"
                    style="border-radius: 30px">
                    Verify OTP
                </button>
            </mat-dialog-actions>

        </div>
    </div>
</form>