import { Component, ElementRef, Input, ViewChild, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { AuthServerProvider } from '../../auth/auth-oauth2.service';
import { FileUploadService } from './file-upload.service';
import { FileUploadSettings } from './file-upload-settings.model';
import { FileUploader } from 'ng2-file-upload';
import { FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
type AOA = Array<Array<any>>;
import { SnBToasterService } from '../toaster/toaster.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AlertService } from '../../../blocks/interceptor/alert.service';

@Component({
    selector: 'app-excelfile-upload-read',
    templateUrl: './excelfile-upload-read.component.html',
    styleUrls: ['./file-upload.scss']
})
export class ExcelFileUploadComponent implements OnInit {

    actionButtonLabel = 'Retry';
    action = true;
    setAutoHide = true;
    autoHide = 8000;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    @Input() public fileSettings: FileUploadSettings;

    public uploader: FileUploader;

    @ViewChild('fileInput') inputEl: ElementRef;
    @Output() returnSuccessDataUpload: EventEmitter<any> = new EventEmitter();
    public fileUploadResponse: any;
    tempItem: any = [];
    data: AOA = [];
    tmp: AOA = [];
    val: any;
    csv: string;
    wopts: XLSX.WritingOptions = { bookType: 'csv', type: 'binary' };
    // fileName:string = "SheetJS.csv";
    ignoreInvalid = false;
    warnings: any = [];
    errors: any;
    isResult: any;
    message: string;
    temp: any = {};
    uploadedInprogress = false;
    showNotCSVFileError = false;
    showEditTemplate = false;
    uploadFailed = false;

    constructor(
        private authService: AuthServerProvider,
        private alertService: AlertService,
        public fileUploaderService: FileUploadService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private toasterService: SnBToasterService,
        private snackBar: MatSnackBar,
    ) { }

    onChanges(event, j, i) {
        // this.tmp.push(event);
        // this.tmp[j][i]=event;
        this.tmp[j][i] = event;
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    ngOnInit() {

        const accessToken: string = 'Bearer ' + this.authService.getToken()['access_token'];

        this.uploader = new FileUploader(
            {
                url: this.fileSettings.url,
                authToken: accessToken
            });

        this.uploader.onAfterAddingFile = ((item: FileItem): any => {
            this.fileUploadResponse = undefined;
        });

        this.uploader.onErrorItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
        });
        this.uploader.onSuccessItem = ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any => {
            if (response) {
                this.setResponse(response, item);
            } else {
                this.fileUploadResponse = undefined;
            }
        });

    }

    deleteRow(index) {
        // let key=index+"";
        const temp = {};
        temp[index] = this.data.splice(index, 1);
        this.tmp = this.data;
        this.tempItem.push(temp);
    }

    undoLast() {
        const temp = <any>this.tempItem.pop();
        const key = Object.keys(temp)[0];
        this.data.splice(parseInt(key, 0), 0, temp[key][0]);
        this.tmp = this.data;
        /*Object.keys(this.tempItem).forEach((key)=>{
          this.data.splice(parseInt(key),0,this.tempItem[key]);
        })*/
    }


    setResponse(response: string, item: FileItem) {
        this.fileUploadResponse = JSON.parse(response);
        if (this.fileUploadResponse.warnings == null && this.fileUploadResponse.errors == null) {
            item.isSuccess = true;
        } else {
            item.isSuccess = false;
            item.isError = true;
        }
    }



    public onFileChange(evt: any) {
        const scope = this;
        this.showNotCSVFileError = false;
        /* wire up file reader */
        const target: DataTransfer = (<DataTransfer>(evt.target));
        if (target.files.length !== 1) {
            throw new Error('Cannot upload multiple files on the entry');
        }
        if (target.files.length) {
            const fileName = target.files[0].name;
            if (fileName.substring(fileName.lastIndexOf('.')) !== '.csv') {
                this.toasterService.showErrorMessage('error.allowedCSVonly');
                this.showNotCSVFileError = true;
                scope.uploader.clearQueue();
                evt.target.value = '';
                return;
            }
        }
        scope.data = [];
        scope.uploadedInprogress = true;
        const reader = new FileReader();
        reader.onload = function (e: any) {
            /* read workbook */
            const bstr = e.target.result;
            let wbl;
            if (scope.fileSettings.typeOfUpload && scope.fileSettings.typeOfUpload === 'dues') {
                wbl = XLSX.read(bstr, { type: 'binary', dateNF: 'mm-dd' });
            } else {
                wbl = XLSX.read(bstr, { type: 'binary' });
            }
            const wb = wbl;
            /* grab first sheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(ws['!ref']);

            if ((<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }))).length <= scope.fileSettings.maximunRows) {
                /* save data to scope */
                scope.data = (<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 })));
                if (scope.data && scope.data.length && scope.data.length < 102) {
                    for (let i = 1; i < scope.data.length; i++) {
                        for (let j = 0; j < scope.data[0].length; j++) {
                            if (!scope.data[i][j]) {
                                scope.data[i][j] = '';
                            }
                        }
                    }
                    scope.tmp = (<AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 })));
                    // scope.changeDetectorRef.markForCheck();
                    scope.csv = bstr;
                    scope.showEditTemplate = true;
                } else if (scope.data && scope.data.length && scope.data.length > 101) {
                    scope.showEditTemplate = false;
                    scope.uploader.clearQueue();
                    evt.target.value = '';
                    scope.sendDataToServer(bstr);
                }
                scope.uploadedInprogress = false;
            } else {
                scope.uploadedInprogress = false;
                scope.uploader.clearQueue();
                const message = scope.translateService.instant('error.exceededMaxRows', { max: scope.fileSettings.maximunRows });
                const config = scope._createConfig();
                config.panelClass = ['snb-fail-toaster'];
                scope.snackBar.open(message, 'X', config);
            }
        };

        reader.readAsBinaryString(target.files[0]);
    }


    private _createConfig() {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        // config.panelClass = ['custom-class'];
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = this.addExtraClass ? ['party'] : undefined;
        // config.direction = this.dir.value;
        return config;
    }

    convertToBinaryString() {
        // this.changeDetectorRef.detectChanges();
        const ws = XLSX.utils.aoa_to_sheet(this.tmp, { cellDates: true });
        const csv = XLSX.utils.sheet_to_csv(ws);
        this.csv = csv;
        this.sendDataToServer(this.csv);
    }


    export(): void {
        /* generate worksheet */
        const ws = XLSX.utils.aoa_to_sheet(this.data);

        /* generate workbook and add the worksheet */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        const wbout = XLSX.write(wb, this.wopts);
        // console.log(this.fileName);
        // saveAs(new Blob([s2ab(wbout)]), this.fileName);
    }

    private onError(error) {
        this.alertService.addAlert({type: 'danger', translationKey: error}, []);
    }

    upload() {
        // let inputEl = document.getElementById('uploadFileId1');
        /*
            let fileCount = inputEl.files.length;
            let formData = new FormData();
            if (fileCount > 0) { // a file was selected
              for (let i = 0; i < fileCount; i++) {
                formData.append('file[]', inputEl.files.item(i));
              }
              this.fileUploaderService.upload(this.fileSettings.url, formData);
            }*/
        const dialogRef = this.dialog.open(ConfirmDetailsSubmissionDialogComponent, {
            width: '500px',
            data: {

            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.convertToBinaryString();
            }
        });
    }

    sendDataToServer(data) {
        this.uploadFailed = false;
        this.fileUploaderService.upload(`${this.fileSettings.url}?ignoreInvalid=${this.ignoreInvalid}`, data).subscribe(res => {
            this.responseHandler(res);
        }, resp => {
            this.uploadFailed = true;
            this.onError('error.internalServer');
        });
    }



    responseHandler(response) {
        this.isResult = response.isResult;
        if (this.isResult) {
            this.returnSuccessDataUpload.emit(true);
        }
        if (!this.isResult) {
            this.uploadFailed = true;
        }
        this.errors = response.errors;
        this.warnings = response.warnings;
        this.fileUploadResponse = response;
        this.ignoreInvalid = false;
    }

    showError(row, column) {
        if (!this.isResult) {
            for (const warning of this.warnings) {
                if (row === warning.lineNumber && (column + 1) === warning.fieldNumber) {
                    this.temp.row = row;
                    this.temp.column = column;
                    this.temp.message = warning.message;
                    return 'show-class';
                }
            }
        }
    }
    remove(item: FileItem) {
        item.remove();
        this.isResult = {};
        this.errors = {};
        this.warnings = {};
        this.fileUploadResponse = {};
    }

    cancelUpload() {
        this.isResult = false;
        this.showEditTemplate = false;
        this.uploader.clearQueue();
    }

    onChange(event: EventTarget) {
        //const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
        //const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    }

    hasMessage(row, column) {
        let value;
        if (this.temp.row === row && this.temp.column === column) {
            value = this.temp.message;
        } else {
            value = null;
        }
        return value;
    }

}


@Component({
    templateUrl: './confirm-details-submission-dialog.html'
})
export class ConfirmDetailsSubmissionDialogComponent implements OnInit {


    constructor(
        public dialogRef: MatDialogRef<ConfirmDetailsSubmissionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit() {

    }

    submitDetails(event) {
        this.dialogRef.close(true);
    }
}
