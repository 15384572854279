
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Transaction, TransactionType } from './transaction.model';
import { SNB_PGW_URL, SNB_CORE_URL, USER_TYPES } from '../../app.constants';
import { PaymentRequestItem } from './payment-item.model';
import { ApplicationTypes } from '../applications/application-types';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';


@Injectable()
export class PaymentService {
    applicationTypes: any;

    constructor(
        private httpClient: HttpClient,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService
    ) {
        this.applicationTypes = ApplicationTypes;
    }


    postDataToTwallet(request: string): Observable<any> {
        return this.httpClient.post(request, {}).pipe(map(
            (res: any) => {
                return res;
            }, (error: any) => {
                return error;
            }
        ));
    }

    reconcileByApplication(id): Observable<any> {
        const options = this.prepareHeader({});

        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_CORE_URL}/foreman`;
        } else if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator`;
        }

        return this.httpClient.put(`${url}/payment/transaction/reconcile-by-application/${id}`, {}, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    createOfflinePayment<T>(trnx: Transaction):Observable<any> {
        trnx.trnxType = TransactionType[TransactionType.OFFLINE_PAY];
        const options = this.prepareHeader(trnx);

        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_CORE_URL}/foreman`;
        } else if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator`;
        } else {
            url = `${SNB_CORE_URL}/public`;
        }

        return this.httpClient.post(`${url}/payment/transaction/new?checkPendingTrnx=true`, trnx, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }


    create<T>(trnx: Transaction):Observable<any> {
        trnx.trnxType = TransactionType[TransactionType.PAY];
        const options = this.prepareHeader(trnx);

        let url = '';
        if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_CORE_URL}/foreman`;
        } else {
            url = `${SNB_CORE_URL}/public`;
        }
        return this.httpClient.post(`${url}/payment/transaction/new?checkPendingTrnx=true`, trnx, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getPaymentTransactions<T>(req?: any):Observable<any> {
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator/payment/transaction`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_CORE_URL}/foreman/payment/transaction`;
        } else {
            url = `${SNB_CORE_URL}/user/payment/transaction`;
        }

        const options = this.prepareHeader(req);
        return this.httpClient.get<T>(`${url}`, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }


    getTransactionDetails<T>(id: any, req?: any):Observable<any> {
        let url = '';
        if (USER_TYPES.IS_REGULATOR) {
            url = `${SNB_CORE_URL}/regulator/payment/transaction/${id}`;
        } else if (USER_TYPES.IS_FOREMAN) {
            url = `${SNB_CORE_URL}/foreman/payment/transaction/${id}`;
        } else {
            url = `${SNB_CORE_URL}/public/payment/transaction/${id}`;
        }
        const options = this.prepareHeader(req);
        return this.httpClient.get<T>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    getTransaction<T>(id):Observable<any> {
        return this.httpClient.get(`${SNB_PGW_URL}/transaction/${id}`, this.prepareHeader()).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    refreshPaymentService<T>(trnx, req?: any):Observable<any> {
        const options = this.prepareHeader(req);
        let user = '';
        if (USER_TYPES.IS_REGULATOR) {
            user = 'regulator';
        } else if (USER_TYPES.IS_FOREMAN) {
            user = 'foreman';
        } else {
            user = 'public';
        }
        return this.httpClient.get(`${SNB_CORE_URL}/${user}/payment/transaction/reconcile/${trnx.appTrnxRefId}`, {
            headers: options['headers'], params: options['params'], observe: 'response'
        }).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }

    formatPaymetDetails(paymentData) {
        const item1: PaymentRequestItem = new PaymentRequestItem();
        item1.amount = paymentData.processVariables.applicationFee;
        item1.trnxDesc =  'Online Payment';
        item1.foremanCompanyBranchCode = paymentData.processVariables.foremanCompanyBranchCode;
        item1.chitRefId = paymentData.processVariables.chitRefId;
        item1.workFlowFormName = this.applicationTypes[paymentData.activeTask.taskDefKey].type;
        item1.workFlowProcessInstanceId = paymentData.processInstanceId;

        if(paymentData.processDefKey === "ArbitrationApplicationProcess" &&  paymentData.processVariables.arbitrationDetails) {
            item1.userCharges = paymentData.processVariables.arbitrationDetails.arbitrationUserCharges;
        }
        const token = this.localStorage.retrieve('authenticationToken');
        const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
        const trnx = {
            amount: paymentData.processVariables.applicationFee,
            userId: userData.user_internal_id,
            provenance: paymentData.processVariables.regulatorOrgCode,
            foremanCompanyCode: paymentData.processVariables.companyCode,
            regulatorBranchCode: paymentData.processVariables.regulatorBranchCode,
            items: [item1]
        }
        
        return trnx;
    }

    private prepareHeader(urlSearchParams?: any, headers?: HttpHeaders | null): object {

        headers = headers || new HttpHeaders();

        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');


        let params = new HttpParams();

        if (urlSearchParams) {
            if (typeof (urlSearchParams.page) !== 'undefined') {
                params = params.set('page', urlSearchParams.page);
            }
            if (urlSearchParams.size) {
                params = params.set('size', urlSearchParams.size);
            }
            if (urlSearchParams.sort) {
                params = params.set('sort', urlSearchParams.sort);
            }
            if (urlSearchParams.stateIn) {
                params = params.set('stateIn', urlSearchParams.stateIn);
            }

            if (urlSearchParams.query) {
                const keys: string[] = Object.keys(urlSearchParams.query);
                keys.forEach(key => {
                    if (urlSearchParams.query[key]) {
                        params = params.set(key, urlSearchParams.query[key]);
                    }
                });
            }
        }

        return {
            headers: headers,
            params: params
        };
    }

}

