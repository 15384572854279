import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'snb-form-icon',
    template: `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 70.096 77.595" style="enable-background:new 0 0 70.096 77.595;" xml:space="preserve">
<g>
   <path   d="M55.6,17.273h-3.623v-3.621c0-1.999-1.619-3.621-3.619-3.621H12.144
       c-2.002,0-3.621,1.622-3.621,3.621v43.454c0,1.999,1.619,3.62,3.621,3.62h3.622v3.621c0,1.999,1.619,3.623,3.621,3.623H55.6
       c2.002,0,3.621-1.624,3.621-3.623V20.896C59.221,18.897,57.602,17.273,55.6,17.273z M12.144,57.106V13.652h36.214v43.454H12.144z
        M55.6,64.348H19.386v-3.621h28.971c2,0,3.619-1.621,3.619-3.62v-36.21H55.6V64.348z"/>
   <path   d="M42.924,20.896H24.818c-0.996,0-1.809,0.813-1.809,1.809c0,0.996,0.814,1.81,1.809,1.81h18.107
       c0.998,0,1.811-0.813,1.811-1.81C44.735,21.71,43.923,20.896,42.924,20.896z"/>
   <path   d="M42.924,31.759H17.576c-0.997,0-1.81,0.813-1.81,1.809c0,0.998,0.813,1.812,1.81,1.812h25.349
       c0.998,0,1.811-0.813,1.811-1.812C44.735,32.572,43.923,31.759,42.924,31.759z"/>
   <path   d="M42.924,39H17.576c-0.997,0-1.81,0.813-1.81,1.812c0,0.996,0.813,1.81,1.81,1.81h25.349
       c0.998,0,1.811-0.813,1.811-1.81C44.735,39.813,43.923,39,42.924,39z"/>
   <path   d="M42.924,46.244H17.576c-0.997,0-1.81,0.813-1.81,1.809c0,0.999,0.813,1.812,1.81,1.812h25.349
       c0.998,0,1.811-0.813,1.811-1.812C44.735,47.058,43.923,46.244,42.924,46.244z"/>
</g>
</svg>
`
})
export class SnBFormIconComponent {



}
