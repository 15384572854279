<div class="chm-dialog-popup">
    <div class="chm-dialog-popup-title">
        Add Foreman
    </div>
    <div class="alert alert-info alert-dismissible" role="alert" *ngIf="isExtingUser">
        <button class="close" aria-label="close" data-dismiss="alert">&times;</button>
        Existing User
    </div>
    <div class="alert alert-success alert-dismissible in" *ngIf="isNewUser">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        New User
    </div>
    <div class="form_groups">
        <form novalidate [formGroup]="verifyFormanMobileForm" [hidden]="!afterMobileNumberVerified" (ngSubmit)="checkForemanNumber($event)">
            <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" class="snb-form-field-without-icon">
                <mat-label>Mobile Number</mat-label>
                <input matInput formControlName="mobileNumber1" minlength=10 maxlength=10
                    required>
                <mat-error *ngIf="verifyFormanMobileForm.controls.mobileNumber1.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                <mat-error *ngIf="verifyFormanMobileForm.controls.mobileNumber1.hasError('pattern') && verifyFormanMobileForm.controls.mobileNumber1.value"
                    jhiTranslate="entity.validation.mobile"></mat-error>
            </mat-form-field>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" mat-raised-button color="primary" class="pull-right" style="float: right;margin-left: 10px;border-radius: 30px">
                        Verify
                    </button>
                    <button mat-raised-button type="button" (click)="onNoClick()" style="float: right;border-radius: 30px">Cancel</button>
                </div>
            </div>
        </form>


        <form novalidate [formGroup]="addFormanForm" [hidden]="!isMobileNumberVerified" (ngSubmit)="addForemanToBranch($event)">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.title' | translate}}</mat-label>
                        <mat-select formControlName="title" matInput
                            required>
                            <mat-option value="Mr.">{{'foremanCompany.title.mr' | translate}}</mat-option>
                            <mat-option value="Mrs.">{{'foremanCompany.title.mrs' | translate}}</mat-option>
                            <mat-option value="Ms.">{{'foremanCompany.title.ms' | translate}}</mat-option>
                            <mat-option value="Miss">{{'foremanCompany.title.miss' | translate}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="addFormanForm.controls.title.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>{{'foremanCompany.new.firstName' | translate}}</mat-label>
                        <input type="text" formControlName="firstName" matInput
                            required>
                        <mat-error *ngIf="addFormanForm.controls.firstName.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>{{'foremanCompany.new.lastName' | translate}}</mat-label>
                        <input type="text" formControlName="lastName" matInput
                            required>
                        <mat-error *ngIf="addFormanForm.controls.lastName.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>

                <div formGroupName="contactDetails">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>{{'foremanCompany.new.email' | translate}}</mat-label>
                            <input type="email" formControlName="email" matInput>
                            <mat-error *ngIf="addFormanForm.get('contactDetails')['controls'].email.value && addFormanForm.get('contactDetails')['controls'].email.errors?.pattern"
                                jhiTranslate="entity.validation.email"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>{{'foremanCompany.new.mobileNumber' | translate}}</mat-label>
                            <input type="tel" required formControlName="mobileNumber" matInput>
                            <mat-error *ngIf="addFormanForm.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                jhiTranslate="entity.validation.required"></mat-error>
                            <mat-error *ngIf="addFormanForm.get('contactDetails')['controls'].mobileNumber.value && addFormanForm.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                jhiTranslate="entity.validation.mobile"></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12" style="margin-top: 15px;">
                    <button type="submit" mat-raised-button color="primary" class="pull-right" style="float: right;margin-left: 10px;border-radius: 30px;">
                        Add
                    </button>
                    <button mat-raised-button type="button" (click)="onNoClick()" style="float: right;border-radius: 30px;">Cancel</button>
                </div>

            </div>
        </form>
    </div>
</div>