<div class="custom-data-table">

    <div class="row no-margin">
        <div class="col-md-7 col-xs-12" [ngClass]="{'col-md-12' : !foremanAuditors?.length}">
            <div class="timeline_card text-left">
                <div class="subscribers_list custome_table">
                    <mat-table #table [dataSource]="foremanAuditors">

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-users"
                                        aria-hidden="true"></i></span><span class="afterFont">Auditor Name</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-cog"
                                        aria-hidden="true"></i></span><span class="afterFont">Status</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.isActive ? 'Active' : 'Inactive' }} </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef><span class="fontIcon"><i class="fa fa-cog"
                                        aria-hidden="true"></i></span><span class="afterFont">Actions</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a [matMenuTriggerFor]="workFlowActions">
                                    <i class="material-icons actions">more_vert</i>
                                </a>
                                <mat-menu xPosition="before" yPosition="below" #workFlowActions="matMenu">
                                    <button mat-menu-item class="chit-group-row-action" (click)="editAuditor()">
                                        <i class="material-icons">edit</i>
                                        <span>Edit</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container> -->

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row (click)="showMoreDetails(row, $event)" *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                    </mat-table>
                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>
                    <div *ngIf="foremanAuditors?.length===0 && !requestInProgress" class="empty-row">No data to display
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-5 hidden-xs" *ngIf="foremanAuditors?.length">
            <div class="right-col">
                <div class="step-col-content">
                    <h3 class="step-col-label">
                        More Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col">
                            <span class="property">
                                Auditor Name
                            </span>
                            <div class="value s-col-full">
                                {{selectedAuditorData?.name}}
                            </div>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Address
                            </span>
                            <div class="value s-col-full" *ngIf="selectedAuditorData?.address">
                                {{selectedAuditorData?.address?.addressLine1}},
                                {{selectedAuditorData?.address?.addressLine2}},
                                {{selectedAuditorData?.address?.street}},
                                {{selectedAuditorData?.address?.city}},
                                {{selectedAuditorData?.address?.district}},
                                {{selectedAuditorData?.address?.state}},
                                PIN- {{selectedAuditorData?.address?.pin}}
                            </div>
                            <div class="value s-col-full" *ngIf="!selectedAuditorData?.address">
                                --
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
