import { Component, OnInit, Inject, Input, ChangeDetectorRef, AfterViewChecked, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { ForemanCompaniesService } from '../foreman-companies.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../../shared/date-adapter';
import { SnBToasterService } from '../../../shared/components/toaster/toaster.service';
import { TranslateService } from "@ngx-translate/core";
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONW_REGEX = /^\+?\d{2}[- ]?\d{3}[- ]?\d{5}$/;
import { ForemanOrgsTypes } from './foreman-companies-new.model';

import { USER_TYPES } from '../../../app.constants';
import { ProfileService } from '../../profile/profile.service';
import { DesignationTypes } from '../../profile/profile.model';
import { ErrorConfigService } from '../../../shared/components/error/errors.config.service';
import { ApplicationsService } from '../../applications/applications.service';
import { Principal } from '../../../shared/auth/principal.service';
import { BaPageTitleService } from '../../../theme/services/baMenu/baMenu.service';
import { MatPaginator } from '@angular/material/paginator';
import { merge ,  Observable } from 'rxjs';
import { catchError ,  map ,  startWith ,  switchMap } from 'rxjs/operators';
import { LoaderService } from '../../../shared/loader.service';
import { OrganisationBankDetailComponent } from './organisation-bank-details.component';
import { OrganisationDirectorAndCeoDetailsComponent } from './director-ceo-details.component';
import { OrganisationAuditorDetailComponent } from './organisation-auditor-details.component';
import { FormatInputs } from '../../../shared/format-values';
import { AddForemanDialogComponent } from './add-foreman-dialog.component';
import { SnbTableComponent } from '../../../shared/components/table/snb-table.component';
import { LogService } from '../../../shared/services/log-service';
import { DeleteConfirmationDialog } from '../../../shared/components/dialogs/delete-confirmation-dialog.component';
import { ConfirmationDialog } from '../../../shared/components/dialogs/confirmation-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
    templateUrl: 'branches-foreman-delete-dialog.component.html',
    styleUrls: ['./foreman-companies-new.scss']
})

export class DeleteForemanDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<DeleteForemanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
    removeForeman() {
        this.dialogRef.close(true);
    }
}

@Component({
    selector: 'app-branches-details',
    templateUrl: './branches-details.component.html',
    styleUrls: ['./dialog-popup-content.scss']
})
export class BranchesDetailsComponent implements OnInit {

    selectedBranchData: any;
    branchAdminUsers: any;
    regulatorDetails: any;
    isRegulatorSuperAdmin = false;
    isCustSuport = false;
    @Input() companyCode: any;
    organisationBranches: any[];
    constructor(
        public dialogRef: MatDialogRef<BranchesDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private toasterService: SnBToasterService,
        private dialog: MatDialog,
        private principalService: Principal,
        public loaderService: LoaderService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.selectedBranchData = this.data.selectedBranchData;
        this.branchAdminUsers = this.data.branchAdminUsers;
        this.regulatorDetails = this.data.regulatorDetails;
        this.isRegulatorSuperAdmin = this.principalService.hasAnyAuthority(['ROLE_REGULATOR_SUPER_ADMIN']);
        this.isCustSuport = this.principalService.hasAnyAuthority(['ROLE_CUST_SUPPORT']);
    }

    sendActivationLinkToForeman(id, type) {
        this.foremanCompanyService.sendActivationLinkToForeman(id, type).subscribe(res => {
            this.toasterService.showSuccessToaster('Activation Link Sent to Foreman Successfully');
        });
    }

    openForemanDeleteConfirmDialog(branchAdminUser, selectedBranchData): void {
        const dialogRef = this.dialog.open(DeleteForemanDialogComponent, {
            width: '250px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeForeman(branchAdminUser, selectedBranchData);
            }
        });
    }

    removeForeman(branchAdminUser, selectedBranchData) {
        this.foremanCompanyService.deleteForeman(selectedBranchData.companyCode, selectedBranchData.branchCode,
            branchAdminUser.id).subscribe((res: any) => {
                // TODO - Pending Task
                // this.getForemanCompanySubBranchesData(this.companyCode);
                this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.deleteForemanFromBranch'));
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

@Component({
    templateUrl: 'branches-foreman-add-dialog.component.html',
    styleUrls: ['./dialog-popup-content.scss'],
})

export class AddForemanToBranchDialogComponent implements OnInit {
    addFormanForm: any;
    verifyFormanMobileForm: FormGroup;
    selectedBranchData: any;
    foremanCompanyCode: any;
    isMobileNumberVerified = false;
    afterMobileNumberVerified = true;
    foremanDetails: any;
    @Input() companyCode: any;
    isExtingUser = false;
    isNewUser = false;
    constructor(
        public dialogRef: MatDialogRef<AddForemanToBranchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private foremanCompanyService: ForemanCompaniesService,
        private activatedRoute: ActivatedRoute,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.foremanCompanyCode = params.id;
        });
    }

    ngOnInit() {
        this.addFormanForm = this.fb.group({
            title: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            contactDetails: this.fb.group({
                email: ['', Validators.pattern(EMAIL_REGEX)],
                mobileNumber: ['', Validators.pattern(PHONW_REGEX)],
            }),
            designation: [['FOREMAN']]
        });

        this.verifyFormanMobileForm = this.fb.group({
            mobileNumber1: ['', [Validators.required, Validators.pattern(PHONW_REGEX)]],
        });
    }

    checkForemanNumber(event) {
        event.preventDefault();
        if (!this.verifyFormanMobileForm.valid) {
            return;
        }
        this.foremanDetails = {};
        this.foremanCompanyService.getFormanDetailByMobileNumber(
            this.foremanCompanyCode, this.verifyFormanMobileForm.value.mobileNumber1).subscribe((res: any) => {
                this.foremanDetails = res;
                this.applyFormValues(this.addFormanForm, res);
                this.addFormanForm.disable();
                this.afterMobileNumberVerified = false;
                this.isMobileNumberVerified = true;
                this.isExtingUser = true;
            }, (fail) => {
                const newUserMobileNumber = this.verifyFormanMobileForm.value.mobileNumber1;
                this.afterMobileNumberVerified = false;
                this.isMobileNumberVerified = true;
                this.isNewUser = true;
                this.addFormanForm.controls.contactDetails['controls'].mobileNumber.setValue(newUserMobileNumber);
                this.addFormanForm.controls.contactDetails['controls'].mobileNumber.disable();
            });
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        formControl.setValue(formValues[key]);
                    }
                }
            }
        });
    }

    addForemanToBranch(event) {
        event.preventDefault();
        if (this.foremanDetails && this.foremanDetails.id && this.foremanDetails.companyCode) {
            this.foremanCompanyService.addExistingForeman(this.foremanDetails.companyCode, this.data.branchData.branchCode,
                this.foremanDetails.id).subscribe((res: any) => {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.addForemanToBranch'));
                    this.dialogRef.close(true);
                });
        } else {
            if (!this.addFormanForm.valid) {
                return;
            }
            this.foremanCompanyService.addNewForemanToBranch(
                this.foremanCompanyCode, this.data.branchData.branchCode, this.addFormanForm.getRawValue()).subscribe(res => {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.addForemanToBranch'));
                    this.dialogRef.close(true);
                });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    templateUrl: './foreman-company-branch.html',
})
export class ForemanCompanyBranchDialogComponent implements OnInit, AfterViewChecked {
    public subBranchForm: FormGroup;
    isFormSubmitted = false;
    rocBranchesList: any;
    isForeman = false;
    // regulatorBranchOption = true;

    foremanCompanyBranchHasData = false;
    private foremanCompanyId: any;

    constructor(
        public dialogRef: MatDialogRef<ForemanCompanyBranchDialogComponent>,
        private fb: FormBuilder,
        private foremanCompanyService: ForemanCompaniesService,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private applicationsService: ApplicationsService,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA)
        public data: any
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.foremanCompanyId = params.id;
        });
        if (USER_TYPES.IS_FOREMAN) {
            this.isForeman = true;
        } else {
            this.isForeman = false;
        }
    }


    ngOnInit() {
        this.subBranchForm = this.branchForm();
        this.getROCbranches();
        if (this.data.subBranchData) {
            this.applyFormValues(this.subBranchForm, this.data.subBranchData);
        }
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    getROCbranches() {
        this.rocBranchesList = [];
        this.applicationsService.getAllROCBranches().subscribe(result => {
            this.rocBranchesList = result;
        }, fail => {
            if (fail._body) {
                const errors = JSON.parse(fail._body);
            }
        });
    }

    private applyFormValues(group, formValues) {
        this.foremanCompanyBranchHasData = true;
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        formControl.setValue(formValues[key]);
                        if (this.isForeman && key === 'mobileNumber') {
                            formControl.disable();
                        }
                        if (this.isForeman && key === 'email' && formValues[key]) {
                            formControl.disable();
                        }
                    }
                }
            }
        });
    }

    branchForm() {
        return this.fb.group({
            name: '',
            regulatorBranchCode: ['', Validators.required],
            contactDetails: this.fb.group({
                email: ['', Validators.pattern(EMAIL_REGEX)],
                mobileNumber: ['', Validators.pattern(PHONW_REGEX)],
                addresses: this.fb.array([
                    this.fb.group({
                        addressField: [''],
                        addressLine1: ['', [Validators.required]],
                        addressLine2: [''],
                        street: ['', [Validators.required]],
                        city: ['', [Validators.required]],
                        district: ['', [Validators.required]],
                        state: ['', [Validators.required]],
                        pin: ['', [Validators.required]],
                        type: ''
                    })
                ])
            }),
            adminUser: this.fb.group({
                title: '',
                firstName: '',
                lastName: '',
                contactDetails: this.fb.group({
                    email: ['', Validators.pattern(EMAIL_REGEX)],
                    mobileNumber: ['', Validators.pattern(PHONW_REGEX)]
                }),
                designation: ['ADMIN']
            }),
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    submitBranchData(branchData, event: Event, action) {
        event.preventDefault();
        if (!branchData.valid) {
            if (!branchData['controls'].contactDetails.controls.addresses.controls[0].controls.addressField.value) {
                branchData['controls'].contactDetails.controls.addresses.controls[0].controls.addressField.markAsTouched();
            }
            return false;
        }
        let data = branchData.value;
        const branchAddresses = data.contactDetails.addresses;
        if ((data.adminUser.designation && typeof (data.adminUser.designation) !== 'object') || !data.adminUser.designation) {
            data.adminUser.designation = [data.adminUser.designation];
        }
        for (let i = 0; i < branchAddresses.length; i++) {
            delete branchAddresses[i].addressField;
        }
        if (this.data.requestByForeman) {
            data.companyCode = this.data.companyCode;
            data.contactDetails.addresses['0'].type = 'OFFICE';
            data.regulatorBranchRefId = this.selectedROCBranch(data.regulatorBranchCode);
            this.isFormSubmitted = true;
            this.foremanCompanyService.requestForemanCompanyBranchByForeman(data, this.data.companyCIN, this.data.foremanCompanyName)
                .subscribe(result => {
                    this.isFormSubmitted = false;
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.branch.requestByForemanSuccess'));
                    this.dialogRef.close(true);
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
        } else {
            if (action === 'save') {
                data.companyCode = this.data.companyCode;
                data.contactDetails.addresses['0'].type = 'OFFICE';
                data.regulatorBranchRefId = this.selectedROCBranch(data.regulatorBranchCode);
                this.isFormSubmitted = true;
                this.foremanCompanyService.createForemanCompanyBranch(data).subscribe(result => {
                    this.isFormSubmitted = false;
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.branch.success'));
                    this.dialogRef.close(true);
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            } else {
                data.contactDetails.email = branchData.get('contactDetails')['controls'].email.value;
                data.contactDetails.mobileNumber = branchData.get('contactDetails')['controls'].mobileNumber.value;
                data.companyCode = this.data.companyCode;
                data = Object.assign(this.data.subBranchData, data);
                data.regulatorBranchRefId = this.selectedROCBranch(data.regulatorBranchCode);
                this.isFormSubmitted = true;
                this.foremanCompanyService.updateForemanCompanyBranch(data).subscribe(result => {
                    this.isFormSubmitted = false;
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.branch.updateSuccess'));
                    this.dialogRef.close(true);
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        }
    }

    selectedROCBranch(branchCode) {
        return this.rocBranchesList.find(x => x.branchCode === branchCode).id;
    }
}


@Component({
    templateUrl: './foreman-companies-add-more-info.html',
})
export class ForemanCompanyAddDirectorDialogComponent implements OnInit, AfterViewChecked {

    foremanUser: FormGroup;
    bankAndAuditors: FormGroup;
    private designationTypes: any[];
    foremanDesignation: any;
    isFormSubmitted = false;
    newForemanCompanyForm: FormGroup;
    foremanOrgTypes = ForemanOrgsTypes;
    maxIncorpDate = new Date();

    constructor(
        public dialogRef: MatDialogRef<ForemanCompanyAddDirectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private fb: FormBuilder,
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private formatInputs: FormatInputs,
        private toasterService: SnBToasterService
    ) {
        this.designationTypes = DesignationTypes;
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    ngOnInit() {
        this.foremanDesignation = this.data.userType ? this.data.userType : 'EMPLOYEE';

        this.newForemanCompanyForm = this.fb.group({
            name: ['', [Validators.required]],
            incorporationDate: undefined,
            cin: ['', [Validators.required]],
            pan: [''],
            gstn: [''],
            website: [''],
            noOfPartners: [''],
            orgType: ['', Validators.required],
            adminUser: this.fb.group({
                title: '',
                firstName: '',
                lastName: '',
                contactDetails: this.fb.group({
                    email: ['', Validators.pattern(EMAIL_REGEX)],
                    mobileNumber: ['', Validators.pattern(PHONW_REGEX)]
                }),
                designation: ['ADMIN']
            }),
            mainBranch: this.fb.group({
                name: '',
                regulatorBranchRefId: ['', Validators.required],
                contactDetails: this.fb.group({
                    email: ['', [Validators.pattern(EMAIL_REGEX)]],
                    mobileNumber: ['', Validators.pattern(PHONW_REGEX)],
                    altPhoneNumbers: [[]],
                    addresses: this.fb.array([
                        this.fb.group({
                            addressField: [''],
                            addressLine1: ['', [Validators.required]],
                            addressLine2: [''],
                            street: ['', [Validators.required]],
                            city: ['', [Validators.required]],
                            district: ['', [Validators.required]],
                            state: ['', [Validators.required]],
                            pin: ['', [Validators.required]]
                        })
                    ])
                })
            })
        });

        this.foremanUser = this.fb.group({
            title: '',
            firstName: '',
            lastName: '',
            contactDetails: this.fb.group({
                email: ['', Validators.pattern(EMAIL_REGEX)],
                mobileNumber: ['', Validators.pattern(PHONW_REGEX)],
                addresses: this.fb.array([
                    this.fb.group({
                        addressField: [''],
                        addressLine1: ['', [Validators.required]],
                        addressLine2: [''],
                        street: ['', [Validators.required]],
                        city: ['', [Validators.required]],
                        district: ['', [Validators.required]],
                        state: ['', [Validators.required]],
                        pin: ['', [Validators.required]]
                    })
                ])
            }),
            isNextToCeoInManagerialSetup: false,
            designation: [this.foremanDesignation]
        });

        this.bankAndAuditors = this.fb.group({
            name: ['', [Validators.required]],
            companyCode: this.data.companyCode,
            address: this.fb.group({
                addressField: [''],
                addressLine1: ['', [Validators.required]],
                addressLine2: [''],
                street: ['', [Validators.required]],
                city: ['', [Validators.required]],
                district: ['', [Validators.required]],
                state: ['', [Validators.required]],
                pin: ['', [Validators.required]],
                type: ['OFFICE']
            })
        });
        if (this.data.data) {
            this.bankAndAuditors.controls.name.setValue(this.data.data.name);
            this.applyFormValues(this.foremanUser, this.data.data);
        }
        if (this.data.organisation) {
            this.newForemanCompanyForm.disable();
            this.newForemanCompanyForm.controls.orgType.enable();
            this.newForemanCompanyForm.controls.name.enable();
            this.newForemanCompanyForm.controls.incorporationDate.enable();
            this.newForemanCompanyForm.controls.cin.enable();
            this.newForemanCompanyForm.controls.pan.enable();
            this.newForemanCompanyForm.controls.noOfPartners.enable();
            this.newForemanCompanyForm.controls.gstn.enable();
            this.newForemanCompanyForm.controls.website.enable();
            this.applyFormValues(this.newForemanCompanyForm, this.data.data);
            // tslint:disable-next-line: max-line-length
            this.newForemanCompanyForm.controls.incorporationDate.setValue(new Date(this.formatInputs.formatDateToTimeStampToMilliSeconds(this.data.data.incorporationDate)));
        }
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        formControl.setValue(formValues[key]);
                    }
                }
            }
        });
    }

    updateForemanOrg(foremanCompanyForm, event: Event) {
        event.preventDefault();
        if (!foremanCompanyForm.valid) {
            return false;
        }
        let data = foremanCompanyForm.value;
        data = Object.assign(this.data.data, data);
        this.isFormSubmitted = true;
        this.foremanCompanyService.updateNewForemanCompany(data).subscribe(result => {
            if (result) {
                this.isFormSubmitted = false;
                this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateSuccess'));
                this.dialogRef.close(true);
            }
        }, fail => {
            this.isFormSubmitted = false;
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }

    submitUserData(foremanUser, event: Event, action) {
        event.preventDefault();
        if (!foremanUser.valid) {
            if (!this.foremanUser.controls.contactDetails['controls'].addresses.controls[0].controls.addressField.value) {
                this.foremanUser.controls.contactDetails['controls'].addresses.controls[0].controls.addressField.markAsTouched();
            }
            return false;
        }
        let data = foremanUser.value;
        data.companyCode = this.data.companyCode;
        // if (!this.data.userType) {
        data.isNextToCeoInManagerialSetup = true;
        // }
        const branchAddresses = data.contactDetails.addresses;
        for (let i = 0; i < branchAddresses.length; i++) {
            delete branchAddresses[i].addressField;
        }
        if ((data.designation && typeof (data.designation) !== 'object') || !data.designation) {
            data.designation = [data.designation];
        }
        if (action === 'update') {
            data = Object.assign(this.data.data, data);
            this.foremanCompanyService.updateForemanUser(data).subscribe(res => {
                if (this.data.userType === 'DIRECTOR') {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateDirector'));
                } else if (this.data.userType === 'CEO') {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateCEO'));
                } else {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateOtherForeman'));
                }
                this.isFormSubmitted = true;
                this.dialogRef.close(true);
            }, fail => {
                if (fail) {
                    this.toasterService.showFailToaster(fail);
                }
            });
        } else {
            if (this.data.userType === 'CEO') {
                // this user is CEO, so call CEO api.
                this.foremanCompanyService.createForemanCEO(data).subscribe(res => {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createCEO'));
                    this.dialogRef.close(true);
                }, fail => {
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });

            } else {
                // this user is other type of foreman, so call generic api which creates the foreman without login.
                this.foremanCompanyService.createForemanUserWithoutLogin(data).subscribe(res => {
                    if (this.data.userType === 'DIRECTOR') {
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createDirector'));
                    } else {
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createOtherForeman'));
                    }
                    this.dialogRef.close(true);
                }, fail => {
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveBankerAndAuditor(bankAndAuditors, event: Event, action) {
        event.preventDefault();
        if (!bankAndAuditors.valid) {
            if (!this.bankAndAuditors.controls.address['controls'].addressField.value) {
                this.bankAndAuditors.controls.address['controls'].addressField.markAsTouched();
            }
            return false;
        }
        let data = bankAndAuditors.value;
        console.log(data);
        delete data.address.addressField;
        data.companyCode = this.data.companyCode;
        if (this.data.userType === 'BANKER') {
            this.isFormSubmitted = true;
            if (action === 'update') {
                data = Object.assign(this.data.data, data);
                this.foremanCompanyService.updateBankForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateBank'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            } else {
                this.foremanCompanyService.createBankForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createBank'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    this.isFormSubmitted = false;
                    if (fail) {
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        } else if (this.data.userType === 'AUDITOR') {
            this.isFormSubmitted = true;
            if (action === 'update') {
                data = Object.assign(this.data.data, data);
                this.foremanCompanyService.updateAuditorForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.updateAuditor'));
                        this.isFormSubmitted = true;
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    if (fail) {
                        this.isFormSubmitted = false;
                        this.toasterService.showFailToaster(fail);
                    }
                });
            } else {
                this.foremanCompanyService.createAuditorForCompany(data).subscribe(res => {
                    if (res) {
                        this.isFormSubmitted = false;
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.createAuditor'));
                        this.dialogRef.close(true);
                    }
                }, fail => {
                    if (fail) {
                        this.isFormSubmitted = false;
                        this.toasterService.showFailToaster(fail);
                    }
                });
            }
        }
    }

}

@Component({
    selector: 'app-foreman-organisation',
    templateUrl: './organisation.html',
    styleUrls: ['foreman-companies-new.scss'],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})

export class ForemanOrganisationComponent implements OnInit, AfterViewChecked {

    public newForemanCompanyForm: FormGroup;
    maxDate = new Date();
    public foremanOrgTypes: any;
    foremanCompanyData: any;
    isFormSubmitted = false;
    rocBranchesList: any;
    isForeman = false;

    @Input() companyCode: string;

    constructor(
        private fb: FormBuilder,

        private changeDetector: ChangeDetectorRef,
        private foremanCompanyService: ForemanCompaniesService,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        private router: Router,
        private applicationsService: ApplicationsService,
        private dialog: MatDialog,
        private formatInputs: FormatInputs,
        private pageTitleService: BaPageTitleService
    ) {
        
        
       

        this.foremanOrgTypes = ForemanOrgsTypes;
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
        } else {
            this.isForeman = true;
        }
    }

    ngOnInit() {
        this.newForemanCompanyForm = this.fb.group({
            name: ['', [Validators.required]],
            incorporationDate: undefined,
            cin: ['', [Validators.required]],
            noOfPartners: [''],
            pan: [''],
            gstn: [''],
            website: [''],
            orgType: ['', Validators.required],
            adminUser: this.fb.group({
                title: '',
                firstName: '',
                lastName: '',
                contactDetails: this.fb.group({
                    email: ['', Validators.pattern(EMAIL_REGEX)],
                    mobileNumber: ['', Validators.pattern(PHONW_REGEX)]
                }),
                designation: ['ADMIN']
            }),
            mainBranch: this.fb.group({
                name: '',
                regulatorBranchRefId: ['', Validators.required],
                contactDetails: this.fb.group({
                    email: ['', [Validators.pattern(EMAIL_REGEX)]],
                    mobileNumber: ['', Validators.pattern(PHONW_REGEX)],
                    altPhoneNumbers: [[]],
                    addresses: this.fb.array([
                        this.fb.group({
                            addressField: [''],
                            addressLine1: ['', [Validators.required]],
                            addressLine2: [''],
                            street: ['', [Validators.required]],
                            city: ['', [Validators.required]],
                            district: ['', [Validators.required]],
                            state: ['', [Validators.required]],
                            pin: ['', [Validators.required]]
                        })
                    ])
                })
            })
        });

        if (this.companyCode) {
            this.getForemanCompanyData();
        }
        this.getROCbranches();
        this.pageTitleService.currentMessage.subscribe();
        this.pageTitleService.changeMessage('Foreman Organisation > Details');
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    getROCbranches() {
        this.rocBranchesList = [];
        this.applicationsService.getROCbranches().subscribe(result => {
            this.rocBranchesList = result;
        }, fail => {
            if (fail._body) {
                const errors = JSON.parse(fail._body);
            }
        });
    }

    createNewForemanCompany(newForemanCompany, event: Event) {
        event.preventDefault();
        if (!newForemanCompany.valid) {
            if (!this.newForemanCompanyForm.controls['mainBranch']
            ['controls'].contactDetails.controls.addresses.controls[0].controls.addressField.value) {
                this.newForemanCompanyForm.controls['mainBranch']
                ['controls'].contactDetails.controls.addresses.controls[0].controls.addressField.markAsTouched();
            }
            return false;
        }
        const data = newForemanCompany.value;
        const branchAddresses = data.mainBranch.contactDetails.addresses;
        for (let i = 0; i < branchAddresses.length; i++) {
            delete branchAddresses[i].addressField;
        }
        if ((data.mainBranch.contactDetails.altPhoneNumbers &&
            typeof (data.mainBranch.contactDetails.altPhoneNumbers) !== 'object') || !data.mainBranch.contactDetails.altPhoneNumbers) {
            data.mainBranch.contactDetails.altPhoneNumbers = [data.mainBranch.contactDetails.altPhoneNumbers];
        }
        if ((data.adminUser.designation && typeof (data.adminUser.designation) !== 'object') || !data.adminUser.designation) {
            data.adminUser.designation = [data.adminUser.designation];
        }
        this.isFormSubmitted = true;
        this.foremanCompanyService.createNewForemanCompany(data).subscribe(result => {
            if (result) {
                this.isFormSubmitted = false;
                this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.success'));
                this.router.navigate(['/pages/foreman-companies/list']);
            }
        }, fail => {
            this.isFormSubmitted = false;
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }

    getForemanCompanyData() {
        this.foremanCompanyData = {};
        this.foremanCompanyService.getForemanCompanyDetails(this.companyCode).subscribe((res: any) => {
            this.foremanCompanyData = res;
            this.applyFormValues(this.newForemanCompanyForm, this.foremanCompanyData);
            // tslint:disable-next-line: max-line-length
            this.newForemanCompanyForm.controls.incorporationDate.setValue(new Date(this.formatInputs.formatDateToTimeStampToMilliSeconds(this.foremanCompanyData.incorporationDate)));
            this.newForemanCompanyForm.disable();
            this.pageTitleService.changeMessage('Foreman Organisation'+" > "+this.foremanCompanyData.name+" > Details");
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }

    private applyFormValues(group, formValues) {
        if (!group || !formValues) {
            return false;
        }
        Object.keys(formValues).forEach(key => {
            const formControl = <UntypedFormControl>group.controls[key];
            if (formControl instanceof FormGroup) {
                this.applyFormValues(formControl, formValues[key]);
            } else {
                if (formControl && !formControl['controls']) {
                    if (formValues[key] && typeof formValues[key] === 'object' && formValues[key].length) {
                        formControl.setValue(formValues[key][0]);
                    } else {
                        formControl.setValue(formValues[key]);
                    }
                }
            }
        });
    }

    editForemanOrganisation(userType, data) {
        const dialogRef = this.dialog.open(ForemanCompanyAddDirectorDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '350px',
            height: '550px',
            data: {
                'companyCode': this.foremanCompanyData.companyCode,
                'userType': userType,
                'foremanUser': false,
                'organisation': true,
                'data': data,
                'update': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getForemanCompanyData();
            }
        });
    }
}

@Component({
    selector: 'app-organisation-branches',
    templateUrl: './branches.html',
    styleUrls:['./branches.scss']
})

export class OrganisationBranchesComponent implements OnInit, AfterViewChecked {
    selectedRowIndex = 0;
    organisationBranches: any;
    displayedColumns: any;
    selectedBranchData: any;
    isForeman = false;
    branchAdminUsers: any;
    regulatorDetails: any;
    @Input() companyCode: any;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    itemsPerPage = 10;
    resultsLength = 0;
    isRegulatorSuperAdmin = false;
    isCustSuport = false;
    requestInProgress = false;
    public innerWidth: any;
    constructor(
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private toasterService: SnBToasterService,
        private dialog: MatDialog,
        private principalService: Principal,
        public loaderService: LoaderService,
        private translateService: TranslateService,
    ) {
        this.requestInProgress = true;
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
            this.displayedColumns = ['name', 'mobileNumber', 'actions'];
        } else {
            this.isForeman = true;
            this.displayedColumns = ['name', 'mobileNumber'];
        }
    }

    ngOnInit() {
        this.getForemanCompanySubBranchesData(this.companyCode);
    }

    addForeman() {
        const dialogRef = this.dialog.open(AddForemanToBranchDialogComponent, {
            panelClass: "custom-dialog-container",
            width: "500px",
            disableClose: true,
            autoFocus: false,
            data: {
                branchData: this.selectedBranchData
            },
            maxHeight: "90vh",
        });
        const self = this;
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                self.getForemanCompanySubBranchesData(this.companyCode);
            }
        });
    }

    openForemanDeleteConfirmDialog(branchAdminUser, selectedBranchData): void {
        const dialogRef = this.dialog.open(DeleteForemanDialogComponent, {
            width: '250px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeForeman(branchAdminUser, selectedBranchData);
            }
        });
    }

    removeForeman(branchAdminUser, selectedBranchData) {
        if (USER_TYPES.IS_FOREMAN) {

        } else {
            this.foremanCompanyService.deleteForeman(selectedBranchData.companyCode, selectedBranchData.branchCode,
                branchAdminUser.id).subscribe((res: any) => {
                    // this.branchAdminUsers.splice(res, 1);
                    this.getForemanCompanySubBranchesData(this.companyCode);
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.new.deleteForemanFromBranch'));
                });
        }
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
        this.onResize();
    }

    getForemanCompanySubBranchesData(companyCode) {
        this.requestInProgress = true;
        // Get my owned branches for Only for Foreman  user
        if (USER_TYPES.IS_REGULATOR || this.principalService.hasAnyAuthority(['ROLE_FOREMAN_SUPER_ADMIN'])) {
            this.organisationBranches = [];
            merge(this.paginator.page).pipe(startWith({}), switchMap(() => {
                return this.foremanCompanyService.getForemanCompanySubBranchesList(companyCode, {
                    page: this.paginator.pageIndex,
                    size: this.itemsPerPage,
                    sort: 'name,asc'
                });
            }), map((res: any) => {
                this.loaderService.display(false);
                this.requestInProgress = false;
                return res;
            }), catchError(() => {
                this.loaderService.display(false);
                this.requestInProgress = false;
                return [];
            })).subscribe(data => {
                this.organisationBranches = data['body'];
                this.requestInProgress = false;
                this.resultsLength = parseInt(data.headers.get('X-Total-Count'), 0);
                this.selectedRowIndex = 0;
                if (this.organisationBranches.length) {
                    if (this.innerWidth > 769) {
                        this.showMoreDetails(this.organisationBranches[this.selectedRowIndex], undefined);
                    }
                }
                this.loaderService.display(false);
            });

        } else {
            this.organisationBranches = [];

            merge(this.paginator.page).pipe(startWith({}), switchMap(() => {
                return this.foremanCompanyService.getForemanCompanyMyOwnedBranches(companyCode, {
                    page: this.paginator.pageIndex,
                    size: this.itemsPerPage
                });
            }), map((res: any) => {
                this.requestInProgress = false;
                this.loaderService.display(false);
                return res;
            }), catchError(() => {
                this.requestInProgress = false;
                this.loaderService.display(false);
                return [];
            })).subscribe(data => {
                this.requestInProgress = false;
                this.organisationBranches = data['body'];
                this.resultsLength = parseInt(data.headers.get('X-Total-Count'), 0);
                this.selectedRowIndex = 0;
                if (this.organisationBranches.length) {
                    if (this.innerWidth > 769) {
                        this.showMoreDetails(this.organisationBranches[this.selectedRowIndex], undefined);
                    }
                }
                this.loaderService.display(false);
            });

        }
    }

    // detect window width on window resize
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
    }


    showMoreDetails(row, event: Event) {
        if (event && event['toElement'] && event['toElement'].classList && event['toElement'].classList.contains('actions')) {
            return;
        }
        this.selectedRowIndex = row.id;
        this.selectedBranchData = row;
        this.viewBranchAdminUserInfo();
        this.isRegulatorSuperAdmin = this.principalService.hasAnyAuthority(['ROLE_REGULATOR_SUPER_ADMIN']);
        this.isCustSuport = this.principalService.hasAnyAuthority(['ROLE_CUST_SUPPORT']);
    }

    sendActivationLinkToForeman(id, type) {
        this.foremanCompanyService.sendActivationLinkToForeman(id, type).subscribe(res => {
            this.toasterService.showSuccessToaster('Activation Link Sent to Foreman Successfully');
        });
    }

    editBranch(event: Event) {
        const width = '350px';
        const dialogRef = this.dialog.open(ForemanCompanyBranchDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: width,
            data: {
                'subBranchData': this.selectedBranchData,
                'companyCode': this.companyCode,
                'viewBranchData': true,
                'viewAdminData': false
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getForemanCompanySubBranchesData(this.companyCode);
            }
        });
    }

    viewBranchAdminUserInfo() {
        this.branchAdminUsers = [];
        if (!this.selectedBranchData || !this.selectedBranchData.owners || !this.selectedBranchData.owners.length) {
            if (this.selectedBranchData.regulatorBranchRefId) {
                this.viewRegulatorInfo();
            }
            return;
        }
        const foremen = this.selectedBranchData.owners;
        this.viewRegulatorInfo();
        for (let i = 0; i < foremen.length; i++) {
            this.foremanCompanyService.getForemanDetailsById(foremen[i]).subscribe(res => {
                if (res) {
                    this.branchAdminUsers.push(res);
                }
            }, fail => {
                if (fail) {
                    this.toasterService.showFailToaster(fail);
                }
            });
        }
    }

    viewRegulatorInfo() {
        const id = this.selectedBranchData.regulatorBranchRefId;
        this.foremanCompanyService.getROCBranchData(id).subscribe(res => {
            if (res) {
                this.regulatorDetails = res;
                if (this.innerWidth <= 769) {
                    this.dialog.open(BranchesDetailsComponent, {
                        width: '450px',
                        data: {
                            selectedBranchData: this.selectedBranchData,
                            branchAdminUsers: this.branchAdminUsers,
                            regulatorDetails: this.regulatorDetails
                        }
                    });
                }
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }

}

@Component({
    selector: 'app-foreman-users',
    templateUrl: './foreman-users.html',
    styleUrls:['./foreman-companies-new.scss']
})

export class ForemanUsersComponent implements OnInit, AfterViewChecked {
    componentName = 'ForemanUsersComponent';
    selectedRowIndex = 0;
    foremanAdmins: any;
    designationTypes: any;
    displayedColumns: any;
    selectedForemanData: any;
    isForeman = false;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    itemsPerPage = 10;
    resultsLength = 0;
    isRegulatorSuperAdmin = false;
    isCustSuport = false;
    public innerWidth: any;
    requestInProgress = false;
    @Input() companyCode: any;
    isRoc = false;

    constructor(
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        private principalService: Principal,
        private loaderService: LoaderService,
        private toasterService: SnBToasterService,
        private logService: LogService,
        private translateService: TranslateService,
    ) {
        this.requestInProgress = true;
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
            this.isRoc = true;
            this.displayedColumns = ['firstName', 'mobileNumber', 'designation'];
        } else {
            this.isForeman = true;
            this.displayedColumns = ['firstName', 'mobileNumber', 'designation'];
        }
    }

    ngOnInit() {
        this.isRegulatorSuperAdmin = this.principalService.hasAnyAuthority(['ROLE_REGULATOR_SUPER_ADMIN']);
        this.isCustSuport = this.principalService.hasAnyAuthority(['ROLE_CUST_SUPPORT']);
        
        this.getDirectorsInformation(this.companyCode);
    }

    checkAccess(designation) {
        return (designation.indexOf('ADMIN') === -1 && designation.indexOf('FOREMAN') === -1) ? true : false;
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
        this.onResize();
    }
    // detect window width on window resize
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
    }
    getDirectorsInformation(companyCode) {
        this.requestInProgress = true;
        merge(this.paginator.page).pipe(startWith({}), switchMap(() => {
            return this.foremanCompanyService.getForemanDirectorInfo(companyCode, false, {
                page: this.paginator.pageIndex,
                size: this.itemsPerPage,
                sort: 'firstName,asc'
            });
        }), map((res: any) => {
            this.loaderService.display(false);
            this.requestInProgress = false;
            return res;
        }), catchError(() => {
            this.loaderService.display(false);
            this.requestInProgress = false;
            return [];
        })).subscribe(res => {
            this.foremanAdmins = res['content'];
            this.requestInProgress = false;
            this.resultsLength = parseInt(res.totalElements, 0);
            this.selectedRowIndex = 0;
            if (this.foremanAdmins.length) {
                if (this.innerWidth > 769) {
                    this.showMoreDetails(this.foremanAdmins[this.selectedRowIndex]);
                }
            }
            this.loaderService.display(false);
        });
    }

    showMoreDetails(row, event?: any) {
        this.selectedRowIndex = row.id;
        this.selectedForemanData = row;
        this.logService.logWC(this.componentName, "Row clicked. Index", this.selectedRowIndex);
        if (event && event.target && event.target.className.indexOf('actions') > -1) {
            return;
        }
        if (this.innerWidth <= 769) {
            this.dialog.open(OrganisationDirectorAndCeoDetailsComponent, {
                width: '450px',
                data: {
                    selectedForemanData: this.selectedForemanData,
                }
            });
        } else {
            this.getLoginAccountDetails();
        }

    }

    sendActivationLinkToForeman(id, type) {
        this.foremanCompanyService.sendActivationLinkToForeman(id, type).subscribe(res => {
            this.toasterService.showSuccessToaster('Activation Link Sent to Foreman Successfully');
        });
    }

    editForemanUser() {
        const dialogRef = this.dialog.open(AddForemanDialogComponent, {
            panelClass: "custom-dialog-container",
            width: '700px',
            data: {
                'companyCode': this.companyCode,
                'userType': this.selectedForemanData.designation[0],
                'foremanUser': true,
                'data': this.selectedForemanData,
                'update': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getDirectorsInformation(this.companyCode);
            }
        });
    }

    makeForemanAsAdmin() {
       // there are saved notices, give confirmation
       const confirmDialog = this.dialog.open(ConfirmationDialog, {
            panelClass: "custom-dialog-container",
            width: "300px",
            autoFocus: false,
            data: {
                message:
                    "Are you sure you want to make "+this.selectedForemanData.firstName +" as admin?",
            },
        });

        confirmDialog.afterClosed().subscribe((res) => {
            console.log("Confirmation Response is ", res);
            if (res && res === "YES") {
                this.loaderService.display(true);
                this.foremanCompanyService.makeForemanAsAdmin(this.selectedForemanData.companyCode, this.selectedForemanData.id).subscribe((res) => {
                    this.loaderService.display(false);
                    this.getDirectorsInformation(this.companyCode);
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.user.adminAccessGranted'));
                }, (faile) => {
                    this.loaderService.display(false);
                })
            } else {
                return;
            }
        });
    }

    removeAdminAccess() {
        // there are saved notices, give confirmation
        const confirmDialog = this.dialog.open(ConfirmationDialog, {
             panelClass: "custom-dialog-container",
             width: "300px",
             autoFocus: false,
             data: {
                 message:
                     "Are you sure you want to remove Admin access to "+this.selectedForemanData.firstName +"?",
             },
         });
 
         confirmDialog.afterClosed().subscribe((res) => {
             console.log("Confirmation Response is ", res);
             if (res && res === "YES") {
                 this.loaderService.display(true);
                 this.foremanCompanyService.removeAdminAccess(this.selectedForemanData.companyCode, this.selectedForemanData.id).subscribe((res) => {
                     this.loaderService.display(false);
                     this.getDirectorsInformation(this.companyCode);
                     this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.user.adminAccessRevoked'));
                 }, (faile) => {
                     this.loaderService.display(false);
                 })
             } else {
                 return;
             }
         });
     }

     /**
      * This is only function for customer support to add Foreman Designation to the user.
      * 
      */
     giveForemanDesignationToUser() {
        // there are saved notices, give confirmation
        const confirmDialog = this.dialog.open(ConfirmationDialog, {
             panelClass: "custom-dialog-container",
             width: "300px",
             autoFocus: false,
             data: {
                 message:
                     "Are you sure you want to add Foreman Designation to "+this.selectedForemanData.firstName +"?",
             },
         });
 
         confirmDialog.afterClosed().subscribe((res) => {
             console.log("Confirmation Response is ", res);
             if (res && res === "YES") {
                 this.loaderService.display(true);
                 this.foremanCompanyService.addForemanDesignation(this.selectedForemanData.companyCode, this.selectedForemanData.id, "FOREMAN").subscribe((res) => {
                     this.loaderService.display(false);
                     this.getDirectorsInformation(this.companyCode);
                     this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.user.foremanDesignationAdded'));
                 }, (faile) => {
                     this.loaderService.display(false);
                 })
             } else {
                 return;
             }
         });
     }

     /**
      * This is only function for customer support to add Foreman Designation to the user.
      * 
      */
     removeForemanDesignationToUser() {
        // there are saved notices, give confirmation
        const confirmDialog = this.dialog.open(ConfirmationDialog, {
             panelClass: "custom-dialog-container",
             width: "300px",
             autoFocus: false,
             data: {
                 message:
                     "Are you sure you want to remove Foreman Designation to "+this.selectedForemanData.firstName +"?",
             },
         });
 
         confirmDialog.afterClosed().subscribe((res) => {
             console.log("Confirmation Response is ", res);
             if (res && res === "YES") {
                 this.loaderService.display(true);
                 this.foremanCompanyService.removeForemanDesignation(this.selectedForemanData.companyCode, this.selectedForemanData.id, "FOREMAN").subscribe((res) => {
                     this.loaderService.display(false);
                     this.getDirectorsInformation(this.companyCode);
                     this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.user.foremanDesignationRemoved'));
                 }, (faile) => {
                     this.loaderService.display(false);
                 })
             } else {
                 return;
             }
         });
     }

    /**
     * This method fetches the login account details of selected foreman.
     */
    getLoginAccountDetails() {
        this.logService.logWC(this.componentName, "Fetching the login a/c details");
        if(!this.selectedForemanData || !this.selectedForemanData.userId) {
            this.logService.logWC(this.componentName, "User id does not exist, fetching login details ignored");
            // user id does not exist, he is not activated yet.
            return;
        }
        
        if(this.selectedForemanData.loginAccountDetails) {
            this.logService.logWC(this.componentName, "Login a/c detials already fetched, so returning");
            // details already populated.
            return ;
        }
        
        if(this.isCustSuport || this.isRegulatorSuperAdmin) {
            // only allow for customer support or regulator super admin to inspect the login details of the user.
            let userId: number = this.selectedForemanData.userId;
            this.foremanCompanyService.getLoginAccountDetails(userId).subscribe(res => {
                this.selectedForemanData.loginAccountDetails = res;
                this.logService.logWC(this.componentName, "Login a/c detials fetched and assigned to loginAccountDetails", this.selectedForemanData.loginAccountDetails);
            });
        } else {
            //this.logService.logWC(this.componentName, "User is not super admin.");
        }
    }

}

@Component({
    selector: 'app-organisation-bank',
    templateUrl: './organisation-bank.html',
    styles:[
        `::ng-deep .mat-mdc-menu-panel .foreman-company-more-actions i.material-icons {
            font-size: 18px;
            position: relative;
            top: 3px;
            color: #2979FF;
            background-color: white;
        }
        .foreman-company-more-actions i.material-icons,i.fa {
            background-color: inherit;
        }`
    ]
})

export class OrganisationBankComponent extends SnbTableComponent implements OnInit, AfterViewChecked {

    displayedColumns: any;
    isForeman = false;
    @Input() companyCode: any;

    constructor(
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private toasterService: SnBToasterService,
        private dialog: MatDialog,
        public loaderService: LoaderService,
        public logService: LogService,
        private translateService: TranslateService,

    ) {
        super(loaderService, logService);

        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
            this.displayedColumns = ['name','bank','usageType', 'branchName'];
        } else {
            this.isForeman = true;
            this.displayedColumns = ['name','bank','usageType', 'branchName', 'actions'];
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.loadAll();
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
        this.onResize();
    }

    fetchData(): Observable<any> {
        let data = this.foremanCompanyService.getBankDetailsForCompany(this.companyCode, {
            page: this.paginator.pageIndex,
            size: this.itemsPerPage,
            sort: this.sort.active + ',' + this.sort.direction,
        });
        return data;
    }

    rowClicked(event: any, rowData: any) {
        if (this.innerWidth > 769) {
            this.showMoreDetails(rowData);
        }
    }

    tableDataLoaded() {
        console.log("table Data Loaded");
        console.log("rows: ", this.rows);
        if(this.rows && this.rows.length > 0) {
            this.selectedRow = this.rows[0];
            this.selectedRowIndex = this.selectedRow.id;
        }
    }


    showMoreDetails(row, event?: any) {
        this.selectedRowIndex = row.id;
        this.selectedRow = row;
        if (event && event.target && event.target.className.indexOf('actions') > -1) {
            return;
        }
        if (this.innerWidth <= 769) {
            this.dialog.open(OrganisationBankDetailComponent, {
                width: '450px',
                data: {
                    selectedBankData: this.selectedRow,
                }
            });
        }
    }

    editBank() {
        const dialogRef = this.dialog.open(AddForemanDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '350px',
            // height: '250px',
            data: {
                'companyCode': this.companyCode,
                'userType': 'BANKER',
                'foremanUser': false,
                'bankAndAuditor': true,
                'data': this.selectedRow,
                'update': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.refreshData();
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
                this.requestInProgress = false;
            }
        });

    }

    deleteBank(selectedBank: any) {
        const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container',
            width: '350px',
            // height: '250px',
            data: { }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'YES') {
                this.foremanCompanyService.deleteBankDetailsById(selectedBank.id, selectedBank.companyCode).subscribe(res => {
                    this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.bank.deleteSuccess'));
                    this.refreshData();
                });
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
                this.requestInProgress = false;
            }
        });

    }

    updateActivattionStatusOfBank(selectedBank: any, status: boolean) {
            const confirmDialog = this.dialog.open(BankStatusUpdateDialogComponent, {
                panelClass:'custom-dialog-container',
                width: "450px",
                autoFocus: false,
                data:{
                    selectedBank: selectedBank,
                    status: status
                }
            });
            confirmDialog.afterClosed().subscribe(result =>{
                console.log(result,"result")
                if (result) {
                    // Create a new Date object using the provided date string
                    var updateDate = new Date(result);
                    // Convert the date to UTC format
                    var utcUpdateDateString = updateDate.toISOString();
                    this.foremanCompanyService.updateBankDetailsActiveStatus(selectedBank.id, status, selectedBank.companyCode,utcUpdateDateString).subscribe(res => {
                        this.toasterService.showSuccessToaster(this.translateService.instant('foremanCompany.bank.updateSuccess'));
                        this.refreshData();
                    });
                }
            }, fail => {
                if (fail) {
                    this.toasterService.showFailToaster(fail);
                    this.requestInProgress = false;
                }
            });
    }
}

@Component({
    selector: 'app-organisation-auditor',
    templateUrl: './organisation-auditor.html'
})

export class OrganisationAuditorComponent implements OnInit, AfterViewChecked {

    selectedRowIndex = 0;
    displayedColumns = ['name', 'actions'];
    selectedAuditorData: any;
    foremanAuditors: any;
    isForeman = false;
    public innerWidth: any;
    @Input() companyCode: any;
    requestInProgress = false;

    constructor(
        private foremanCompanyService: ForemanCompaniesService,
        private changeDetector: ChangeDetectorRef,
        private toasterService: SnBToasterService,
        private dialog: MatDialog
    ) {
        this.requestInProgress = true;
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
            this.displayedColumns = ['name', 'status'];
        } else {
            this.isForeman = true;
            this.displayedColumns = ['name', 'status'];
        }
    }

    ngOnInit() {
        this.getAuditorsDetailsForCompany(this.companyCode);
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
        this.onResize();
    }
    // detect window width on window resize
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
    }
    getAuditorsDetailsForCompany(companyCode) {
        this.requestInProgress = true;
        this.foremanAuditors = [];
        this.foremanCompanyService.getAuditorsDetailsForCompany(companyCode).subscribe(res => {
            this.foremanAuditors = res;
            this.requestInProgress = false;
            if (this.foremanAuditors.length && !this.selectedRowIndex) {
                // this.showMoreDetails(res[this.selectedRowIndex]);
                if (this.foremanAuditors.length) {
                    if (this.innerWidth > 769) {
                        this.showMoreDetails(res[this.selectedRowIndex]);
                    }
                }
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
                this.requestInProgress = false;
            }
        });
    }

    showMoreDetails(row, event?: any) {
        this.selectedRowIndex = row.id;
        this.selectedAuditorData = row;
        if (event && event.target && event.target.className.indexOf('actions') > -1) {
            return;
        }
        if (this.innerWidth <= 769) {
            this.dialog.open(OrganisationAuditorDetailComponent, {
                width: '450px',
                data: {
                    selectedAuditorData: this.selectedAuditorData,
                }
            });
        }
    }

    editAuditor() {
        const dialogRef = this.dialog.open(ForemanCompanyAddDirectorDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '350px',
            height: '250px',
            data: {
                'companyCode': this.companyCode,
                'userType': 'AUDITOR',
                'foremanUser': false,
                'bankAndAuditor': true,
                'data': this.selectedAuditorData,
                'update': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAuditorsDetailsForCompany(this.companyCode);
            }
        });
    }

}

@Component({
    templateUrl: './foreman-companies-new.html',
})

export class ForemanCompaniesNewComponent implements OnInit, AfterViewChecked {
    public foremanCompanyData: any;
    public foremanInformantionForm: FormGroup;
    foremanCompanyId: any;
    isForeman = false;
    isFormSubmitted = false;
    rocBranchesList: any;

    constructor(
        private fb: FormBuilder,

        private foremanCompanyService: ForemanCompaniesService,
        private activatedRoute: ActivatedRoute,
        private toasterService: SnBToasterService,
        private changeDetector: ChangeDetectorRef,
        private profileService: ProfileService,
        private pageTitleService: BaPageTitleService
    ) {
        if (USER_TYPES.IS_REGULATOR) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.foremanCompanyId = params.id;
            });
        } else {
            this.isForeman = true;
            this.profileService.getForemanProfileData().subscribe(res => {
                this.foremanCompanyId = res['companyCode'];
                this.getForemanCompanyData();
            }, fail => {
                if (fail) {
                    this.toasterService.showFailToaster(fail);
                }
            });
        }
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    ngOnInit() {
        if (this.foremanCompanyId) {
            this.getForemanCompanyData();
        }
        this.pageTitleService.currentMessage.subscribe();
        this.pageTitleService.changeMessage('My Organisation');
    }

    getForemanCompanyData() {
        this.foremanCompanyData = {};
        this.foremanCompanyService.getForemanCompanyDetails(this.foremanCompanyId).subscribe((res: any) => {
            this.foremanCompanyData = res;
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }

    tabChanged = (tabChangeEvent: MatTabChangeEvent, orgAddActionsComponent): void => {
        orgAddActionsComponent.tabChangedEvent(tabChangeEvent.index);
    }
}


@Component({
    selector: 'app-organisation-add-actions',
    templateUrl: './organisation-add-actions.html',
    styleUrls: ['./dialog-popup-content.scss']
})

export class OrganisationAddActionsComponent implements OnInit, AfterViewChecked {

    selectedIndex = 0;
    isForeman = false;
    isAdminUser: boolean = false;
    @Input() companyCode: any;
    @Input('auditor') auditorComponent: any;
    @Input('bank') bankComponent: any;
    @Input('branches') branchesComponent: any;
    @Input('foreman') directorAndCEOComponent: any;
    @Input() companyCIN: any;
    @Input() foremanCompanyName: any;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dialog: MatDialog,
        private router: Router,
        public localStorage: LocalStorageService,
        private foremanCompanyService: ForemanCompaniesService,
        public sessionStorage: SessionStorageService,
    ) {
        if (USER_TYPES.IS_REGULATOR) {
            this.isForeman = false;
        } else {
            this.isForeman = true;
        }
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    ngOnInit() {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if (token && token.access_token) {
            const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
            if(userData.authorities.includes('ROLE_FOREMAN_SUPER_ADMIN')) {
                this.isAdminUser = true;
            }
        }
    }

    tabChangedEvent(index) {
        this.selectedIndex = index;
        switch (index) {
            case 1:
                //this.branchesComponent.getForemanCompanySubBranchesData(this.companyCode);
                break;
            case 2:
                //this.directorAndCEOComponent.getDirectorsInformation(this.companyCode);
                break;
            case 3:
                // this.bankComponent.getBankDetailsForCompany(this.companyCode);
                break;
            case 4:
                // this.auditorComponent.getAuditorsDetailsForCompany(this.companyCode);
                break;
        }
    }

    createNewBranch(request) {

        const dialogRef = this.dialog.open(ForemanCompanyBranchDialogComponent, {
            width: '700px',
            data: {
                'companyCode': this.companyCode,
                'viewBranchData': true,
                'viewAdminData': true,
                'requestByForeman': request ? true : false,
                'companyCIN': this.companyCIN,
                'foremanCompanyName': this.foremanCompanyName
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && this.selectedIndex === 1 && !request) {
                this.branchesComponent.getForemanCompanySubBranchesData(this.companyCode);
            }
        });
    }

    addForemanDetailsUsingDialog(userType) {
        const dialogRef = this.dialog.open(AddForemanDialogComponent, {
            panelClass: "custom-dialog-container",
            width: '700px',
            data: {
                'companyCode': this.companyCode,
                'userType': userType,
                'foremanUser': true,
                'create': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && this.selectedIndex === 2) {
                this.directorAndCEOComponent.getDirectorsInformation(this.companyCode);
            }
        });
    }

    addForemanUser(userType: string) {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if(token && token.access_token) {
            const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
            if(userData.authorities.includes('ROLE_REGULATOR_SUPER_ADMIN')) {
                // Regulator can directly add the user without any regulator approval
                this.addForemanDetailsUsingDialog(userType);
                return;
            }
        }

        this.foremanCompanyService.checkIsRegulatorApprovalRequiredForOrgChanges().subscribe((res: any) => {
            // In case of DIRECTOR/CEO/AUDITOR users, if atleast One PSO is approved then next all change requests will redirect through workflow process
            // else we will capture data using Mat-dialog without requiring of Regulator approval
            if ((userType === 'DIRECTOR' || userType === 'CEO' || userType === 'AUDITOR') && res.isRequired) {
                let confirmDialog = this.dialog.open(ConfirmationDialog, {
                    panelClass: "custom-dialog-container",
                    width: "400px",
                    autoFocus: false,
                    data: {
                        message:
                            `As there are Chits present in the system, any changes in Organisation Data requires 
                            Regulator approvals. Are you sure to proceed ?`,
                    },
                });
        
                confirmDialog.afterClosed().subscribe((res) => {
                    console.log("Confirmation Response is ", res);
                    if (res && res === "YES") {
                        this.router.navigate(['/pages/applications/change-request'], {
                            queryParams: { userType: userType.toLowerCase(), action: 'ADD' }
                        });
                    } else if(res && res === "NO"){
                        return;
                    }
                });
            } else {
                this.addForemanDetailsUsingDialog(userType);
            }
        })
    }

    addBankDetailsWithoutRegulatorApproval(type: string, accType: string) {
        const dialogRef = this.dialog.open(AddForemanDialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '350px',
            // height: '510px',
            data: {
                'companyCode': this.companyCode,
                'userType': type,
                'accType': accType,
                'bankAndAuditor': true,
                'create': true
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && (this.selectedIndex === 3 || this.selectedIndex === 4)) {
                if (type === 'AUDITOR') {
                    this.auditorComponent.getAuditorsDetailsForCompany(this.companyCode);
                } else {
                    //this.bankComponent.getBankDetailsForCompany(this.companyCode);
                }
            }
        });
    }

    addBankDetails(type: string, accType: string) {
        console.log(this.auditorComponent);
        if(type === 'BANKER' && accType === 'COLLECTION') {
            this.addBankDetailsWithoutRegulatorApproval(type, accType);
        } else {
            this.foremanCompanyService.checkIsRegulatorApprovalRequiredForOrgChanges().subscribe((res: any) => {
                // If atleast One PSO is approved then next all Bank Acct requests will go through approval process
                // else we will capture data using Mat-dialog without requiring of Regulator approval
                if(type === 'BANKER' && res.isRequired) {
                    let confirmDialog = this.dialog.open(ConfirmationDialog, {
                        panelClass: "custom-dialog-container",
                        width: "400px",
                        autoFocus: false,
                        data: {
                            message:
                                `Adding of new Separate Account for any branch requires Regulator approval. Do you wish to proceed ?`,
                        },
                    });
            
                    confirmDialog.afterClosed().subscribe((res) => {
                        console.log("Confirmation Response is ", res);
                        if (res && res === "YES") {
                            this.router.navigate(['/pages/applications/service-request'], {
                                queryParams: { serviceType: 'bankDetails', accType: accType }
                            });
                        } else if(res && res === "NO"){
                            return;
                        }
                    });
                } else {
                    this.addBankDetailsWithoutRegulatorApproval(type, accType);
                }
            });
        }
    }

}

@Component({
    templateUrl: './bank-status-update-dialog.component.html',
    styleUrls: ['./dialog-popup-content.scss']
})

export class BankStatusUpdateDialogComponent implements OnInit {
    inActiveForm: FormGroup;
    isFormSubmited = false;
    maxDate = new Date();
    minDate : any;
    constructor(
        public dialogRef: MatDialogRef<BankStatusUpdateDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

    ngOnInit() {
        this.inActiveForm = this.fb.group({
            updateDate:['',Validators.required]
        }) 
        this.minDate = new Date(this.data.selectedBank.startDate * 1000)
    }

    submitForm(event :Event, form: FormGroup) {
        event.preventDefault();
        this.isFormSubmited = true;
        if (form.valid) {
            this.isFormSubmited = false;
            this.dialogRef.close(form.controls.updateDate.value);
        }
        else {
            this.isFormSubmited = false;
            return false;
        }
    }

    cancelDialog() {
        this.dialogRef.close();
    }
}
