<div class="chm-dialog-popup">
    <div class="chm-dialog-popup-title">
        <span *ngIf="!data?.status">Bank  In Active</span>
        <span *ngIf="data?.status">Bank Active</span>
    </div>
    <div class="form-wrapper">
        <div class="row snb_form_entries">
            <form [formGroup]="inActiveForm" autocomplete="off" (ngSubmit)="submitForm($event,inActiveForm)" class="form_align">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ data?.status ? 'Bank Start Date' : 'Bank End Date' }}</mat-label>
                            <mat-icon matPrefix>date_range</mat-icon>
                            <input matInput [matDatepicker]="picker" format-date formControlName="updateDate"
                                (click)="picker.open()" (focus)="picker.open();"
                                readonly [max]="maxDate" [min]="minDate" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"
                                style="display:none"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="inActiveForm?.controls?.updateDate?.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="snb_form_actions text-center">
                        <button mat-raised-button class="snb_btn_reset" [mat-dialog-close]="true"(click)="cancelDialog()">
                            <span>Cancel</span>
                        </button>
                        <button mat-raised-button class="btn-group snb-raised-button snb_btn_submit"
                            type="submit" [disabled]='isFormSubmited' >
                            Submit
                            <i *ngIf="isFormSubmited" style="margin-left: 10px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>