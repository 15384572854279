<div class="snb_upload_file">
    <span class="upload_icon">
        <a>
            <i class="material-icons">cloud_upload</i>
            <input type="file" (click)="onFileSelected($event)" (change)="valuechange($event)" [id]="accessDataName"
                [attr.filename]="fileData?.name" ng2FileSelect [accept]="acceptFileTypes" [uploader]="uploaderA" />
        </a>
    </span>
    <div class="file_upload">
        <h5>{{placeholder}}</h5>
        <!-- <div class="file_name" *ngIf="uploadedAFile?.file && !fileUploadInProgress">test{{uploadedAFile?.file?.name}}</div>-->
        <div class="file_name" *ngIf="!fileUploadInProgress && fileData">{{fileData?.name}}</div>
        <i *ngIf="fileUploadInProgress" style="font-size: 18px; float: right;color: green;"
            class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <app-snb-file-preview *ngIf="fileData?.created && !fileUploadInProgress" [fileData]="fileData">
        </app-snb-file-preview>
    </div>
    <!-- <div class="progress" *ngIf="uploadedAFile?.isReady || uploadedAFile?.isUploading || uploadedAFile?.isSuccess">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader?.progress + '%' }"></div>
    </div> -->
</div>