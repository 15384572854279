import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { Principal } from './principal.service';

@Directive({
    selector: '[jhiHasAuthority]'
})
export class HasAuthorityDirective implements OnInit {

    @Input() jhiHasAuthority: string;
    authority: string;

    constructor(private principal: Principal, private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit() {
        this.authority = this.jhiHasAuthority.replace(/\s+/g, '');

        if (this.authority.length > 0) {
            this.setVisibilityAsync();
        }
        this.principal.getAuthenticationState().subscribe(identity => this.setVisibilitySync());
    }

    private setVisibilitySync() {
        if (this.principal.hasAnyAuthority([this.authority])) {
            this.setVisible();
        } else {
            this.setHidden();
        }
    }

    private setVisible() {
        this.renderer.removeClass(this.el.nativeElement, 'hidden-xs-up');
    }

    private setHidden() {
        this.renderer.addClass(this.el.nativeElement, 'hidden-xs-up');
    }

    private setVisibilityAsync() {
        this.principal.hasAuthority(this.authority).then(result => {
            if (result) {
                this.setVisible();
            } else {
                this.setHidden();
            }
        });
    }
}
