import { NgModule, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RouterModule } from '@angular/router';
import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';
import { ChitmonksApiSharedModule, UserRouteAccessService } from './shared';
import { LoaderService } from './shared/loader.service';
import { UserAuthenticationService } from './shared/user-auth.service';
/*
 * Platform and Environment providers/directives/pipes
 */
import { AppRoutingModule } from './app.routing';

// App is our top level component
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { PagesModule } from './pages/pages.module';
import { AppCustomPreloader } from './app.routing.loader';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './shared/auth/http-error-interceptor';
import { BaPageTitleService } from './theme/services/baMenu/baMenu.service';
import { AppComponent } from './app.component';
import { ConnectionService } from './app.network.connection.service';
import { HttpRequestResponseInterceptor } from './blocks/interceptor/http-request-response.interceptor';
import { TranslationModule } from './shared/language/translation.module';
import { TranslateModule } from '@ngx-translate/core';
import { httpInterceptorProviders } from './blocks/interceptor';



// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  UserRouteAccessService
];


interface StoreType {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent
  ],
  imports: [ // import Angular's modules
    BrowserAnimationsModule,
    RouterModule,
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
    ChitmonksApiSharedModule,
    PagesModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    importProvidersFrom(TranslationModule),
    httpInterceptorProviders,
    LoaderService,
    // StompService,
    UserAuthenticationService,
    AppCustomPreloader,
    BaPageTitleService,
    ConnectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestResponseInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  exports:[TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {

  constructor(public appRef: ApplicationRef, public appState: AppState) {
  }

  hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    console.log('HMR store', JSON.stringify(store, null, 2));
    // set state
    this.appState._state = store.state;
    // set input values
    if ('restoreInputValues' in store) {
      const restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // save state
    const state = this.appState._state;
    store.state = state;
    // recreate root elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // save input values
    store.restoreInputValues = createInputTransfer();
    // remove styles
    removeNgStyles();
  }

  hmrAfterDestroy(store: StoreType) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
