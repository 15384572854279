
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SNB_BASE_URL, USER_TYPES } from '../../app.constants';
import { HttpUtilService } from '../http-util.service';
import { FilterCriteria } from './filter-criteria.model';

const filterMetaDataJson: any = require('./filter-meta-data.json');

@Injectable()
export class FilterCriteriaNewService {

    module: any;
    filtersWithFullData: any;
    filterMetaDataDefMap: any;
    companyCode: any;

    constructor(
        private httpClient: HttpClient,
        private httpUtilService: HttpUtilService
    ) { 
        this.filterMetaDataDefMap = filterMetaDataJson;
    }

    /**
     * This is new implementation to get filter metadata by filter id.
     * @author: sidhu
     */
    getFiltersByFilterId(filterId: string): FilterCriteria [] {
        console.log('fetching filter meta by filterId', filterId);
        const data = this.filterMetaDataDefMap[filterId];
        console.log('filter meta data of filter ', filterId, ' is ', JSON.stringify(data));
        return data; 
    }

    getFilterValues<T>(itemAttribute: any, uri: any, req?: any, predicates?: any, item?: any, selectedItems?: any):Observable<any> {
        const options = this.httpUtilService.createRequestOption(req);
        let url = SNB_BASE_URL;

        if (!predicates) {
            predicates = {};
        }
        
        url = `${url}${uri}/get-filter-values/${itemAttribute}`;
        
        if (item && item.dependsOnOtherAttribute) {
            const dependentItem = selectedItems.filter(x => item.dependsAttributeName === x.attribute);
            if (dependentItem[0].valueCode) {
                url = `${url}?${item.dependsAttributeName}=${dependentItem[0].valueCode}`;
            } else if (dependentItem[0].value) {
                url = `${url}?${item.dependsAttributeName}=${dependentItem[0].value}`;
            } else {
                url = url;
            }
        }

        return this.httpClient.get<any>(`${url}`, options).pipe(catchError((err) => {
            return observableThrowError(err);
        }));
    }
    
    setFiltersWithFullData(data) {
        this.filtersWithFullData = data;
    }

    getFiltersWithFullData() {
        return this.filtersWithFullData;
    }

}
