<div [formGroup]="addressForm">
    <mat-form-field style="width: 100%" appearance="outline" [ngClass]="{'snb-form-field-without-icon': !showIcon}" (click)="openAddressForm(addressForm, $event, this)">
        <mat-label>{{addressPlaceHolder}}</mat-label>
        <mat-icon matPrefix *ngIf="showIcon">location_on</mat-icon>
        <input matInput formControlName="addressField"  [required]="mandatory" readonly
            [value]="formatAddress(addressValue)">
        <mat-error *ngIf="addressForm.controls.addressField.hasError('required')"
            jhiTranslate="entity.validation.required"></mat-error>
        <mat-error *ngIf="addressForm.controls.addressField.hasError('allRequired')"
            jhiTranslate="entity.validation.allRequired"></mat-error>
    </mat-form-field>
</div>