<div mat-dialog-content>
    <div>
        <div class="right-col">
            <div class="step-col-content">
                <h3 class="step-col-label">
                    More Details
                </h3>
                <div class="col-wrapper light-bg">
                    <div class="s-col">
                        <span class="property">
                            Email
                        </span>
                        <div class="value s-col-ellipsis" *ngIf="selectedBranchData?.contactDetails?.email" 
                            [matTooltip]="selectedBranchData?.contactDetails?.email" matTooltipPosition="below">
                            {{selectedBranchData?.contactDetails?.email}}
                        </div>
                        <div class="value s-col-ellipsis" *ngIf="!selectedBranchData?.contactDetails?.email">
                            --
                        </div>
                    </div>
                    <div class="s-col">
                        <span class="property">
                            Address
                        </span>
                        <div class="value s-col-full" *ngIf="selectedBranchData?.contactDetails?.addresses?.length">
                            {{selectedBranchData?.contactDetails?.addresses[0]?.addressLine1}},
                            {{selectedBranchData?.contactDetails?.addresses[0]?.addressLine2}},
                            {{selectedBranchData?.contactDetails?.addresses[0]?.street}},
                            {{selectedBranchData?.contactDetails?.addresses[0]?.city}},
                            {{selectedBranchData?.contactDetails?.addresses[0]?.district}},
                            {{selectedBranchData?.contactDetails?.addresses[0]?.state}},
                            <div>PIN- {{selectedBranchData?.contactDetails?.addresses[0]?.pin}}</div>
                        </div>
                        <div class="value s-col-full" *ngIf="!selectedBranchData?.contactDetails?.addresses?.length">
                            --
                        </div>
                    </div>
                </div>

                <h3 class="step-col-label">
                    Branch Foreman Details
                </h3>
                <mat-accordion class="branch_panel">
                    <mat-expansion-panel *ngFor="let branchAdminUser of branchAdminUsers">
                        <mat-expansion-panel-header style="background: #f7f7f7">
                            <mat-panel-title>
                                <div class="value s-col-full" *ngIf="branchAdminUser" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100%;">
                                    <span style="margin-left: 2px"><i class="fa fa-check-circle" *ngIf="!branchAdminUser?.activationId"
                                            aria-hidden="true" style="color: #5cb85c"></i></span>
                                    <span style="margin-left: 2px"><i class="fa fa-times-circle" *ngIf="branchAdminUser?.activationId"
                                            aria-hidden="true" style="color: #f57621"></i></span>
                                    &nbsp;<b>{{branchAdminUser?.title}} {{branchAdminUser?.firstName}}
                                        {{branchAdminUser?.lastName}}</b>
                                </div>
                                <div class="value s-col-full" *ngIf="!branchAdminUser">
                                    --
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="col-wrapper light-bg" *ngIf="branchAdminUsers" style="margin-top: 15px">
                            <div class="row">
                                <div class="col-md-12" style="margin: 0 0 10px">
                                    <!-- Send Activation Link starts here -->
                                    <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.activationId">

                                        <a *ngIf="isRegulatorSuperAdmin || isCustSuport" #menuTrigger="matMenuTrigger"
                                            [matMenuTriggerFor]="menu" style="cursor: pointer">
                                            <span class="pulse"></span> Send Activation Link</a>
                                        <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
                                            <button mat-menu-item (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'EMAIL')">
                                                <mat-icon>email</mat-icon>
                                                <span>EMAIL</span>
                                            </button>
                                            <button mat-menu-item (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'SMS')">
                                                <mat-icon>textsms</mat-icon>
                                                <span>SMS</span>
                                            </button>
                                            <button mat-menu-item (click)="sendActivationLinkToForeman(branchAdminUser?.activationId,'ALL')">
                                                <mat-icon>send</mat-icon>
                                                <span>EMAIL & SMS</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <!-- Send activation link code ends here -->
                                </div>
                            </div>

                            <div class="s-col">
                                <span class="property">
                                    Email
                                </span>
                                <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.contactDetails?.email" 
                                    [matTooltip]="branchAdminUser?.contactDetails?.email" matTooltipPosition="below">
                                    {{branchAdminUser?.contactDetails?.email}}
                                </div>
                                <div class="value s-col-ellipsis" *ngIf="!branchAdminUser?.contactDetails?.email">
                                    --
                                </div>
                            </div>
                            <div class="s-col">
                                <span class="property">
                                    Mobile No.
                                </span>
                                <div class="value s-col-ellipsis" *ngIf="branchAdminUser?.contactDetails?.mobileNumber">
                                    {{branchAdminUser?.contactDetails?.mobileNumber}}
                                </div>
                                <div class="value s-col-ellipsis" *ngIf="!branchAdminUser?.contactDetails?.mobileNumber">
                                    --
                                </div>
                            </div>
                            <div class="row">
                                <!-- Remove button starts here -->
                                <div class="col-md-6 pull-right" *ngIf="isRegulatorSuperAdmin">
                                    <button mat-icon-button color="warn" matTooltipClass="example-tooltip-red"
                                        matTooltipPosition="left" matTooltip="Delete Foreman" class="pull-right" style="background-color: #f7f7f7; box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                                        (click)="openForemanDeleteConfirmDialog(branchAdminUser, selectedBranchData)">
                                        <mat-icon aria-label="Delete Icon" style="color: #b71c1c">delete</mat-icon>
                                    </button>
                                </div>
                                <!-- remove button ends here -->
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <h3 class="step-col-label" style="margin-top: 15px;">
                    Regulator Branch Details
                </h3>
                <div class="col-wrapper light-bg">
                    <div class="s-col">
                        <span class="property">
                            Branch Name
                        </span>
                        <div class="value s-col-full">
                            {{regulatorDetails?.name}}
                        </div>
                    </div>

                </div>
                <div class="s-col">
                    <span class="property">
                        Address
                    </span>
                    <div class="value s-col-full" *ngIf="regulatorDetails?.contactDetails?.addresses?.length">
                        {{regulatorDetails?.contactDetails?.addresses[0]?.addressLine1}},
                        {{regulatorDetails?.contactDetails?.addresses[0]?.addressLine2}},
                        {{regulatorDetails?.contactDetails?.addresses[0]?.street}},
                        {{regulatorDetails?.contactDetails?.addresses[0]?.city}},
                        {{regulatorDetails?.contactDetails?.addresses[0]?.district}},
                        {{regulatorDetails?.contactDetails?.addresses[0]?.state}},
                        <div>PIN- {{regulatorDetails?.contactDetails?.addresses[0]?.pin}}</div>
                    </div>
                    <div class="value s-col-full" *ngIf="!regulatorDetails?.contactDetails?.addresses?.length">
                        --
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="text-align: right;display: block;">
    <div class="row">
        <div class="col-md-12">

            <button mat-raised-button (click)="onNoClick()" class="snb-raised-button" style="border-radius: 30px">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
