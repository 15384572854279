<div class="dialog-wrapper" mat-dialog-content>
    <div class="dialog-title">
        <ng-container *ngIf="data.bankAndAuditor" >
            <ng-container *ngIf="data.userType=='BANKER'">
                {{'foremanCompany.new.banker' | translate}}
            </ng-container>
            <ng-container *ngIf="data.userType=='AUDITOR'">
                {{'foremanCompany.new.auditor' | translate}}
            </ng-container>
        </ng-container>

        <ng-container *ngIf="data.organisation" >
            {{'foremanCompany.new.organisationDetails' | translate}}
        </ng-container>

        <button (click)='closeDialog()' type="button" style="display: contents;">
            <span><i class="material-icons close-btn">close</i></span>
        </button>

    </div>
    <div class="dialog-content-wrapper">
        <form [formGroup]="newForemanCompanyForm" *ngIf="data.organisation" novalidate autocomplete="off">
            <div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.organisationType' | translate}}</mat-label>
                        <mat-select formControlName="orgType" matInput
                            required>
                            <mat-option *ngFor="let org of foremanOrgTypes" [value]="org.id">
                                {{ org.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="newForemanCompanyForm.controls.orgType.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.companyName' | translate}}</mat-label>
                        <input type="text" formControlName="name" matInput
                            required>
                        <mat-error *ngIf="newForemanCompanyForm.controls.name.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.incorporationDate' | translate}}</mat-label>
                        <input matInput [matDatepicker]="picker" [max]="maxIncorpDate" format-date formControlName="incorporationDate"
                            (click)="picker.open();"
                            (focus)="picker.open();" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="newForemanCompanyForm.controls.incorporationDate.hasError('required')"
                            jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.cin' | translate}}</mat-label>
                        <input type="text" formControlName="cin" matInput
                            required>
                        <mat-error *ngIf="newForemanCompanyForm.controls.cin.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div class="snb_single_col" *ngIf="data?.data?.orgType == 'UNREGISTERED_ASSOCIATION_OF_INDIVIDUALS' || data?.data?.orgType == 'PARTNERSHIP' ||  data?.data?.orgType == 'SOLE_PROPRIETORSHIP'">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.noOfPartners' | translate}}</mat-label>
                        <input type="text" formControlName="noOfPartners" matInput>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.pan' | translate}}</mat-label>
                        <input type="text" formControlName="pan" matInput>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.gstn' | translate}}</mat-label>
                        <input type="text" formControlName="gstn" matInput>
                    </mat-form-field>
                </div>
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label>{{'foremanCompany.new.website' | translate}}</mat-label>
                        <input type="text" formControlName="website" matInput>
                    </mat-form-field>
                </div>
                <button mat-raised-button class="btn-group snb-raised-button snb_btn_submit" style="float: right;" [disabled]="isFormSubmitted"
                    (click)="updateForemanOrg(newForemanCompanyForm, $event)">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>
            </div>
        </form>

        <form [formGroup]="foremanUser" *ngIf="data.foremanUser" novalidate (ngSubmit)="submitUserData(foremanUser, $event, 'save')"
            autocomplete="off">
            <div class="card dep-a" style="min-height:320px !important">
                <div class="snb_card_label" *ngIf="data.userType=='ADMIN'">
                    Admin's Information
                </div>
                <div class="snb_card_label" *ngIf="data.userType=='DIRECTOR'">
                    {{'foremanCompany.new.directorDetails' | translate}}
                </div>
                <div class="snb_card_label" *ngIf="data.userType=='CEO'">
                    {{'foremanCompany.new.ceoDetails' | translate}}
                </div>
                <div class="snb_card_label" *ngIf="!data.userType">
                    {{'foremanCompany.new.otherForemen' | translate}}
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.title' | translate}}</mat-label>
                            <mat-select formControlName="title" matInput
                                required>
                                <mat-option value="Mr.">{{'foremanCompany.title.mr' | translate}}</mat-option>
                                <mat-option value="Mrs.">{{'foremanCompany.title.mrs' | translate}}</mat-option>
                                <mat-option value="Ms.">{{'foremanCompany.title.ms' | translate}}</mat-option>
                                <mat-option value="Miss">{{'foremanCompany.title.miss' | translate}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="foremanUser.controls.title.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.firstName' | translate}}</mat-label>
                            <input type="text" formControlName="firstName" matInput
                                required>
                            <mat-error *ngIf="foremanUser.controls.firstName.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.lastName' | translate}}</mat-label>
                            <input type="text" formControlName="lastName" matInput
                                required>
                            <mat-error *ngIf="foremanUser.controls.lastName.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                            <mat-label>{{'foremanCompany.new.designation' | translate}}</mat-label>
                            <mat-select formControlName="designation" [disabled]="true" matInput
                                required>
                                <mat-option *ngFor="let designation of designationTypes" [value]="designation">
                                    {{ designation }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="foremanUser.controls.designation.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                        </mat-form-field>
                    </div>
                    <div formGroupName="contactDetails">
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                                <mat-label>{{'foremanCompany.new.email' | translate}}</mat-label>
                                <input type="email" required formControlName="email" matInput>
                                <mat-error *ngIf="foremanUser.get('contactDetails')['controls'].email.value && foremanUser.get('contactDetails')['controls'].email.errors?.pattern"
                                    jhiTranslate="entity.validation.email"></mat-error>
                                <mat-error *ngIf="foremanUser.get('contactDetails')['controls'].email.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                                <mat-label>{{'foremanCompany.new.mobileNumber' | translate}}</mat-label>
                                <input type="tel" required formControlName="mobileNumber" matInput>
                                <mat-error *ngIf="foremanUser.get('contactDetails')['controls'].mobileNumber.hasError('required')"
                                    jhiTranslate="entity.validation.required"></mat-error>
                                <mat-error *ngIf="foremanUser.get('contactDetails')['controls'].mobileNumber.value && foremanUser.get('contactDetails')['controls'].mobileNumber.hasError('pattern')"
                                    jhiTranslate="entity.validation.mobile"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-sm-6" formArrayName="addresses">
                            <cm-address addressPlaceHolder="Address" [group]="foremanUser?.controls?.contactDetails?.controls?.addresses?.controls[0]"
                                [addressValue]="data?.data?.contactDetails?.addresses?.length ? data?.data?.contactDetails?.addresses[0]: {}"></cm-address>
                        </div>
                    </div>
                </div>
                <!-- <div class="snb_form_terms text-center" *ngIf="!data.userType">
                    <mat-checkbox formControlName="isNextToCeoInManagerialSetup"></mat-checkbox>
                    <span>Is this Foreman under CEO ?</span>
                </div> -->



                <button mat-raised-button type="submit" class="snb-raised-button snb_btn_submit" [disabled]="isFormSubmitted"
                    style="float: right;" *ngIf="data?.create">
                    <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>

                <button mat-raised-button class="snb-raised-button snb_btn_submit" style="float: right;" [disabled]="isFormSubmitted"
                    *ngIf="data?.update" (click)="submitUserData(foremanUser, $event, 'update')">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>
                <button mat-raised-button [mat-dialog-close]="true" style="margin-right: 10px;float: right;border-radius: 30px;">
                    <span>Cancel</span>
                </button>
                <!-- <button *ngIf="foremanCompanyBranchHasData" mat-raised-button (click)="submitBranchData(subBranchForm, $event, 'update')"
                    class="snb-raised-button" style="float: right;">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                </button> -->
            </div>
        </form>

        <form [formGroup]="bankAndAuditors" *ngIf="data.bankAndAuditor" novalidate (ngSubmit)="saveBankerAndAuditor(bankAndAuditors, $event, 'save')"
            autocomplete="off">
            <div style="min-height:150px !important">
                <div class="snb_single_col">
                    <mat-form-field appearance="outline" style="width: 100%" class="snb-form-field-without-icon">
                        <mat-label *ngIf="data.userType=='BANKER'">{{'foremanCompany.new.bankName' | translate}}</mat-label>
                        <input type="text" formControlName="name" matInput
                            required *ngIf="data.userType=='BANKER'">
                        <mat-label *ngIf="data.userType=='AUDITOR'">{{'foremanCompany.new.auditorName' | translate}}</mat-label>
                        <input type="text" formControlName="name" matInput
                            required *ngIf="data.userType=='AUDITOR'">
                        <mat-error *ngIf="bankAndAuditors.controls.name.hasError('required')" jhiTranslate="entity.validation.required"></mat-error>
                    </mat-form-field>
                </div>
                <div formGroupName="address" *ngIf="data.userType=='AUDITOR'">
                    <cm-address addressPlaceHolder="Auditor Address" [group]="bankAndAuditors.controls.address" [addressValue]="data?.data?.address"></cm-address>
                </div>
                <div formGroupName="address" *ngIf="data.userType=='BANKER'">
                    <cm-address addressPlaceHolder="Bank Address" [group]="bankAndAuditors.controls.address" [addressValue]="data?.data?.address"></cm-address>
                </div>
                <button mat-raised-button type="submit" class="btn-group snb-raised-button snb_btn_submit" style="float: right;"
                    [disabled]="isFormSubmitted" *ngIf="data?.create">
                    <span jhiTranslate="foremanCompany.new.btnSubmit"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>

                <button mat-raised-button class="btn-group snb-raised-button snb_btn_submit" style="float: right;" [disabled]="isFormSubmitted"
                    *ngIf="data?.update" (click)="saveBankerAndAuditor(bankAndAuditors, $event, 'update')">
                    <span jhiTranslate="foremanCompany.new.btnUpdate"></span>
                    <i *ngIf="isFormSubmitted" style="margin-left: 10px; font-size: 20px;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </button>
                <button mat-raised-button class="btn-group" [mat-dialog-close]="true" style="margin-right: 10px;float: right;border-radius: 30px;">
                    <span>Cancel</span>
                </button>
            </div>
        </form>
    </div>
</div>
