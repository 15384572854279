<div class="snb_filter">
    <mat-form-field class="demo-chip-list">
        <mat-chip-grid #chipList>
            <span matPrefix>
                <i class="material-icons filter_icon">filter_list</i>
            </span>
            <div *ngFor="let item of selectedItems"
                class="mat-chip mat-primary mat-standard-chip mat-chip-with-trailing-icon" (click)="setFocus(item)">
                <span class="filter_key" style="font-weight: bold;">{{item.name}}</span>

                <button mat-icon-button id="btn-{{item.attribute}}" class="filter-buttons invisible-menu-button"
                    [matMenuTriggerFor]="dropdownMenu" style="height: 0px; width: 0px; padding: 0px;"> </button>

                <input autocomplete="off" spellcheck="false" class="filter_val"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput 
                    *ngIf="(!item.type || item.type==='string') && item?.uri" id="{{item.attribute}}"
                    (keyup)="getFilterValues(item, $event)" (focus)="getFilterValues(item, $event)"
                    [(ngModel)]="item.value" (keyup.enter)="emitCommonFunc()" />
                <input autocomplete="off" spellcheck="false" class="filter_val" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="(!item.type || item.type==='string') && !item.uri" id="{{item.attribute}}"
                    (keyup.enter)="emitCommonFunc()" [(ngModel)]="item.value" />
                <input autocomplete="off" spellcheck="false" class="filter_val" placeholder="Enter value"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="item.type=='input' && !item?.uri" id="{{item.attribute}}" (keyup.enter)="emitCommonFunc()"
                    [(ngModel)]="item.value" />
                <input autocomplete="off" spellcheck="false" class="filter_val" format-date placeholder="Choose Date"
                    style="width: auto;margin: 1px 0px 1px 6px;" matInput
                    *ngIf="item.type=='date'" id="{{item.attribute}}" (click)="picker1.open()" (focus)="picker1.open();"
                    [matDatepicker]="picker1" [(ngModel)]="item.value" readonly [max]="maxDate"
                    (dateChange)="filterDataForDates(item.attribute, $event)">
                <mat-datepicker-toggle matSuffix [for]="picker1" style="display:none"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>

                <app-date-range-picker id="{{item?.attribute?.replace('.','')}}" (click)='activeteFocusedItem(item);'
                    [defaultValue]='item?.value' [rangeOptions]='item.type'
                    (dateChanged)="onDateChanged({value: $event, item: item})"
                    *ngIf="item?.type=='dateDuration' || item?.type=='finYear' || item?.type=='dateDurationAndFinYear'">
                </app-date-range-picker>


                <mat-select *ngIf="item.type=='enum' && item?.uri" class="filter_val"
                    style="width: auto;margin: 1px 0px 1px 6px;min-width: 185px !important;"
                    [placeholder]="item.value? item.value :'Select value'" (focus)="getFilterValues(item, $event)">
                </mat-select>
                <mat-icon class="mat-chip-remove mat-chip-trailing-icon mat-icon material-icons"
                    *ngIf="!item?.isMandatory" (click)="remove(item)">
                    cancel
                </mat-icon>

            </div>
            <input matInput #inputAutoComplit [matChipInputFor]="chipList" placeholder="Filter.."
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                [matAutocomplete]="auto" [formControl]="stateCtrl" (matChipInputTokenEnd)="add($event)"
                (focus)="openAutocomplete()" />

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let state of filteredStates" [value]="state.attribute" class="filter-option">
                    <span>{{ state.name }}</span>
                </mat-option>
            </mat-autocomplete>

        </mat-chip-grid>


        <mat-menu #dropdownMenu yPosition="below" xPosition="after" class="filter-dropdown-items">
            <div class="filter-items" *ngFor="let item of filterItems" (click)="selectItemFromDropdown(item)">
                <div>{{item}} </div>
            </div>
            <div class="filter-items" *ngFor="let item of filterObjectItems" (click)="selectObjectFromDropdown(item)">
                <div>{{item.name}} </div>
            </div>
            <div *ngIf="(filterObjectItems.length === 0) && (filterItems.length === 0)">No Match Found!</div>
        </mat-menu>


    </mat-form-field>


</div>