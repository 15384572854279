import { PAGES_MENU } from './pages/pages.menu';

export const MENU = [
  ...PAGES_MENU
];

export const USER_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'grievance',
        data: {
          menu: {
            title: 'Grievance',
            pagetitle: 'Grievance',
            icon: 'ion-ios-hand',
            selected: false,
            expanded: true,
            order: 0
          }
        },
        children: [
          {
            path: 'status',
            data: {
              menu: {
                title: 'Status',
                pagetitle: 'Grievance > Status',
                icon: 'fa fa-hourglass-start',
                hasOtherRoute: true,
                fullPath: '/pages/grievance/status'
              }
            }
          },
          {
            path: 'new',
            data: {
              menu: {
                title: 'New',
                pagetitle: 'Grievance > New',
                icon: 'fa fa-plus',
                hasOtherRoute: true,
                fullPath: '/pages/grievance/new'
              }
            }
          }
        ]
      },
      {
        path: 'chitinfo',
        data: {
          menu: {
            title: 'Chit Info',
            pagetitle: 'Chit Groups Info for PUBLIC',
            icon: 'fa fa-flag',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'enroll-company',
        data: {
          menu: {
            title: 'Register Foreman Company',
            pagetitle: 'Register Foreman Company',
            icon: 'fa fa-building-o',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'notices',
        data: {
          menu: {
            title: 'Notices',
            pagetitle: 'Notices',
            icon: 'ion-ios-paper',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      }
    ]
  }
];
