<div class="custom-data-table">

    <div class="row no-margin">
        <div class="col-md-7" [ngClass]="{'col-md-12 col-xs-12' : !enableShowMoredetails}">
            <div class="timeline_card text-left">
                <div class="subscribers_list">
                    <mat-table #table [dataSource]="financialData">
                        <ng-container matColumnDef="financialYear">
                            <mat-header-cell *matHeaderCellDef>Financial Year</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                 {{element.financialYear}} 
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                 {{(element.dataType == "FY_AUDITED_BALANCE_SHEET") ? "Audited Balance Sheet" : ((element.dataType == "INITIAL_PAIDUP_CAPITAL") ? "Initial Paidup Capital": ((element.dataType == "REVISED_PAIDUP_CAPITAL_OR_RESERVE_FUNDS") ? "Revised PaidUp Capital" : "-"))}} 
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="netOwnedAssets">
                            <mat-header-cell *matHeaderCellDef>Net Owned Assets</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.netownedAssets?.toLocaleString('en-IN')}} </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row (click)="showMoreDetails(row)" *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'active_row' : selectedRowIndex == row.id}"></mat-row>
                    </mat-table>
                    <app-table-loader *ngIf="requestInProgress"></app-table-loader>
                    <div *ngIf="financialData?.length===0 && !requestInProgress" class="empty-row">No data to display
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5" *ngIf="enableShowMoredetails && financialData?.length">
            <div class="right-col">
                <div class="step-col-content">
                    <h3 class="step-col-label">
                        More Details
                    </h3>
                    <div class="col-wrapper light-bg">
                        <div class="s-col">
                            <span class="property">
                                Financial Year
                            </span>
                            <span class="value">
                                {{selectedRowData?.financialYear}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Net Profit
                            </span>
                            <span class="value">
                                {{selectedRowData?.netProfit?.toLocaleString('en-IN')}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Net Owned Funds
                            </span>
                            <span class="value">
                                {{selectedRowData?.netownedAssets?.toLocaleString('en-IN')}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                PaidUp Capital
                            </span>
                            <span class="value">
                                {{selectedRowData?.paidUpCapital?.toLocaleString('en-IN')}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Free Reserves
                            </span>
                            <span class="value">
                                {{selectedRowData?.reserveFund?.toLocaleString('en-IN')}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                Description
                            </span>
                            <span class="value show-overflow-as-ellipse" [matTooltip]="selectedRowData?.description" matTooltipPosition="below">
                                {{selectedRowData?.description}}
                            </span>
                        </div>
                        <div class="s-col">
                            <span class="property">
                                No Of Current Chits
                            </span>
                            <span class="value">
                                {{selectedRowData?.noOfCurrentChits}}
                            </span>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <h3 class="step-col-label">Supporting Documents</h3>
                                <div class="form_attached_files" *ngIf="attachments">
                                        <div class="s-col" *ngFor="let attachment of attachments">
                                            <app-snb-file-item [fileData]="attachment"></app-snb-file-item>
                                        </div>
                                </div>
                                <span *ngIf="!attachments">No Documents Available</span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>

</div>