import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-organisation-director-ceo-details',
    templateUrl: './director-ceo-details.component.html',
    styleUrls: ['./dialog-popup-content.scss']
})

export class OrganisationDirectorAndCeoDetailsComponent implements OnInit {

    selectedForemanData: any;
    constructor(
        public dialogRef: MatDialogRef<OrganisationDirectorAndCeoDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.selectedForemanData = this.data.selectedForemanData;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

}
