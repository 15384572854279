import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
    selector: 'app-snb-process-comments',
    templateUrl: './process-comments.html'
})
export class SnbProcessCommentsComponent implements OnChanges {
    userId: any;
    @Input('customTitle') customTitle;
    @Input('openByDefault') openByDefault;
    
    constructor(private localStorage: LocalStorageService) {
        const token = this.localStorage.retrieve('authenticationToken');
        const userData = JSON.parse(atob(token.access_token.toString().split('.')[1]));
        this.userId = userData.user_internal_id;
    }

    @Input() public comments: any;

    // Reacts to the new comment when added from parent component
    ngOnChanges(changes: SimpleChanges) {
        if (changes.comments) {
            this.comments = changes.comments.currentValue ;
          }
    }

}
