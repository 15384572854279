import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

import { ClickStopPropagation } from './directives/commonDirectives';
import {
  BaThemeConfig
} from './theme.config';

import {
  BaThemeConfigProvider
} from './theme.configProvider';

import {
  BaBackTopComponent,
  BaContentTopComponent,
  BaMenuItemComponent,
  BaMenuComponent,
  BaPageTopComponent,
  BaSidebarComponent,
  RouterExtService,
} from './components';

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import {
  BaScrollPosition,
  BaSlimScroll,
  BaThemeRun,
} from './directives';

import { FormatINRCurrency, FormatDate, SnbUpperCase, SnbStartCase, SnbLowerCase } from './directives/formatInput/formatInput.directive';

import {
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  OrderByDatePipe,
  SNBDateCorrectionPipe,
  SNBDateConvertToMilisPipe,
  ArraySortPipe
} from './pipes';

import {
  BaImageLoaderService,
  BaMenuService,
  BaThemePreloader,
  BaThemeSpinner
} from './services';

import {
  EmailValidator,
  EqualPasswordsValidator
} from './validators';
import { ChmAmountUnitPipeConverter, NumberToIndianLocaleConvertor, NumberToOrdinalConverter, ReplaceUnderscorePipe, StringToNumberConverter } from '../shared/format-values';
import { BaPageRightMenuComponent } from './components/baPageRightMenu/baPagesRightMenu.component';
import { TranslateModule } from '@ngx-translate/core';
import { IterateObjKeyValuesPipe } from './pipes/iterateObjKeyValue';

const NGA_COMPONENTS = [
  BaBackTopComponent,
  BaContentTopComponent,
  BaMenuItemComponent,
  BaMenuComponent,
  BaPageTopComponent,
  BaPageRightMenuComponent,
  BaSidebarComponent,
];

const NGA_DIRECTIVES = [
  BaScrollPosition,
  BaSlimScroll,
  BaThemeRun,
  BaCardBlur,

];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  OrderByDatePipe,
  SNBDateCorrectionPipe,
  SNBDateConvertToMilisPipe,
  StringToNumberConverter,
  ChmAmountUnitPipeConverter,
  NumberToOrdinalConverter,
  NumberToIndianLocaleConvertor,
  ArraySortPipe,
  IterateObjKeyValuesPipe,
  FormatINRCurrency,
  ClickStopPropagation,
  FormatDate,
  ReplaceUnderscorePipe,
  SnbStartCase,
  SnbUpperCase,
  SnbLowerCase
];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService,
  RouterExtService
];

const NGA_VALIDATORS = [
  EmailValidator,
  EqualPasswordsValidator
];

/**
 * This module contains the all core theme related dependencies
 * like core theme, material, fontawesome etc..
 */

@NgModule({
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule
  ],

  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
    MaterialModule,
    TranslateModule
  ],
  providers: [
    BaThemeConfigProvider,
    BaThemeConfig,
    ...NGA_VALIDATORS,
    ...NGA_SERVICES
  ]
})
export class NgaModule {
  // static forRoot(): ModuleWithProviders<any> {
  //   return <ModuleWithProviders<any>>{
  //     ngModule: NgaModule,
  //     providers: [
  //       BaThemeConfigProvider,
  //       BaThemeConfig,
  //       ...NGA_VALIDATORS,
  //       ...NGA_SERVICES
  //     ],
  //   };
  // }
}
