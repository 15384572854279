import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-table-loader',
    templateUrl: './table-loader.component.html',
    styleUrls: ['./table-loader.scss'],
})
export class TableLoadingComponent implements OnInit {
    constructor(
    ) { }

    ngOnInit() { }
}

