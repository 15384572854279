import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';


@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    // styleUrls: ['date-range-picker.component.scss']
})

export class DateRangePickerComponent implements OnInit {

    @Input() formCtrl: UntypedFormControl;
    @Input() defaultValue: any;
    @Input() rangeOptions: any;
    @Output() dateChanged: EventEmitter<any> = new EventEmitter();

    constructor(
        
    ) {
        
        // Browser globals
        window['daterangepicker'] = this.initDatePicker();
    }

    ngOnInit() {
        console.log('enter to date picker class');
        const self = this;
        let defaultStart = moment().startOf('day');
        let defaultEnd = moment().endOf('day');
        if (this.defaultValue) {
            switch (this.defaultValue) {
                case 'TODAY':
                    defaultStart = moment().startOf('day');
                    defaultEnd = moment().endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'YESTERDAY':
                    defaultStart = moment().subtract(1, 'days').startOf('day');
                    defaultEnd = moment().subtract(1, 'days').endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'LAST_7_DAYS':
                    defaultStart = moment().subtract(6, 'days').startOf('day');
                    defaultEnd = moment().endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'LAST_30_DAYS':
                    defaultStart = moment().subtract(29, 'days').startOf('day');
                    defaultEnd = moment().endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'THIS_MONTH':
                    defaultStart = moment().startOf('month').startOf('day');
                    defaultEnd = moment().endOf('month').endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'LAST_MONTH':
                    defaultStart = moment().subtract(1, 'month').startOf('month').startOf('day');
                    defaultEnd = moment().subtract(1, 'month').endOf('month').endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'CURRENT_FY':
                    const currentMonth = moment().get('month');  // 0 to 11
                    let currentFinYear = moment().get('year');
                    if (currentMonth <= 2) { // jan, feb, march falls in previous financial year
                        currentFinYear = currentFinYear - 1;
                    }
                    defaultStart = moment([currentFinYear, 3, 1]).startOf('month').startOf('day'); // April 1st
                    defaultEnd = moment([currentFinYear + 1, 2, 31]).endOf('day'); // March 31st
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                case 'TILL_TODAY':
                    defaultStart = moment('2000-01-31');
                    defaultEnd = moment().endOf('day');
                    $('#paymentsByDate').val(moment(defaultStart).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(defaultEnd).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([defaultStart.toISOString(), defaultEnd.toISOString()]);
                    break;
                default:
                    break;
            }
        }
        (<any>$('#paymentsByDate')).daterangepicker({
            ranges: {
                'Today': [moment().startOf('day'), moment().endOf('day')],
                'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month').startOf('day'), moment().subtract(1, 'month').endOf('month').endOf('day')],
                'From Date': [],
                'Till Date': []
            },
            opens: 'center',
            maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        }, (start, end, label) => {
            setTimeout(function () {
                // set value here
                if (label === 'Custom Range') {
                    const startDate = moment(start).format('DD/MM/YYYY') + ' ' + $($('.hourstxt')[0]).val() + ':' + $($('.minstxt')[0]).val() + ' ' + $($('.ampmselect')[0]).val();
                    const endDate = moment(end).format('DD/MM/YYYY') + ' ' + $($('.hourstxt')[1]).val() + ':' + $($('.minstxt')[1]).val() + ' ' + $($('.ampmselect')[1]).val();
                    $('#paymentsByDate').val(startDate + ' - ' + endDate);

                    const sHours = $($('.ampmselect')[0]).val() === 'AM' ? Number($($('.hourstxt')[0]).val()) : Number($($('.hourstxt')[0]).val()) + 12;
                    const sMins = Number($($('.minstxt')[0]).val());
                    let sDate = Number(new Date(start.toString()));
                    if (sHours !== 12 || sMins !== 0) {
                        if (sHours !== 12) {
                            sDate = new Date(start.toString()).setHours(sHours);
                        }
                        if (sMins !== 0) {
                            sDate = new Date(sDate).setMinutes(sMins);
                        }
                        sDate = new Date(sDate).setSeconds(0);
                    }
                    const eHours = $($('.ampmselect')[1]).val() === 'AM' ? Number($($('.hourstxt')[1]).val()) : Number($($('.hourstxt')[1]).val()) + 12;
                    const eMins = Number($($('.minstxt')[1]).val());
                    let eDate = Number(moment(end).endOf('day'));
                    if (eHours !== 24 || eMins !== 0) {
                        if (eHours !== 24) {
                            eDate = new Date(end.toString()).setHours(eHours);
                        }
                        if (eMins !== 0) {
                            eDate = new Date(eDate).setMinutes(eMins);
                        }
                        eDate = new Date(eDate).setSeconds(59);
                    }
                    self.dateChanged.emit([new Date(sDate).toISOString(), new Date(eDate).toISOString()]);
                } else if (label === 'Financial Year') {
                    $('#paymentsByDate').val(moment(new Date(Number(start))).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(new Date(Number(end))).format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([new Date(Number(start)).toISOString(), new Date(Number(end)).toISOString()]);
                } else if (label === 'Till Date') {
                    start = moment(new Date(1)).startOf('day');
                    this.startDate = start;
                    end = moment(end).endOf('day');
                    this.endDate = end;
                    $('#paymentsByDate').val(moment(new Date(Number(start))).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(new Date(Number(end))).format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([new Date(Number(start)).toISOString(), new Date(Number(end)).toISOString()]);
                } else if (label === 'From Date') {
                    start = moment(start).startOf('day');
                    this.startDate = start;
                    end = moment(new Date()).endOf('day');
                    this.endDate = end;
                    $('#paymentsByDate').val(moment(new Date(Number(start))).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(new Date(Number(end))).format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([new Date(Number(start)).toISOString(), new Date(Number(end)).toISOString()]);
                } else {
                    start = moment(start).startOf('day');
                    this.startDate = start;
                    end = moment(end).endOf('day');
                    this.endDate = end;
                    $('#paymentsByDate').val(moment(start).format('DD/MM/YYYY') + ' 12:00 AM' + ' - ' + moment(end).endOf('day').format('DD/MM/YYYY') + ' 11:59 PM');
                    self.dateChanged.emit([new Date(start).toISOString(), new Date(end).toISOString()]);
                }
            }, 100);
        });
    }

    initDatePicker() {
        const self = this;

        const DateRangePicker = function (element, options, cb) {

            this.parentEl = 'body';
            this.element = $(element);
            this.startDate = moment().startOf('day');
            this.endDate = moment().endOf('day');
            this.minDate = false;
            this.maxDate = true;
            this.maxSpan = false;
            this.autoApply = false;
            this.singleDatePicker = true;
            this.showDropdowns = false;
            this.minYear = moment().subtract(100, 'year').format('YYYY');
            this.maxYear = moment().format('YYYY');
            this.showWeekNumbers = false;
            this.showISOWeekNumbers = false;
            this.showCustomRangeLabel = true;
            this.showfinancialYearLabel = true;
            this.timePicker = true;
            this.timePicker24Hour = false;
            this.timePickerIncrement = 1;
            this.timePickerSeconds = false;
            this.linkedCalendars = true;
            this.autoUpdateInput = false;
            this.alwaysShowCalendars = true;
            this.ranges = {};

            this.opens = 'right';
            if (this.element.hasClass('pull-right')) {
                this.opens = 'center';
            }

            this.drops = 'down';
            if (this.element.hasClass('dropup')) {
                this.drops = 'up';
            }

            this.buttonClasses = 'btn btn-sm';
            this.applyButtonClasses = 'btn-primary';
            this.cancelButtonClasses = 'btn-default';

            this.locale = {
                direction: 'ltr',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                tillDateLabel: 'Till Date',
                fromDateLabel: 'From Date',
                financialYearLabel: 'Financial Year',
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: moment.localeData().firstDayOfWeek()
            };

            this.financialYears = [
                {
                    start: moment().month(3).startOf('month').startOf('day'),
                    end: moment().month(2).endOf('month').endOf('day').add(1, 'year'),
                    text: `${moment().month(3).format('YYYY')}-${moment().month(2).endOf('month').endOf('day').add(1, 'year').format('YY')}`
                },
                {
                    start: moment().month(3).startOf('month').startOf('day').subtract(1, 'year'),
                    end: moment().month(2).endOf('month').endOf('day'),
                    text: `${moment().month(3).startOf('month').startOf('day').subtract(1, 'year').format('YYYY')}-${moment().month(2).endOf('month').endOf('day').format('YY')}`
                },
                {
                    start: moment().month(3).startOf('month').startOf('day').subtract(2, 'year'),
                    end: moment().month(2).endOf('month').endOf('day').subtract(1, 'year'),
                    text: `${moment().month(3).startOf('month').startOf('day').subtract(2, 'year').format('YYYY')}-
                    ${moment().month(2).endOf('month').endOf('day').subtract(1, 'year').format('YY')}`
                },
                {
                    start: moment().month(3).startOf('month').startOf('day').subtract(3, 'year'),
                    end: moment().month(2).endOf('month').endOf('day').subtract(2, 'year'),
                    text: `${moment().month(3).startOf('month').startOf('day').subtract(3, 'year').format('YYYY')}-
                    ${moment().month(2).endOf('month').endOf('day').subtract(2, 'year').format('YY')}`
                },
                {
                    start: moment().month(3).startOf('month').startOf('day').subtract(4, 'year'),
                    end: moment().month(2).endOf('month').endOf('day').subtract(3, 'year'),
                    text: `${moment().month(3).startOf('month').startOf('day').subtract(4, 'year').format('YYYY')}-
                    ${moment().month(2).endOf('month').endOf('day').subtract(3, 'year').format('YY')}`
                }
            ];

            this.callback = function () { };

            //  some state information
            this.isShowing = false;
            this.leftCalendar = {};
            this.rightCalendar = {};

            //  custom options from user
            if (typeof options !== 'object' || options === null) {
                options = {};
            }

            //  allow setting options with data attributes
            //  data-api options will be overwritten with custom javascript options
            options = $.extend(this.element.data(), options);

            //  html template for the picker UI
            if (typeof options.template !== 'string' && !(options.template instanceof $)) {
                let fyList = '';
                for (const y of this.financialYears) {
                    fyList += `<li data-fy-start="${y.start}" data-fy-end="${y.end}">${y.text}</li>`;
                }
                options.template =
                    '<div class="daterangepicker">' +
                    '<div class="ranges"></div>' +
                    '<div class="drp-calendar left">' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                    '</div>' +
                    '<div class="drp-calendar right">' +
                    '<div class="calendar-table"></div>' +
                    '<div class="calendar-time"></div>' +
                    '</div>' +
                    '<div class="financial-years-list" style="visibility: hidden;"><ul>' + fyList + '</ul></div>' +
                    '<div class="drp-buttons">' +
                    '<button class="cancelBtn" type="button"></button>' +
                    '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
                    '</div>' +
                    '</div>';
            }

            this.parentEl = (options.parentEl && $(options.parentEl).length) ? $(options.parentEl) : $(this.parentEl);
            this.container = $(options.template).appendTo(this.parentEl);

            //
            //  handle all the possible options overriding defaults
            //

            if (typeof options.locale === 'object') {

                if (typeof options.locale.direction === 'string') {
                    this.locale.direction = options.locale.direction;
                }

                if (typeof options.locale.format === 'string') {
                    this.locale.format = options.locale.format;
                }

                if (typeof options.locale.separator === 'string') {
                    this.locale.separator = options.locale.separator;
                }

                if (typeof options.locale.daysOfWeek === 'object') {
                    this.locale.daysOfWeek = options.locale.daysOfWeek.slice();
                }

                if (typeof options.locale.monthNames === 'object') {
                    this.locale.monthNames = options.locale.monthNames.slice();
                }

                if (typeof options.locale.firstDay === 'number') {
                    this.locale.firstDay = options.locale.firstDay;
                }

                if (typeof options.locale.applyLabel === 'string') {
                    this.locale.applyLabel = options.locale.applyLabel;
                }

                if (typeof options.locale.cancelLabel === 'string') {
                    this.locale.cancelLabel = options.locale.cancelLabel;
                }

                if (typeof options.locale.weekLabel === 'string') {
                    this.locale.weekLabel = options.locale.weekLabel;
                }

                if (typeof options.locale.customRangeLabel === 'string') {
                    //  Support unicode chars in the custom range name.
                    const elem = document.createElement('textarea');
                    elem.innerHTML = options.locale.customRangeLabel;
                    const rangeHtml = elem.value;
                    this.locale.customRangeLabel = rangeHtml;
                }
            }
            this.container.addClass(this.locale.direction);

            if (typeof options.startDate === 'string') {
                this.startDate = moment(options.startDate, this.locale.format);
            }

            if (typeof options.endDate === 'string') {
                this.endDate = moment(options.endDate, this.locale.format);
            }

            if (typeof options.minDate === 'string') {
                this.minDate = moment(options.minDate, this.locale.format);
            }

            if (typeof options.maxDate === 'string') {
                this.maxDate = moment(options.maxDate, this.locale.format);
            }

            if (typeof options.startDate === 'object') {
                this.startDate = moment(options.startDate);
            }

            if (typeof options.endDate === 'object') {
                this.endDate = moment(options.endDate);
            }

            if (typeof options.minDate === 'object') {
                this.minDate = moment(options.minDate);
            }

            if (typeof options.maxDate === 'object') {
                this.maxDate = moment(options.maxDate);
            }

            //  sanity check for bad options
            if (this.minDate && this.startDate.isBefore(this.minDate)) {
                this.startDate = this.minDate.clone();
            }

            //  sanity check for bad options
            if (this.maxDate && this.endDate.isAfter(this.maxDate)) {
                this.endDate = this.maxDate.clone();
            }

            if (typeof options.applyButtonClasses === 'string') {
                this.applyButtonClasses = options.applyButtonClasses;
            }

            if (typeof options.applyClass === 'string') {
                this.applyButtonClasses = options.applyClass;
            }

            if (typeof options.cancelButtonClasses === 'string') {
                this.cancelButtonClasses = options.cancelButtonClasses;
            }

            if (typeof options.cancelClass === 'string') {
                this.cancelButtonClasses = options.cancelClass;
            }

            if (typeof options.maxSpan === 'object') {
                this.maxSpan = options.maxSpan;
            }

            if (typeof options.dateLimit === 'object') {
                this.maxSpan = options.dateLimit;
            }

            if (typeof options.opens === 'string') {
                this.opens = options.opens;
            }

            if (typeof options.drops === 'string') {
                this.drops = options.drops;
            }

            if (typeof options.showWeekNumbers === 'boolean') {
                this.showWeekNumbers = options.showWeekNumbers;
            }

            if (typeof options.showISOWeekNumbers === 'boolean') {
                this.showISOWeekNumbers = options.showISOWeekNumbers;
            }

            if (typeof options.buttonClasses === 'string') {
                this.buttonClasses = options.buttonClasses;
            }

            if (typeof options.buttonClasses === 'object') {
                this.buttonClasses = options.buttonClasses.join(' ');
            }

            if (typeof options.showDropdowns === 'boolean') {
                this.showDropdowns = options.showDropdowns;
            }

            if (typeof options.minYear === 'number') {
                this.minYear = options.minYear;
            }

            if (typeof options.maxYear === 'number') {
                this.maxYear = options.maxYear;
            }

            if (typeof options.showCustomRangeLabel === 'boolean') {
                this.showCustomRangeLabel = options.showCustomRangeLabel;
            }

            if (typeof options.singleDatePicker === 'boolean') {
                this.singleDatePicker = options.singleDatePicker;
                if (this.singleDatePicker) {
                    this.endDate = this.startDate.clone();
                }
            }

            if (typeof options.timePicker === 'boolean') {
                this.timePicker = options.timePicker;
            }

            if (typeof options.timePickerSeconds === 'boolean') {
                this.timePickerSeconds = options.timePickerSeconds;
            }

            if (typeof options.timePickerIncrement === 'number') {
                this.timePickerIncrement = options.timePickerIncrement;
            }

            if (typeof options.timePicker24Hour === 'boolean') {
                this.timePicker24Hour = options.timePicker24Hour;
            }

            if (typeof options.autoApply === 'boolean') {
                this.autoApply = options.autoApply;
            }

            if (typeof options.autoUpdateInput === 'boolean') {
                this.autoUpdateInput = options.autoUpdateInput;
            }

            if (typeof options.linkedCalendars === 'boolean') {
                this.linkedCalendars = options.linkedCalendars;
            }

            if (typeof options.isInvalidDate === 'function') {
                this.isInvalidDate = options.isInvalidDate;
            }

            if (typeof options.isCustomDate === 'function') {
                this.isCustomDate = options.isCustomDate;
            }

            if (typeof options.alwaysShowCalendars === 'boolean') {
                this.alwaysShowCalendars = options.alwaysShowCalendars;
            }

            //  update day names order to firstDay
            if (this.locale.firstDay !== 0) {
                let iterator = this.locale.firstDay;
                while (iterator > 0) {
                    this.locale.daysOfWeek.push(this.locale.daysOfWeek.shift());
                    iterator--;
                }
            }

            let start, end, range;

            //  if no start/end dates set, check if an input element contains initial values
            if (typeof options.startDate === 'undefined' && typeof options.endDate === 'undefined') {
                if ($(this.element).is(':text')) {
                    const val = $(this.element).val().toString(),
                        split = val.split(this.locale.separator);

                    start = end = null;

                    if (split.length === 2) {
                        start = moment(split[0], this.locale.format);
                        end = moment(split[1], this.locale.format);
                    } else if (this.singleDatePicker && val !== '') {
                        start = moment(val, this.locale.format);
                        end = moment(val, this.locale.format);
                    }
                    if (start !== null && end !== null) {
                        this.setStartDate(start);
                        this.setEndDate(end);
                    }
                }
            }

            if (typeof options.ranges === 'object') {
                for (range in options.ranges) {
                    if (range) {
                        if (typeof options.ranges[range][0] === 'string') {
                            start = moment(options.ranges[range][0], this.locale.format);
                        } else {
                            start = moment(options.ranges[range][0]);
                        }

                        if (typeof options.ranges[range][1] === 'string') {
                            end = moment(options.ranges[range][1], this.locale.format);
                        } else {
                            end = moment(options.ranges[range][1]);
                        }

                        //  If the start or end date exceed those allowed by the minDate or maxSpan
                        //  options, shorten the range to the allowable period.
                        if (this.minDate && start.isBefore(this.minDate)) {
                            start = this.minDate.clone();
                        }

                        let maxDate = this.maxDate;
                        if (this.maxSpan && maxDate && start.clone().add(this.maxSpan).isAfter(maxDate)) {
                            maxDate = start.clone().add(this.maxSpan);
                        }
                        if (maxDate && end.isAfter(maxDate)) {
                            end = maxDate.clone();
                        }

                        //  If the end of the range is before the minimum or the start of the range is
                        //  after the maximum, don't display this range option at all.
                        if ((this.minDate && end.isBefore(this.minDate, this.timepicker ? 'minute' : 'day'))
                            || (maxDate && start.isAfter(maxDate, this.timepicker ? 'minute' : 'day'))) {
                            continue;
                        }

                        //  Support unicode chars in the range names.
                        const elem = document.createElement('textarea');
                        elem.innerHTML = range;
                        const rangeHtml = elem.value;

                        this.ranges[rangeHtml] = [start, end];
                    }
                }

                let list = '<ul>';
                if (self.rangeOptions === 'dateDuration' || self.rangeOptions === 'dateDurationAndFinYear') {
                    for (range in this.ranges) {
                        if (range) {
                            list += '<li data-range-key="' + range + '">' + range + '</li>';
                        }
                    }
                }
                if (this.showfinancialYearLabel && (self.rangeOptions === 'finYear' || self.rangeOptions === 'dateDurationAndFinYear')) {
                    list += '<li data-range-key="' + this.locale.financialYearLabel + '" class="financial-year-li">' + this.locale.financialYearLabel + '</li>';
                }
                if (this.showCustomRangeLabel && (self.rangeOptions === 'dateDuration' || self.rangeOptions === 'dateDurationAndFinYear')) {
                    list += '<li data-range-key="' + this.locale.customRangeLabel + '">' + this.locale.customRangeLabel + '</li>';
                }
                list += '</ul>';
                this.container.find('.ranges').prepend(list);
            }

            if (typeof cb === 'function') {
                this.callback = cb;
            }

            if (!this.timePicker) {
                this.startDate = this.startDate.startOf('day');
                this.endDate = this.endDate.endOf('day');
                this.container.find('.calendar-time').hide();
            }

            //  can't be used together for now
            if (this.timePicker && this.autoApply) {
                this.autoApply = false;
            }

            if (this.autoApply) {
                this.container.addClass('auto-apply');
            }

            if (typeof options.ranges === 'object') {
                this.container.addClass('show-ranges');
            }

            if (this.singleDatePicker) {
                this.container.addClass('single');
                this.container.find('.drp-calendar.left').addClass('single');
                this.container.find('.drp-calendar.left').show();
                this.container.find('.drp-calendar.right').hide();
                if (!this.timePicker) {
                    this.container.addClass('auto-apply');
                }
            }

            if ((typeof options.ranges === 'undefined' && !this.singleDatePicker) || this.alwaysShowCalendars) {
                this.container.addClass('show-calendar');
            }

            this.container.addClass('opens' + this.opens);

            //  apply CSS classes and labels to buttons
            this.container.find('.applyBtn, .cancelBtn').addClass(this.buttonClasses);
            if (this.applyButtonClasses.length) {
                this.container.find('.applyBtn').addClass(this.applyButtonClasses);
            }
            if (this.cancelButtonClasses.length) {
                this.container.find('.cancelBtn').addClass(this.cancelButtonClasses);
            }
            this.container.find('.applyBtn').html(this.locale.applyLabel);
            this.container.find('.cancelBtn').html(this.locale.cancelLabel);

            //
            //  event listeners
            //

            this.container.find('.drp-calendar')
                .on('click.daterangepicker', '.prev', $.proxy(this.clickPrev, this))
                .on('click.daterangepicker', '.next', $.proxy(this.clickNext, this))
                .on('mousedown.daterangepicker', 'td.available', $.proxy(this.clickDate, this))
                .on('mouseenter.daterangepicker', 'td.available', $.proxy(this.hoverDate, this))
                .on('change.daterangepicker', 'select.yearselect', $.proxy(this.monthOrYearChanged, this))
                .on('change.daterangepicker', 'select.monthselect', $.proxy(this.monthOrYearChanged, this));

            this.container.find('.ranges')
                .on('click.daterangepicker', 'li', $.proxy(this.clickRange, this));

            this.container.find('.financial-years-list')
                .on('click.daterangepicker', 'li', $.proxy(this.selectFinancialRange, this));

            this.container.find('.drp-buttons')
                .on('click.daterangepicker', 'button.applyBtn', $.proxy(this.clickApply, this))
                .on('click.daterangepicker', 'button.cancelBtn', $.proxy(this.clickCancel, this));

            if (this.element.is('input') || this.element.is('button')) {
                this.element.on({
                    'click.daterangepicker': $.proxy(this.show, this),
                    'focus.daterangepicker': $.proxy(this.show, this),
                    'keyup.daterangepicker': $.proxy(this.elementChanged, this),
                    'keydown.daterangepicker': $.proxy(this.keydown, this)
                });
            } else {
                this.element.on('click.daterangepicker', $.proxy(this.toggle, this));
                this.element.on('keydown.daterangepicker', $.proxy(this.toggle, this));
            }

            //
            //  if attached to a text input, set the initial value
            //

            this.updateElement();

        };


        DateRangePicker.prototype = {

            constructor: DateRangePicker,

            setStartDate: function (startDate) {
                if (typeof startDate === 'string') {
                    this.startDate = moment(startDate, this.locale.format);
                }

                if (typeof startDate === 'object') {
                    this.startDate = moment(startDate);
                }

                if (!this.timePicker) {
                    this.startDate = this.startDate.startOf('day');
                }

                if (this.timePicker && this.timePickerIncrement) {
                    this.startDate.minute(Math.round(this.startDate.minute() / this.timePickerIncrement) * this.timePickerIncrement);
                }

                if (this.minDate && this.startDate.isBefore(this.minDate)) {
                    this.startDate = this.minDate.clone();
                    if (this.timePicker && this.timePickerIncrement) {
                        this.startDate.minute(Math.round(this.startDate.minute() / this.timePickerIncrement) * this.timePickerIncrement);
                    }
                }

                if (this.maxDate && this.startDate.isAfter(this.maxDate)) {
                    this.startDate = this.maxDate.clone();
                    if (this.timePicker && this.timePickerIncrement) {
                        this.startDate.minute(Math.floor(this.startDate.minute() / this.timePickerIncrement) * this.timePickerIncrement);
                    }
                }

                if (!this.isShowing) {
                    this.updateElement();
                }

                this.updateMonthsInView();
            },

            setEndDate: function (endDate) {
                if (typeof endDate === 'string') {
                    this.endDate = moment(endDate, this.locale.format);
                }

                if (typeof endDate === 'object') {
                    this.endDate = moment(endDate);
                }

                if (!this.timePicker) {
                    this.endDate = this.endDate.endOf('day');
                }

                if (this.timePicker && this.timePickerIncrement) {
                    this.endDate.minute(Math.round(this.endDate.minute() / this.timePickerIncrement) * this.timePickerIncrement);
                }

                if (this.endDate.isBefore(this.startDate)) {
                    this.endDate = this.startDate.clone();
                }

                if (this.maxDate && this.endDate.isAfter(this.maxDate)) {
                    this.endDate = this.maxDate.clone();
                }

                if (this.maxSpan && this.startDate.clone().add(this.maxSpan).isBefore(this.endDate)) {
                    this.endDate = this.startDate.clone().add(this.maxSpan);
                }

                this.previousRightTime = this.endDate.clone();

                this.container.find('.drp-selected').html(this.startDate.format(this.locale.format) +
                    this.locale.separator + this.endDate.format(this.locale.format));

                if (!this.isShowing) {
                    this.updateElement();
                }

                this.updateMonthsInView();
            },

            isInvalidDate: function () {
                return false;
            },

            isCustomDate: function () {
                return false;
            },

            updateView: function () {
                if (this.timePicker) {
                    this.renderTimePicker('left');
                    this.renderTimePicker('right');
                    if (!this.endDate) {
                        this.container.find('.right .calendar-time select').prop('disabled', true).addClass('disabled');
                    } else {
                        this.container.find('.right .calendar-time select').prop('disabled', false).removeClass('disabled');
                    }
                }
                if (this.endDate) {
                    this.container.find('.drp-selected').html(this.startDate.format(this.locale.format) +
                        this.locale.separator + this.endDate.format(this.locale.format));
                }
                this.updateMonthsInView();
                this.updateCalendars();
                this.updateFormInputs();
            },

            updateMonthsInView: function () {
                if (this.endDate) {

                    //  if both dates are visible already, do nothing
                    if (!this.singleDatePicker && this.leftCalendar.month && this.rightCalendar.month &&
                        (this.startDate.format('YYYY-MM') === this.leftCalendar.month.format('YYYY-MM') ||
                            this.startDate.format('YYYY-MM') === this.rightCalendar.month.format('YYYY-MM'))
                        &&
                        (this.endDate.format('YYYY-MM') === this.leftCalendar.month.format('YYYY-MM') ||
                            this.endDate.format('YYYY-MM') === this.rightCalendar.month.format('YYYY-MM'))
                    ) {
                        return;
                    }

                    this.leftCalendar.month = this.startDate.clone().date(2);
                    if (!this.linkedCalendars &&
                        (this.endDate.month() !== this.startDate.month() || this.endDate.year() !== this.startDate.year())) {
                        this.rightCalendar.month = this.endDate.clone().date(2);
                    } else {
                        this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
                    }

                } else {
                    if (this.leftCalendar.month.format('YYYY-MM') !== this.startDate.format('YYYY-MM') &&
                        this.rightCalendar.month.format('YYYY-MM') !== this.startDate.format('YYYY-MM')) {
                        this.leftCalendar.month = this.startDate.clone().date(2);
                        this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
                    }
                }
                if (this.maxDate && this.linkedCalendars && !this.singleDatePicker && this.rightCalendar.month > this.maxDate) {
                    this.rightCalendar.month = this.maxDate.clone().date(2);
                    this.leftCalendar.month = this.maxDate.clone().date(2).subtract(1, 'month');
                }
            },

            updateCalendars: function () {

                if (this.timePicker) {
                    let hour, minute, second;
                    if (this.endDate) {
                        hour = parseInt(this.container.find('.left .hourselect').val(), 10);
                        minute = parseInt(this.container.find('.left .minuteselect').val(), 10);
                        if (isNaN(minute)) {
                            minute = parseInt(this.container.find('.left .minuteselect option:last').val(), 10);
                        }
                        second = this.timePickerSeconds ? parseInt(this.container.find('.left .secondselect').val(), 10) : 0;
                        if (!this.timePicker24Hour) {
                            const ampm = this.container.find('.left .ampmselect').val();
                            if (ampm === 'PM' && hour < 12) {
                                hour += 12;
                            }
                            if (ampm === 'AM' && hour === 12) {
                                hour = 0;
                            }
                        }
                    } else {
                        hour = parseInt(this.container.find('.right .hourselect').val(), 10);
                        minute = parseInt(this.container.find('.right .minuteselect').val(), 10);
                        if (isNaN(minute)) {
                            minute = parseInt(this.container.find('.right .minuteselect option:last').val(), 10);
                        }
                        second = this.timePickerSeconds ? parseInt(this.container.find('.right .secondselect').val(), 10) : 0;
                        if (!this.timePicker24Hour) {
                            const ampm = this.container.find('.right .ampmselect').val();
                            if (ampm === 'PM' && hour < 12) {
                                hour += 12;
                            }
                            if (ampm === 'AM' && hour === 12) {
                                hour = 0;
                            }
                        }
                    }
                    this.leftCalendar.month.hour(hour).minute(minute).second(second);
                    this.rightCalendar.month.hour(hour).minute(minute).second(second);
                }

                this.renderCalendar('left');
                this.renderCalendar('right');

                //  highlight any predefined range matching the current start and end dates
                // this.container.find('.ranges li').removeClass('active');
                if (this.endDate === null) { return; }

                this.calculateChosenLabel();
            },

            renderCalendar: function (side) {

                //
                //  Build the matrix of dates that will populate the calendar
                //

                let calendar = side === 'left' ? this.leftCalendar : this.rightCalendar;
                const month = calendar.month.month();
                const year = calendar.month.year();
                const hour = calendar.month.hour();
                const minute = calendar.month.minute();
                const second = calendar.month.second();
                const daysInMonth = moment([year, month]).daysInMonth();
                const firstDay = moment([year, month, 1]);
                const lastDay = moment([year, month, daysInMonth]);
                const lastMonth = moment(firstDay).subtract(1, 'month').month();
                const lastYear = moment(firstDay).subtract(1, 'month').year();
                const daysInLastMonth = moment([lastYear, lastMonth]).daysInMonth();
                const dayOfWeek = firstDay.day();

                //  initialize a 6 rows x 7 columns array for the calendar
                calendar = [];
                calendar.firstDay = firstDay;
                calendar.lastDay = lastDay;

                for (let i = 0; i < 6; i++) {
                    calendar[i] = [];
                }

                // populate the calendar with date objects
                let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1;
                if (startDay > daysInLastMonth) {
                    startDay -= 7;
                }

                if (dayOfWeek === this.locale.firstDay) {
                    startDay = daysInLastMonth - 6;
                }

                let curDate = moment([lastYear, lastMonth, startDay, 12, minute, second]);

                for (let i = 0, col = 0, row = 0; i < 42; i++, col++, curDate = moment(curDate).add(24, 'hour')) {
                    if (i > 0 && col % 7 === 0) {
                        col = 0;
                        row++;
                    }
                    calendar[row][col] = curDate.clone().hour(hour).minute(minute).second(second);
                    curDate.hour(12);

                    if (this.minDate && calendar[row][col].format('YYYY-MM-DD') === this.minDate.format('YYYY-MM-DD') &&
                        calendar[row][col].isBefore(this.minDate) && side === 'left') {
                        calendar[row][col] = this.minDate.clone();
                    }
                    if (this.maxDate && calendar[row][col].format('YYYY-MM-DD') === this.maxDate.format('YYYY-MM-DD') &&
                        calendar[row][col].isAfter(this.maxDate) && side === 'right') {
                        calendar[row][col] = this.maxDate.clone();
                    }

                }

                //  make the calendar object available to hoverDate/clickDate
                if (side === 'left') {
                    this.leftCalendar.calendar = calendar;
                } else {
                    this.rightCalendar.calendar = calendar;
                }

                //
                //  Display the calendar
                //

                const minDate = side === 'left' ? this.minDate : this.startDate;
                let maxDate = this.maxDate;
                const selected = side === 'left' ? this.startDate : this.endDate;
                const arrow = this.locale.direction === 'ltr' ?
                    { left: 'chevron-left', right: 'chevron-right' } : { left: 'chevron-right', right: 'chevron-left' };

                let html = '<table class="table-condensed">';
                html += '<thead>';
                html += '<tr>';

                //  add empty cell for week number
                if (this.showWeekNumbers || this.showISOWeekNumbers) {
                    html += '<th></th>';
                }

                if ((!minDate || minDate.isBefore(calendar.firstDay)) && (!this.linkedCalendars || side === 'left')) {
                    html += '<th class="prev available"><span></span></th>';
                } else {
                    html += '<th></th>';
                }

                let dateHtml = this.locale.monthNames[calendar[1][1].month()] + calendar[1][1].format(' YYYY');

                if (this.showDropdowns) {
                    const currentMonth = calendar[1][1].month();
                    const currentYear = calendar[1][1].year();
                    const maxYear = (maxDate && maxDate.year()) || (this.maxYear);
                    const minYear = (minDate && minDate.year()) || (this.minYear);
                    const inMinYear = currentYear === minYear;
                    const inMaxYear = currentYear === maxYear;

                    let monthHtml = '<select class="monthselect">';
                    for (let m = 0; m < 12; m++) {
                        if ((!inMinYear || (minDate && m >= minDate.month())) && (!inMaxYear || (maxDate && m <= maxDate.month()))) {
                            monthHtml += '<option value=\'' + m + '\'' +
                                (m === currentMonth ? ' selected=\'selected\'' : '') +
                                '>' + this.locale.monthNames[m] + '</option>';
                        } else {
                            monthHtml += '<option value=\'' + m + '\'' +
                                (m === currentMonth ? ' selected=\'selected\'' : '') +
                                ' disabled=\'disabled\'>' + this.locale.monthNames[m] + '</option>';
                        }
                    }
                    monthHtml += '</select>';

                    let yearHtml = '<select class="yearselect">';
                    for (let y = minYear; y <= maxYear; y++) {
                        yearHtml += '<option value="' + y + '"' +
                            (y === currentYear ? ' selected="selected"' : '') +
                            '>' + y + '</option>';
                    }
                    yearHtml += '</select>';

                    dateHtml = monthHtml + yearHtml;
                }

                html += '<th colspan="5" class="month">' + dateHtml + '</th>';
                if ((!maxDate || maxDate.isAfter(calendar.lastDay)) &&
                    (!this.linkedCalendars || side === 'right' || this.singleDatePicker)) {
                    html += '<th class="next available"><span></span></th>';
                } else {
                    html += '<th></th>';
                }

                html += '</tr>';
                html += '<tr>';

                //  add week number label
                if (this.showWeekNumbers || this.showISOWeekNumbers) {
                    html += '<th class="week">' + this.locale.weekLabel + '</th>';
                }

                $.each(this.locale.daysOfWeek, function (index, dofWeek) {
                    html += '<th>' + dofWeek + '</th>';
                });

                html += '</tr>';
                html += '</thead>';
                html += '<tbody>';

                //  adjust maxDate to reflect the maxSpan setting in order to
                //  grey out end dates beyond the maxSpan
                if (this.endDate === null && this.maxSpan) {
                    const maxLimit = this.startDate.clone().add(this.maxSpan).endOf('day');
                    if (!maxDate || maxLimit.isBefore(maxDate)) {
                        maxDate = maxLimit;
                    }
                }

                for (let row = 0; row < 6; row++) {
                    html += '<tr>';

                    //  add week number
                    if (this.showWeekNumbers) {
                        html += '<td class="week">' + calendar[row][0].week() + '</td>';
                    } else if (this.showISOWeekNumbers) {
                        html += '<td class="week">' + calendar[row][0].isoWeek() + '</td>';
                    }

                    for (let col = 0; col < 7; col++) {

                        const classes = [];

                        //  highlight today's date
                        if (calendar[row][col].isSame(new Date(), 'day')) {
                            classes.push('today');
                        }

                        //  highlight weekends
                        if (calendar[row][col].isoWeekday() > 5) {
                            classes.push('weekend');
                        }

                        //  grey out the dates in other months displayed at beginning and end of this calendar
                        if (calendar[row][col].month() !== calendar[1][1].month()) {
                            classes.push('off', 'ends');
                        }

                        //  don't allow selection of dates before the minimum date
                        if (this.minDate && calendar[row][col].isBefore(this.minDate, 'day')) {
                            classes.push('off', 'disabled');
                        }

                        //  don't allow selection of dates after the maximum date
                        if (maxDate && calendar[row][col].isAfter(maxDate, 'day')) {
                            classes.push('off', 'disabled');
                        }

                        //  don't allow selection of date if a custom function decides it's invalid
                        if (this.isInvalidDate(calendar[row][col])) {
                            classes.push('off', 'disabled');
                        }

                        //  highlight the currently selected start date
                        if (calendar[row][col].format('YYYY-MM-DD') === this.startDate.format('YYYY-MM-DD')) {
                            classes.push('active', 'start-date');
                        }

                        //  highlight the currently selected end date
                        if (this.endDate !== null && calendar[row][col].format('YYYY-MM-DD') === this.endDate.format('YYYY-MM-DD')) {
                            classes.push('active', 'end-date');
                        }

                        //  highlight dates in-between the selected dates
                        if (this.endDate !== null && calendar[row][col] > this.startDate && calendar[row][col] < this.endDate) {
                            classes.push('in-range');
                        }

                        //  apply custom classes for this date
                        const isCustom = this.isCustomDate(calendar[row][col]);
                        if (isCustom !== false) {
                            if (typeof isCustom === 'string') {
                                classes.push(isCustom);
                            } else {
                                Array.prototype.push.apply(classes, isCustom);
                            }
                        }

                        let cname = '', disabled = false;
                        for (let i = 0; i < classes.length; i++) {
                            cname += classes[i] + ' ';
                            if (classes[i] === 'disabled') {
                                disabled = true;
                            }
                        }
                        if (!disabled) {
                            cname += 'available';
                        }

                        html += '<td class="' + cname.replace(/^\s+|\s+$/g, '') +
                            '" data-title="' + 'r' + row + 'c' + col + '">' + calendar[row][col].date() + '</td>';

                    }
                    html += '</tr>';
                }

                html += '</tbody>';
                html += '</table>';

                this.container.find('.drp-calendar.' + side + ' .calendar-table').html(html);

            },

            renderTimePicker: function (side) {

                //  Don't bother updating the time picker if it's currently disabled
                //  because an end date hasn't been clicked yet
                if (side === 'right' && !this.endDate) { return; }

                let html = '', selected, minDate, maxDate = this.maxDate;

                if (this.maxSpan && (!this.maxDate || this.startDate.clone().add(this.maxSpan).isBefore(this.maxDate))) {
                    maxDate = this.startDate.clone().add(this.maxSpan);
                }

                if (side === 'left') {
                    selected = this.startDate.clone();
                    minDate = this.minDate;
                } else if (side === 'right') {
                    selected = this.endDate.clone();
                    minDate = this.startDate;
                    //  Preserve the time already selected
                    const timeSelector = this.container.find('.drp-calendar.right .calendar-time');
                    if (timeSelector.html() !== '') {

                        selected.hour(!isNaN(selected.hour()) ?
                            selected.hour() : timeSelector.find('.hourselect option:selected').val());

                        selected.minute(!isNaN(selected.minute()) ?
                            selected.minute() : timeSelector.find('.minuteselect option:selected').val());

                        selected.second(!isNaN(selected.second()) ?
                            selected.second() : timeSelector.find('.secondselect option:selected').val());

                        if (!this.timePicker24Hour) {
                            const ampm = timeSelector.find('.ampmselect option:selected').val();
                            if (ampm === 'PM' && selected.hour() < 12) {
                                selected.hour(selected.hour() + 12);
                            }
                            if (ampm === 'AM' && selected.hour() === 12) {
                                selected.hour(0);
                            }
                        }

                    }

                    if (selected.isBefore(this.startDate)) {
                        selected = this.startDate.clone();
                    }

                    if (maxDate && selected.isAfter(maxDate)) {
                        selected = maxDate.clone();
                    }

                }

                //  hours
                //
                let h = 12;
                let m = 0;
                if (side === 'left') {
                    h = $($('.hourstxt')[0]).val() ? Number($($('.hourstxt')[0]).val()) : 12;
                    m = $($('.minstxt')[0]).val() ? Number($($('.minstxt')[0]).val()) : 0;
                } else {
                    h = $($('.hourstxt')[1]).val() ? Number($($('.hourstxt')[1]).val()) : 11;
                    m = $($('.minstxt')[1]).val() ? Number($($('.minstxt')[1]).val()) : 59;
                }

                // tslint:disable-next-line: max-line-length
                html += `<input class='chm-time-picker hourstxt' placeholder='_ _' type="number" min="1" max="12" value=${h > 9 ? h : '0' + h} onKeyUp="if (this.value > 12) { this.value = '12'; } else if (this.value < 1) { this.value = '01'; }">`;

                // tslint:disable-next-line: max-line-length
                html += ` : <input class='chm-time-picker minstxt' placeholder='_ _' type="number" value=${m > 9 ? m : '0' + m} min="0" max="59" onKeyUp="if(this.value>59){this.value='59';}else if(this.value<0){this.value='00';}">`;

                //  seconds

                if (this.timePickerSeconds) {
                    html += ': <select class="secondselect">';

                    for (let i = 0; i < 60; i++) {
                        const padded = i < 10 ? '0' + i : i;
                        const time = selected.clone().second(i);

                        let disabled = false;
                        if (minDate && time.isBefore(minDate)) {
                            disabled = true;
                        }
                        if (maxDate && time.isAfter(maxDate)) {
                            disabled = true;
                        }

                        if (selected.second() === i && !disabled) {
                            html += '<option value="' + i + '" selected="selected">' + padded + '</option>';
                        } else if (disabled) {
                            html += '<option value="' + i + '" disabled="disabled" class="disabled">' + padded + '</option>';
                        } else {
                            html += '<option value="' + i + '">' + padded + '</option>';
                        }
                    }

                    html += '</select> ';
                }

                //  AM/PM

                if (!this.timePicker24Hour) {
                    html += '<select class="ampmselect">';

                    let am_html = '';
                    let pm_html = '';

                    if (minDate && selected.clone().hour(12).minute(0).second(0).isBefore(minDate)) {
                        am_html = ' disabled="disabled" class="disabled"';
                    }

                    if (maxDate && selected.clone().hour(0).minute(0).second(0).isAfter(maxDate)) {
                        pm_html = ' disabled="disabled" class="disabled"';
                    }

                    if (side === 'left') {
                        if (!$($('.ampmselect')[0]).val() || $($('.ampmselect')[0]).val() === 'AM') {
                            html += '<option value="AM" selected="selected"' + am_html + '>AM</option><option value="PM"' + pm_html + '>PM</option>';
                        } else {
                            html += '<option value="AM"' + am_html + '>AM</option><option value="PM" selected="selected"' + pm_html + '>PM</option>';
                        }
                    } else {
                        if (!$($('.ampmselect')[1]).val() || $($('.ampmselect')[1]).val() === 'PM') {
                            html += '<option value="AM"' + am_html + '>AM</option><option value="PM" selected="selected"' + pm_html + '>PM</option>';
                        } else {
                            html += '<option value="AM" selected="selected"' + am_html + '>AM</option><option value="PM"' + pm_html + '>PM</option>';
                        }
                    }

                    html += '</select>';
                }

                this.container.find('.drp-calendar.' + side + ' .calendar-time').html(html);

            },

            updateFormInputs: function () {
                if (this.singleDatePicker || (this.endDate &&
                    (this.startDate.isBefore(this.endDate) || this.startDate.isSame(this.endDate)))) {
                    this.container.find('button.applyBtn').prop('disabled', false);
                } else {
                    this.container.find('button.applyBtn').prop('disabled', true);
                }

            },

            move: function () {
                let parentOffset = { top: 0, left: 0 },
                    containerTop;
                let parentRightEdge = $(window).width();
                if (!this.parentEl.is('body')) {
                    parentOffset = {
                        top: this.parentEl.offset().top - this.parentEl.scrollTop(),
                        left: this.parentEl.offset().left - this.parentEl.scrollLeft()
                    };
                    parentRightEdge = this.parentEl[0].clientWidth + this.parentEl.offset().left;
                }

                if (this.drops === 'up') {
                    containerTop = this.element.offset().top - this.container.outerHeight() - parentOffset.top;
                } else {
                    containerTop = this.element.offset().top + this.element.outerHeight() - parentOffset.top;
                }

                //  Force the container to it's actual width
                this.container.css({
                    top: 0,
                    left: 0,
                    right: 'auto'
                });
                const containerWidth = this.container.outerWidth();

                this.container[this.drops === 'up' ? 'addClass' : 'removeClass']('drop-up');

                if (this.opens === 'left') {
                    const containerRight = parentRightEdge - this.element.offset().left - this.element.outerWidth();
                    if (containerWidth + containerRight > $(window).width()) {
                        this.container.css({
                            top: containerTop,
                            right: 'auto',
                            left: 9
                        });
                    } else {
                        this.container.css({
                            top: containerTop,
                            right: containerRight,
                            left: 'auto'
                        });
                    }
                } else if (this.opens === 'center') {
                    const containerLeft = this.element.offset().left - parentOffset.left + this.element.outerWidth() / 2
                        - containerWidth / 2;
                    if (containerLeft < 0) {
                        this.container.css({
                            top: containerTop,
                            right: 'auto',
                            left: 9
                        });
                    } else if (containerLeft + containerWidth > $(window).width()) {
                        this.container.css({
                            top: containerTop,
                            left: 'auto',
                            right: 0
                        });
                    } else {
                        this.container.css({
                            top: containerTop,
                            left: containerLeft,
                            right: 'auto'
                        });
                    }
                } else {
                    const containerLeft = this.element.offset().left - parentOffset.left;
                    if (containerLeft + containerWidth > $(window).width()) {
                        this.container.css({
                            top: containerTop,
                            left: 'auto',
                            right: 0
                        });
                    } else {
                        this.container.css({
                            top: containerTop,
                            left: containerLeft,
                            right: 'auto'
                        });
                    }
                }
            },

            show: function (e) {

                if (this.isShowing) { return; }

                //  Create a click proxy that is private to this instance of datepicker, for unbinding
                this._outsideClickProxy = $.proxy(function (ev) { this.outsideClick(ev); }, this);

                //  Bind global datepicker mousedown for hiding and
                $(document)
                    .on('mousedown.daterangepicker', this._outsideClickProxy)
                    //  also support mobile devices
                    .on('touchend.daterangepicker', this._outsideClickProxy)
                    //  also explicitly play nice with Bootstrap dropdowns, which stopPropagation when clicking them
                    .on('click.daterangepicker', '[data-toggle=dropdown]', this._outsideClickProxy)
                    //  and also close when focus changes to outside the picker (eg. tabbing between controls)
                    .on('focusin.daterangepicker', this._outsideClickProxy);

                //  Reposition the picker if the window is resized while it's open
                $(window).on('resize.daterangepicker', $.proxy(function (evt) { this.move(evt); }, this));

                this.oldStartDate = this.startDate.clone();
                this.oldEndDate = this.endDate.clone();
                this.previousRightTime = this.endDate.clone();

                this.updateView();
                this.container.show();
                this.move();
                this.element.trigger('show.daterangepicker', this);
                this.isShowing = true;
            },

            hide: function (e) {
                if (!this.isShowing) { return; }

                //  incomplete date selection, revert to last values
                if (!this.endDate) {
                    this.startDate = this.oldStartDate.clone();
                    this.endDate = this.oldEndDate.clone();
                }

                //  if a new date range was selected, invoke the user callback function
                // Commented it to show the today date by default
                // if (!this.startDate.isSame(this.oldStartDate) || !this.endDate.isSame(this.oldEndDate)) {
                if (this.chosenLabel === this.locale.financialYearLabel) {
                    this.callback(
                        this.container.find('.financial-years-list li.active').attr('data-fy-start'),
                        this.container.find('.financial-years-list li.active').attr('data-fy-end'),
                        this.chosenLabel
                    );
                } else {
                    this.callback(this.startDate.clone(), this.endDate.clone(), this.chosenLabel);
                }
                // }

                //  if picker is attached to a text input, update it
                this.updateElement();

                $(document).off('.daterangepicker');
                $(window).off('.daterangepicker');
                this.container.hide();
                this.element.trigger('hide.daterangepicker', this);
                this.isShowing = false;
            },

            toggle: function (e) {
                if (this.isShowing) {
                    this.hide();
                } else {
                    this.show();
                }
            },

            outsideClick: function (e) {
                const target = $(e.target);
                //  if the page is clicked anywhere except within the daterangerpicker/button
                //  itself then call this.hide()
                if (
                    //  ie modal dialog fix
                    e.type === 'focusin' ||
                    target.closest(this.element).length ||
                    target.closest(this.container).length ||
                    target.closest('.calendar-table').length
                ) { return; }
                this.hide();

                if (self.formCtrl && !self.formCtrl.value) {
                    self.formCtrl.setValue('');
                    self.formCtrl.markAsTouched({ onlySelf: true });
                }
                return;
                // this.element.trigger('outsideClick.daterangepicker', this);
            },

            showCalendars: function () {
                this.container.addClass('show-calendar');
                this.move();
                this.element.trigger('showCalendar.daterangepicker', this);
            },

            hideCalendars: function () {
                this.container.removeClass('show-calendar');
                this.element.trigger('hideCalendar.daterangepicker', this);
            },

            clickRange: function (e) {
                const label = e.target.getAttribute('data-range-key');
                this.chosenLabel = label;
                $('.financial-years-list').css('visibility', 'hidden');
                $('.drp-calendar').css('visibility', 'hidden');
                for (let i = 0; i < this.container.find('.ranges li').length; i++) {
                    this.container.find('.ranges li:eq(' + i + ')').removeClass('active').attr('data-range-key');
                }
                if (label === this.locale.customRangeLabel) {
                    this.singleDatePicker = false;
                    e.target.classList.add('active');
                    $('.drp-calendar').css('visibility', 'visible');
                    $('.drp-calendar.right').css('display', 'block');
                    this.showCalendars();
                } else if (label === this.locale.fromDateLabel || label === this.locale.tillDateLabel) {
                    this.singleDatePicker = true;
                    this.endDate = this.startDate.clone();
                    this.updateView();
                    e.target.classList.add('active');
                    $('.drp-calendar.right').css('visibility', 'hidden');
                    $('.drp-calendar.left').css('visibility', 'visible');
                } else if (label === this.locale.financialYearLabel) {
                    e.target.classList.add('active');
                    $('.financial-years-list').css('visibility', 'visible');
                    $('.drp-calendar').css('visibility', 'hidden');
                } else {
                    e.target.classList.add('active');
                    const dates = this.ranges[label];
                    this.startDate = dates[0];
                    this.endDate = dates[1];

                    if (!this.timePicker) {
                        this.startDate.startOf('day');
                        this.endDate.endOf('day');
                    }

                    if (!this.alwaysShowCalendars) {
                        this.hideCalendars();
                    }
                    this.clickApply();
                }
            },

            selectFinancialRange: function (e) {
                for (let i = 0; i < this.container.find('.financial-years-list li').length; i++) {
                    this.container.find('.financial-years-list li:eq(' + i + ')').removeClass('active');
                }
                e.target.classList.add('active');
                this.clickApply();
            },

            clickPrev: function (e) {
                const cal = $(e.target).parents('.drp-calendar');
                if (cal.hasClass('left')) {
                    this.leftCalendar.month.subtract(1, 'month');
                    if (this.linkedCalendars) {
                        this.rightCalendar.month.subtract(1, 'month');
                    }
                } else {
                    this.rightCalendar.month.subtract(1, 'month');
                }
                this.updateCalendars();
            },

            clickNext: function (e) {
                const cal = $(e.target).parents('.drp-calendar');
                if (cal.hasClass('left')) {
                    this.leftCalendar.month.add(1, 'month');
                } else {
                    this.rightCalendar.month.add(1, 'month');
                    if (this.linkedCalendars) {
                        this.leftCalendar.month.add(1, 'month');
                    }
                }
                this.updateCalendars();
            },

            hoverDate: function (e) {

                // ignore dates that can't be selected
                if (!$(e.target).hasClass('available')) { return; }

                let title = $(e.target).attr('data-title');
                let row = title.substr(1, 1);
                let col = title.substr(3, 1);
                let cal = $(e.target).parents('.drp-calendar');
                const date = cal.hasClass('left') ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];

                // highlight the dates between the start date and the date being hovered as a potential end date
                const leftCalendar = this.leftCalendar;
                const rightCalendar = this.rightCalendar;
                const startDate = this.startDate;
                if (!this.endDate) {
                    this.container.find('.drp-calendar tbody td').each(function (index, el) {

                        // skip week numbers, only look at dates
                        if ($(el).hasClass('week')) { return; }

                        title = $(el).attr('data-title');
                        row = title.substr(1, 1);
                        col = title.substr(3, 1);
                        cal = $(el).parents('.drp-calendar');
                        const dt = cal.hasClass('left') ? leftCalendar.calendar[row][col] : rightCalendar.calendar[row][col];

                        if ((dt.isAfter(startDate) && dt.isBefore(date)) || dt.isSame(date, 'day')) {
                            $(el).addClass('in-range');
                        } else {
                            $(el).removeClass('in-range');
                        }

                    });
                }

            },

            clickDate: function (e) {

                if (!$(e.target).hasClass('available')) { return; }
                const activeItem = this.container.find('.ranges li.active').attr('data-range-key');
                for (let i = 0; i < this.container.find('.ranges li').length; i++) {
                    this.container.find('.ranges li:eq(' + i + ')').removeClass('active').attr('data-range-key');
                }
                $('.ranges li[data-range-key="' + activeItem + '"]').addClass('active');
                const title = $(e.target).attr('data-title');
                const row = title.substr(1, 1);
                const col = title.substr(3, 1);
                const cal = $(e.target).parents('.drp-calendar');
                let date = cal.hasClass('left') ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];

                //  this function needs to do a few things:
                //  * alternate between selecting a start and end date for the range,
                //  * if the time picker is enabled, apply the hour/minute/second from the select boxes to the clicked date
                //  * if autoapply is enabled, and an end date was chosen, apply the selection
                //  * if single date picker mode, and time picker isn't enabled, apply the selection immediately
                //  * if one of the inputs above the calendars was focused, cancel that manual input

                if (this.endDate || date.isBefore(this.startDate, 'day')) { // picking start
                    if (this.timePicker) {
                        let hour = parseInt(this.container.find('.left .hourselect').val(), 10);
                        if (!this.timePicker24Hour) {
                            const ampm = this.container.find('.left .ampmselect').val();
                            if (ampm === 'PM' && hour < 12) {
                                hour += 12;
                            }
                            if (ampm === 'AM' && hour === 12) {
                                hour = 0;
                            }
                        }
                        let minute = parseInt(this.container.find('.left .minuteselect').val(), 10);
                        if (isNaN(minute)) {
                            minute = parseInt(this.container.find('.left .minuteselect option:last').val(), 10);
                        }
                        const second = this.timePickerSeconds ? parseInt(this.container.find('.left .secondselect').val(), 10) : 0;
                        date = date.clone().hour(hour).minute(minute).second(second);
                    }
                    this.endDate = null;
                    this.setStartDate(date.clone());
                } else if (!this.endDate && date.isBefore(this.startDate)) {
                    // special case: clicking the same date for start/end,
                    // but the time of the end date is before the start date
                    this.setEndDate(this.startDate.clone());
                } else { //  picking end
                    if (this.timePicker) {
                        let hour = parseInt(this.container.find('.right .hourselect').val(), 10);
                        if (!this.timePicker24Hour) {
                            const ampm = this.container.find('.right .ampmselect').val();
                            if (ampm === 'PM' && hour < 12) {
                                hour += 12;
                            }
                            if (ampm === 'AM' && hour === 12) {
                                hour = 0;
                            }
                        }
                        let minute = parseInt(this.container.find('.right .minuteselect').val(), 10);
                        if (isNaN(minute)) {
                            minute = parseInt(this.container.find('.right .minuteselect option:last').val(), 10);
                        }
                        const second = this.timePickerSeconds ? parseInt(this.container.find('.right .secondselect').val(), 10) : 0;
                        date = date.clone().hour(hour).minute(minute).second(second);
                    }
                    this.setEndDate(date.clone());
                    if (this.autoApply) {
                        this.calculateChosenLabel();
                        this.clickApply();
                    }
                }

                if (this.singleDatePicker) {
                    this.setEndDate(this.startDate);
                    if (!this.timePicker) {
                        this.clickApply();
                    }
                }

                this.updateView();

                // This is to cancel the blur event handler if the mouse was in one of the inputs
                e.stopPropagation();

            },

            calculateChosenLabel: function () {
                let customRange = true;
                let i = 0;
                for (const range in this.ranges) {
                    if (range) {
                        if (!this.chosenLabel || this.chosenLabel === range) {
                            if (this.timePicker) {
                                const format = this.timePickerSeconds ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm';
                                // ignore times when comparing dates if time picker seconds is not enabled
                                if (this.startDate.format(format) === this.ranges[range][0].format(format) &&
                                    this.endDate.format(format) === this.ranges[range][1].format(format)) {
                                    customRange = false;
                                    this.chosenLabel = this.container.find('.ranges li:eq(' + i + ')').addClass('active').attr('data-range-key');
                                    break;
                                }
                            } else {
                                // ignore times when comparing dates if time picker is not enabled
                                if (this.startDate.format('YYYY-MM-DD') === this.ranges[range][0].format('YYYY-MM-DD') &&
                                    this.endDate.format('YYYY-MM-DD') === this.ranges[range][1].format('YYYY-MM-DD')) {
                                    customRange = false;
                                    this.chosenLabel = this.container.find('.ranges li:eq(' + i + ')').addClass('active').attr('data-range-key');
                                    break;
                                }
                            }
                        }
                        i++;
                    }
                }
                if (this.chosenLabel === this.locale.customRangeLabel) {
                    if (this.showCustomRangeLabel) {
                        this.chosenLabel = this.container.find('.ranges li:last').addClass('active').attr('data-range-key');
                    } else {
                        this.chosenLabel = null;
                    }
                    this.showCalendars();
                }
                if (this.chosenLabel === this.locale.financialYearLabel) {
                    if (this.showfinancialYearLabel) {
                        this.chosenLabel = this.container.find('.ranges li.financial-year-li').addClass('active').attr('data-range-key');
                        $('.financial-years-list').css('visibility', 'visible');
                    } else {
                        this.chosenLabel = null;
                    }
                }
            },

            clickApply: function (e) {
                this.hide();
                this.element.trigger('apply.daterangepicker', this);
            },

            clickCancel: function (e) {
                this.startDate = this.oldStartDate;
                this.endDate = this.oldEndDate;
                this.hide();
                this.element.trigger('cancel.daterangepicker', this);
            },

            monthOrYearChanged: function (e) {
                const isLeft = $(e.target).closest('.drp-calendar').hasClass('left'),
                    leftOrRight = isLeft ? 'left' : 'right',
                    cal = this.container.find('.drp-calendar.' + leftOrRight);

                //  Month must be Number for new moment versions
                let month = parseInt(cal.find('.monthselect').val(), 10);
                let year = cal.find('.yearselect').val();

                if (!isLeft) {
                    if (year < this.startDate.year() || (year === this.startDate.year() && month < this.startDate.month())) {
                        month = this.startDate.month();
                        year = this.startDate.year();
                    }
                }

                if (this.minDate) {
                    if (year < this.minDate.year() || (year === this.minDate.year() && month < this.minDate.month())) {
                        month = this.minDate.month();
                        year = this.minDate.year();
                    }
                }

                if (this.maxDate) {
                    if (year > this.maxDate.year() || (year === this.maxDate.year() && month > this.maxDate.month())) {
                        month = this.maxDate.month();
                        year = this.maxDate.year();
                    }
                }

                if (isLeft) {
                    this.leftCalendar.month.month(month).year(year);
                    if (this.linkedCalendars) {
                        this.rightCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
                    }
                } else {
                    this.rightCalendar.month.month(month).year(year);
                    if (this.linkedCalendars) {
                        this.leftCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');
                    }
                }
                this.updateCalendars();
            },

            elementChanged: function () {
                if (!this.element.is('input')) { return; }
                if (!this.element.val().length) { return; }

                const dateString = this.element.val().split(this.locale.separator);
                let start = null;
                let end = null;

                if (dateString.length === 2) {
                    start = moment(dateString[0], this.locale.format);
                    end = moment(dateString[1], this.locale.format);
                }

                if (this.singleDatePicker || start === null || end === null) {
                    start = moment(this.element.val(), this.locale.format);
                    end = start;
                }

                if (!start.isValid() || !end.isValid()) { return; }

                this.setStartDate(start);
                this.setEndDate(end);
                this.updateView();
            },

            keydown: function (e) {
                // hide on tab or enter
                if ((e.keyCode === 9) || (e.keyCode === 13)) {
                    this.hide();
                }

                // hide on esc and prevent propagation
                if (e.keyCode === 27) {
                    e.preventDefault();
                    e.stopPropagation();

                    this.hide();
                }
            },

            updateElement: function () {
                if (this.element.is('input') && this.autoUpdateInput) {
                    let newValue = this.startDate.format(this.locale.format);
                    if (!this.singleDatePicker) {
                        newValue += this.locale.separator + this.endDate.format(this.locale.format);
                    }
                    if (newValue !== this.element.val()) {
                        this.element.val(newValue).trigger('change');
                    }
                }
            },

            remove: function () {
                this.container.remove();
                this.element.off('.daterangepicker');
                this.element.removeData();
            }

        };

        $.fn['daterangepicker'] = function (options, callback) {
            const implementOptions = $.extend(true, {}, $.fn['daterangepicker'].defaultOptions, options);
            this.each(function () {
                const el = $(this);
                if (el.data('daterangepicker')) {
                    el.data('daterangepicker').remove();
                }
                el.data('daterangepicker', new DateRangePicker(el, implementOptions, callback));
            });
            return this;
        };

        return DateRangePicker;
    }

}
