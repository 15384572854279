import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { Principal } from '../../../shared';
import { SnBToasterService } from '../../../shared/components/toaster/toaster.service';
import { BaPageTitleService } from '../../../theme/services/baMenu/baMenu.service';
import { PaymentService } from '../payment.service';
import { ApplicationCounter } from './application-counter.model';
import { WorkflowService } from '../../workflows/workflows.service';
import { ApplicationTypes, CompletedApplicationTypes } from '../../applications/application-types';

@Component({
    selector: "app-transaction-details",
    templateUrl: "./transaction-detail.component.html",
    styleUrls: ["./transaction-detail.scss"],
})
export class PaymentTransactionDetailsComponent implements OnInit {
    displayedColumns = ["chitNo", "BranchName", "formName", "amount"];
    id: any;
    transaction: any = {};
    isRegulatorSuperAdmin = false;
    isCustSupport = false;
    printsList = [];
    otherDetails: any;
    taskDetails: any;
    pendingApplicationTypes: any;
    completedApplicationTypes: any;
    pendingApiCallCounter = 0;
    completedApiCallCounter = 0;
    psoList: ApplicationCounter;
    agreementList: ApplicationCounter;
    ccbList: ApplicationCounter;
    form7List: ApplicationCounter;
    minutesList: ApplicationCounter;
    transferList: ApplicationCounter;
    releaseList: ApplicationCounter;
    financialList: ApplicationCounter;
    miscList: ApplicationCounter;

    constructor(
        private pageTitleService: BaPageTitleService,
        private activatedRoute: ActivatedRoute ,
        private paymentService: PaymentService,
        private principalService: Principal,
        private toasterService: SnBToasterService,
        private translateService: TranslateService,
        private workflowService: WorkflowService,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.id = params.id;
        });
        
        this.printsList = [
            {
                printFormName: "Transaction Details",
                printFormId: "customTransactionPrint",
            },
        ];
        this.pendingApplicationTypes = ApplicationTypes;
        this.completedApplicationTypes = CompletedApplicationTypes;
    }

    ngOnInit() {
        this.pageTitleService.currentMessage.subscribe();
        this.principalService
            .hasAuthority("ROLE_REGULATOR_SUPER_ADMIN")
            .then((res) => (this.isRegulatorSuperAdmin = res));
        this.principalService
            .hasAuthority("ROLE_CUST_SUPPORT")
            .then((res) => (this.isCustSupport = res));
        this.pageTitleService.changeMessage("Payment > Transactions Details");
        this.getPaymentDetails();
    }

    initCounters() {
        this.psoList = new ApplicationCounter(0, 0);
        this.agreementList = new ApplicationCounter(0, 0);
        this.ccbList = new ApplicationCounter(0, 0);
        this.form7List = new ApplicationCounter(0, 0);
        this.minutesList = new ApplicationCounter(0, 0);
        this.transferList = new ApplicationCounter(0, 0);
        this.releaseList = new ApplicationCounter(0, 0);
        this.financialList = new ApplicationCounter(0, 0);
        this.miscList = new ApplicationCounter(0, 0);
    }

    getPaymentDetails() {
        this.initCounters();
        this.paymentService.getTransactionDetails(this.id).subscribe((res) => {
            this.transaction = res;
            this.otherDetails = res["items"];
            const itemsList = res["items"];
            for (let i = 0; i < itemsList.length; i++) {
                if (!itemsList[i].workFlowFormName) {
                    return;
                }
                if (itemsList[i].workFlowFormName === "PSO") {
                    this.psoList.count += 1;
                    this.psoList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "CAR") {
                    this.agreementList.count += 1;
                    this.agreementList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "CCB") {
                    this.ccbList.count += 1;
                    this.ccbList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "Form7") {
                    this.form7List.count += 1;
                    this.form7List.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "MinutesFiling") {
                    this.minutesList.count += 1;
                    this.minutesList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "ChitTransfers") {
                    this.transferList.count += 1;
                    this.transferList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "Release") {
                    this.releaseList.count += 1;
                    this.releaseList.applicationFee += itemsList[i].amount;
                } else if (itemsList[i].workFlowFormName === "Financial Data") {
                    this.financialList.count += 1;
                    this.financialList.applicationFee += itemsList[i].amount;
                } else {
                    this.miscList.count += 1;
                    this.miscList.applicationFee += itemsList[i].amount;
                }
            }

        });
    }

    reconcileTransaction(transaction) {
        this.paymentService.refreshPaymentService(transaction).subscribe(
            (res: any) => {
                if (res.status === 200) {
                    this.getPaymentDetails();
                    this.toasterService.showSuccessToaster(
                        this.translateService.instant(
                            "chitmonksApiApp.transaction.reconcile.success"
                        )
                    );
                }
            },
            (fail) => {
                console.log(fail);
            }
        );
    }

    /**
     * 
     * @param processInstanceId 
     * This method will call the task api based on the trnx period 
     */

    handleRouting (processInstanceId) {
        // Convert trnxDate to a JavaScript Date object
        const trnxDate = new Date(this.transaction.trnxDate * 1000);
    
        // Get today's date
        const today = new Date();
    
        // Strip the time portion to compare only dates by creating date objects with no time
        const trnxDateOnly = new Date(trnxDate.getFullYear(), trnxDate.getMonth(), trnxDate.getDate());
        const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        console.log(trnxDateOnly);
    
        // Get the time values (in milliseconds) to perform arithmetic
        const trnxTime = trnxDateOnly.getTime();
        const todayTime = todayDateOnly.getTime();
    
        // Calculate the difference in days
        const timeDifference = todayTime - trnxTime; // Difference in milliseconds
        const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days
    
        // Check if trnxDate is more than 15 days ago
        if (dayDifference > 15) {
            this.getCompletedTaskDetails(processInstanceId, true); 
            console.log("Transaction date is more than 15 days");
        } else {
            this.getPendingTaskDetails(processInstanceId, true);
            console.log("Transaction date is less than 15 days");
        }
    }
    
    /**
     * 
     * @param processInstanceId 
     * @param tryCompletedProcessIfActiveProcNotFound 
     * This method will get the data of the task details based on the processInstanceId
     */
    getPendingTaskDetails(processInstanceId, tryCompletedProcessIfActiveProcNotFound) {
        const reqBody = {
            processInstanceId: processInstanceId
        };
        this.workflowService.getPendingWorkflowList({}, reqBody).subscribe((res: any) => {
            if (res && res.body.length) {
                this.taskDetails = res.body[0];
                this.router.navigate(
                    [
                        "/pages/" +
                        this.pendingApplicationTypes[this.taskDetails.activeTask.taskDefKey].clickRoute,
                    ],
                    {
                        queryParams: { id: this.taskDetails.activeTask.taskId },
                    }
                );
            } else if (tryCompletedProcessIfActiveProcNotFound) {
                this.getCompletedTaskDetails(processInstanceId, false); 
            } else {
                // If both attempts have failed
                this.toasterService.showFailToaster('Application not found. Please contact support team');
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }
    
    /**
     * 
     * @param processInstanceId 
     * @param tryActiveProcessIfCompletedProcNotFound 
     * This method will get the data of the task details based on the processInstanceId
     */
    getCompletedTaskDetails(processInstanceId, tryActiveProcessIfCompletedProcNotFound) {
        const req = {
            processInstanceId: processInstanceId
        };
        this.workflowService.getApprovedWorkflowList(req).subscribe((res: any) => {
            if (res && res.body.length) {
                this.taskDetails = res.body[0];
                this.router.navigate(
                    [
                        "/pages/" +
                        this.completedApplicationTypes[this.taskDetails.processDefKey].clickRoute,
                    ],
                    {
                        queryParams: {
                            id: this.taskDetails.processInstanceId,
                            type: this.taskDetails.processVariables.transferType,
                            status: "closed",
                        },
                    }
                );
            } else if (tryActiveProcessIfCompletedProcNotFound) {
                this.getPendingTaskDetails(processInstanceId, false); 
            } else {
                // If both attempts have failed
                this.toasterService.showFailToaster('Application not found. Please contact support team');
            }
        }, fail => {
            if (fail) {
                this.toasterService.showFailToaster(fail);
            }
        });
    }
    
    
}
